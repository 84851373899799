import {createAction, props} from '@ngrx/store';

export const updateTemplate = createAction(
  '[Export] Setting the active template',
  props<{template: any}>(),
);

export const updateMetadata = createAction('[Export] meta data updated', props<{metaData: any}>());

export const minimizeExportSettings = createAction('[Export] Export Settings Minimize');

export const maximizeExportSettings = createAction('[Export] Export Settings Maximize');

export const toggleExportSettings = createAction('[Export] Export Settings Toggle');

export const minimizeExportDca = createAction('[Export] Export Dca Minimize');

export const maximizeExportDca = createAction('[Export] Export Dca Maximize');
