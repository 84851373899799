export interface References {
    /**
     * List of references in the reference list (may be html).
     */
    references: {
        /** The reference string (including em/i/strong) */
        htmlContent: string;

        /** The reference as CSL JSON (citation style language) */
        csl: object;

        /** The reference number in case of numeric reference styles */
        referenceNumber: string;


        /** The ID of the html element the reference was found in (or the id of the heading) */
        htmlElId: string;
    }[];
}
