import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SfoUiJSONSchema7 } from '../../../lib/metadata.model';

@Component({
  selector: 'sfo-code-editor',
  templateUrl: './code-editor.component.html',
})
export class CodeEditorComponent implements OnInit {
  @Input() schema: SfoUiJSONSchema7;
  @Input() aFormControl: FormControl;
  @Input() defaultValue: unknown;

  ngOnInit(): void {
    const currentValue = this.aFormControl.value;

    if (currentValue === null || currentValue === undefined) {
      const defaultValue = this.defaultValue ?? this.schema?.default;
      if (defaultValue !== undefined) {
        this.aFormControl.setValue(defaultValue, {emitEvent: false});
      }
    }
  }
}