<!-- <div class="editor-bottom-sheet"> -->
<h2 class="h4 mb1" mat-dialog-title>Footnote</h2>
<mat-dialog-content>
    <sfo-editor [editorId]="{ id: 'footnote-id', projectId: '' }" schema="inline" hideModals=true [content]="data.node"
    (change)="updateDocument($event)"></sfo-editor>
    <button (click)="close()" mat-button class="uppercase right">close</button>
</mat-dialog-content>

<!-- </div> -->




