<div class="mt2">
  <mat-expansion-panel
    [expanded]="expanded"
    hideToggle
    [ngClass]="preset"
    [disabled]="hideToggle"
    aria-labelledby="admonition-title"
    [attr.aria-expanded]="expanded"
  >
    <mat-expansion-panel-header>
      <mat-panel-title [matTooltip]="title || ''">
        <mat-icon
          *ngIf="computedIcon"
          class="admonition-icon"
          aria-hidden="true"
          aria-label="Toggle panel"
          role="img"
        >
          {{ computedIcon }}
        </mat-icon>
        {{ title }}
      </mat-panel-title>
      <mat-icon *ngIf="!hideToggle" class="header-toggle">chevron_right</mat-icon>
    </mat-expansion-panel-header>
    <div
      id="'content-' + title"
      class="admonition-content"
      role="region"
      [attr.aria-labelledby]="'admonition-title'"
    >
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</div>
