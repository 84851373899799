<mat-form-field floatLabel="always" appearance="fill" class="col-12" subscriptSizing="dynamic">
  <mat-label>{{ schema.title }} </mat-label>

  <textarea
    class="col-12"
    matInput
    [formControl]="aFormControl"
    [placeholder]="schema.minLength !== undefined ? '' : (schema.default || '').toString()"
    rows="10"
  ></textarea>

  <mat-hint *ngIf="schema.description">
    {{schema.description}}
  </mat-hint>

  <div *ngIf="aFormControl.invalid && (aFormControl.dirty || aFormControl.touched)">
    <div *ngIf="aFormControl.errors?.required">This field is required.</div>
    <div *ngIf="aFormControl.errors?.minlength">Minimum length is not met.</div>
    <div *ngIf="aFormControl.errors?.maxlength">Maximum length exceeded.</div>
  </div>
</mat-form-field>