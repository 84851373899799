import {Action, createReducer, createSelector, on, ActionReducer} from '@ngrx/store';
import * as editorActions from './editor.actions';

export const editorFeatureKey = 'editor';

export interface EditorState {
  instances: {
    id: string;
    key: string;
    projectId: string;
    version: string;
    elements: any[];
    state: any;
    lastModified?: number;
  }[];
  selection?: any;
  dirty?: boolean;
  imageResources: any[];
}

const initialState: EditorState = {
  instances: [],
  selection: undefined,
  dirty: false,
  imageResources: [],
};

const editorReducer = createReducer(
  initialState,
  on(editorActions.registerInstance, (state, action) => ({
    ...state,
    instances: [
      ...state.instances,
      {
        id: action.id,
        key: action.key,
        projectId: action.projectId,
        lastModified: action.lastModified,
        version: action.version,
        elements: [],
        state: action.state,
      },
    ],
  })),
  on(editorActions.replaceFile, (state, action) => ({
    ...state,
    dirty: true,
    instances: state.instances.map((instance) => {
      console.log('updating instance with file', action, instance);
      if (instance.id === action.id) {
        return {
          ...instance,
          state: {
            ...instance.state,
            files: [
              ...instance.state.files?.filter((f) => f.id !== action.file?.id),
              action.file,
            ].filter((f) => f != null),
          },
        };
      }
      return instance;
    }),
  })),
  on(editorActions.updateState, (state, action) => ({
    ...state,
    selection: action.selection,
    instances: state.instances.map((instance) => {
      if (instance.id === action.id) {
        return {
          ...instance,
          elements: action.elements,
          state: {
            ...instance.state,
            ...action.state,
          },
        };
      }
      return instance;
    }),
  })),
  on(editorActions.removeAllInstances, (state) => ({...state, instances: []})),
  on(editorActions.removeInstance, (state, action) => ({
    ...state,
    instances: state.instances.filter((instance) => instance.id !== action.id),
  })),
  on(editorActions.updateDocumentState, (state, action) => ({...state, dirty: action.dirty})),
  on(editorActions.updateImageResources, (state, action) => ({
    ...state,
    imageResources: action.imageResources,
  })),
);

export const selectEditorState = (state): EditorState => state.editor;

export const selectInstances = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances,
);

export const selectFootnoteActive = createSelector(selectEditorState, (state: EditorState) =>
  state.instances.some((instance) => 'footnote-id' === instance.id),
);

export const selectActiveInstance = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances[0],
);

export const selectDocumentStateIsDirty = createSelector(
  selectEditorState,
  (state: EditorState) => state.dirty,
);

/**
 * Selects an instance of the editor (there may be multiple editors at the same time)
 */
export const selectInstance = (id: string) =>
  createSelector(selectEditorState, (state: EditorState) =>
    state.instances.find((instance) => id === instance.id),
  );

/**
 * Gets the current selection.
 */
export const selectSelection = createSelector(
  selectEditorState,
  (state: EditorState) => state.selection,
);

/**
 * Watches the selection for a specific element (e.g. heading).
 */
export const selectElement = (id: string) =>
  createSelector(selectEditorState, (state: EditorState) => {
    const instances: any[][] = state.instances.map((instance) =>
      instance.elements
        .filter((element) => element.id === id)
        .map((element) => ({
          ...element,
          instanceId: instance.id,
          selected: state.selection === element.id,
        })),
    );

    const elements = instances.flat();
    return elements.length > 0 ? elements[0] : null;
  });

export const selectFiles = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances[0]?.state.files,
);

/**
 * Gets the currently selected elements (e.g. heading).
 */
export const selectElements = createSelector(selectEditorState, (state: EditorState) => {
  const instances: any[][] = state.instances.map((instance) =>
    instance.elements.map((element) => ({
      ...element,
      instanceId: instance.id,
      selected: state.selection === element.id,
    })),
  );

  return instances.flat();
});

export const reducer: ActionReducer<EditorState, any> = (
  state: EditorState | undefined,
  action: Action,
) => {
  return editorReducer(state, action);
};
