import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExportComponent} from './export/export.component';
import {ExportSettingsComponent} from './export/export-settings/export-settings.component';

const routes: Routes = [
  {path: '', component: ExportComponent},
  {
    path: ':template',
    component: ExportComponent,
    children: [
      {
        path: 'setting',
        component: ExportSettingsComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExportRoutingModule {}
