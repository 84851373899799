import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { selectActiveEditor } from 'projects/reference-app/src/app/app-state.reducer';
import { take } from 'rxjs/operators';
import { fileUploaded } from './shared.store';
import { firstValueFrom, timer } from 'rxjs';
import { WindowRef } from 'projects/author-management/src/lib/WindowRef';
import { PluginStoreService } from 'projects/reference-app/src/editor/shared/services/plugin.service';
import { StatusState } from 'projects/reference-app/src/editor/shared/models/state.enum';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private pluginService: PluginStoreService,
    private apollo: Apollo,
    private snackBar: MatSnackBar,
    private store: Store,
    private windowRef: WindowRef,
  ) { }

  public static getFileIdFromName(documentName: string): string {
    return documentName
      .split('.')
      .slice(0, documentName.split('.').length - 1)
      .join('-')
      .replace(' ', '-')
      .toLowerCase();
  }

  /**
   * Reads an image from the original upload (e.g. docx) and re-uploads it
   * @deprecated Use the asset service
   */
  async extractAssets(
    projectId: string,
    documentName: string,
    assets: { key: string; id: string }[],
  ): Promise<{ files: { id: string; type: 'image'; url: string }[]; results: any[] }> {
    const files: any[] = [];
    let fileNumber = 0;
    let results: any[] = [];
    for (let asset of assets) {
      console.log(asset);

      fileNumber++;
      // we get the file from the server and then upload it there.
      // this could include a preview component / cropping component and other checks (otherwise we could have had processed the files on the server directly)
      // we do not do this to keep the server side simple (even though this has massive performance / network implications).
      // Since this is the initial import, we accept that

      // import files are usually extracted from the original file (e.g. DOCX/ZIP/..)
      const fileUrl = `/import/extract-asset/${projectId}/${documentName}/${encodeURIComponent(asset.key)}`; // being explicit with media here

      let retries = 0;
      const maxRetries = 3;

      // TODO extract asset.stats
      while (retries < maxRetries) {
        this.pluginService.updateAssetStatus(asset.id, StatusState.Processing);

        const file = await fetch(fileUrl);

        if (file.status === 200) {
          const blob = await file.arrayBuffer();
          let name = asset.key;
          if (name.endsWith('.emf') || name.endsWith('.wmf')) {
            name = name.replace('.emf', '.png').replace('.wmf', '.png');
          }
          // we prefix with the document name to upload to a unique path inside the project
          let fileName = encodeURIComponent(
            FileService.getFileIdFromName(documentName) + '/' + name,
          );

          // TODO (PETER): Remove push to snack bar - should rather get the asset from plugin service and then update the status
          // this.snackBar.open(
          //   `(${fileNumber}/${assets.length}) Processing ${fileName} (${blob.byteLength})  (attempt ${retries}/3)`,
          // );

          const result = await this.uploadFile(
            projectId,
            new File([blob], fileName),
            `(${fileNumber}/${assets.length})`,
          );
          // TODO add absolute URL here
          // we keep the original id, since that's whats referenced in the document
          const url = `/export/asset/${projectId}/${fileName}`;
          files.push({
            id: asset.id || asset.key?.replaceAll('/', '_'),
            type: 'image',
            name: result.name || asset.key, // use the new key
            url,
          });

          console.info(`Uploaded ${result.name} to ${this.windowRef.origin}${url}`);
          results.push({
            id: asset.key?.replaceAll('/', '_'),
            key: asset.key,
            fileName,
            name: asset.key,
            success: true,
            retry: retries,
          });

          this.pluginService.updateAssetStatus(asset.id, StatusState.Success);
          break;
        } else {
          // we do not push missing files
          // files.push({ id: asset.key, name: undefined, url: undefined });
          console.error('Could not retrieve asset: ' + file.statusText, { asset, fileUrl, retries });
          // this.snackBar.open('Could not process ' + asset.key);
          // backoff a bit
          await new Promise((resolve) => setTimeout(resolve, 1000 * retries)); // TODO (PETER): check if this is still needed
          retries++;

          if (retries === maxRetries) {
            this.pluginService.updateAssetStatus(asset.id, StatusState.Error);
          }
        }
      }

      if (retries === maxRetries) {
        results.push({ key: asset.key, success: false });
      }
    }

    return { files, results };
  }

  /** Upload a file to the project */
  async uploadFile(projectId: string, file: File, message?: string) {
    const instance = await this.store.select(selectActiveEditor).pipe(take(1)).toPromise();
    if (!projectId && instance?.projectId) {
      projectId = instance.projectId;
    }
    if (!projectId) {
      throw new Error('Project ID must be provided');
    }

    console.log('Uploading file', projectId, file.name, file);
    
    let ref;
    if (message) {
      ref = this.snackBar.open(' 💾  Saving file to server ...');
    }
    const uploadResult: any = await this.apollo
      .mutate({
        mutation: gql`mutation Upload($file: Upload!, $projectId: String) {
        uploadFile(input: { file: $file, projectId: $projectId }) {
          key
          name
          projectId
          version {
            VersionId
            ETag
          }
        }
      }`,
        variables: { file, projectId },
      })
      .toPromise();

    ref?.dismiss();

    const result = uploadResult?.data?.uploadFile;
    if (message) {
      this.snackBar.open((message ? `${message} ` : '') + 'Saved file as ' + result?.key, undefined, {
        duration: 2000,
      });
    }
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    this.store.dispatch(fileUploaded({ projectId, name: file.name, extension: ext }));
    return result;
  }

  async getDocument(projectId, documentName, processCitations = true, events?: string[]) {
    let documentResult: any;
    try {
      documentResult = await this.apollo
        .query({
          query: gql`query GetPreview($key: String!, $version: String, $processCitations: Boolean, $events: [String]) {
        document(key: $key, version: $version, processCitations: $processCitations, events: $events) {
          key
          logs
          manuscript {
            document
            authors
            metaData
            references
            lastModified
            template
            assets
            files {
              id
              type
              url
            }
          }
        }
      }`,
          variables: {
            key: `${projectId}/${documentName}`,
            processCitations,
            events
          },
        })
        .toPromise();
    } catch (e: any) {
      return { key: `${projectId}/${documentName}`, errors: e.graphQLErrors };
    }

    const { key, preview } = documentResult.data?.document;

    return {
      key,
      ...documentResult.data?.document
    };
  }
}
