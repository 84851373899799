import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EditorView } from 'prosemirror-view';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  currentView = new BehaviorSubject<EditorView | null>(null);

  constructor(){}

  changeView(view) {
    this.currentView.next(view);
  }
}
