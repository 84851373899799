import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatOptionModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {SfoDynamicFormModule} from '../../../ui-components/src';
import {ExportRoutingModule} from './export-routing.module';
import {ExportSettingsComponent} from './export/export-settings/export-settings.component';
import {ExportComponent} from './export/export.component';
import {ExportEffects} from './store/export.effects';
import {exportFeatureKey, exportMetadataReducer} from './store/export.reducer';

@NgModule({
  declarations: [ExportComponent, ExportSettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ExportRoutingModule,
    MatInputModule,
    MatProgressBarModule,
    MatMenuModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    StoreModule.forFeature(exportFeatureKey, exportMetadataReducer),
    EffectsModule.forFeature(ExportEffects),
    SfoDynamicFormModule,
    MatButtonToggleModule,
  ],
})
export class ExportModule {}
