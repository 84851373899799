/**
 * SFO uses citeproc-js for reference formating.
 * @see https://citeproc-js.readthedocs.io/en/latest/
 */

/** Custom formats that require a combination or options to be set
 * default/null: (Lastname, 2021)
 * suppress-author: 2021
 * author-only: lastname
 * combined: Author 2021
*/
export type customDisplayFormat = null | 'suppress-author' | 'author-only' | 'combined';

 export interface CSLReference {
    id: string;
    [key: string]: any;
}

/** Just like a citation but with a mandatory citationID */
export interface CitationWithId extends Citation {
    /** a mandatory citation id */
    citationID: string;
    plainCitation?: string;
}

/** A citation (e.g. (Smith 2021)) */
export interface Citation {
    /** A unique identifier added by the engine (thus optional) */
    citationID?: string;
    /** List of one or more citation items (order is important) */
    citationItems: CitationItem[];
    properties: {
        mode?: 'composite';
        /** the footnote number a citation appears in (0 for main document body) */
        noteIndex: number;
        /** An infix like 's early work */
        infix?: string;
        /** The rendered citation as a string */
        plainCitation?: string;
    }
}

/**
 * Options used to change how a citation is displayed.
 */
export interface CitationItemOptions {
    /** Do not include the author name */
    'suppress-author'?: boolean;
    /** Do only include the author name */
    'author-only'?: boolean;
}

/**
 * A citation item
 * @see https://citeproc-js.readthedocs.io/en/latest/csl-json/markup.html#cite-items
 */
export interface CitationItem extends CitationItemOptions {
    /** The reference id this citation is made for */
    id: string;
    /** The page/chapter where to reference */
    locator?: string;
    /** A label like page for the locator @see https://docs.citationstyles.org/en/stable/specification.html#locators */
    label?: string;
    prefix?: string;
    suffix?: string;
    /** Optional attribute to indicate the index of this citation within all citations */
    position?: number;
    /** Whether the same reference was made near this one (style dependent). */
    'near-note'?: boolean;
    /** The Zotero JSON for the reference (if available) */
    itemData?: any;
}

export interface CitationCache {
    index: number;
    renderedCitation: string;
    citationID: string;
}

export type CitationIDNoteIndexPair = [citationID: string, noteIndex: number];

/**
 * Old format used by SciFlow to encode a citation.
 */
export interface LecacyReference {
    /** the reference ids as an array seperated by , (order is important) */
    ids: string[];
    /** the citation item information for each id (may be empty) */
    options?: {
        [key: string]: CitationItem;
    }
}

/**
 * Formating options for references (CSL citeproc).
 * @see https://citeproc-js.readthedocs.io/en/latest/running.html#makebibliography
 */
export interface BibliographyFormatting {
    /**
     * The maximum number of characters in any label used in the bibliography, 
     * determining the indentation length for entries set flush to the margin. 
     * This is relevant when labels are used for citation in the main text and 
     * may require indentation in the bibliography for uniform presentation.
     */
    maxoffset: number;

    /**
     * The vertical spacing between consecutive entries in the bibliography, 
     * specified as an integer. This spacing is applied between the entries to
     * ensure that there is a clear separation and to enhance readability.
     */
    entryspacing: number;

    /**
     * The vertical spacing between lines within each bibliography entry, 
     * specified as an integer. This controls the density of text within 
     * each entry, affecting readability.
     */
    linespacing: number;

    /**
     * Indicates whether a hanging indent should be applied to bibliography 
     * entries. A hanging indent is a typographic style where all lines of a 
     * paragraph are indented except the first line.
     */
    hangingindent: boolean;

    /**
     * Specifies the alignment of the second field in bibliography entries 
     * based on the "second-field-align" CSL option. It can be "flush" or 
     * "margin" to dictate the alignment strategy. If the option is not set, 
     * this value is false.
     */
    'second-field-align': 'flush' | 'margin' | false;

    /**
     * A string to be prepended to the beginning of the compiled bibliography, 
     * allowing for the addition of any necessary introductory text or formatting.
     */
    bibstart: string;

    /**
     * A string to be appended to the end of the compiled bibliography, 
     * enabling the inclusion of any concluding text or formatting.
     */
    bibend: string;

    /**
     * An array of errors encountered during the compilation of the bibliography. 
     * The specific structure of each error should be defined based on possible 
     * error conditions.
     */
    bibliography_errors: BibliographyError[];

    /**
     * A two-dimensional array where each sub-array contains IDs of bibliography 
     * entries in the order they should appear. This structure supports grouping 
     * of entries if required by the bibliography format.
     */
    entry_ids: string[][];
}

/**
 * Defines the structure of an error encountered in bibliography compilation. 
 * This is a placeholder and should be replaced with a more specific definition 
 * tailored to the possible errors.
 */
export interface BibliographyError {
    errorCode: string;
    message: string;
    // Additional properties can be added here to capture more details about each error.
}
