import { Injector, Renderer2 } from '@angular/core';
import {  MatDialog,  MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MathEditorComponent } from '../../text-elements/math/math-editor/math-editor.component';
import { TextSelection } from 'prosemirror-state';
import { MathService } from '../../text-elements/math/math.service';
import { NodeView } from 'prosemirror-view';
/**
 * MathView that renders MathML and allows for editing.
 */
export class MathView implements NodeView {
    dom;
    dialog: MatDialog;

    private disableMath = sessionStorage.getItem('disableMath') === 'true';
    stop$ = new Subject<void>();

    tex = '';
    numbered = false;

    dialogRef: MatDialogRef<MathEditorComponent>;
    config: MatDialogConfig = new MatDialogConfig();
    mathService: MathService = this.injector.get(MathService);

    constructor(private node: any, private view: any, private getPos, private injector: Injector, private editorView) {
        this.config.width = '40rem';
        const renderer: Renderer2 = injector.get(Renderer2);

        this.dom = renderer.createElement('span');
        this.dom.setAttribute('data-id', this.node.attrs.id);
        this.dom.contentEditable = false;
        this.dom.spellcheck = false;
        this.dom.classList.add('js-math');


        this.dom.innerHTML = this.node.attrs.tex;
        this.tex = this.node?.attrs?.tex;

        this.dom.addEventListener('click', this.openDialog.bind(this));

        this.updatePreview(this.tex);
        this.updateStyle(this.node);
    }

    updatePreview(tex: string) {
        const result = this.mathService.renderEquation(tex);
        if (result.errors?.length > 0) {
            this.dom.innerHTML = ' ' + result.errors.map(({ message }) => message).join();
        } else {
            this.dom.innerHTML = result.node;
        }

        if (this.disableMath) {
            return '<small>' + tex + '</small>';
        } else {return null; }
    }

    openDialog(): void {
        if (!this.dialog) {
            this.dialog = this.injector.get(MatDialog);
        }

        this.dialogRef = this.dialog.open(MathEditorComponent, this.config);
        this.dialogRef.componentInstance.tex = this.tex;
        this.dialogRef.componentInstance.numbered = this.numbered;
        this.dialogRef.afterClosed().subscribe(async (result) => {
        // this.editorView.focus();

        const tr = this.view.state.tr;
        const selTr = this.view.state.tr.setSelection(TextSelection.create(tr.doc, this.getPos() + 2));
        selTr.scrollIntoView();
        this.view.dispatch(selTr);

        if (!result) { return; }
        const { tex, numbered } = result;

        this.tex = tex;
        this.numbered = numbered;

        this.emitChange();
        });
    }

    /**
     * Emits a change based on the latest TeX provided by the editor.
     */
    async emitChange(): Promise<void> {
        if (this.tex.length === 0) { return; }
        const tr = this.view.state.tr.replaceWith(this.getPos(), this.getPos() + this.node.nodeSize, this.view.state.schema.node('math', {
            id: this.node.attrs.id,
            tex: this.tex,
            style: this.numbered ? 'block' : 'inline'
        }, [
            this.view.state.schema.text(this.tex)
        ]));

        this.view.dispatch(tr);
    }

    /**
     * Incoming update from the editor.
     * @param node
     * @param decorations
     */
    update(node, decorations): boolean {
        if (!node.sameMarkup(this.node)) { return false; }
        if (node.type !== this.node.type) { return false; }

        this.updatePreview(node.attrs.tex);
        this.tex = node.attrs.tex;
        this.node = node;
        this.updateStyle(node);
        return true;
    }

    /**
     * Set the style based on the style option of the node.
     */
    updateStyle(node): void {
        const style = node.attrs.style;
        if (!style || style === 'inline') {
            this.numbered = false;
            this.dom.classList.add('js-math-inline');
            this.dom.classList.remove('js-math-block');
        } else if (style === 'block') {
            this.numbered = true;
            this.dom.classList.remove('js-math-inline');
            this.dom.classList.add('js-math-block');
        }

        if (!node.attrs.tex && (!node.textContent || node.textContent.length <= 1)) {
            this.dom.classList.add('js-math-empty');
        } else {
            this.dom.classList.remove('js-math-empty');
        }
    }

    ignoreMutation(mutation): boolean {
        return true;
    }

    destroy(): void {
        this.stop$.next();
    }
}
