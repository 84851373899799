import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sf-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input() user;

  constructor() { }

  renderShort(user) {
    if (!user) { return ''; }

    const short = [user.name, user.firstName, user.lastName].filter(n => n?.length > 0).map(n => n[0].toUpperCase()).join('');
    if (short?.length > 0) { return short; }

    if (!user.email) { return ''; }
    const name = user.email.split('@');
    const emailPre = name[0];
    return emailPre?.substr(0, 4);
  }

  ngOnInit(): void {
  }

}
