import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, firstValueFrom, map, shareReplay } from 'rxjs';
import { Asset } from '../models/asset.model';
import { Listing } from '../models/listing.model';
import { PreviewMode } from '../models/preview-mode.type';
import { StatusState } from '../models/state.enum';
import { Apollo, gql } from 'apollo-angular';

interface AssetState extends Asset {
  status: StatusState;
}

@Injectable({
  providedIn: 'root',
})
export class PluginStoreService {
  private assets: BehaviorSubject<AssetState[]> = new BehaviorSubject<AssetState[]>([]);
  private listing: BehaviorSubject<Listing | null> = new BehaviorSubject<Listing | null>(null);
  private previewMode: BehaviorSubject<PreviewMode> = new BehaviorSubject<PreviewMode>(
    'verification',
  );

  // TODO:
  // Additional variables needed for AssetProcess since class is treated as external
  // code and has no instantiation of Angular instances. This could be investigate if
  // it's possible to inject Angular instance to pure JS classes.
  private projectId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private documentName: BehaviorSubject<string> = new BehaviorSubject<string>('');

  assets$: Observable<AssetState[]> = this.assets.asObservable();
  listing$: Observable<Listing | null> = this.listing.asObservable();
  previewMode$: Observable<PreviewMode> = this.previewMode.asObservable();

  projectId$ = this.projectId.asObservable();
  documentName$ = this.documentName.asObservable();

  // Selector for Child Process
  isAssetsProcessingSelector$: Observable<boolean> = this.assets.pipe(
    map((assets) => !!assets.find((asset) => asset.status === 'processing')),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  assetErrors$: Observable<number> = this.assets.pipe(
    map((assets) => assets.filter((asset) => asset.status === StatusState.Error).length),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  setProjectId(projectId: string) {
    this.projectId.next(projectId);
  }

  setDocumentName(documentName: string) {
    this.documentName.next(documentName);
  }

  loadAssets(initialAssets: Asset[]) {
    const assetState: AssetState[] = initialAssets.map((asset: Asset) => ({
      ...asset,
      status: StatusState.Initial,
    }));

    this.assets.next(assetState);
  }

  updateAssetStatus(assetId: string, newStatus: StatusState): void {
    const assets = this.assets.value.map((asset) =>
      asset.id === assetId ? { ...asset, status: newStatus } : asset,
    );

    this.assets.next(assets);
  }

  setListing(listing: Listing) {
    this.listing.next(listing);
  }

  setPreviewMode(mode: PreviewMode) {
    this.previewMode.next(mode);
  }
}
