<div class="dca-heading flex items-center justify-between px2" *ngIf="access$ | async; let access">
  <div class="h2">Authors</div>
  <!-- <button (click)="ref.emit({ action: 'close' })" mat-icon-button matTooltip="Close" matTooltipPosition="below" matTooltipShowDelay="200"
        matTooltipHideDelay="300">
        <mat-icon>close</mat-icon>
      </button> -->
  <div class="flex items-center">
    <!-- <button *ngIf="access.write" (click)="ref.emit({ action: 'add_author' })" mat-icon-button matTooltip="Invite an author" matTooltipPosition="below"
      matTooltipShowDelay="200" matTooltipHideDelay="300">
      <mat-icon svgIcon="add_author"></mat-icon>
    </button> -->
    <button (click)="ref.emit({ action: 'new_author' })" mat-icon-button matTooltip="Add author without document access" matTooltipPosition="below"
      matTooltipShowDelay="200" matTooltipHideDelay="300">
      <mat-icon>playlist_add</mat-icon>
    </button>
  </div>
</div>