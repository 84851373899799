import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IAppState } from './author-management.reducer';
import { selectDocumentId } from 'projects/reference-app/src/app/app-state.reducer';

/**
 * Reroutes dca via the loading context
 * @export
 * @class LoadingService
 */
@Injectable()
export class DcaRoutingService {

  constructor(private router: Router, private store: Store<IAppState>) { }

  public async openDca(context: string) {
    const documentId = await this.store.select(selectDocumentId).pipe(take(1)).toPromise();
    this.router.navigate([`/edit/${documentId}`, { outlets: { context: context } }], { replaceUrl: true });
  }

  /**
   * Closes the dca
   * @private
   * @param {string[]} context
   * @memberof DcaRoutingService
   */
  public async closeDca(): Promise<void> {
    const documentId = await this.store.select(selectDocumentId).pipe(take(1)).toPromise();
    this.router.navigate([`/edit/${documentId}`, { outlets: { context: null } }], { replaceUrl: true });
  }
}
