import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SfoUiJSONSchema7} from '../../../../metadata.model';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'sfo-date-input',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent implements OnInit {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  isTouchDevice: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver.observe(['(pointer: coarse)']).subscribe((state: BreakpointState) => {
      this.isTouchDevice = state.matches;
    });

    this.aFormControl.valueChanges.subscribe((value: Date) => {
      console.log(value);

      if (value && moment.isMoment(value)) {
        const date = value.format('YYYY-MM-DD');
        this.aFormControl.setValue(date, {emitEvent: false});
      }
    });
  }
}
