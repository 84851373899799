import {createSelector} from '@ngrx/store';
import {
  AuthorManagementState,
  reducer as authermanagementReducer,
} from 'projects/author-management/src/lib/author-management.reducer';
import {EditorState, reducer as editorReducer} from 'projects/editor/src/lib/editor.reducer';
import {ProjectState, reducer as projectReducer} from '../editor/project/project.reducer';
import {ExportState, exportReducer} from 'export';

export const referenceFeatureKey = 'reference-app';

export const extractTitle = (doc): string | void => {
  const header = doc?.content && doc?.content.length > 0 ? doc.content[0] : undefined;
  if (header?.type === 'header') {
    const titleNode = header.content && header.content[0];
    if (titleNode) {
      return titleNode.content?.map((n) => n.text).join('');
    }
  }

  return '';
};

export interface ReferenceState {
  sharedEditor: EditorState;
  sharedAuthorManagement: AuthorManagementState;
  sharedExport: ExportState;
}

export const selectAuthorManagementState = (state): AuthorManagementState =>
  state.sharedAuthorManagement;

export const selectSelectedAuthor = createSelector(
  selectAuthorManagementState,
  (state: AuthorManagementState) => state.selectedAuthor,
);

export const checkSelectedAuthor = createSelector(
  selectAuthorManagementState,
  (state: AuthorManagementState) => state.maximized,
);

export const selectAuthors = createSelector(
  selectAuthorManagementState,
  (state: AuthorManagementState) => state.authors,
);

export const selectedDocument = (state): ProjectState => state.sharedProject;

export const selectDocumentModified = createSelector(
  selectedDocument,
  (state: ProjectState) => state.lastModified,
);

export const selectEditorState = (state): EditorState => state.sharedEditor;

export const selectDocumentVersion = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances[0]?.version,
);

export const selectDocumentId = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances[0]?.id,
);

/** Gets the active editor instance. */
export const selectActiveEditor = createSelector(
  selectEditorState,
  (state: EditorState) => state.instances[0],
);

export const selectDocumentTitle = createSelector(selectEditorState, (state: EditorState) =>
  extractTitle(state.instances[0]?.state.doc),
);

/**
 * checks weather the document is dirty or not
 */
export const selectIsDirty = createSelector(selectEditorState, (state: EditorState) => state.dirty);

export const selectExport = (state): ExportState => state.sharedExport;

export const selectExportMaximize = createSelector(
  selectExport,
  (state: ExportState) => state.isExportSettingsMaximized,
);

export const appReducer = {
  sharedEditor: editorReducer,
  sharedAuthorManagement: authermanagementReducer,
  sharedProject: projectReducer,
  sharedExport: exportReducer,
};

export const getUser = createSelector(
  selectAuthorManagementState,
  (state: AuthorManagementState) => state.options[0],
);

export const getDocumentContext = createSelector(
  selectAuthorManagementState,
  (state: AuthorManagementState) => state.options[0]?.activeDocumentContext,
);
