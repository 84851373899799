import { Injectable } from '@angular/core';
import { WindowRef } from 'projects/author-management/src/lib/WindowRef';


@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  _info;

  constructor(private windowRef: WindowRef) { }

  public async sendActivationEmail(email: string) {
    const info = await this._info;
    const response = await fetch(info.connectUrl + '/session/request-verification?__amp_source_origin=' + encodeURI(this.windowRef.origin), {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      mode: 'cors',
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        email
      })
    });

    const body: any = response.json();
    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }
}
