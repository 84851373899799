import {Pipe, PipeTransform} from '@angular/core';
import {SfoUiJSONSchema7} from '../../metadata.model';

@Pipe({
  name: 'handleSchemaType',
})
export class HandleSchemaTypePipe implements PipeTransform {
  transform(schema: SfoUiJSONSchema7): string {
    if (Array.isArray(schema.type)) {
      if (schema.type.includes('string') && schema.type.includes('number')) {
        return 'string';
      }
      return schema.type[0]; // Return the first type as a fallback
    }

    if (schema.anyOf || schema.oneOf) {
      const types = schema.anyOf || schema.oneOf;

      if (!types) return schema.type || '';

      const typeSet = new Set(types.map((t: any) => t.type as string));

      if (typeSet.has('string') && typeSet.has('number')) {
        return 'string';
      }
    }

    return schema.type || '';
  }
}
