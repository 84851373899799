import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { DocumentOutlineRoutingModule } from './document-outline-routing.module';
import { OutlineComponent } from './outline/outline.component';
import { FilterByTypePipe } from './pipes/filter.pipe';
import { documentFeatureKey, DocumentOutlineReducer } from './store/outline.reducer';



@NgModule({
  declarations: [
    OutlineComponent,
    FilterByTypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    DocumentOutlineRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    StoreModule.forFeature(documentFeatureKey, DocumentOutlineReducer)
  ],
  exports: [
  ]
})
export class DocumentOutlineModule { }
