<mat-form-field floatLabel="auto" appearance="fill" subscriptSizing="dynamic">
  <mat-label>
    {{ schema.title }}
    <span *ngIf="schema.default">
      {{ schema.default }}
    </span>
  </mat-label>
  <input matInput [formControl]="aFormControl" type="number" step="1" />
  <mat-hint *ngIf="schema.description">
    {{schema.description}}
  </mat-hint></mat-form-field
>
