import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';

import {QuickSettingsComponent} from './quicksettings.component';
import {DynamicFieldModule} from '../form-fields';
import {PresetMenuModule} from '../preset-menu';

@NgModule({
  declarations: [QuickSettingsComponent],
  imports: [
    CommonModule,
    MatAccordion,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    DynamicFieldModule,
    PresetMenuModule,
  ],
  exports: [QuickSettingsComponent],
})
export class QuickSettingsModule {}
