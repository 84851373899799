import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthorListPipe } from './author-list.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AuthorListPipe],
  exports: [AuthorListPipe]
})
export class PipesModule { }
