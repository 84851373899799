export interface Parts {
    /** Parts of the document */
    parts: {
        /** The chapter title (may contain html inline elements like <b>, <em>, <i>, <sup>, <sub>) */
        title: string;
        /** Only for the abstract: Content as a HTML string without the chapter title */
        htmlContent?: string;
        /** The ID of the html element the part was found in */
        htmlElId: string;
        /** The ID of the html element the part title was found in (may be the same as the part content if the heading is inline) */
        titleHTMLElId: string;
        /** The type of the part/chapter
         * @default chapter
        */
        type: 'abstract' | 'appendix' | 'chapter' | 'bibliography' | string;
        /** ISO 639-3 language code
         * @default eng
        */
        lang: 'en-US' | string;
    }[];
}