import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as jose from 'jose';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  instanceData = JSON.parse(this.document.getElementById('instance-data').innerHTML);
  user = this.init();

  constructor(@Inject(DOCUMENT) private document: any) {
    this.init();
  }

  async init() {
    const appDomain = this.instanceData.sciflowAppDomain;
    if (!appDomain) { return; }
    const auth = await fetch(`https://${appDomain}/session/authorized`, { credentials: 'include' });
    if (auth.status !== 200) {
      console.info('Not authenticated', auth.status);
    }
    const result = await auth.json();
    if (result.token) {
      return jose.decodeJwt(result.token);
    }

    return undefined;
  }
}
