<div>
    <h2 mat-dialog-title>Add group</h2>
    <mat-dialog-content>
        <p>Adding a group will add a new group to the author list and add this author as a group member.</p>
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="name">
            <mat-hint>The group name</mat-hint>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="mt2" align="end">
        <button mat-button mat-dialog-close class="uppercase">Discard changes</button>
        <button mat-button color="primary" class="uppercase" (click)="save()" cdkFocusInitial
            [disabled]="name.pristine || name.invalid">Save</button>
    </mat-dialog-actions>
</div>
