import { Plugin, TextSelection } from 'prosemirror-state';
import { dropPoint } from 'prosemirror-transform';
import { insertOutline } from './commands';

const handleDrop = (view, event, slice, moved) => {
    const node = slice.content.firstChild;

    if (!moved && node.type.name === 'link' && node?.attrs?.type === 'xref') {
        const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
        if (!eventPos) { return false; }
        const mousePos = view.state.doc.resolve(eventPos.pos);

        const tr = view.state.tr;
        const insertPos = dropPoint(view.state.doc, mousePos.pos, slice);
        if (insertPos == null) { return false; }
        view.dispatch(tr.setSelection(TextSelection.create(tr.doc, insertPos)));
        insertOutline(node.attrs, node.textContent)(view.state, view.dispatch);
        return true;
      }
    return false;
};

export const getCrossReferencePlugin = () => {
    return new Plugin({
        props: {
            handleDrop
        }
    });
};
