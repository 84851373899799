import { Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { FileService } from 'shared';
import { DropService } from '../../text-elements/figure/drop.service';
import { EditorView } from 'prosemirror-view';
import { TextSelection, Selection, NodeSelection } from 'prosemirror-state';
import { Fragment, Node } from 'prosemirror-model';
import { SFNodeType } from '@sciflow/schema';

export class PartView {

    dom;
    contentDOM: HTMLElement;
    figureElement;
    label;

    private stop$ = new Subject<void>();

    constructor(private node, private view: EditorView, private getPos) {
        this.dom = document.createElement('section');
        this.dom.setAttribute('data-type', 'part');
        this.dom.setAttribute('data-id', this.node.attrs.id);
        this.dom.setAttribute('id', this.node.attrs.id);

        const schema = this.view.state.schema;
        const partType = schema.nodes[SFNodeType.part];
        const headingType = schema.nodes[SFNodeType.heading];

        const addPart = (event) => {
            const posAtCoords = view.posAtCoords({ left: event.clientX, top: event.clientY });

            if (posAtCoords) {
                // the end of our node view
                const start = getPos();
                const end = start + this.node.nodeSize;
                const $head = this.view.state.doc.resolve(end);
                const $anchor = this.view.state.doc.resolve(posAtCoords.pos);

                const tr = this.view.state.tr;
                const part = partType.create({}, [headingType.create({ level: 1 }, [schema.text('Chapter title')])]);
                tr.insert(end, part);
                tr.setSelection(TextSelection.create(tr.doc, end, end + part.nodeSize));
                tr.scrollIntoView();
                this.view.dispatch(tr);

            }
        }

        const handleDragEnd = (event) => {
            const posAtCoords = view.posAtCoords({ left: event.clientX, top: event.clientY });

            if (posAtCoords) {
                // the end of our node view
                const dragPos = posAtCoords.pos;
                const start = getPos();
                const end = start + this.node.nodeSize;
                const $head = this.view.state.doc.resolve(end);
                const $anchor = this.view.state.doc.resolve(posAtCoords.pos);

                // does the drag land after the view
                if (end < dragPos && start < dragPos) {
                    const slice = this.view.state.doc.slice($head.pos, $anchor.end());
                    const tr = this.view.state.tr;
                    tr.delete($head.pos, $anchor.end());
                    tr.insert(end - 1, slice.content);
                    this.view.dispatch(tr);

                    // does it land inside the node view
                } else if (dragPos < end && dragPos > start) {
                    const slice = this.view.state.doc.slice($anchor.pos, $head.pos);
                    const tr = this.view.state.tr;
                    tr.insert(end, slice.content);
                    tr.delete($anchor.pos, $head.pos);
                    this.view.dispatch(tr);
                }
            }
        };

        const draggerTop = document.createElement('div');
        draggerTop.classList.add('dragger');
        draggerTop.classList.add('dragger-top');
        draggerTop.draggable = true;
        draggerTop.contentEditable = 'false';
        draggerTop.classList.add('mb1');
        //this.dom.appendChild(draggerTop);
        draggerTop.addEventListener('dragend', handleDragEnd);

        this.label = document.createElement('div');
        this.label.classList.add('part-type-label');
        this.label.innerHTML = 'Chapter';

        const topActions = document.createElement('div');
        topActions.contentEditable = 'false';
        topActions.classList.add('flex');
        topActions.classList.add('flex-wrap');
        topActions.classList.add('justify-between');
        topActions.appendChild(this.label);

        const button = document.createElement('button');
        button.innerHTML = 'x';
        topActions.appendChild(button)

        this.dom.appendChild(topActions);
        button.addEventListener('click', (e) => {
            const tr = this.view.state.tr;
            const start = getPos();
            const end = start + this.node.nodeSize;
            const slice = this.view.state.doc.slice(start + 1, end - 1);
            tr.delete(start, end);
            tr.insert(start, slice.content);
            this.view.dispatch(tr);
        });

        this.contentDOM = document.createElement('div');
        this.contentDOM.classList.add('part-editor');
        this.dom.appendChild(this.contentDOM);
        this.contentDOM.contentEditable = 'true';

        const draggerBottom = document.createElement('div');
        draggerBottom.classList.add('dragger');
        draggerBottom.contentEditable = 'false';
        draggerBottom.classList.add('dragger-bottom');
        draggerBottom.draggable = true;
        draggerBottom.contentEditable = 'false';
        //this.dom.appendChild(draggerBottom);
        draggerBottom.addEventListener('dragend', handleDragEnd);

        const addButton = document.createElement('button');
        addButton.innerHTML = `+`;
        addButton.classList.add('add-button');
        addButton.addEventListener('click', addPart);

        const bottomActions = document.createElement('div');
        bottomActions.contentEditable = 'false';
        bottomActions.classList.add('flex');
        bottomActions.classList.add('flex-wrap');
        bottomActions.classList.add('justify-around');
        bottomActions.appendChild(addButton);
        bottomActions.contentEditable = 'false';

        this.dom.appendChild(bottomActions);
        this.checkNode(node);
    }

    private checkNode(node: Node) {
        if (!node.content.size) {
            this.dom.classList.add('empty');
        } else {
            this.dom.classList.remove('empty');
            if (node.firstChild?.type.name === SFNodeType.heading && node.firstChild?.attrs?.level === 1) {
                
                const headingAttrs = node.firstChild.attrs;
                this.dom.setAttribute('data-part-type', 'chapter');
                if (headingAttrs?.placement) { this.dom.setAttribute('data-placement', headingAttrs.placement); }
                if (headingAttrs?.role) {this.dom.setAttribute('data-role', headingAttrs.role); }
                if (headingAttrs?.numbering) { this.dom.setAttribute('data-numbering', headingAttrs.numbering); }

                this.label.innerHTML = `Chapter (${headingAttrs.placement || 'body'})`;

            } else {
                this.label.innerHTML = 'Free part (part without a heading 1)';
                this.dom.setAttribute('data-part-type', 'free');
            }
        }


        this.dom.node = node;
    }

    update(node) {
        if (!node.sameMarkup(this.node)) { return false; }
        if (node.type !== this.node.type) { return false; }
        this.checkNode(node);
        this.node = node;
        return true;
    }

    selectNode() {
        this.dom.classList.add('selected');
    }

    deselectNode() {
        this.dom.classList.remove('selected');
    }

    ignoreMutation(mutation) {
        return true;
    }

    destroy() {
        this.stop$.next();
    }
}

