import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { dump } from 'js-yaml';

@Pipe({
  name: 'yaml'
})
export class YamlPipe implements PipeTransform {

  constructor() {}

 public transform(value: any): any {
     return dump(value);
  }
}