import { Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorView } from 'prosemirror-view';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectElement } from '../../editor.reducer';

export class XRefView {
    dom: HTMLSpanElement;

    private stop$ = new Subject<void>();
    private intersecting$ = new Subject();

    constructor(private node, private view, private options: { renderer: Renderer2, store: Store, observer: IntersectionObserver }) {
        this.dom = this.options.renderer.createElement('a');
        this.dom.setAttribute('data-type', 'xref');
        this.dom.setAttribute('href', this.node.attrs.href);
        this.dom.contentEditable = 'false';
        this.dom.innerHTML = node.textContent?.substr(0, 22) + (node.textContent?.length > 22 ? '...' : '');
        (this.dom as any).notify = (intersecting) => this.intersecting$.next(intersecting);

        this.options.observer?.observe(this.dom);
        // TODO set initial values from the element itself

        this.dom.addEventListener('click', (event) => {
            event.preventDefault();
        });

        this.options.store.select(selectElement(node.attrs.href.replace('#', '')))
            .pipe(
                debounceTime(200),
                withLatestFrom(this.intersecting$),
                filter(([_element, isIntersecting]) => isIntersecting === true),
                map(([element]) => element),
                filter(v => v != null),
                distinctUntilKeyChanged('label'),
                takeUntil(this.stop$))
            .subscribe((value) => {
                if (value) {
                    this.dom.innerHTML = value.label?.substr(0, 22) + (value.label?.length > 22 ? '...' : '');
                    this.dom.setAttribute('data-target-level', value.level);
                    this.dom.setAttribute('data-target', value.type);
                    this.dom.setAttribute('data-label', value.label);
                }
            });
    }

    update(node, decorations) {
        if (!node.sameMarkup(this.node)) { return false; }
        if (node.type !== this.node.type) { return false; }
        this.node = node;
        return true;
    }

    ignoreMutation(mutation) {
        return true;
    }

    destroy() {
        this.options.observer?.unobserve(this.dom);
        this.stop$.next();
    }
}