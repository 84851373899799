import jp from 'json-pointer';
import { Fragment, Node } from 'prosemirror-model';

interface ProseMirrorNodeLike {
    type: string;
    content?: ProseMirrorNodeLike[];
    text?: string;
}

interface Match {
    node: Node;
    pos: number;
    parent: Node | null;
}

/**
 * Takes a RFC 6901 JSON Pointer and returns the corresponding ProseMirror Node.
 * @param pointer the JSON pointer string
 * @param source the ProseMirror document to find the node in
 */
export const pointerToNode = (pointer: string, doc: Node): Match | undefined => {

    const getNodeFromPath = () => {
        const path = jp.parse(pointer);
        let current: Node | Fragment = doc;
        for (let i = 0; i < path.length; i++) {
            let segment = path[i];
            if (segment === 'content') {
                if (!current[segment]) { return undefined; }
                current = current[segment] as Fragment;
                continue;
            }

            if (segment === 'text') {
                return current;
            }

            if (!Number.isNaN(Number.parseInt(segment))) {
                const childIndex = Number.parseInt(segment);
                current = current.child(childIndex);
                continue;
            }
        }

        return current;
    }

    const node = getNodeFromPath();
    let matches: Match[] = [];
    doc.descendants((...match) => {
        if (match[0] === node) {
            matches.push({ node: match[0], pos: match[1], parent: match[2] });
        }
    });
    if (matches.length> 1) {
        throw new Error('Node was not unique for ' + pointer);
    }

    return matches[0];
};
