export interface SemanticHTMLTags {
    /**
     * A list of element ids that are part of a elements.class
     */
    elements: {
        /** the element id */
        id: string;
        /**
         * The class the element belongs to.
         * 
         * examples for semantic classes:
         * title: the title of the document, usually in the beginning of the manuscript
         * author: authors are often only in the beginning of the document and before a real chapter or the abstract, they may have affiliations indicated by a <sup>
         * affiliation: the institution the author is affiliated with. May span multiple lines (e.g. the end of an address may end up on the next line and should also be tagged affiliation).
         * correponding-author-email: often starts with "Email:" or "Correspondence to:" or alike.
         * section-title: is the title of a top level chapter like Introduction, Methods, References, .. (or something publication specific)
         * section: a generic class for sections, further specified by section-type
         * section-type: the type of section, such as abstract, methods, results, discussion, conclusion, etc.
         * text: the text body, a normal paragraph that is no section title or other meta data
         * caption: usually starts with Fig/Figure, Tab/Table, Eq. .. and then provides a title for a figure
         * reference: a reference string as part of the bibliography/reference list (usually towards the end)
         * footnotes: additional information or citations found at the bottom of the page.
         * keywords: the actual list of keywords for the article (often preceeded by a heading or text saying Keywords) (the keywords heading itself should be marked as section with section-type keywords)
         */
        class: 'title' | 'author' | 'corresponding-author-email' | 'affiliation' | 'reference' | 'section' | 'section-type' | 'caption' | 'in-text-citation' | 'cross-reference' | 'text' | 'funding-information' | 'acknowledgments' | 'footnotes' | 'keywords' | 'appendix' | string;

        /**
         * Possible values for section-type, further specifying the section class.
         */
        sectionType: 'abstract' | 'introduction' | 'methods' | 'results' | 'discussion' | 'conclusion' | 'references' | string;
        /**
         * If a section-title, caption, or reference is numbered (starts with it), store it here. A caption numbering may be 'Table 2, Figure 2, ..'
         */
        numbering: string;
        
        /** The language of the text */
        lang: string;

        /**
         * If the element continues from the previous one (i.e. not a new one but a new element)
         */
        continuing: boolean;

        /** Provide a confidence between 0.00 and 1.00 that the class was assigned correctly */
        confidence: number;
    }[];
}
