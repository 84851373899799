<div class="p2">
    <div class="flex">
        <div class="dca-width" *ngIf="authors$ | async; let authors">
            <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div *ngIf="authors.length === 0" class="m1">
                    There are currently no authors in this document. To add one, click <mat-icon>playlist_add</mat-icon> above.
                </div>
                <div *ngFor="let author of authors" cdkDrag>
                    <div class="drag-handle" cdkDragHandle>
                        <mat-icon>drag_indicator</mat-icon>
                    </div>
                    <sf-user-card [user]="author"
                        [class]="((selectedAuthorId$ | async) == author.authorId) ? 'selected' : ''"
                        class="mb1 card-interactive" (click)="selectAuthor(author.authorId)"></sf-user-card>
                    <div class="drag-placeholder" *cdkDragPlaceholder></div>
                </div>
            </div>
        </div>
        <sf-author-edit *ngIf="selectedAuthorId$ | async; let authorId" [authorId]="authorId" [isAuthor]="true"
            (closed)="onClosedEdit()" (createNew)="onClosedEdit()" (update)="onChange($event)"></sf-author-edit>
    </div>
</div>