import { createAction, props } from '@ngrx/store';

export const registerInstance = createAction('[Editor] Register editor instance', props<{ id: string; key: string; projectId: string; version: string; state: any; lastModified?: number; }>());

export const removeInstance = createAction('[Editor] Remove editor instance', props<{ id: string; }>());

export const removeAllInstances = createAction('[Editor] Remove all editor instances');

export const updateState = createAction('[Editor] Update editor instance state', props<{ id: string; projectId: string; elements: any[]; selection: any; state: any; }>());

export const replaceFile = createAction('[Editor] Replace a file', props<{ id: string; projectId: string; file: any; }>());

export const updateDocumentState = createAction('[Editor] Update document state', props<{ dirty: boolean; }>());

export const updateImageResources = createAction('[Editor] Update image resources', props<{ imageResources: any[]; }>());
