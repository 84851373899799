<mat-drawer-container autosize hasBackdrop="true" [class]="(isDark) ? 'dark-theme' : 'light-theme'">
  <mat-drawer #drawer mode="over">
    <div class="p2">
      <p>Auto-resizing sidenav {{ isDark }}</p>
    </div>
  </mat-drawer>

  <div class="container-scrollable">
    <mat-toolbar class="justify-between mat-elevation-z1" *ngIf="openFile$ | async; let openFile">
      <nav *ngIf="openFile?.projectId" class="flex items-center justify-between col-12" aria-label="main navigation">
        <div class="flex items-center">
          <!-- <button mat-icon-button aria-label="Menu" (click)="drawer.toggle()" disabled>
              <mat-icon>menu</mat-icon>
            </button> -->
          <span class="title-text xs-hide sm-hide">
            <a [routerLink]="['/', 'start', openFile?.projectId]">{{ (openFile?.projectId || '') | truncate:[16, ' /']
              }}</a> {{ openFile?.id }} <small *ngIf="openFile.lastModified">({{ openFile.lastModified | date:'short'
              }})</small>
          </span>
        </div>

        <!-- <mat-slide-toggle (toggleChange)="isDark = !isDark">
            <span class="test p1">{{ isDark ? 'dark' : 'light' }}</span>
          </mat-slide-toggle> -->
        <div class="flex items-center">
          <!-- <button mat-icon-button (click)="copyToClipboard()" matTooltip="Copy link to editor">
              <mat-icon>link</mat-icon>
            </button> -->

          <span *ngIf="!instanceData.sciflowAppDomain; else exportToSciFlow">
            <button mat-stroked-button (click)="save()" matTooltip="Save document">{{
              openFile.isDirty ? 'Save changes' : 'Export' }}</button>
          </span>

          <ng-template #exportToSciFlow>
            <button [disabled]="openFile.isDirty" mat-flat-button color="primary" class="mr1" (click)="openInSciFlow()">Import to SciFlow</button>
            <button [disabled]="!openFile.isDirty" mat-stroked-button (click)="save(false)" matTooltip="Save document">Save changes</button>
          </ng-template>

<!--           <a mat-icon-button href="https://docs.sciflow.org/self-hosting/" rel="noopener" target="_blank"
            matTooltip="About this editor">
            <mat-icon>info</mat-icon>
          </a>
 -->
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Select Theme" class="mb1">
              <mat-icon>tonality</mat-icon>
              <mat-icon style="margin-left: -5px;">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="selectTheme('ST')">
                <span>Use device theme</span>
              </button>
              <button mat-menu-item (click)="selectTheme('DT')">
                <span>Dark theme</span>
              </button>
              <button mat-menu-item (click)="selectTheme('LT')">
                <span>Light theme</span>
              </button>
            </mat-menu> -->
        </div>
      </nav>
    </mat-toolbar>

    <div class="container-scrollable-wrapper">
      <main id="mainContent" tabindex="-1" class="container-scrollable-overflow relative">
        <router-outlet></router-outlet>
      </main> <!-- container-scrollable-overflow -->
    </div> <!-- container-scrollable-wrapper-->


  </div> <!-- container-scrollable -->
</mat-drawer-container>
<input type="text" value="" style="display: none;" #copyToClipboardInput />