import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SfoAdmonitionModule} from './admonition';
import {SfoRichTextModule} from './rich-text';
import {SfoCodeEditorModule} from './code-editor';

@NgModule({
  imports: [CommonModule, SfoAdmonitionModule, SfoRichTextModule, SfoCodeEditorModule],
  exports: [SfoAdmonitionModule, SfoRichTextModule, SfoCodeEditorModule],
})
export class SfoWidgetModule {}
