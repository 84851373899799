<div class="add-author">
    <div class="flex items-center" style="height: 3.5rem">
        <button mat-icon-button (click)="ref.emit({ action: 'close' })" matTooltip="Close 'Invite an author'"
            matTooltipPosition="below" matTooltipShowDelay="200" matTooltipHideDelay="300">
            <mat-icon>close</mat-icon>
        </button>
        <h2 class="title">Invite an author</h2>
    </div>
    <ng-template #notAnAuthor>
        <p *ngIf="documentId && inviteUrls.length === 0">Please ask a document admin to promote you to
            <b>author</b> to invite others.
        </p>
    </ng-template>
    <div class="fields p2" *ngIf="inviteUrls.length !== 0; else notAnAuthor">
        <section *ngIf="user$ | async; let user">
            <div class="flex col-12">
                <mat-form-field class="col-12 pb1 pr2">
                    <mat-label>Invite a new</mat-label>
                    <mat-select [(ngModel)]="selectedRole">
                        <mat-option *ngFor="let option of inviteUrls" [value]="option.role">{{roleLabels[option.role]}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="copyToClipboard()" matTooltip="Copy invite link to clipboard"
                    matTooltipPosition="below" matTooltipShowDelay="200" matTooltipHideDelay="300">
                    <mat-icon>link</mat-icon>
                </button>
                <input type="text" value="" style="/* transform: translateX(1000rem); */position: absolute;visibility: hidden;"
                    #copyToClipboardInput />
            </div>
            <div>
                <div *ngIf="!user?.emailVerified" class="py2 m2">
                    <p class="sf-accent">To invite authors through email, please confirm your Email adress.</p>
                    <button class="col-12" mat-raised-button (click)="sendActivationEmail(user.email)">Resend activation
                        email</button>
                </div>
                <div *ngIf="user?.emailVerified">
                    <mat-form-field appearance="outline" class="col-12 pb1" >
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Email" [formControl]="email" required #emailField>
                        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-hint>Who would you like to invite?</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-12 pb1" >
                        <mat-label>Message</mat-label>
                        <textarea matInput placeholder="Your message" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="4" cdkAutosizeMaxRows="8" [formControl]="message"></textarea>
                        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                        <mat-hint>What should they do with the document?</mat-hint>
                    </mat-form-field>
                    <div class="input-element pb1">
                        <mat-slide-toggle matInput [formControl]="inviteAnother"
                            matTooltip="Keep the window open to send another invite afterwards">invite more authors
                        </mat-slide-toggle>
                    </div>
                    <div class="input-element pb1">
                        <mat-slide-toggle matInput [formControl]="useEmailClient">use my own email client</mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="actions p2">
                <button (click)="submit()" [disabled]="inviteUrls.length === 0" mat-raised-button color="primary"
                    matTooltip="Send the invite" matTooltipPosition="above" matTooltipShowDelay="500"
                    matTooltipHideDelay="300" [disabled]="!user?.emailVerified">
                    Send invite
                </button>
                <button mat-button (click)="copyToClipboard()" matTooltip="Copy invite link to clipboard"
                    matTooltipPosition="below" matTooltipShowDelay="200" matTooltipHideDelay="300">
                    Just copy the link
                </button>
            </div>
        </section>
    </div>
</div>