import { Plugin, PluginKey, EditorState } from 'prosemirror-state';

export const statePluginKey = new PluginKey<{ elements: any[]; }>('state');

const calcElements = (doc) => {
    const elements: any[] = [];
    let i = 1, j = 0, k = 0, l = 0, m = 0, n = 0;

    const getLabel = (node) => {
        switch (node.type.name) {
            case 'heading':
            case 'citation':
            case 'figure':
            case 'code':
                return node.textContent;
            case 'math':
                return node.attrs.tex;
        }
    };

    // extract headings
    doc.descendants((node) => {
        if (node.type.name === 'paragraph') { return true; }
        if (node.attrs.id != null) {
            let nodeType = node.type.name === 'figure' ? node.content.content[0].type.name : node.type.name;
            let nodeLabel = node.type.name === 'figure' ? node.content.content[1]?.content.content[0]?.textContent : getLabel(node);
            let index;
            switch (node.type.name) {
                case 'figure':
                    // native-table
                    if (node.content.content.length === 2) {
                        index = i + j;
                        j++;
                        nodeLabel = node.content.content[1]?.content.content[0]?.textContent;
                        nodeType = node.content.content[0].type.name;
                    }
                    // figure
                    else if (node.content.content.length === 1) {
                        index = i + k;
                        k++;
                        nodeLabel = getLabel(node);
                        nodeType = node.type.name;
                    }
                    break;
                case 'heading':
                    index = i + l;
                    l++;
                    break;
                case 'math':
                    index = i + m;
                    m++;
                    nodeType = 'Equation';
                    break;
                case 'code':
                    index = i + n;
                    n++;
                    break;
            }
            elements.push({
                type: nodeType, level: node.attrs.level || 1, id: node.attrs.id, label: nodeLabel, pos : index
            });
            return false;
        }

        return true;
    });

    return elements;
};

/*** Calculates state that will be needed outside of the editor instance */
export const statePlugin: Plugin = new Plugin<{ elements: any[]; }>({
    key: statePluginKey,
    state: {
        init(_config, state: EditorState) { return { elements: calcElements(state.doc) }; },
        apply(transaction, oldState: any, newState: EditorState) {
            if (!transaction.docChanged) { return oldState; }
            const elements = calcElements(transaction.doc);
            return { ...oldState, elements };
        },
    }
});
