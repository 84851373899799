import {Action, ActionReducer, createReducer, createSelector, on} from '@ngrx/store';
import * as exportActions from './export.actions';

export const exportFeatureKey = 'export';

export interface ExportState {
  template: any;
  metaData: object;
  isExportSettingsMaximized: boolean;
  isExportDcaMaximized: boolean;
}

const initialState: ExportState = {
  template: undefined,
  metaData: {},
  isExportSettingsMaximized: false,
  isExportDcaMaximized: true,
};

export const exportReducer = createReducer(
  initialState,
  on(exportActions.updateTemplate, (state, action) => ({...state, template: action.template})),
  on(exportActions.updateMetadata, (state, action) => ({...state, metaData: action.metaData})),
  on(exportActions.minimizeExportSettings, (state) => ({
    ...state,
    isExportSettingsMaximized: false,
    isExportDcaMaximized: true,
  })),
  on(exportActions.maximizeExportSettings, (state) => ({
    ...state,
    isExportSettingsMaximized: true,
    isExportDcaMaximized: false,
  })),
  on(exportActions.minimizeExportDca, (state) => ({
    ...state,
    isExportDcaMaximized: false,
  })),
  on(exportActions.maximizeExportDca, (state) => ({
    ...state,
    isExportDcaMaximized: true,
  })),
);

export const selectExportState = (state): ExportState => state.export;

/**
 * Selects the template
 */
export const selectTemplate = createSelector(
  selectExportState,
  (state: ExportState) => state.template,
);

/**
 * Selects the meta data
 */
export const selectMetadata = createSelector(
  selectExportState,
  (state: ExportState) => state.metaData,
);

export const selectExportMaximize = createSelector(
  selectExportState,
  (state: ExportState) => state.isExportSettingsMaximized,
);

export const selectExportDcaMaximize = createSelector(
  selectExportState,
  (state: ExportState) => state.isExportDcaMaximized,
);

export const exportMetadataReducer: ActionReducer<ExportState, any> = (
  state: ExportState | undefined,
  action: Action,
) => {
  return exportReducer(state, action);
};
