<header class="flex justify-between">
  <h2 mat-dialog-title>
    <span *ngIf="toggleHelp">LaTeX math equation editor</span>
    <span *ngIf="!toggleHelp">Equations for non-LaTeX users</span>
  </h2>
  <div class="flex">
    <button mat-icon-button (click)="toggleHelp = !toggleHelp">
      <mat-icon *ngIf="!toggleHelp">keyboard_return</mat-icon>
    </button>
    <div *ngIf="toggleHelp">
      <button *ngIf="useMathJax$ | async" mat-button [matMenuTriggerFor]="examples">MathJax</button>
      <mat-menu #examples="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="equations">Equations</button>
        <button mat-menu-item [matMenuTriggerFor]="environments">Environments</button>
      </mat-menu>

      <mat-menu #equations="matMenu">
        <button mat-menu-item (click)="replace('x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}')">The Lorenz Equations</button>
        <button mat-menu-item (click)="replace('\\begin{align}
    \\dot{x} & = \\sigma(y-x) \\\\
    \\dot{y} & = \\rho x - y - xz \\\\
    \\dot{z} & = -\\beta z + xy
  \\end{align}')">The Cauchy-Schwarz Inequality</button>
        <button mat-menu-item (click)="replace('
  \\mathbf{V}_1 \\times \\mathbf{V}_2 =
  \\begin{vmatrix}
    \\mathbf{i} & \\mathbf{j} & \\mathbf{k} \\\\
    \\frac{\\partial X}{\\partial u} & \\frac{\\partial Y}{\\partial u} & 0 \\\\
    \\frac{\\partial X}{\\partial v} & \\frac{\\partial Y}{\\partial v} & 0 \\\\
  \\end{vmatrix}
  ')">A Cross Product Formula</button>
        <a mat-menu-item href="https://mathjax.github.io/MathJax-demos-web/tex-chtml.html" rel="noopener"
          target="_blank">...</a>
      </mat-menu>

      <mat-menu #environments="matMenu">
        <button mat-menu-item (click)="insert('\\begin{align}\n\\end{align}')">align</button>
        <button mat-menu-item (click)="insert('\\begin{gather}\n\\end{gather}')">gather</button>
        <button mat-menu-item (click)="insert('\\begin{equation}\n\\end{equation}')">equation</button>
        <a mat-menu-item href="https://docs.mathjax.org/en/latest/input/tex/macros/index.html#environments"
          rel="noopener" target="_blank">...</a>
      </mat-menu>

      <button mat-icon-button (click)="toggleHelp = !toggleHelp">
        <mat-icon>help_outline</mat-icon>
      </button>
    </div>
  </div>
</header>

<mat-dialog-content [ngClass]="{'hide':!toggleHelp}">
  
    
  <h3 for="tex-edit">Code</h3>
  <div class="mx0" #editor></div>
  <div class="right-align">
    <small *ngIf="useMathJax$ | async">Using <a
        href="https://docs.mathjax.org/en/latest/input/tex/macros/index.html" target="_blank"
        rel="noopener">MathJax</a> to render equation</small>
  </div>
  
  <div class="mt2">
    <h3 class="m0">Preview</h3>
    <div class="p2" *ngIf="results$ | async; let result"
      [ngClass]="{'equation__block': !(isInline$ | async), 'equation__inline': (isInline$ | async)}">
      <div #preview [innerHTML]="result.node"></div>
      <div class="errors" #errors>
        <div *ngFor="let error of result.errors">
          <b>{{error.id}}</b> {{error.message}}
        </div>
      </div>
    </div>
  </div>

  <div class="my1">
    <h3 class="mb0">Position in text</h3>
    <mat-slide-toggle [checked]="isInline$ | async" (change)="acceptInlineToggle($event)"
      matTooltip="Inline equations are rendered in the normal text flow like this α while block equations will have space around it and can be numbered as well as cross-referenced.">
      inline
    </mat-slide-toggle>
  </div>

</mat-dialog-content>

<mat-dialog-content [ngClass]="{'hide':toggleHelp}"
  class="flex flex-column flex-justify">
  <div>
    <p>We recommend using a visual equation editor like
      <a href="https://latex.codecogs.com/eqneditor/editor.php?mode=NEW" target="_blank" rel="noopener">CodeCogs
        3.3</a>.
    </p>
    <p class="pt1">Create your equation there and paste the LaTeX-result into our editor.</p>
  </div>
  <small>
    We use MathJax to show equations when you export your document.
    <br />
    <!-- <mat-slide-toggle class="m1" [checked]="useMathJax$ | async" (change)="toggleLegacy($event)">use MathJax
        </mat-slide-toggle> -->
  </small>
</mat-dialog-content>

<mat-dialog-actions align="end" class="mt2">
  <button mat-button (click)="close()" class="uppercase">
    <span *ngIf="toggleHelp">Cancel</span>
  </button>
  <button *ngIf="toggleHelp" mat-button color="primary" (click)="apply()" class="uppercase">Apply changes</button>
</mat-dialog-actions>