import { Action, ActionReducer, createReducer, createSelector, on } from '@ngrx/store';
import { CitationWithId, CSLReference } from '@sciflow/cite';
import * as referenceActions from './references.actions';

export const referenceFeatureKey = 'reference-management';

export interface References {
    references: { csl: CSLReference; title: string; key: string; bib: string; }[];
    citations: CitationWithId[];
}

const initialState: References = {
    references: [],
    citations: []
};

export const referenceReducer = createReducer(
    initialState,
    on(referenceActions.setReferences, (state, action) => ({ ...state, references: [
        ...action.references, ...state.references
    ].filter((r, i, l) => l.findIndex(r2 => r2.key === r.key) === i) })),
    on(referenceActions.updateCitation, (state, { citation }) => ({
        ...state, citations: [citation, ...state.citations]
            .filter((c1, index, array) => index === array.findIndex(c2 => c1.citationID === c2.citationID))
    })),
    on(referenceActions.addCitations, (state, action) => ({ ...state, citations: action.citations }))
);

export const selectReferenceManagementState = (state): References => state['reference-management'];

/**
 * Selects all the references uploaded to the project
 */
export const selectReferenceList = createSelector(
    selectReferenceManagementState,
    (state: References) => state.references
);

/**
 * Selects references/citations added in the document(editor) using referencePlugin
 */
export const selectCitationlist = createSelector(
    selectReferenceManagementState,
    (state: References) => state.citations
);

/** Selects a citation. */
export const selectCitation = (citationID: string) => createSelector(
    selectReferenceManagementState,
    (state: References) => state.citations.find(citation => citation.citationID === citationID)
);

/**
 * Selects all the references used in the project
 */
export const selectUsedReferences = createSelector(
    selectReferenceManagementState,
    (state: References) => {
        const referenceIds: string[] = state.citations.reduce((list, { citationItems }) => ([
            ...list,
            ...citationItems.map(ci => ci.id)
        ]), [] as string[]).filter((id, index, array) => array.indexOf(id) === index);
        return referenceIds.map((id) => {
            const ref = state.references.find((ref) => ref.key === id);
            if (!ref) {
                console.warn('Reference ' + id + ' was cited but not found in reference list');
            }
            return ref;
        })
            .filter(ref => ref !== undefined && ref.csl !== undefined);
    }
);

export const referenceMangementReducer: ActionReducer<References, any> = (state: References | undefined, action: Action) => {
    return referenceReducer(state, action);
};
