import { schemas } from './schema';
import { DocumentNode, SFNodeType } from './types';
import { Node, DOMSerializer } from 'prosemirror-model';

export const parse = (json: any) => {
    return Node.fromJSON(schemas.manuscript, json);
};

export const toDOM = (doc: any, opts?: { document?: any }) => {
    const serializer = DOMSerializer.fromSchema(schemas.manuscript);
    const documentInstance = opts?.document || document;
    if (!documentInstance) { throw new Error('Document must be provided for DOM access'); }
    return serializer.serializeNode(doc, { document: documentInstance });
};

/**
 * Creates a document with one or more heading 1.
 * (!) This will create multiple heading 1 in one part (e.g. this will not work with the normal chapter schema)
 */
export const createFreeDocument = (headings: { id: string; title: string, }[], attrs?: any): DocumentNode<SFNodeType.document> => ({
    type: SFNodeType.document,
    attrs: attrs || {},
    content: headings.map(heading => ({
        type: SFNodeType.heading,
        attrs: { id: heading.id, level: 1 },
        content: [
            {
                attrs: {},
                type: SFNodeType.text,
                text: heading.title
            }
        ]
    }))
});