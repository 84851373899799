import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule,MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ErrorDialogService } from './error-dialog/error-dialog.service';
import { FilterPipe } from './pipes/filter.pipe';
import { ReferenceManagementRoutingModule } from './reference-management-routing.module';
import { CitationEditorComponent } from './references/citation-editor/citation-editor.component';
import { ReferencesComponent } from './references/references.component';
import { referenceFeatureKey, referenceMangementReducer } from './store/references.reducer';

@NgModule({
  declarations: [
    ReferencesComponent,
    FilterPipe,
    CitationEditorComponent,
    ErrorDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReferenceManagementRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatCardModule,
    MatInputModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatTooltipModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatDialogModule,
    StoreModule.forFeature(referenceFeatureKey, referenceMangementReducer)
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    ErrorDialogService
  ]
})
export class ReferenceManagementModule { }
