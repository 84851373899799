<mat-form-field appearance="fill" class="col-12" subscriptSizing="dynamic">
  <mat-label>
    {{ schema.title }}
    <span *ngIf="schema.default"> (default: {{ schema.default }})</span>
  </mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="aFormControl" />
  <mat-hint>YYYY-MM-DD</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker [touchUi]="isTouchDevice" #picker></mat-datepicker>
</mat-form-field>
