import { Directionality } from "@angular/cdk/bidi";
import { Overlay, OverlayKeyboardDispatcher, OverlayOutsideClickDispatcher, OverlayPositionBuilder, ScrollStrategyOptions } from "@angular/cdk/overlay";
import { DOCUMENT, Location } from "@angular/common";
import { ComponentFactoryResolver, Inject, Injectable, Injector, NgZone, Renderer2, RendererFactory2 } from "@angular/core";
import { EditorOverlayContainer } from "./editor-overlay-container";

@Injectable()
export class EditorOverlay extends Overlay {

    private _editorOverlay: EditorOverlayContainer;
    private renderer: Renderer2;

    constructor(
        scrollStrategies: ScrollStrategyOptions,
        _overlayContainer: EditorOverlayContainer,
        _componentFactoryResolver: ComponentFactoryResolver,
        _positionBuilder: OverlayPositionBuilder,
        _keyboardDispatcher: OverlayKeyboardDispatcher,
        _injector: Injector,
        _ngZone: NgZone,
        @Inject(DOCUMENT) _document: any,
        _directionality: Directionality,
        rendererFactory: RendererFactory2,
        _location: Location,
        _overlay: EditorOverlayContainer,
        _outsideClickDispatcher: OverlayOutsideClickDispatcher) {
        super(scrollStrategies, _overlayContainer, _componentFactoryResolver, _positionBuilder, _keyboardDispatcher, _injector, _ngZone, _document, _directionality, _location, _outsideClickDispatcher);
        this.renderer = rendererFactory.createRenderer(null, null);

        this._editorOverlay = _overlayContainer;
    }

    public setContainerElement(containerElement: HTMLElement): void {
        this.renderer.setStyle(containerElement, 'transform', 'translateZ(0)');
        this._editorOverlay.setContainerElement(containerElement);
    }
}