
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorEditComponent } from './author-edit/author-edit.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AffiliationDialogComponent } from './affiliation-dialog/affiliation-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PortalModule } from '@angular/cdk/portal';

import * as fromAuthorManagement from './author-management.reducer';
import { AuthorListComponent } from './author-list/author-list.component';
import { UserCardComponent } from './user-card/user-card.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthorManagementEffects } from './author-management.effects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FunderDialogComponent } from './funder-dialog/funder-dialog.component';
import { GroupDialogComponent } from './group-dialog/group-dialog.component';
import { AuthorManagementNavigationComponent } from './author-management-navigation/author-management-navigation.component';
import { AddAuthorComponent, BACKDROP_PORTAL_DATA } from './add-author/add-author.component';
import { AuthorManagementComponent } from './author-management/author-management.component';
import { AuthorManagementRoutingModule } from './author-management-routing.module';
import { PortalRef } from './portal-ref';
import { DcaRoutingService } from './dca-routing.service';
import { HelperService } from './helper.service';

@NgModule({
  declarations: [AuthorEditComponent, AffiliationDialogComponent, AuthorListComponent, UserCardComponent, UserAvatarComponent, FunderDialogComponent, GroupDialogComponent,
    AuthorManagementNavigationComponent, AddAuthorComponent, AuthorManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    AuthorManagementRoutingModule,
    MatCheckboxModule,
    MatInputModule,
    MatBadgeModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    DragDropModule,
    MatSlideToggleModule,
    PortalModule,
    EffectsModule.forFeature(),
    StoreModule.forFeature(fromAuthorManagement.authorFeatureKey, fromAuthorManagement.reducer),
    EffectsModule.forFeature([AuthorManagementEffects])
  ],
  exports: [
    AuthorEditComponent,
    UserAvatarComponent
  ],
  providers: [
    {provide: PortalRef, useValue: { emit: () => []}},
    {provide: BACKDROP_PORTAL_DATA, useValue: {}}, DcaRoutingService, HelperService]
})
export class AuthorManagementModule {}
