import { createAction, props } from '@ngrx/store';
import { ProjectState } from './project.reducer';

/**
 * Sets the document last modified date
 */
export const selectedDocument = createAction('[Project] Document Selected', props<{ lastModified: Date }>());

/**
 * Checks the project list
 */
export const projectsList = createAction('[Project] Project List', props<{ projectsAvailable: boolean }>());
