import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }

  /**
   * The hostname (w/o a port).
   */
  get hostname(): string {
    return this.nativeWindow.location.hostname;
  }

  /**
   * The host and port.
   */
  get host(): string {
    return this.nativeWindow.location.host;
  }

  set href(href: string) {
    this.nativeWindow.location.href = href;
  }

  get href() {
    return this.nativeWindow.location.href;
  }

  get origin() {
    return this.nativeWindow.location.origin;
  }

  get pathname() {
    return this.nativeWindow.location.pathname;
  }

  /**
   * The protocol (e.g. 'https:')
   */
  get protocol(): string {
    return this.nativeWindow.location.protocol;
  }

  open(url: string, target?: string, windowFeatures?) {
    this.nativeWindow.open(url, target, windowFeatures);
  }

  /**
   * The port (e.g. 8090, 80).
   */
  get port(): string {
    return this.nativeWindow.location.port;
  }

  reload() {
    return this.nativeWindow.location.reload();
  }

  get webSocketBaseUrl() {
    let host = this.hostname;
    if (this.port !== '80') { host = host + ':' + this.port; }
    if (this.protocol === 'https:') { return 'wss://' + host; }
    return 'ws://' + host;
  }
}
