import {
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatSelectionList} from '@angular/material/list';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SfoUiJSONSchema7} from '../../metadata.model';
import {FormService} from '../form.service';
import {SettingsViewEditorComponent} from './settings-editor-view/settings-editor-view.component';

interface Tab {
  id: string;
  label: string;
  description?: string;
  icon?: string;
  tag?: string;
}

@Component({
  selector: 'settings-tab-view',
  templateUrl: './settings-view-tab.component.html',
  styleUrls: ['./settings-view-tab.component.scss'],
})
export class SettingsViewComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private currentComponent?: ComponentRef<SettingsViewEditorComponent>;
  private lastRenderedControl: string | null = null;

  schema$ = new BehaviorSubject<SfoUiJSONSchema7 | null>(null);

  @ViewChild('vcr', {static: true, read: ViewContainerRef}) vcr!: ViewContainerRef;
  @ViewChild('matSelectionList') matSelectionList: MatSelectionList;

  @Input() aFormControl: FormGroup;
  @Input() set schema(data: SfoUiJSONSchema7 | null) {
    this.schema$.next(data);
  }

  activeTab: string = '';
  tabs: Tab[] = [];
  advancedMode$ = this.formService.advancedMode$;

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.schema$.subscribe(() => {
        this.initializeTabs();
        this.renderDynamicComponent('');
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.reset();
    this.schema$.complete();
  }

  private initializeTabs(): void {
    this.reset();
    this.tabs = [];
    this.activeTab = '';
    this.lastRenderedControl = null;

    if (this.matSelectionList) {
      this.matSelectionList.deselectAll();
    }

    const schema = this.schema$.getValue();
    if (!schema || !schema.properties) {
      return;
    }

    this.tabs = Object.entries(schema.properties).map(([key, value]) => ({
      label: value?.title ?? key,
      icon: value['_ui_icon'],
      id: key,
      tag: value['_ui_tag'],
      description: value?.description,
    }));
  }

  selectConfiguration(control: string): void {
    if (this.activeTab === control) {
      return;
    }

    this.activeTab = control;
    this.renderDynamicComponent(control);
  }

  private renderDynamicComponent(control: string): void {
    const schema = this.schema$.getValue();

    if (!schema || this.lastRenderedControl === control) {
      return;
    }

    this.destroyCurrentComponent();

    this.currentComponent = this.vcr.createComponent(SettingsViewEditorComponent);
    const instance = this.currentComponent.instance;
    instance.schema = schema;
    instance.aFormControl = this.aFormControl;
    instance.control = control;

    this.lastRenderedControl = control;
    this.currentComponent.changeDetectorRef.detectChanges();
  }

  private destroyCurrentComponent(): void {
    if (this.currentComponent) {
      this.currentComponent.destroy();
      this.currentComponent = undefined;
      this.lastRenderedControl = null;
    }
  }

  private reset(): void {
    this.destroyCurrentComponent();
    if (this.vcr) {
      this.vcr.clear();
    }
  }

  trackByLabel(_: number, tab: Tab): string {
    return tab.id;
  }

  toggleAdvanceMode(): void {
    this.formService.toggleAdvancedMode();
  }
}
