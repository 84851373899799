import { Plugin, PluginKey, EditorState } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const replacePluginKey = new PluginKey('state');

export const replacePlugin: Plugin = new Plugin({
    key: replacePluginKey,
    state: {
        init(_config, state: EditorState) { return { search: null, decorations: DecorationSet.empty }; },
        apply(transaction, oldState: any, newState: EditorState) {
            let search = oldState.search;
            let decorations: Decoration[] = [];
            if (transaction.getMeta('search-and-replace')) {
                console.log('replace', transaction.getMeta('search-and-replace'));
                search = transaction.getMeta('search-and-replace');
                newState.doc.descendants((node, pos) => {
                    if (node.isText) {
                        if (node.textContent.indexOf(search) !== -1) {
                            const index = node.textContent.indexOf(search);
                            console.log('SHOULD ADD RANGE', search, node);
                            const from: number = pos + index;
                            const to: number = pos + index + search.length;
                            decorations.push(Decoration.inline(from, to, { class: 'js-search-highlight' }));
                        }
                        return false;
                    }

                    if (node.textContent.indexOf(search) !== -1) {
                        return true;
                    }

                    return false;
                });
            }
            return { decorations: DecorationSet.create(newState.doc, decorations), search };
        },
    },
    props: {
        decorations(s) {
            return (this as Plugin).getState(s)?.decorations;
        }
    }
});
