import { Action, ActionReducer, createReducer, createSelector, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';

export const projectFeatureKey = 'project';

export interface ProjectState {
    lastModified: any;
    projectsAvailable: boolean;
}

const initialState: ProjectState = {
    lastModified: undefined,
    projectsAvailable: false
};

const ProjectReducer = createReducer(
    initialState,
    on(ProjectActions.selectedDocument, (state, action) => ({ ...state, lastModified: action.lastModified, })),
    on(ProjectActions.projectsList, (state, action) => ({ ...state, projectsAvailable: action.projectsAvailable }))
);

export const selectDocument = (state): ProjectState => state.project;

export const selectProjectAvailable = createSelector(
    selectDocument,
    (state: ProjectState) => state.projectsAvailable
);

export const reducer: ActionReducer<ProjectState, any> = (state: ProjectState | undefined, action: Action) => {
    return ProjectReducer(state, action);
};
