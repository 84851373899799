export function hasWriteAccess(roles: string[]) {
    for (let role of roles) {
        // only owners and authors have write access
        // check for role names used in backend
        if (['owner', 'author', 'editor'].indexOf(role) !== -1) { return true; }
        // second check for roles used in UI
        if (['Admin', 'Author', 'Editor'].indexOf(role) !== -1) { return true; }
    }
    return false;
};

export function hasReadAccess(roles: string[]) {
    return hasAnnotationAccess(roles) || hasWriteAccess(roles);
}

export function hasAnnotationAccess(roles: string[]) {
    if (hasWriteAccess(roles)) { return true; }
    for (let role of roles) {
        // check for role names used in backend
        if (['comment-only'].indexOf(role) !== -1) { return true; }
        // second check for roles used in UI
        if (['CommentOnly'].indexOf(role) !== -1) { return true; }
    }
    return false;
}

export function hasAdminAccess(roles: string[]) {
    return roles.indexOf('owner') !== -1 || roles.indexOf('Admin') !== -1;
}

export function hasEditingAccess(roles: string[]) {
    return roles.indexOf('editor') !== -1 || roles.indexOf('owner') !== -1;
}

export const rolesChanged = (before: string[], after: string[]) => before.sort().join() !== after.sort().join();