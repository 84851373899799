<!-- <mat-toolbar *ngIf="hideModals" class="editor-toolbar mat-elevation-z0">
    <div *ngFor="let command of footnoteMenu" class="flex group">
        <span class="flex">
            <div class="flex">
                <button mat-icon-button (click)="execCommand(command)" [disabled]="!command.active">
                    <mat-icon [svgIcon]="command.svgIcon" *ngIf="command.svgIcon"
                        [matTooltip]="command.tooltip || ''" matTooltipClass="tooltip"></mat-icon>
                    <mat-icon *ngIf="!command.svgIcon" [matTooltip]="command.tooltip || ''"
                        matTooltipClass="tooltip">{{ command.materialIconName }}</mat-icon>
                </button>
            </div>
        </span>
    </div>
</mat-toolbar> -->

<div #sfoEditorOverlayContainer class="editor-overlay"></div>

<div id="editorWrapper" class="editor-wrapper" #editorWrapper translate="no">
	<div *ngIf="!hideModals" class="editor-toolbar mt1 rounded mat-elevation-z1">
		<span *ngIf="!simpleMode">
			<mat-toolbar class="toolbar" *ngIf="state$ | async; let state">
				<div *ngFor="let group of menu$ | async" class="flex group">
					<span *ngIf="group.id==='format'" class="flex flex-wrap">
						<div *ngFor="let command of group.commands" class="flex">
							<button mat-icon-button (click)="execCommand(command)"
								[disabled]="!command.active || inActiveMainMenu">
								<mat-icon [svgIcon]="command.svgIcon" *ngIf="command.svgIcon"
									[matTooltip]="command.tooltip || ''" matTooltipClass="tooltip"></mat-icon>
								<mat-icon *ngIf="!command.svgIcon" [matTooltip]="command.tooltip || ''"
									matTooltipClass="tooltip">{{ command.materialIconName }}</mat-icon>
							</button>
						</div>
					</span>
				</div>
			</mat-toolbar>
			<mat-toolbar class="editor-toolbar secondary-group toolbar" *ngIf="tableMenuVisible">
				<div *ngFor="let group of menu$ | async" class="flex">
					<span *ngIf="(group.id==='table') && tableMenuVisible" class="flex">
						<div *ngFor="let command of group.commands" class="flex">
							<button mat-icon-button (click)="execCommand(command)" [disabled]="!command.active">
								<mat-icon [svgIcon]="command.svgIcon" *ngIf="command.svgIcon"
									[matTooltip]="command.tooltip || ''" matTooltipClass="tooltip"></mat-icon>
								<mat-icon *ngIf="!command.svgIcon" [matTooltip]="command.tooltip || ''"
									matTooltipClass="tooltip">{{ command.materialIconName }}</mat-icon>
							</button>
						</div>
					</span>
				</div>
			</mat-toolbar>
		</span>
	</div>

	<div #editor class="editor" [ngClass]="{ 'labels': showLabels }"></div>
	<!-- <div #editor *ngIf="!hideModals" class="editor" [ngClass]="{ 'disable-event': inActiveMainMenu}"></div> -->
	<div class="editor-bottom-sheet-wrapper">
		<ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
	</div>
</div>

<ng-template cdkPortal #templatePortals="cdkPortal">
	<mat-form-field>
		<mat-select>
			<mat-option [value]="'test'" *ngFor="let command of ['h1', 'h2', 'h3', 'h4', 'p', 'quote']">
				{{ command }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</ng-template>

<button *ngIf="hideModals" color="primary" mat-button class="uppercase right" (click)="updateFootnote()">Update</button>