import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as AuthorManagementActions from './author-management.actions';

@Injectable()
export class AuthorManagementEffects {

  notUpdatingAuthors$ = createEffect(() => this.actions$.pipe(
    ofType<any>(AuthorManagementActions.authorNotSaved.type),
    mergeMap((action) => {
      for (const error of action.errors) {
        this.snackbar.open(`${action.message}: ${error.message}`, undefined, { duration: 4000 });
      }
      return of({ type: '[Author Management] Error displayed' });
    })
  ));

  updatingAuthors$ = createEffect(() => this.actions$.pipe(
    ofType<any>(AuthorManagementActions.authorSaved.type),
    mergeMap(() => {
      this.snackbar.open(`Changes saved`, undefined, { duration: 4000 });
      return of({ type: '[Author Management] Success message displayed' });
    })
  ));

  constructor(private actions$: Actions, private snackbar: MatSnackBar) { }
}
