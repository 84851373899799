<div>
    <h2 mat-dialog-title>Create a <b>native table</b> in the document.</h2>
    <div mat-dialog-content>
        <form class="pt2">
            <mat-form-field appearance="outline" class="mr2">
                <mat-label>columns</mat-label>
                <input matInput type="number" value=3 #cols>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mr2">
                <mat-label>rows</mat-label>
                <input matInput type="number" value=2 #rows>
            </mat-form-field>
        </form>
    </div>
    <mat-dialog-actions align="end" class="mt2">
        <button (click)="uploadImage()" mat-button class="uppercase">Upload an image instead</button>
        <button color="primary" (click)="createTable(cols.value, rows.value)" mat-button class="uppercase">Create table</button>
    </mat-dialog-actions>
</div>