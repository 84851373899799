<div class="cite-dialog" #dialog>
    <div class="flex flex-justify">
      <h2 class="title my-auto">Insert citation</h2>
    </div>
    <div *ngIf="(hasReferences$ | async) == null">
      <div class="progress">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
      </div>
    </div>
    <div *ngIf="(hasReferences$ | async) === false" class="cite-dialog-content" class="empty-view mx4">
      <h2 class="h2">No references to search for, yet</h2>
      <p class='text-with-icon px4'>Let's change this. Check out the References <mat-icon class="mat-36"
          svgIcon="references">
        </mat-icon> tab on the right and upload a reference or connect your reference manager.</p>
    </div>
    <div class="cite-dialog-content" tabIndex="-1" *ngIf="hasReferences$ | async">
      <form class="flex">
        <mat-form-field>
          <input type="text" #searchField [placeholder]="(searching$ | async) ? 'Searching ...' : 'Type to search'" aria-label="References" matInput
            [formControl]="searchInput" [matAutocomplete]="auto" #trigger="matAutocompleteTrigger" >
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
            <!-- <mat-option *ngFor="let reference of filteredReferences$ | async" [value]="reference" class="flex" matTooltip="[{{reference.id}}] {{ reference.author | authorList: 2 }} / {{reference['container-title']}}">
              <span class="label" [innerHTML]="reference.renderedString"></span>
            </mat-option> -->
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div class="px2 mt3" *ngIf="selectedReferences?.length === 0">
        Search for any reference in this document or your personal library.
      </div>
      <div class="detail-table" *ngIf="selectedReferences.length > 0">
        <table>
          <thead>
            <tr>
              <th class="non-numeric">Prefix</th>
              <th class="non-numeric">Reference</th>
              <th class="non-numeric">Page</th>
              <th class="non-numeric">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reference of selectedReferences">
              <td class="ref-prefix">
                <mat-form-field style="width: 2em;">
                  <input matInput maxSize="10" size="4" class="non-numeric" [value]="reference.options.prefix"
                    (keyup)="referenceChange()">
                </mat-form-field>
              </td>
              <td class="ref-title non-numeric" [innerHTML]="reference.renderedString" matTooltip="{{ reference.id }}">
              </td>
              <td class="ref-page">
                <mat-form-field style="width: 2em;">
                  <input matInput class="non-numeric" maxSize="10" size="3" [value]="reference.options.locator"
                    (keyup)="referenceChange()">
                </mat-form-field>
              </td>
              <td class="non-numeric">
                <button mat-icon-button (click)="removeReference(reference)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="additional-options">
          <mat-expansion-panel [expanded]="customFormat != null">
            <mat-expansion-panel-header>
              <mat-panel-title>
                custom format
              </mat-panel-title>
            </mat-expansion-panel-header>
  
            <mat-button-toggle-group class="citation-options" [value]="customFormat">
              <mat-button-toggle>
                <span>default</span>
              </mat-button-toggle>
              <mat-button-toggle value="suppress-author">
                <span style="text-decoration:line-through">author</span>
              </mat-button-toggle>
              <mat-button-toggle value="author-only">
                <span>only author</span>
              </mat-button-toggle>
              <mat-button-toggle value="combined">
                <span>combined</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <div class="right-align mt2">
      <button mat-button color="mat-warn" (click)="close()">
        <span>CANCEL</span>
      </button>
      <button cdk-focus-initial mat-button color="primary" (click)="closeAndSubmit()" #insertButton
        [disabled]="selectedReferences.length === 0">
        <span>INSERT</span>
      </button>
    </div>
  </div>
  
  <ng-template #loading>
    <div class="progress">
      <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
    </div>
  </ng-template>