import { Injectable } from '@angular/core';
import { NodeView } from 'prosemirror-view';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { Store } from '@ngrx/store';
import { getCrossReferencePlugin } from './prosemirror/cross-reference.plugin';
import { LinkView } from './prosemirror/link.view';

@Injectable({
  providedIn: 'root'
})
export class DocumentOutlineService {

  constructor(private store: Store) { }

  public focusElement(elementId: string): void {
    const element = document.querySelector('[data-id="' + elementId + '"]');
    console.log('Looking for ', elementId, element);
    if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }

  /**
   * @returns the plugins and views needed by this module.
   */
   getProsemirror(injector): { views: { [nodeType: string]: (node: ProseMirrorNode) => NodeView }, plugins: Plugin[] } {
    return {
      plugins: [getCrossReferencePlugin()],
      views: {
        link: (node) => new LinkView(node, injector, { store: this.store })
      }
    };
  }
}
