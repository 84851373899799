import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrcidService {

  constructor() { }

  async getOrcid(orcid: string) {
    try {
      const source = await fetch('https://pub.orcid.org/v3.0/' + orcid, { headers: { 'Accept': 'application/xml' } })
      const blob = await source.blob();
      const record = await blob.text();
      return this.orcidUpdate(record);
    } catch(e) {
      console.error('Error:', e);
      throw new Error('Could not fetch ORCID data');
    }    
  }

  orcidUpdate(record: string) {
    const parser = new DOMParser();
    let parsed = parser.parseFromString(record, "application/xml");
    let authorAffiliations = [] as  any;
    let authorFunders = [] as  any;
    let authorUpdate = {
      firstName: this.valueCheck(this.evaluateXPath(parsed, '//personal-details:given-names/text()')),
      lastName: this.valueCheck(this.evaluateXPath(parsed, '//personal-details:family-name/text()')),
      email: null,
      twitter: null,
      // positions: authorAffiliations,
      // groups: [],
      // funders: [],
    };

    this.evaluateXPath(parsed, '//employment:employment-summary' ).forEach(  (e:any) => {
      let affiliation = {
        id: this.valueCheck(this.evaluateXPath(e, '@put-code')),
        title: this.valueCheck(this.evaluateXPath(e, './common:role-title/text()')),
        institution: this.valueCheck(this.evaluateXPath(e, './common:organization/common:name/text()')),
        department: this.valueCheck(this.evaluateXPath(e, './common:department-name/text()')),
        street: null,
        city: this.valueCheck(this.evaluateXPath(e, './common:organization/common:address/common:city/text()')),
        country: this.valueCheck(this.evaluateXPath(e, './common:organization/common:address/common:country/text()')),
        phone: null,
        primary: null,
      }
      
      authorAffiliations.push(affiliation);
    });

    this.evaluateXPath(parsed, '//funding:funding-summary' ).forEach(  (e:any) => {
      let funder = {
        id: this.valueCheck(this.evaluateXPath(e, '@put-code')),
        countryCode: this.valueCheck(this.evaluateXPath(e, './common:organization/common:address/common:country/text()')),
        institution: {
          id: this.valueCheck(this.evaluateXPath(e, './common:organization/common:disambiguated-organization/common:disambiguated-organization-identifier/text()')),
          name: this.valueCheck(this.evaluateXPath(e, './common:organization/common:name/text()')),
          type: 'doi'
        },
        awardIds: this.valueCheck(this.evaluateXPath(e, './common:external-ids/common:external-id/common:external-id-value/text()')),
      }
      authorFunders.push(funder);
    });

    
    return {
      author: this.removeEmpty(authorUpdate),
      affiliations: authorAffiliations || [],
      funders: authorFunders || []
    }
    
  }

  valueCheck(nodeArray) {
    return (nodeArray[0]) ? nodeArray[0].nodeValue : null
  }

  removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce(
        (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? this.removeEmpty(v) : v }),
        {}
      );
  }

  evaluateXPath(aNode, aExpr) {
    const xpe = new XPathEvaluator();
    const nsResolver = xpe.createNSResolver(aNode.ownerDocument == null ?
      aNode.documentElement : aNode.ownerDocument.documentElement);
    let result: XPathResult = xpe.evaluate(aExpr, aNode, nsResolver, 0, null);
    let found: any[] = [];
    let res: Node | null;
    while (res = result.iterateNext()){
      found.push(res);
    }
    return found;
  }
}
