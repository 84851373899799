import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { editorFeatureKey, reducer } from './editor.reducer';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MenuService } from './menu.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TextElementsModule } from './text-elements/text-elements.module';
import { ProsemirrorModule } from './prosemirror/prosemirror.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    TextElementsModule,
    ProsemirrorModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    StoreModule.forFeature(editorFeatureKey, reducer)
  ],
  providers: [MenuService],
  schemas: [ NO_ERRORS_SCHEMA ], // TODO remove this
  exports: [TextElementsModule, ProsemirrorModule]
})
export class EditorModule { }
