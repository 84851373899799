import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hasMultipleKeys',
})
export class HasMultipleKeysPipe implements PipeTransform {
  transform(value: unknown): boolean {
    if (!value || typeof value !== 'object') {
      return false;
    }

    const keys = Object.keys(value);
    return keys.length > 1;
  }
}
