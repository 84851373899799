import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PresetMenuComponent} from './preset-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [PresetMenuComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
  exports: [PresetMenuComponent],
})
export class PresetMenuModule {}
