<form [formGroup]="addLinkFormGroup">
  <h2 mat-dialog-title>Add or edit a hyperlink <span class="opacity">({{ isMac ? '⌘' : 'ctrl' }}+⇧+h)</span></h2>
  <mat-dialog-content class="mat-dialog-content">
    <div class="pt1">
      <mat-form-field appearance="outline" class="col-12 mb2">
        <mat-label>Text</mat-label>
        <input matInput placeholder="Text" #textField [value]="data?.text || ''" (keydown)="inputKeyDown($event)"
          tabindex="1">
        <mat-error *ngIf="textField.oninvalid">{{'Should be filled'}}</mat-error>
        <mat-hint>The text displayed in the document</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="col-12 mb2">
      <mat-label>URL</mat-label>
      <input matInput placeholder="URL" required #urlField [value]="data?.attrs?.href || ''" formControlName="linUrl"
        (keydown)="inputKeyDown($event)" tabindex="2">
      <mat-error *ngIf="urlField.oninvalid">{{'Should be filled'}}</mat-error>
      <mat-icon matSuffix (click)="openUrl()" class="cursor-pointer">link</mat-icon>
      <mat-hint>The web address, usually starts with https://</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 mb2">
      <mat-label>Title</mat-label>
      <input matInput placeholder="title" #titleField [value]="data?.attrs?.title || ''"
        (keydown)="inputKeyDown($event)" tabindex="3">
      <mat-hint>Optional link title</mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions mt2">
    <button mat-button (click)="delete()" tabindex="4" *ngIf="data?.attrs?.href" aria-label="delete link"
      class="uppercase justify-start">
      remove link
    </button>
    <span class="fill-remaining-space"></span>
    <button mat-button (click)="close()" tabindex="-1" class="justify-end uppercase">cancel</button>
    <button color="primary" mat-button (click)="submit()" tabindex="4" class="justify-end uppercase"
      [disabled]="!addLinkFormGroup.valid">{{ data?.attrs && data?.attrs.href != null ? 'update' : 'add' }}
      link</button>
  </mat-dialog-actions>
</form>