import { Injectable, Inject } from '@angular/core';
import { Observer, Observable } from 'rxjs';

import { DOCUMENT } from '@angular/common';

/**
 * Handles processing of drop events.
 *
 * @export
 * @class DropService
 */
@Injectable({
  providedIn: 'root'
})
export class DropService {
  constructor(@Inject(DOCUMENT) private document) { }

  /**
   * Cluster files by type and processs them in the appropriate service.
   *
   * @private
   * @param {FileList} input
   */
  public processFiles(input: FileList): Observable<any> {
    const files: File[] = []; for (let i = 0; i < input.length; i++) { files.push(input[i]); }
    // cluster files by type (we may have multiple ones: references, images, ..)
    const cluster: { [extension: string]: File[] } = { bib: [], ris: [], xml: [], jpg: [], png: [], svg: [], tiff: [], gif: [] };
    files.forEach((file: File) => {
      let ext = file.name.split('.').pop();
      if (ext == null) { console.error('Did not recognize file', file.name); return; }
      ext = ext.toLowerCase();
      if (ext === 'jpeg') { ext = 'jpg'; }
      if (ext === 'tif') { ext = 'tiff'; }
      if (!cluster[ext]) { cluster[ext] = []; }
      cluster[ext].push(file);
    });

    const calculateDimensions = (width, height) => {
      const aspectRatio = width / height;
      if (width > height) {
        // in case of wide images, like for table images we want all of the width at a reduced height
        if (height > 580) {
          return {
            height: 580,
            width: 580 * aspectRatio
          // tslint:disable-next-line: semicolon
          }
        }
      } else {
        // in case of tall images we want enough of the height to be visible, so we'll cap off at 700 width
        if (width > 700) {
          return {
            height: 700 / aspectRatio,
            width: 700
          // tslint:disable-next-line: semicolon
          }
        }
      }

      // image was smaller anyways
      return {
        height,
        width
      };
    };

    const createThumbnailImage = (image: HTMLImageElement, width, height, name): HTMLCanvasElement => {
      const nameSplit = name.split('.');
      const ext = nameSplit[nameSplit.length - 1];

      // we scale up svg to at least the preview hight
      const aspectRatio = width / height;
      if (ext === 'svg' && width < 700) { width = 700; height = width / aspectRatio; }
      const calculatedDimensions = calculateDimensions(width, height);
      const canvas = this.document.createElement('canvas');
      canvas.width = calculatedDimensions.width;
      canvas.height = calculatedDimensions.height;
      console.log('aspect ratio', aspectRatio, 'height', canvas.height, 'width', canvas.width);
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      return canvas;
    };

    // const references: File[] = [...cluster['bib'], ...cluster['ris'], ...cluster['xml']];
    const images: File[] = [...cluster['jpg'], ...cluster['png'], ...cluster['svg'], ...cluster['tiff'], ...cluster['gif']];

    let imageCount = 0;
    return Observable.create((obs: Observer<any>) => {
      images.forEach((image) => {
        imageCount++;
        const url: string = URL.createObjectURL(image);
        const tempImage = new Image();
        tempImage.onerror = (error) => {
          obs.error(error);
        };
        function handleLoad() { // use function to bind the width
          const { naturalHeight, naturalWidth } = this as HTMLImageElement;
          const thumbnail: HTMLCanvasElement = createThumbnailImage(this as HTMLImageElement, naturalWidth, naturalHeight, image.name);
          const thumbUrl = thumbnail.toDataURL('image/jpeg', 0.92);
          obs.next({
            type: 'image',
            name: image.name,
            thumbnail: thumbUrl,
            original: image,
            dimensions: { height: naturalHeight, width: naturalWidth }
          });
          if (images.length === imageCount) {
            // all images have been processed
            obs.complete();
          }
        }
        tempImage.onload = handleLoad;
        tempImage.src = url;
      });
    });

  }
}
