import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelection } from '../../editor.reducer';
import { EditorService } from '../../editor.service';
import { HyperlinkDialogComponent } from '../../text-elements/hyperlink-dialog/hyperlink-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { removeHyperlink, updateAndFocusHyperlink } from '../commands';
import { EditorView } from 'prosemirror-view';

@Component({
  selector: 'sfo-selection-actions',
  templateUrl: './selection-actions.component.html',
  styleUrls: ['./selection-actions.component.css']
})
export class SelectionActionsComponent implements OnInit {

  selection$ = this.store.select(selectSelection);
  view: EditorView | null;

  constructor(private store: Store, public dialog: MatDialog, private editorservise: EditorService) {
    // FIXME #18 we should find a way to get the events directly 
    // from the component portal instead of using a global service
    this.editorservise.currentView.subscribe(view => this.view = view );
  }

  ngOnInit(): void { }

  editLink(anchor) {
    let dialogRef = this.dialog.open(HyperlinkDialogComponent, {
      data: {
        text: anchor.node.text,
        attrs: anchor.anchor.attrs,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.delete) {
        return removeHyperlink(result.id)(this.view?.state, this.view?.dispatch);
      } 
      if (result && !result.isNew && result.attrs && result.attrs.id) {
        return updateAndFocusHyperlink(result.attrs.id, result.attrs, result.text)(this.view?.state, this.view?.dispatch);
      }
      return;
    });
  }

}
