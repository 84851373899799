import { Injectable } from '@angular/core';
import { ReferenceRenderer, CSLReference, CitationItem } from '@sciflow/cite';

@Injectable({
  providedIn: 'root'
})
export class RenderingService {

  styleUrl = '/export/csl';
  localUrl = '/export/csl/locales-';
  defaults: { styleXML: string; localeXML: string; };

  constructor() {
    this.loadDefaults();
  }

  /**
   * Renders one or more references as a citation.
   */
  render(citationItems: CitationItem[], references: CSLReference[]): string {
    if (!this.defaults?.styleXML) { throw new Error('No styles loaded'); }
    const renderer = new ReferenceRenderer(this.defaults?.styleXML, this.defaults.localeXML, references, { format: 'text' });
    return renderer.renderCitationOutOfContext(citationItems);
  }

  public getBibliography(references: CSLReference[]): string[] {
    if (!this.defaults?.styleXML) { throw new Error('No styles loaded'); }
    const renderer = new ReferenceRenderer(this.defaults?.styleXML, this.defaults.localeXML, references, { format: 'text' });
    const bibliography = renderer.getBibliographyFromAll();
    return bibliography.references;
  }

  /**
   * Loads the default citation style and locale.
   */
  private async loadDefaults(): Promise<void> {
    const styleXML = await this.getStyleXML();
    const localeXML = await this.getLocaleXML();

    this.defaults = { styleXML, localeXML };
  }

  private async getStyleXML(id = 'apa'): Promise<string> {
    const res = await fetch(`${this.styleUrl}/${id}.xml`);

    if (res.status === 200) {
      return res.text();
    }

    throw new Error('Could not load style');
  }

  private async getLocaleXML(locale = 'en-US') {
    const res = await fetch(`${this.localUrl}${locale}.xml`);

    if (res.status === 200) {
      return res.text();
    }

    throw new Error('Could not load locale');
  }
}
