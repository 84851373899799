<div *ngIf="affiliation">
    <h2 mat-dialog-title>Add affiliation</h2>
    
    <mat-dialog-content>
        <p>Your affiliations will be exported when you choose a journal template or any of our XML export formats like JATS.</p>
        <h3>Search affiliations</h3>
        <mat-form-field appearance="outline" class="col-12">
            <input type="text" placeholder="" aria-label="Institution" matInput [formControl]="institutionsControl"
                [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option"
                    (onSelectionChange)="selectedInstitution($event)">
                    {{option.long}}
                </mat-option>
            </mat-autocomplete>
            <mat-hint>You can select from any affiliations already in use in this document.</mat-hint>
        </mat-form-field>
        <h3>Institution</h3>
        <div [formGroup]="affiliation">
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Institution</mat-label>
                <input matInput formControlName="institution">
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Department</mat-label>
                <input matInput formControlName="department">
                <mat-hint>The subsection, field, or department related to the affiliation. For employment, this could be the specific department, e.g. Music Theory.</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Job title</mat-label>
                <input matInput formControlName="title">
                <mat-hint>Be sure to include a descriptive title when adding an affiliation! Include the role or job title, e.g. Intern.</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Street</mat-label>
                <input matInput formControlName="street">
            </mat-form-field>
            <div class="flex items-center flex-wrap mxn2">
                <mat-form-field appearance="outline" class="col-12 md-col-6 px2">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>
      
                <mat-form-field appearance="outline" class="col-12 md-col-6 px2">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                        <mat-option *ngFor="let data of countryList" value={{data.code}}>{{data.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phone" type="tel">
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt2" align="end">
        <button mat-button mat-dialog-close class="uppercase">Discard changes</button>
        <button mat-button color="primary" class="uppercase" (click)="save()" cdkFocusInitial
            [disabled]="affiliation.pristine || affiliation.invalid">Save</button>
    </mat-dialog-actions>
</div>
