import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { CitationItem, CitationWithId } from '@sciflow/cite';
import { Apollo } from 'apollo-angular';
import { Plugin } from 'prosemirror-state';
import { EditorView, NodeViewConstructor } from 'prosemirror-view';
import { take } from 'rxjs/operators';
import { CitationView } from '../prosemirror/citation.view';
import { updateCitationByIdCommand } from '../prosemirror/commands';
import { getReferencePlugin } from '../prosemirror/references.plugin';
import { RenderingService } from '../rendering.service';
import { updateCitation } from '../store/references.actions';
import { selectReferenceList } from '../store/references.reducer';

@Injectable({
  providedIn: 'root'
})
export class ReferenceManagementService {

  private view: EditorView;

  constructor(private apollo: Apollo, private store: Store, private injector: Injector, private renderingService: RenderingService) { }

  /** Returns the CSL for a list of citation items. */
  // FIXME make this return Promise<CSLReference> instead of any
  async getCSL(citationItems: CitationItem[]): Promise<any> {
    const references = await this.store.select(selectReferenceList).pipe(take(1)).toPromise();
    return references?.filter((reference) => citationItems.some((citationItem) => citationItem.id === reference.csl?.id)).map((ref) => ref?.csl);
  }

  async getReferencesEditCitation(citationItems: CitationItem[]): Promise<any> {
    const references = await this.store.select(selectReferenceList).pipe(take(1)).toPromise();
    return references?.map(({ csl }) => csl).filter((reference) => citationItems.some((citationItem) => citationItem.id === reference.csl?.id)).map((ref) => ref);
  }

  /**
   * Updates a citation in the editor.
   */
  async updateCitation(citation: CitationWithId): Promise<void> {
    const csls = await this.getCSL(citation.citationItems);
    const plainCitation = this.renderingService.render(citation.citationItems, csls);
    this.store.dispatch(updateCitation({ citation: { ...citation, plainCitation } }));
    if (this.view) {
      console.log('updating citation in editor', citation.citationID, plainCitation);
      updateCitationByIdCommand(citation, plainCitation)(this.view.state, this.view.dispatch);
    }
  }

  /**
   * @returns the plugins and views needed by this module.
   */
  getProsemirror(injector): { views: { [nodeType: string]: NodeViewConstructor }, plugins: Plugin[] } {
    return {
      plugins: [getReferencePlugin(this.store, (editorView: EditorView) => this.view = editorView)],
      views: {
        citation: (node, nodeView, getPos) => new CitationView(node, nodeView, getPos, injector)
      }
    };
  }

}
