import { Observable, Subject } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

/**
 * Maintains a reference of a background component and any events.
 */
export class PortalRef<T, R> {
    private _component: ComponentType<T> | undefined;
    private _result = new Subject<R | undefined>();
    private _config: any;

    constructor(component: ComponentType<T>, config: any) {
        this._component = component;
        this._config = config;
    }

    get initialConfiguration() {
        return this._config;
    }

    emit(result: R | undefined) {
        this._result.next(result);
    }

    onResult(): Observable<R | undefined> {
        return this._result;
    }
}
