import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sfo-figure',
  templateUrl: './figure.component.html',
  styleUrls: ['./figure.component.scss']
})
export class FigureComponent implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUploadButton: ElementRef;
  @Output() commands = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<FigureComponent>) { }

  ngOnInit(): void {
  }

  createTable(cols, rows) {
    const data = { id: 'create-table', payload: { cols, rows, hasHeaderRow: true } };
    this.dialogRef.close(data);
  }

  uploadImage() {
    const data = { id: 'upload-image' };
    this.dialogRef.close(data);
  }

  emit(command: { id: string; payload?: any; }) {
    this.commands.emit(command);
  }

}
