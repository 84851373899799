import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import {SfoUiJSONSchema7} from '../metadata.model';
import {SfoFormHelperService} from './form-helper.class';
import {FormService} from './form.service';
import {ViewType} from './view.types';

@Component({
  selector: 'sfo-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private formService: FormService = inject(FormService);
  private formValue$: BehaviorSubject<object> = new BehaviorSubject({});
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  schema$ = new BehaviorSubject<SfoUiJSONSchema7 | null>(null);

  formReady = signal<boolean>(false);
  formErrors = signal<string | null>(null);
  schemaErrors = signal<string | null>(null);

  parentForm: FormGroup = new FormGroup({});

  /**
   * Sets the JSON schema and triggers a change detection in the component.
   * @param data - The JSON schema data to be set. It is cast to `JSONSchema7Ui` type.
   */
  @Input() set jsonSchema(data: unknown) {
    this.schema$.next(data as SfoUiJSONSchema7);
  }

  /**
   * The view type for the settings. This determines the layout or mode in which settings are displayed.
   * @type {ViewType}
   * @default 'settings'
   */
  @Input() settingsView: ViewType = 'settings';

  /**
   * Patches the current internally generated FormGroup with a new value and triggers a change detection in the component.
   * @param formValue - The value to be set in the form. If null, no action is taken.
   */
  @Input() set formValue(formValue: object | null) {
    if (!formValue) return;
    this.formValue$.next(formValue);
  }

  /**
   * The key of the parent schema. This is used to reference a specific part of the schema within the component.
   * @type {string}
   */
  @Input() parentSchemaKey: string;

  /**
   * The `FormGroup` instance associated with this component. It represents the structure of the form controls and their validation.
   * @type {FormGroup}
   */
  @Input() aFormControl: FormGroup;

  /**
   * Emits an event with the current `FormGroup`.
   * @type {EventEmitter<FormGroup>}
   */
  @Output() formEvent = new EventEmitter<FormGroup>();

  /**
   * Emits an event when the patch is invalid
   * @type {EventEmitter<FormGroup>}
   */
  @Output() patchError = new EventEmitter<unknown | unknown[]>();

  ngOnInit(): void {
    this.schema$
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(() => this.initializeForm()),
        switchMap((schema) =>
          this.parentForm.valueChanges.pipe(
            filter(() => this.parentForm.valid),
            map((formValue) => {
              const cleanedFormValues = SfoFormHelperService.removeNullValues(formValue, schema);
              return SfoFormHelperService.removeDefaults(cleanedFormValues, schema);
            }),
          ),
        ),
      )
      .subscribe((processedValue) => {
        if (!processedValue) return;
        this.formEvent.emit(processedValue);
      });

    combineLatest([this.schema$, this.formValue$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([schema, patch]) => Boolean(schema)),
        tap(([schema, patch]) => this.handleFormPatch(schema, patch)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeForm(): void {
    this.schemaErrors.set(null);
    this.formErrors.set(null);

    const schema = this.schema$.getValue();
    if (!schema) {
      this.schemaErrors.set('The schema provided is empty.');
      return;
    }

    this.formReady.set(false);

    if (this.parentForm) {
      this.parentForm.reset();
    }

    try {
      this.parentForm = this.formService.generateForm(schema);
      this.formReady.set(true);
    } catch (e) {
      console.error(e);
      this.formErrors.set(e?.['message']);
    }
    this.cd.markForCheck();
  }

  private handleFormPatch(schema: any, patch: any): void {
    try {
      const isPatchValid = SfoFormHelperService.isPatchValid(schema, patch);
      if (!isPatchValid?.isValid) {
        this.patchError.emit(isPatchValid?.errors);
        return;
      }
      this.formErrors.set(null);
      this.parentForm.patchValue(patch, {emitEvent: false});
      this.cd.markForCheck();
    } catch (e) {
      this.patchError.emit(e);
      this.formErrors.set(e);
    }
  }
}
