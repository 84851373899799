import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SfoUiJSONSchema7} from '../../../metadata.model';

@Component({
  selector: 'basic-side-tab-child',
  templateUrl: './settings-editor-view.component.html',
})
export class SettingsViewEditorComponent {
  @Input() control: string = '';
  @Input() schema: SfoUiJSONSchema7 | null;
  @Input() aFormControl: FormGroup | null;
}
