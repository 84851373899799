import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';

import {DynamicFieldComponent} from './dynamic-field.component';
import {BooleanInputComponent} from './dynamic-input/boolean-input';
import {DateInputComponent} from './dynamic-input/date-input';
import {DynamicInputComponent} from './dynamic-input/dynamic-input.component';
import {EnumInputComponent} from './dynamic-input/enum-input';
import {IntegerInputComponent} from './dynamic-input/integer-input';
import {NumberInputComponent} from './dynamic-input/number-input';
import {HandleSchemaTypePipe} from './handle-schema-type.pipe';
import {HasMultipleKeysPipe} from './has-multiple-keys.pipe';
import {UnsortedKeyvaluePipe} from './unsorted-key-value.pipe';
import {SfoWidgetModule} from '../../widgets';
import {TextareaInputComponent} from './dynamic-input/textarea/textarea.component';

@NgModule({
  declarations: [
    BooleanInputComponent,
    DateInputComponent,
    DynamicFieldComponent,
    DynamicInputComponent,
    EnumInputComponent,
    IntegerInputComponent,
    NumberInputComponent,
    UnsortedKeyvaluePipe,
    HasMultipleKeysPipe,
    HandleSchemaTypePipe,
    TextareaInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAccordion,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatDividerModule,
    SfoWidgetModule,
  ],
  exports: [
    DynamicFieldComponent,
    BooleanInputComponent,
    DynamicInputComponent,
    EnumInputComponent,
    DateInputComponent,
    IntegerInputComponent,
    NumberInputComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'fill'},
    },
    provideMomentDateAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
      },
      display: {
        dateInput: 'YYYY-MM-DD', // <-- for displaying
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
      },
    }),
  ],
})
export class DynamicFieldModule {}
