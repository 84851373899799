import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class EditorOverlayContainer extends OverlayContainer {

  constructor(@Inject(DOCUMENT) document: any, _platform: Platform) {
    super(document, _platform);
  }

  /**
  * Set the container element from the outside, e.g. from the corresponding directive
  */
  public setContainerElement(element: HTMLElement): void {
    this._containerElement = element;
  }
}
