import {Injectable} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {SfoUiJSONSchema7} from '../metadata.model';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private _advancedMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly advancedMode$ = this._advancedMode$.asObservable();

  constructor(private formBuilder: FormBuilder) {}

  /**
   * Toggles the advanced mode between true and false.
   */
  toggleAdvancedMode(): void {
    this._advancedMode$.next(!this._advancedMode$.value);
  }

  generateForm(schema: SfoUiJSONSchema7): FormGroup {
    if (!schema) {
      throw new Error('No schema provided');
    }

    if (!schema['properties']) {
      throw new Error('Invalid schema provided. Properties are missing');
    }

    try {
      return this.buildFormGroup(schema);
    } catch (e) {
      throw e;
    }
  }

  buildFormGroup(schema: SfoUiJSONSchema7, prefix = '', defaults?: unknown): FormGroup {
    const baseForm: FormGroup = this.formBuilder.group({});

    const properties = schema['properties'] || schema;

    for (const key in properties) {
      const property = properties[key];
      const fullKey = prefix ? `${prefix}.${key}` : key; // get total key path

      if (!property.type) {
        if (property['anyOf'] || property['oneOf']) {
          baseForm.addControl(key, this.buildUnionFormControl(property, fullKey));
        }
      } else if (Array.isArray(property.type)) {
        // TODO: Remove this when ready - Backwards compatibility for ["string", "number"]
        baseForm.addControl(key, this.buildUnionArrayFormControl(property.type, fullKey));
      } else {
        switch (property.type) {
          case 'array':
            if (!property.items) {
              console.error(
                `The property ${fullKey} is an array type and must have some items.\nProperty:\n${property}`,
              );
              break;
            }

            baseForm.addControl(
              key,
              this.buildFormArray(
                property,
                fullKey,
                defaults?.[key] || // access default value of an object, passed down from root
                  defaults || // access default value of primitive, passed from root
                  property?.default || // access default at root level
                  undefined,
              ),
            );
            break;
          case 'object':
            if (key === 'options') debugger;

            const newFormControl = this.buildFormGroup(
              property,
              fullKey,
              defaults?.[key] || // access default value of an object, passed down from root
                defaults || // access default value of primitive, passed from root
                property?.default || // access default at root level
                undefined,
            );

            baseForm.addControl(key, newFormControl);
            break;
          case 'number':
            baseForm.addControl(key, this.formBuilder.control(undefined));
            break;
          case 'string':
            const validators: ValidatorFn[] = this.buildStringValidators(property);
            baseForm.addControl(key, this.formBuilder.control(undefined, validators));
            break;
          case 'boolean':
            baseForm.addControl(key, this.formBuilder.control(undefined));
            break;
          default:
            throw new Error(`Building form failed. Path "${fullKey}" has an unsupported type "${property.type}".`);
        }
      }
    }
    return baseForm;
  }

  private buildStringValidators(property: any): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (property.minLength) {
      validators.push(Validators.minLength(property.minLength));
    }

    if (property.maxLength) {
      validators.push(Validators.maxLength(property.maxLength));
    }

    if (property.pattern) {
      validators.push(Validators.pattern(property.pattern));
    }

    if (property.format) {
      switch (property.format) {
        case 'email':
          validators.push(Validators.email);
          break;
      }
    }

    return validators;
  }

  private buildFormArray(property: any, fullKey: string, schemaDefaults?: any): FormArray {
    const formArray = this.formBuilder.array<FormGroup | FormControl>([]);

    if (property.items.type === 'object') {
      // If there are default properties that live on the root level of the schema object
      if (Array.isArray(property.default) && property.default?.length) {
        property.default.forEach((defaultValue: any) => {
          // must parse the default levels down when building form group
          formArray.push(this.buildFormGroup(property.items, fullKey, defaultValue));
        });
        // collect defaults parsed from the root level
      } else if (Array.isArray(schemaDefaults) && schemaDefaults.length) {
        // build form with the defaults living on the root level
        schemaDefaults.forEach((defaultValue: any) => {
          formArray.push(this.buildFormGroup(property.items, fullKey, defaultValue));
        });
      } else {
        formArray.push(this.buildFormGroup(property.items, fullKey));
      }
    } else {
      const defaults = Array.isArray(property.default) ? property.default : [];
      defaults.forEach((defaultValue: any) => {
        formArray.push(this.formBuilder.control(defaultValue));
      });
    }

    return formArray;
  }

  private buildUnionFormControl(property, key): FormControl {
    const validTypes = ['string', 'number'];

    const isStringNumberUnion = (property.anyOf || property.oneOf).every((option) =>
      validTypes.includes(option.type),
    );

    if (!isStringNumberUnion) {
      throw new Error(
        `Unsupported anyOf/oneOf types for property: "${key}". Expected only "string" and "number".`,
      );
    }

    const unionControl = this.formBuilder.control('');

    return unionControl;
  }

  private buildUnionArrayFormControl(property, key): FormControl {
    const validTypes = ['string', 'number'];

    const targetArray = [...property].sort();
    const sourceArray = [...validTypes].sort();

    let isSame = true;

    for (let i = 0; i < targetArray.length; i++) {
      if (sourceArray[i] !== targetArray[i]) isSame = false;
    }

    if (!isSame || property.length !== validTypes.length) {
      throw new Error(`Property must have ${validTypes}. Contains ${property} for ${key}`);
    }

    const unionControl = this.formBuilder.control('');

    return unionControl;
  }
}
