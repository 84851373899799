<mat-form-field floatLabel="auto" appearance="fill" class="col-12" subscriptSizing="dynamic">
  <mat-label>
    {{ schema.title }}
  </mat-label>
  <mat-select [formControl]="aFormControl">
    <mat-option *ngFor="let value of schema.enum" [value]="value">
      {{ value }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="schema.description">{{ schema.description }}</mat-hint>
</mat-form-field>
