import { Component, Input, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import countries from '../countries.json';
import { selectAuthors } from 'projects/reference-app/src/app/app-state.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';

const createAffiliationId = (affiliation) => JSON.stringify(({ ...affiliation, primary: null }));

@Component({
  selector: 'sf-affiliation-dialog',
  templateUrl: './affiliation-dialog.component.html',
  styleUrls: ['./affiliation-dialog.component.scss']
})
export class AffiliationDialogComponent implements OnInit {
  affiliation = new UntypedFormGroup({});
  countryList = countries;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store, private dialogRef: MatDialogRef<AffiliationDialogComponent>, private snackbar: MatSnackBar) { }

  institutionsControl = new UntypedFormControl();
  institutions$ = this.store.select(selectAuthors).pipe(map((authors) => [].concat.apply([],
    authors.map((author) => author.positions as [] || []))
    .filter((author, index, authors) => index === authors.findIndex((a) => JSON.stringify(a) === JSON.stringify(author)))));

  filteredOptions$: Observable<any[]>;

  selectedInstitution(ins) {
    this.affiliation.patchValue({
      ...ins.source.value.obj,
      id: this.affiliation.get('id')?.value
    });
    this.affiliation.markAsDirty();
  }

  displayFn(institutionObj) {
    return institutionObj && institutionObj.long ? institutionObj.long : '';
  }

  save() {
    if (this.affiliation.pristine) { return this.dialogRef.close(); }
    this.dialogRef.close(this.affiliation.value);
    this.snackbar.open('Saved Affiliation successfully', 'Close', { duration: 5000 });
  }

  ngOnInit(): void {
    this.affiliation = this.data.affiliation;
    this.filteredOptions$ = this.institutionsControl.valueChanges
      .pipe(
        startWith(''),
        withLatestFrom(this.institutions$),
        map(([searchString, institutions]) => {
          console.log(searchString, institutions);
          const allInstitutions = institutions.map((ins: any) => {
            return {
              long: [ins.title, ins.department, ins.institution, ins.city].filter(v => v?.length > 0).join(', '),
              obj: ins
            };
          });
          return (typeof searchString === 'string') ? allInstitutions.filter((ins) => ins.long.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) : allInstitutions;
        })
      );
  }

}
