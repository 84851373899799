import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { AuthorManagementNavigationComponent } from '../author-management-navigation/author-management-navigation.component';
import { AddAuthorComponent, BACKDROP_PORTAL_DATA } from '../add-author/add-author.component';
import { Apollo, gql } from 'apollo-angular';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { createAuthor, initializeUsers, maximized, setSelectedAuthor, updateAuthor } from '../author-management.actions';
import { getUser, selectSelectedAuthor } from '../author-management.reducer';
import { DcaRoutingService } from '../dca-routing.service';
import { selectAuthors, selectDocumentId } from 'projects/reference-app/src/app/app-state.reducer';
import { HelperService } from '../helper.service';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { PortalRef } from '../portal-ref';
import { AuthorService } from '../services/author.service';

export const createId = (): string => 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26)) + Math.random().toString(36).substr(2, 9);

export interface BackdropConfig {
  data?: any;
}

@Component({
  selector: 'sf-author-management',
  templateUrl: './author-management.component.html',
  styleUrls: ['./author-management.component.scss']
})
export class AuthorManagementComponent implements OnInit {
  portal: ComponentPortal<any>;

  @ViewChild('front', { static: true }) front: AuthorManagementComponent;
  @ViewChild('back', { static: true }) back: AuthorManagementComponent;

  stop$ = new Subject<void>();

  document$ = this.helperService.getProjectAndDocument()
    .pipe(
      map(r => r?.documentId),
      filter(e => e != null),
      switchMap((documentId) =>
        {
          return this.authorService.addAuthor(documentId);
        }
      )
    );

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authorService: AuthorService, private drs: DcaRoutingService, private helperService: HelperService, private apollo: Apollo, private snackBar: MatSnackBar, private ar: ActivatedRoute, private store: Store, private _injector: Injector) {

    // check route changes
    this.activatedRoute.params.subscribe(async (params) => {
      const selectedAuthor = await this.store.select(selectSelectedAuthor).pipe(take(1)).toPromise();
      const authors = await this.store.select(selectAuthors).pipe(take(1)).toPromise();
      // if the param has a newer id, navigate there if the author exists
      if (params.authorId && selectedAuthor !== params.authorId) {
        if (authors?.some(author => author.authorId === params.authorId)) {
          this.store.dispatch(setSelectedAuthor({ authorId: params.authorId }));
        }
      }
      return;
    });

    // check selection changes
    this.store.select(selectSelectedAuthor).pipe(takeUntil(this.stop$)).subscribe(async (selectedAuthor) => {
      const authors = await this.store.select(selectAuthors).pipe(take(1)).toPromise();
      if (selectedAuthor && authors?.some(author => author.authorId === selectedAuthor)) {
          this.store.dispatch(maximized());
      }
    });
  }

  async ngOnInit() {
    this.loadNavigation();
  }

  // portal: ComponentPortal<any>;

  attach(component: ComponentType<any>, config?: BackdropConfig): PortalRef<any, any> {
    const ref = new PortalRef(component, config);
    this.portal = new ComponentPortal(component, undefined, this._createInjector(ref, config!));
    return ref;
  }

  _createInjector(ref: PortalRef<any, any>, config: BackdropConfig): PortalInjector {
    const injectionTokens = new WeakMap<any, any>([
      [PortalRef, ref],
      [BACKDROP_PORTAL_DATA, config ? config.data : {}]
    ]);

    return new PortalInjector(this._injector, injectionTokens);
  }

  async setContext(context: boolean) {
    const userOptions = await this.store.select(getUser).pipe(filter(u => u != null), take(1)).toPromise();
    this.store.dispatch(initializeUsers({ user: {
        user: userOptions.user,
        userProfile: userOptions.userProfile,
        activeDocumentContext: context
      }}
      ));
  }

  async loadNavigation() {
    this.attach(AuthorManagementNavigationComponent).onResult().subscribe(async (data) => {
      switch (data.action) {
        case 'close':
          this.drs.closeDca();
          this.setContext(false);
          break;
        case 'add_author':
          this.loadAddAuthorBackdrop();
          break;
        case 'new_author':
          const authorId = createId();
          this.store.dispatch(updateAuthor({ author: createAuthor(authorId), updateProfile: false, clearData: false }));
          this.router.navigate(['author', authorId], { relativeTo: this.activatedRoute });
          break;
      }
    });
  }

  async loadAddAuthorBackdrop() {
// const document = await this.document$.pipe(take(1)).toPromise();
    this.attach(AddAuthorComponent, { data: {} }).onResult().subscribe(async (data) => {
      switch (data.action) {
        case 'close':
          if (data.refetch) {
            const user = await this.store.select(getUser).pipe(filter(u => u != null), take(1)).toPromise();
            // this.documentApiService.populateAuthors(document.documentId, user?.user.userId);
          }
          this.loadNavigation();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
