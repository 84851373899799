type CriticalSeverity = 'error';
type WarningSeverity = 'warning';
type InformationSeverity = 'info';
type ErrorSeverityLevel = CriticalSeverity | WarningSeverity | InformationSeverity;

// type Init = 'init';
// type Processing = 'processing';
// type Success = 'success';
// type Status = Init | Processing | Success | ErrorSeverityLevel;

export enum StatusState {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}
