export interface Affiliations {
    /** All author affiliations listed by their affiliation number */
    affiliations: AnnotatedAffiliation[];
}

export interface AnnotatedAffiliation {
    /** The ID of the html element the affiliation was found in (may be multiple elements for one affiliation) */
    htmlElId?: string;

    /** The full affiliation string including text from the next line/element if it belongs to the same affiliation */
    affiliation: string;

    /** Title of the affiliation (like a work role) */
    title?: string;

    /** The number the affiliation is assigned to (only found in scientific formats) */
    affiliationNumber?: string;

    /** The organization / institution name like a university name (optional) */
    organization?: string;

    /** The department (optional) */
    department?: string;

    /** optional: The street part of an address */
    street?: string;

    /** optional: The city part of an address */
    city?: string;

    /** The country or country code */
    country?: string;
}