import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { WindowRef } from 'projects/author-management/src/lib/WindowRef';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  instanceData = JSON.parse(this.document.getElementById('instance-data').innerHTML);
  private intercom;

  constructor(@Inject(DOCUMENT) private document: any, private authService: AuthService, private windowRef: WindowRef) { }

  async init() {
    const user: any = await this.authService.user;
    if (!user?.intercomUserHash) { return; }
    if (!this.instanceData.intercomApp) { return; }

    const script = document.createElement('script');
    script.onload = () => {
      this.intercom = this.windowRef.nativeWindow.Intercom;
      this.intercom?.('boot', {
        app_id: this.instanceData.intercomApp,
        user_id: user.sub,
        alignment: 'left',
        user_hash: user.intercomUserHash,
        hide_default_launcher: false
      });
    }
    script.src = 'https://widget.intercom.io/widget/' + this.instanceData.intercomApp;
    script.defer = true;
    this.document.head.appendChild(script);
  }
}
