import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SfoRichTextInputComponent} from './rich-text.component';

@NgModule({
  declarations: [SfoRichTextInputComponent],
  imports: [CommonModule],
  exports: [SfoRichTextInputComponent],
})
export class SfoRichTextModule {}
