export interface Title {
    /** Paper title (may contain html inline elements like <b>, <em>, <sup>, <sub>) */
    title: string;
    /** The ID of the html element the title was found in (or the id of the heading) */
    titleHTMLElId: string;
    /** language code */
    lang: 'en-US' | string;
    /** Optional paper subtitle (may contain html inline elements like <b>, <em>, <sup>, <sub>) */
    subtitle?: string;
    /** Titles in other languages */
    altTitles: { title: string; lang: string; subtitle?: string; }[];
}
