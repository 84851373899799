import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CitationItem, CitationWithId } from '@sciflow/cite';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilKeyChanged, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ReferenceManagementService } from '../../services/reference-management.service';
import { selectCitation, selectReferenceList } from '../../store/references.reducer';

interface CitationItemForUI extends CitationItem {
  /** The rendered string this item's reference */
  plainReference: string;
}

interface CitationForUI extends CitationWithId {
  citationItems: CitationItemForUI[];
}

@Component({
  selector: 'lib-citation-editor',
  templateUrl: './citation-editor.component.html',
  styleUrls: ['./citation-editor.component.scss'],
})
export class CitationEditorComponent implements AfterViewInit, OnDestroy {
  stop$ = new Subject<void>();

  /** A search string to filter the reference search */
  searchText$ = new BehaviorSubject<string>('');

  citation$ = this.activatedRoute.params.pipe(
    map(({ citationID }) => citationID),
    switchMap((citationID: string) => this.store.select(selectCitation(citationID)))
  );

  /** All filtered references */
  references$ = combineLatest([
    this.searchText$.pipe(debounceTime(50)), this.store.select(selectReferenceList), this.citation$
  ]).pipe(map(([searchText, references, citation]) => {
    if (!searchText || searchText.length === 0) {
      return references
        // filter out already used references
        .filter(ref => !citation?.citationItems?.some(item => item.id === ref.csl?.id));
    }
    return references
      .filter(ref => ref.bib?.toLowerCase().includes(searchText.toLowerCase()) || ref.csl.id?.toLowerCase().includes(searchText.toLowerCase()))
      .filter(ref => !citation?.citationItems?.some(item => item.id === ref.csl?.id));
  }));

  citationFormGroup = new UntypedFormGroup({
    citationID: new UntypedFormControl(),
    citationItems: new UntypedFormArray([]),
  });

  /** The citation with all citation data filled in */
  citationWithReferences$ = this.citation$.pipe(
    filter(citation => citation !== undefined),
    // we wait until at least one reference is loaded
    debounce(() => this.store.select(selectReferenceList).pipe(filter(r => r.length > 0))),
    withLatestFrom(this.store.select(selectReferenceList)),
    map(([incomingCitation, references]): CitationForUI | undefined => {
      let citation = { ...incomingCitation } as CitationForUI;
      if (citation?.citationItems) {
        // Merge citationItems changes with references
        citation.citationItems = citation?.citationItems.map(citationItem => {
          const reference = references.find(ref => ref.csl.id === citationItem.id);
          if (!reference) { return citationItem };
          return {
            ...citationItem,
            plainReference: reference.bib,
            itemData: reference?.csl
          };
        });
      }

      return citation;
    })
  ) as Observable<CitationForUI>;

  get citationItems(): UntypedFormArray {
    return this.citationFormGroup.get('citationItems') as UntypedFormArray;
  }

  get citationID(): UntypedFormControl {
    return this.citationFormGroup.get('citationID') as UntypedFormControl;
  }

  constructor(private referencesService: ReferenceManagementService, private store: Store, private router: Router,
              private activatedRoute: ActivatedRoute) {
    console.log('view init');
  }

  trackByFn(index) {
    return index;
  }

  routeToReferencesList(): void {
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  deleteReference(index): void {
    this.citationItems.removeAt(index);
  }

  /** Adds a reference to a citation */
  addReference(reference): void {
    this.citationItems.push(this.createCitationItemGroup({
      id: reference.csl?.id,
      itemData: reference.csl,
      plainReference: reference.bib,
      label: 'page'
    }));

    this.searchText$.next('');
  }

  private createCitationItemGroup(item: CitationItemForUI): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(item.itemData.id),
      plainReference: new UntypedFormControl(item.plainReference),
      label: new UntypedFormControl(item.label || 'page'),
      locator: new UntypedFormControl(item.locator),
      prefix: new UntypedFormControl(item.prefix),
      suffix: new UntypedFormControl(item.suffix),
      format: new UntypedFormControl('')
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  ngAfterViewInit(): void {
    this.citationWithReferences$.pipe(distinctUntilKeyChanged('citationID')).subscribe((citation) => {
      if (citation === undefined) { return this.citationItems.clear(); }

      this.citationID.setValue(citation.citationID);
      this.citationItems.clear();
      for (const item of citation.citationItems) {
        this.citationItems.push(this.createCitationItemGroup(item));
      }
      console.log('init');
      // dispatch updates every 500ms
      this.citationFormGroup.valueChanges.pipe(debounceTime(500), takeUntil(this.stop$))
        .subscribe((updatedCitation) => {
          // TODO update options
          this.referencesService.updateCitation(updatedCitation);
        });
    });
  }

}
