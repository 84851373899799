import { Action, ActionReducer, createReducer, createSelector, on } from '@ngrx/store';
import * as DocumentActions from './outline.actions';

export const documentFeatureKey = 'outline';

export interface DocumentState {
    parts: any[];
}

const initialState: DocumentState = {
    parts: []
};

export const documentReducer = createReducer(
    initialState,
    on(DocumentActions.updatePart, (state, { part }) => ({
        ...state, parts: [part, ...state.parts]
            .filter((c1, index, array) => index === array.findIndex(c2 => c1.id === c2.id))
    })),
);

export const selectDocumentState = (state): DocumentState => state.outline;

export const selectPart = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.parts[0]
);

export const DocumentOutlineReducer: ActionReducer<DocumentState, any> = (state: DocumentState | undefined, action: Action) => {
    return documentReducer(state, action);
};
