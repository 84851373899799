<div class="dca-heading flex items-center justify-between px2">
  <div class="flex items-center">
    <button mat-icon-button class="h2" matTooltip="Reference list" (click)="routeToReferencesList()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <div class="m0 h2">Edit citation</div>
  </div>
</div>
<div class="dca-scrollarea">
  <div class="ml1 p1">
    <mat-form-field appearance="outline" class="block">
      <mat-label>Search a reference to add to citation</mat-label>
      <input matInput placeholder="Search title, creator, year" #searchField (keyup)="searchText$.next(searchField.value)" [matAutocomplete]="auto">
      <mat-icon matSuffix (click)="searchText$.next('')">add</mat-icon>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let reference of references$ | async" (click)="addReference(reference)">
        <small>{{ reference.bib }}</small>
      </mat-option>
    </mat-autocomplete>
  </div>
  <form [formGroup]="citationFormGroup">
    <ng-container formArrayName="citationItems">
      <div class="citation-card p2" *ngFor="let citationItem of citationItems.controls; let i=index">
        <div class="flex justify-between mxn1">
          <div class="px1">
            <p class="m0" *ngIf="citationItem.get('plainReference')?.value; let plainReference" [title]="plainReference"><b>{{ i + 1 }}.</b> {{ plainReference?.substr(0,160) }}<span *ngIf="plainReference?.length > 160">...</span></p>
            <!-- <p class="small m0">Source file: {{ reference.key }}</p> -->
          </div>
          <div class="px1">
            <button mat-icon-button type='button' aria-label="Delete reference file" (click)="deleteReference(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="mt2" [formGroupName]="i">
          <div class="flex mxn1">
            <mat-form-field class="col-8 p1" appearance="outline">
              <mat-label>Label</mat-label>
              <mat-select formControlName="label">
                <mat-option [value]="'page'">Page</mat-option>
                <mat-option [value]="'chapter'">Chapter</mat-option>
                <mat-option [value]="'figure'">Figure</mat-option>
                <mat-option [value]="'issue'">Issue</mat-option>
                <mat-option [value]="'volume'">Volume</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-4 p1" appearance="outline">
              <mat-label>Locator</mat-label>
              <input matInput placeholder="Page number of similar" formControlName="locator">
            </mat-form-field>
          </div>

          <div class="flex mxn1">
            <mat-form-field class="col-6 px1" appearance="outline">
              <mat-label>Prefix</mat-label>
              <input matInput placeholder="Some placeholder" formControlName="prefix">
            </mat-form-field>
            <mat-form-field class="col-6 px1" appearance="outline">
              <mat-label>Suffix</mat-label>
              <input matInput placeholder="Some placeholder" formControlName="suffix">
            </mat-form-field>
          </div>
          <!-- 
          <div>
            <mat-radio-group class="flex flex-column" aria-label="Select an format" formControlName="format">
              <mat-radio-button class="m1 block" value="default">default</mat-radio-button>
              <mat-radio-button class="m1 block" value="no_author">no_author</mat-radio-button>
              <mat-radio-button class="m1 block" value="only_author">only_author</mat-radio-button>
              <mat-radio-button class="m1 block" value="combined">combined</mat-radio-button>
            </mat-radio-group>
          </div> -->
        </div>
      </div>
    </ng-container>
  </form>
<!--   <div *ngIf="citation$ | async; let citation">
    <p class="ml1 p1 small">You can add and remove references from this citation <b>{{ citation.plainCitation }}</b>.
      All updates will be reflected in the text right away.</p>
  </div> -->
</div>
