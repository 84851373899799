import { Component, OnInit, ViewChild, ElementRef, Inject, InjectionToken } from '@angular/core';

import { UntypedFormControl, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';

import gql from 'graphql-tag';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { WindowRef } from '../WindowRef';
import { getUserProfile } from '../author-management.reducer';
import { ConnectService } from 'projects/author-management/src/lib/connect.service';
import { PortalRef } from '../portal-ref';
import { AuthorService } from '../services/author.service';

export const BACKDROP_PORTAL_DATA = new InjectionToken<any>('BackdropPortalData');

@Component({
  selector: 'sf-add-author',
  templateUrl: './add-author.component.html',
  styleUrls: ['./add-author.component.scss']
})
export class AddAuthorComponent implements OnInit {

  @ViewChild('copyToClipboardInput') copyToClipboardInput: ElementRef;

  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  message = new UntypedFormControl('', []);
  selectedRole = 'Author';
  useEmailClient = new UntypedFormControl('', []);
  inviteAnother = new UntypedFormControl('', []);
  @ViewChild('emailField') emailField: ElementRef;
  documentId;
  inviteUrls;

  user$ = this.store.select(getUserProfile);

  roleLabels = {
    'Admin': 'Admin',
    'Author': 'Co-Author',
    'CommentOnly': 'Comment only'
  };

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter an email address' :
      this.email.hasError('email') ? 'Not a valid email' : '';
  }

  constructor(@Inject(BACKDROP_PORTAL_DATA) public data: any, private store: Store, private authorService: AuthorService, private connectService: ConnectService, public ref: PortalRef<AddAuthorComponent, any>, private apollo: Apollo, private snackBar: MatSnackBar, private windowRef: WindowRef) {
    this.documentId = data.documentId;
    if(data.inviteUrl !== undefined) {
      this.inviteUrls = Object.keys(data?.inviteUrl).map(role => data?.inviteUrl[role]);
    }
  }

  async sendActivationEmail(email) {
    try {
      const result = await this.connectService.sendActivationEmail(email);
      this.snackBar.open('Please check your email at ' + email, '', { duration: 4000 });
    } catch (e: any) {
      console.error(e);
      this.snackBar.open('Could not send email', '', { duration: 4000 });
    }
  }

  async submit() {

    const selected = this.inviteUrls.find(roleOption => roleOption.role === this.selectedRole);

    if (!this.email.valid) { return; }

    if (this.useEmailClient.value === true) {
      this.windowRef.href = `mailto:${this.email.value}?subject=${encodeURI('You have been invited to join a document on SciFlow')}&body=${encodeURI(`${this.message.value ? `${this.message.value}\n` : ''}

Follow this link to join the document:

${selected.url}`)}`;
      return;
    } else {

      try {
        await this.addAuthor({ email: this.email.value, message: this.message.value, roles: [this.selectedRole] });
        this.snackBar.open('Invite has been sent', '', { duration: 3000 });
      } catch (e: any) {
        if (e.graphQLErrors) {
          for (const error of e.graphQLErrors) {
            this.snackBar.open(error.message, undefined, { duration: 3000 });
          }
        } else {
          this.snackBar.open('There was an error sending the invite', undefined, { duration: 5000 });
        }
      }
    }

    if (this.inviteAnother.value === true) {
      this.emailField.nativeElement.value = '';
      this.emailField.nativeElement.focus();
    } else {
      this.ref.emit({ action: 'close', refetch: true });
    }
  }

  ngOnInit() {
    if (this.emailField) {
      this.emailField.nativeElement.focus();
    }
  }

  async addAuthor(options: {
    email: string,
    message: string,
    roles: string[]
  }) {
    const input = {
      documentId: this.documentId,
      clientMutationId: null,
      email: options.email,
      roles: options.roles,
      message: options.message
    };

    // FIXME remove api calls from libs
    return this.authorService.addAuthor(input);
  }

  copyToClipboard() {
    const selected = this.inviteUrls.find(roleOption => roleOption.role === this.selectedRole);
    this.copyToClipboardInput.nativeElement.value = selected.url;
    this.copyToClipboardInput.nativeElement.select();

    let describeRole = '';
    switch (selected.role) {
      case 'Admin':
        describeRole = 'for Admin access';
        break;
      case 'CommentOnly':
        describeRole = 'for commenting';
        break;
      case 'Author':
        describeRole = 'for editing';
        break;
      case 'Editor':
        describeRole = 'for editing';
        break;
    }

    document.execCommand('copy');
    this.snackBar.open(`We have copied the invite link ${describeRole} to your clipboard`, undefined, { duration: 5000 });
  }

}
