<div *ngFor="let control of controlsInOrder" [class]="settingsView">
  <!-- QuickSettings View -->
  <div class="pb2">
    <mat-expansion-panel expanded="false" class="mat-elevation-z0">
      <mat-expansion-panel-header
        [collapsedHeight]="'2em'"
        [expandedHeight]="'3em'"
        class="flex items-center"
      >
        <mat-panel-title>
          <h2 class="h4">
            <ng-template *ngTemplateOutlet="header"></ng-template>
          </h2>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <sfo-preset-menu
        [propertyName]="control"
        [schema]="schema.properties?.[control]"
        [aFormControl]="getFormControl(control)"
      >
      </sfo-preset-menu>
      <sfo-dynamic-field
        [schema]="schema.properties?.[control]"
        [aFormControl]="aFormControl"
        [settingsView]="'quickSettings'"
        [control]="control"
      ></sfo-dynamic-field>
    </mat-expansion-panel>
  </div>

  <ng-template #header>
    <ng-container
      *ngIf="schema && schema.properties?.[control]?.title !== undefined; else propertyAsTitle"
    >
      {{ schema.properties?.[control]?.title | titlecase }}
    </ng-container>
    <ng-template #propertyAsTitle>
      {{ control | titlecase }}
    </ng-template>
  </ng-template>

  <ng-template #description>
    <p *ngIf="schema?.properties?.[control]?.description">
      {{ schema.properties?.[control]?.description }}
    </p>
  </ng-template>
</div>
