import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SfoAdmonitionComponent} from './admonition.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [SfoAdmonitionComponent],
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatTooltipModule],
  exports: [SfoAdmonitionComponent],
})
export class SfoAdmonitionModule {}
