export interface Keywords {
    /** Keywords mentioned in the beginning of the manuscript. Each language has its own list */
    keywords: {
        /** Title of the keyword section (if any) */
        title?: string;
        values: string[];
        lang: string;
        /** 
         * The ID of the html element the keyword list was found in 
         */
        htmlElId: string;
    }[];
}
