import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';

import {DynamicFieldModule} from '../form-fields';
import {MarkdownPipe} from './markdown.pipe';
import {SettingsViewEditorComponent} from './settings-editor-view/settings-editor-view.component';
import {SettingsViewComponent} from './settings-view-tab.component';
import {SfoWidgetModule} from '../../widgets';

@NgModule({
  declarations: [SettingsViewComponent, SettingsViewEditorComponent, MarkdownPipe],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    DynamicFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSlideToggleModule,
    SfoWidgetModule,
  ],
  exports: [SettingsViewComponent, MarkdownPipe],
})
export class SettingsViewTabModule {}
