/**
 * Zotero Schema provided at
 * https://github.com/zotero/zotero-schema
 */
export const zoteroSchema = {
	"version": 13,
	"itemTypes": [
		{
			"itemType": "annotation",
			"fields": [],
			"creatorTypes": []
		},
		{
			"itemType": "artwork",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "artworkMedium",
					"baseField": "medium"
				},
				{
					"field": "artworkSize"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "artist",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "attachment",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "url"
				}
			],
			"creatorTypes": []
		},
		{
			"itemType": "audioRecording",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "audioRecordingFormat",
					"baseField": "medium"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "place"
				},
				{
					"field": "label",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "performer",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "composer"
				},
				{
					"creatorType": "wordsBy"
				}
			]
		},
		{
			"itemType": "bill",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "billNumber",
					"baseField": "number"
				},
				{
					"field": "code"
				},
				{
					"field": "codeVolume",
					"baseField": "volume"
				},
				{
					"field": "section"
				},
				{
					"field": "codePages",
					"baseField": "pages"
				},
				{
					"field": "legislativeBody"
				},
				{
					"field": "session"
				},
				{
					"field": "history"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "sponsor",
					"primary": true
				},
				{
					"creatorType": "cosponsor"
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "blogPost",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "blogTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "websiteType",
					"baseField": "type"
				},
				{
					"field": "date"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "commenter"
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "book",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "series"
				},
				{
					"field": "seriesNumber"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "edition"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "numPages"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "bookSection",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "bookTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "series"
				},
				{
					"field": "seriesNumber"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "edition"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "bookAuthor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "case",
			"fields": [
				{
					"field": "caseName",
					"baseField": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "court"
				},
				{
					"field": "dateDecided",
					"baseField": "date"
				},
				{
					"field": "docketNumber",
					"baseField": "number"
				},
				{
					"field": "reporter"
				},
				{
					"field": "reporterVolume",
					"baseField": "volume"
				},
				{
					"field": "firstPage",
					"baseField": "pages"
				},
				{
					"field": "history"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "counsel"
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "computerProgram",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "versionNumber"
				},
				{
					"field": "date"
				},
				{
					"field": "system"
				},
				{
					"field": "place"
				},
				{
					"field": "company",
					"baseField": "publisher"
				},
				{
					"field": "programmingLanguage"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "rights"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "programmer",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "conferencePaper",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "date"
				},
				{
					"field": "proceedingsTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "conferenceName"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "volume"
				},
				{
					"field": "pages"
				},
				{
					"field": "series"
				},
				{
					"field": "language"
				},
				{
					"field": "DOI"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "dictionaryEntry",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "dictionaryTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "series"
				},
				{
					"field": "seriesNumber"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "edition"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "document",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "reviewedAuthor"
				}
			]
		},
		{
			"itemType": "email",
			"fields": [
				{
					"field": "subject",
					"baseField": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "date"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "recipient"
				}
			]
		},
		{
			"itemType": "encyclopediaArticle",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "encyclopediaTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "series"
				},
				{
					"field": "seriesNumber"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "edition"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "film",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "distributor",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "genre",
					"baseField": "type"
				},
				{
					"field": "videoRecordingFormat",
					"baseField": "medium"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "director",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "scriptwriter"
				},
				{
					"creatorType": "producer"
				}
			]
		},
		{
			"itemType": "forumPost",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "forumTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "postType",
					"baseField": "type"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "hearing",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "committee"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "documentNumber",
					"baseField": "number"
				},
				{
					"field": "pages"
				},
				{
					"field": "legislativeBody"
				},
				{
					"field": "session"
				},
				{
					"field": "history"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "contributor",
					"primary": true
				}
			]
		},
		{
			"itemType": "instantMessage",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "recipient"
				}
			]
		},
		{
			"itemType": "interview",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "date"
				},
				{
					"field": "interviewMedium",
					"baseField": "medium"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "interviewee",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "interviewer"
				},
				{
					"creatorType": "translator"
				}
			]
		},
		{
			"itemType": "journalArticle",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "publicationTitle"
				},
				{
					"field": "volume"
				},
				{
					"field": "issue"
				},
				{
					"field": "pages"
				},
				{
					"field": "date"
				},
				{
					"field": "series"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "seriesText"
				},
				{
					"field": "journalAbbreviation"
				},
				{
					"field": "language"
				},
				{
					"field": "DOI"
				},
				{
					"field": "ISSN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "editor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "reviewedAuthor"
				}
			]
		},
		{
			"itemType": "letter",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "letterType",
					"baseField": "type"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "recipient"
				}
			]
		},
		{
			"itemType": "magazineArticle",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "publicationTitle"
				},
				{
					"field": "volume"
				},
				{
					"field": "issue"
				},
				{
					"field": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "language"
				},
				{
					"field": "ISSN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "reviewedAuthor"
				}
			]
		},
		{
			"itemType": "manuscript",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "manuscriptType",
					"baseField": "type"
				},
				{
					"field": "place"
				},
				{
					"field": "date"
				},
				{
					"field": "numPages"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "translator"
				}
			]
		},
		{
			"itemType": "map",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "mapType",
					"baseField": "type"
				},
				{
					"field": "scale"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "edition"
				},
				{
					"field": "place"
				},
				{
					"field": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "cartographer",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "newspaperArticle",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "publicationTitle"
				},
				{
					"field": "place"
				},
				{
					"field": "edition"
				},
				{
					"field": "date"
				},
				{
					"field": "section"
				},
				{
					"field": "pages"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "ISSN"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "reviewedAuthor"
				}
			]
		},
		{
			"itemType": "note",
			"fields": [],
			"creatorTypes": []
		},
		{
			"itemType": "patent",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "place"
				},
				{
					"field": "country"
				},
				{
					"field": "assignee"
				},
				{
					"field": "issuingAuthority"
				},
				{
					"field": "patentNumber",
					"baseField": "number"
				},
				{
					"field": "filingDate"
				},
				{
					"field": "pages"
				},
				{
					"field": "applicationNumber"
				},
				{
					"field": "priorityNumbers"
				},
				{
					"field": "issueDate",
					"baseField": "date"
				},
				{
					"field": "references"
				},
				{
					"field": "legalStatus"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "inventor",
					"primary": true
				},
				{
					"creatorType": "attorneyAgent"
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "podcast",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "episodeNumber",
					"baseField": "number"
				},
				{
					"field": "audioFileType",
					"baseField": "medium"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "podcaster",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "guest"
				}
			]
		},
		{
			"itemType": "presentation",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "presentationType",
					"baseField": "type"
				},
				{
					"field": "date"
				},
				{
					"field": "place"
				},
				{
					"field": "meetingName"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "presenter",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "radioBroadcast",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "programTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "episodeNumber",
					"baseField": "number"
				},
				{
					"field": "audioRecordingFormat",
					"baseField": "medium"
				},
				{
					"field": "place"
				},
				{
					"field": "network",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "director",
					"primary": true
				},
				{
					"creatorType": "scriptwriter"
				},
				{
					"creatorType": "producer"
				},
				{
					"creatorType": "castMember"
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "guest"
				}
			]
		},
		{
			"itemType": "report",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "reportNumber",
					"baseField": "number"
				},
				{
					"field": "reportType",
					"baseField": "type"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "place"
				},
				{
					"field": "institution",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "translator"
				},
				{
					"creatorType": "seriesEditor"
				}
			]
		},
		{
			"itemType": "statute",
			"fields": [
				{
					"field": "nameOfAct",
					"baseField": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "code"
				},
				{
					"field": "codeNumber"
				},
				{
					"field": "publicLawNumber",
					"baseField": "number"
				},
				{
					"field": "dateEnacted",
					"baseField": "date"
				},
				{
					"field": "pages"
				},
				{
					"field": "section"
				},
				{
					"field": "session"
				},
				{
					"field": "history"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "thesis",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "thesisType",
					"baseField": "type"
				},
				{
					"field": "university",
					"baseField": "publisher"
				},
				{
					"field": "place"
				},
				{
					"field": "date"
				},
				{
					"field": "numPages"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "tvBroadcast",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "programTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "episodeNumber",
					"baseField": "number"
				},
				{
					"field": "videoRecordingFormat",
					"baseField": "medium"
				},
				{
					"field": "place"
				},
				{
					"field": "network",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "language"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "director",
					"primary": true
				},
				{
					"creatorType": "scriptwriter"
				},
				{
					"creatorType": "producer"
				},
				{
					"creatorType": "castMember"
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "guest"
				}
			]
		},
		{
			"itemType": "videoRecording",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "videoRecordingFormat",
					"baseField": "medium"
				},
				{
					"field": "seriesTitle"
				},
				{
					"field": "volume"
				},
				{
					"field": "numberOfVolumes"
				},
				{
					"field": "place"
				},
				{
					"field": "studio",
					"baseField": "publisher"
				},
				{
					"field": "date"
				},
				{
					"field": "runningTime"
				},
				{
					"field": "language"
				},
				{
					"field": "ISBN"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "archive"
				},
				{
					"field": "archiveLocation"
				},
				{
					"field": "libraryCatalog"
				},
				{
					"field": "callNumber"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "director",
					"primary": true
				},
				{
					"creatorType": "scriptwriter"
				},
				{
					"creatorType": "producer"
				},
				{
					"creatorType": "castMember"
				},
				{
					"creatorType": "contributor"
				}
			]
		},
		{
			"itemType": "webpage",
			"fields": [
				{
					"field": "title"
				},
				{
					"field": "abstractNote"
				},
				{
					"field": "websiteTitle",
					"baseField": "publicationTitle"
				},
				{
					"field": "websiteType",
					"baseField": "type"
				},
				{
					"field": "date"
				},
				{
					"field": "shortTitle"
				},
				{
					"field": "url"
				},
				{
					"field": "accessDate"
				},
				{
					"field": "language"
				},
				{
					"field": "rights"
				},
				{
					"field": "extra"
				}
			],
			"creatorTypes": [
				{
					"creatorType": "author",
					"primary": true
				},
				{
					"creatorType": "contributor"
				},
				{
					"creatorType": "translator"
				}
			]
		}
	],
	"meta": {
		"fields": {
			"date": {
				"type": "date"
			},
			"filingDate": {
				"type": "date"
			}
		}
	},
	"csl": {
		"types": {
			"article": [
				"document",
				"attachment",
				"note"
			],
			"article-journal": [
				"journalArticle"
			],
			"article-magazine": [
				"magazineArticle"
			],
			"article-newspaper": [
				"newspaperArticle"
			],
			"bill": [
				"bill",
				"hearing"
			],
			"book": [
				"book",
				"computerProgram"
			],
			"broadcast": [
				"podcast",
				"tvBroadcast",
				"radioBroadcast"
			],
			"chapter": [
				"bookSection"
			],
			"entry-dictionary": [
				"dictionaryEntry"
			],
			"entry-encyclopedia": [
				"encyclopediaArticle"
			],
			"graphic": [
				"artwork"
			],
			"interview": [
				"interview"
			],
			"legal_case": [
				"case"
			],
			"legislation": [
				"statute"
			],
			"manuscript": [
				"manuscript"
			],
			"map": [
				"map"
			],
			"motion_picture": [
				"film",
				"videoRecording"
			],
			"paper-conference": [
				"conferencePaper"
			],
			"patent": [
				"patent"
			],
			"personal_communication": [
				"letter",
				"email",
				"instantMessage"
			],
			"post": [
				"forumPost"
			],
			"post-weblog": [
				"blogPost"
			],
			"report": [
				"report"
			],
			"song": [
				"audioRecording"
			],
			"speech": [
				"presentation"
			],
			"thesis": [
				"thesis"
			],
			"webpage": [
				"webpage"
			]
		},
		"fields": {
			"text": {
				"abstract": [
					"abstractNote"
				],
				"archive": [
					"archive"
				],
				"archive_location": [
					"archiveLocation"
				],
				"authority": [
					"court",
					"legislativeBody",
					"issuingAuthority"
				],
				"call-number": [
					"callNumber",
					"applicationNumber"
				],
				"chapter-number": [
					"session"
				],
				"collection-number": [
					"seriesNumber"
				],
				"collection-title": [
					"seriesTitle",
					"series"
				],
				"container-title": [
					"publicationTitle",
					"reporter",
					"code"
				],
				"dimensions": [
					"artworkSize",
					"runningTime"
				],
				"DOI": [
					"DOI"
				],
				"edition": [
					"edition"
				],
				"event": [
					"meetingName",
					"conferenceName"
				],
				"event-place": [
					"place"
				],
				"genre": [
					"type",
					"programmingLanguage"
				],
				"ISBN": [
					"ISBN"
				],
				"ISSN": [
					"ISSN"
				],
				"issue": [
					"issue",
					"priorityNumbers"
				],
				"journalAbbreviation": [
					"journalAbbreviation"
				],
				"language": [
					"language"
				],
				"medium": [
					"medium",
					"system"
				],
				"note": [
					"extra"
				],
				"number": [
					"number"
				],
				"number-of-pages": [
					"numPages"
				],
				"number-of-volumes": [
					"numberOfVolumes"
				],
				"page": [
					"pages"
				],
				"publisher": [
					"publisher"
				],
				"publisher-place": [
					"place"
				],
				"references": [
					"history",
					"references"
				],
				"scale": [
					"scale"
				],
				"section": [
					"section",
					"committee"
				],
				"shortTitle": [
					"shortTitle"
				],
				"source": [
					"libraryCatalog"
				],
				"status": [
					"legalStatus"
				],
				"title": [
					"title"
				],
				"title-short": [
					"shortTitle"
				],
				"URL": [
					"url"
				],
				"version": [
					"versionNumber"
				],
				"volume": [
					"volume",
					"codeNumber"
				]
			},
			"date": {
				"accessed": "accessDate",
				"issued": "date",
				"submitted": "filingDate"
			}
		},
		"names": {
			"author": "author",
			"bookAuthor": "container-author",
			"composer": "composer",
			"director": "director",
			"editor": "editor",
			"interviewer": "interviewer",
			"recipient": "recipient",
			"reviewedAuthor": "reviewed-author",
			"seriesEditor": "collection-editor",
			"translator": "translator"
		}
	},
	"locales": {
		"af-ZA": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Artwork",
				"attachment": "Attachment",
				"audioRecording": "Audio Recording",
				"bill": "Bill",
				"blogPost": "Blog Post",
				"book": "Book",
				"bookSection": "Book Section",
				"case": "Case",
				"computerProgram": "Software",
				"conferencePaper": "Conference Paper",
				"dictionaryEntry": "Dictionary Entry",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Encyclopedia Article",
				"film": "Film",
				"forumPost": "Forum Post",
				"hearing": "Hearing",
				"instantMessage": "Instant Message",
				"interview": "Interview",
				"journalArticle": "Journal Article",
				"letter": "Letter",
				"magazineArticle": "Magazine Article",
				"manuscript": "Manuscript",
				"map": "Map",
				"newspaperArticle": "Newspaper Article",
				"note": "Note",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentation",
				"radioBroadcast": "Radio Broadcast",
				"report": "Report",
				"statute": "Statute",
				"thesis": "Thesis",
				"tvBroadcast": "TV Broadcast",
				"videoRecording": "Video Recording",
				"webpage": "Web Page"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "Loc. in Archive",
				"artworkMedium": "Medium",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "File Type",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "Blog Title",
				"bookTitle": "Book Title",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "Code",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Committee",
				"company": "Company",
				"conferenceName": "Conference Name",
				"country": "Country",
				"court": "Court",
				"date": "Date",
				"dateAdded": "Date Added",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Modified",
				"dictionaryTitle": "Dictionary Title",
				"distributor": "Distributor",
				"docketNumber": "Docket Number",
				"documentNumber": "Document Number",
				"DOI": "DOI",
				"edition": "Edition",
				"encyclopediaTitle": "Encyclopedia Title",
				"episodeNumber": "Episode Number",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "First Page",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "History",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Issue",
				"issueDate": "Issue Date",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Type",
				"journalAbbreviation": "Journal Abbr",
				"label": "Label",
				"language": "Language",
				"legalStatus": "Legal Status",
				"legislativeBody": "Legislative Body",
				"letterType": "Type",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Meeting Name",
				"nameOfAct": "Name of Act",
				"network": "Network",
				"number": "Number",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "Pages",
				"patentNumber": "Patent Number",
				"place": "Place",
				"postType": "Post Type",
				"presentationType": "Type",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Publication",
				"publicLawNumber": "Public Law Number",
				"publisher": "Publisher",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Report Number",
				"reportType": "Report Type",
				"rights": "Rights",
				"runningTime": "Running Time",
				"scale": "Scale",
				"section": "Section",
				"series": "Series",
				"seriesNumber": "Series Number",
				"seriesText": "Series Text",
				"seriesTitle": "Series Title",
				"session": "Session",
				"shortTitle": "Short Title",
				"studio": "Studio",
				"subject": "Subject",
				"system": "System",
				"thesisType": "Type",
				"title": "Title",
				"university": "University",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Website Title",
				"websiteType": "Website Type"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Attorney/Agent",
				"author": "Author",
				"bookAuthor": "Book Author",
				"cartographer": "Cartographer",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "Composer",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "Director",
				"editor": "Editor",
				"guest": "Guest",
				"interviewee": "Interview With",
				"interviewer": "Interviewer",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Producer",
				"programmer": "Programmer",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Sponsor",
				"translator": "Translator",
				"wordsBy": "Words By"
			}
		},
		"ar": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "عمل فني",
				"attachment": "مرفق",
				"audioRecording": "تسجيل صوتي",
				"bill": "مسودّة قانون",
				"blogPost": "موضوع في مدونة",
				"book": "كتاب",
				"bookSection": "قسم في كتاب",
				"case": "قضية",
				"computerProgram": "Software",
				"conferencePaper": "ورقة عمل في مؤتمر",
				"dictionaryEntry": "كلمة في المعجم",
				"document": "مستند",
				"email": "بريد الكتروني",
				"encyclopediaArticle": "مقالة في موسوعة",
				"film": "فيلم",
				"forumPost": "موضوع في منتدى",
				"hearing": "سماع شهادة",
				"instantMessage": "مراسلة فورية على الانترنت",
				"interview": "مقابلة",
				"journalArticle": "مقالة في دورية",
				"letter": "خطاب",
				"magazineArticle": "مقالة في مجلة",
				"manuscript": "مخطوطة",
				"map": "خريطة",
				"newspaperArticle": "مقالة في جريدة",
				"note": "ملاحظة",
				"patent": "براءة الإختراع",
				"podcast": "تدوينة وسائطية",
				"presentation": "عرض تقديمي",
				"radioBroadcast": "بث إذاعي",
				"report": "تقرير",
				"statute": "قانون",
				"thesis": "أطروحة",
				"tvBroadcast": "بث تلفزيوني",
				"videoRecording": "تسجيل فيديو",
				"webpage": "صفحة ويب"
			},
			"fields": {
				"abstractNote": "المستخلص",
				"accessDate": "تاريخ الدخول",
				"applicationNumber": "رقم الطلب",
				"archive": "الارشيف",
				"archiveLocation": "الموقع في الأرشيف",
				"artworkMedium": "وسيط العمل الفني",
				"artworkSize": "حجم العمل الفني",
				"assignee": "المخول بالمهمة",
				"audioFileType": "نوع الملف الصوتي",
				"audioRecordingFormat": "صيغة التسجيل الصوتي",
				"billNumber": "رقم مسودّة القانون",
				"blogTitle": "عنوان المدونة",
				"bookTitle": "عنوان الكتاب",
				"callNumber": "رقم الإسترجاع",
				"caseName": "اسم القضية",
				"code": "التشفير",
				"codeNumber": "رقم الرمز",
				"codePages": "صفحات القانون",
				"codeVolume": "مجلد القانون",
				"committee": "اللجنة",
				"company": "الشركة",
				"conferenceName": "اسم المؤتمر",
				"country": "البلد",
				"court": "المحكمة",
				"date": "التاريخ",
				"dateAdded": "تاريخ الإضافة",
				"dateDecided": "تاريخ الحكم",
				"dateEnacted": "تاريخ سن القانون",
				"dateModified": "تاريخ التعديل",
				"dictionaryTitle": "عنوان القاموس",
				"distributor": "الموزع",
				"docketNumber": "رقم القضية",
				"documentNumber": "رقم المستند",
				"DOI": "معرف الكائن الرقمي",
				"edition": "الطبعة",
				"encyclopediaTitle": "عنوان الموسوعة",
				"episodeNumber": "رقم الموضوع",
				"extra": "معلومات إضافية",
				"filingDate": "تاريخ الايداع",
				"firstPage": "الصفحة الأولى",
				"forumTitle": "عنوان المنتدى",
				"genre": "النوع الأدبي",
				"history": "التاريخ",
				"institution": "المؤسسة",
				"interviewMedium": "وسيط المقابلة",
				"ISBN": "تدمك",
				"ISSN": "تدمد",
				"issue": "العدد",
				"issueDate": "تاريخ الإصدار",
				"issuingAuthority": "مسئولية الاصدار",
				"itemType": "نوع العنصر",
				"journalAbbreviation": "اختصار الدورية",
				"label": "علامة",
				"language": "اللغة",
				"legalStatus": "الحالة القانونية",
				"legislativeBody": "الجهة التشريعية",
				"letterType": "نوع الخطاب",
				"libraryCatalog": "فهرس المكتبة",
				"manuscriptType": "نوع المخطوطة",
				"mapType": "نوع الخريطة",
				"meetingName": "اسم الإجتماع",
				"nameOfAct": "اسم القانون",
				"network": "الشبكة",
				"number": "الرقم",
				"numberOfVolumes": "عدد المجلدات",
				"numPages": "عدد الصفحات",
				"pages": "الصفحات",
				"patentNumber": "رقم البرائة",
				"place": "المكان",
				"postType": "نوع الموضوع",
				"presentationType": "نوع العرض التقديمي",
				"priorityNumbers": "ارقام الأولوية",
				"proceedingsTitle": "عنوان ورقة العمل",
				"programmingLanguage": "Prog. Language",
				"programTitle": "عنوان البرنامج",
				"publicationTitle": "عنوان المنشور",
				"publicLawNumber": "رقم القانون العام",
				"publisher": "الناشر",
				"references": "مراجع",
				"reporter": "المراسل",
				"reporterVolume": "مجلد الأحكام",
				"reportNumber": "رقم التقرير",
				"reportType": "نوع التقرير",
				"rights": "الحقوق",
				"runningTime": "وقت التشغيل",
				"scale": "مقياس الرسم",
				"section": "القسم",
				"series": "السلسلة",
				"seriesNumber": "رقم السلسلة",
				"seriesText": "نص السلسلة",
				"seriesTitle": "عنوان السلسلة",
				"session": "الجلسة",
				"shortTitle": "العنوان المختصر",
				"studio": "الأستوديو",
				"subject": "الموضوع",
				"system": "النظام",
				"thesisType": "نوع الأطروحة",
				"title": "العنوان",
				"university": "الجامعة",
				"url": "عنوان الموقع",
				"versionNumber": "Version",
				"videoRecordingFormat": "صيغة التسجيل المرئي",
				"volume": "المجلد",
				"websiteTitle": "اسم موقع الويب",
				"websiteType": "نوع موقع الويب"
			},
			"creatorTypes": {
				"artist": "الفنان",
				"attorneyAgent": "المحامي أو الوكيل",
				"author": "المؤلف",
				"bookAuthor": "مؤلف كتاب",
				"cartographer": "رسام الخريطة",
				"castMember": "عضو طاقم التمثيل",
				"commenter": "المعلق",
				"composer": "الملحن",
				"contributor": "اسم المشارك",
				"cosponsor": "الراعي المشارك",
				"counsel": "المستشار",
				"director": "المخرج",
				"editor": "المحرر",
				"guest": "الضيف",
				"interviewee": "مقابلة مع",
				"interviewer": "المحاور",
				"inventor": "المخترع",
				"performer": "الفنان",
				"podcaster": "المدون",
				"presenter": "المقدم",
				"producer": "المنتج",
				"programmer": "المبرمج",
				"recipient": "المتلقي",
				"reviewedAuthor": "مؤلف مراجع",
				"scriptwriter": "كاتب الحوار",
				"seriesEditor": "محرر السلسلة",
				"sponsor": "الراعي",
				"translator": "المترجم",
				"wordsBy": "من كلمات"
			}
		},
		"bg-BG": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Произведение на изкуството",
				"attachment": "Приложение",
				"audioRecording": "Звукозапис",
				"bill": "Закон",
				"blogPost": "Съобщение в блог",
				"book": "Книга",
				"bookSection": "Глава от книга",
				"case": "Съдебно решение",
				"computerProgram": "Software",
				"conferencePaper": "Публикация от конференция",
				"dictionaryEntry": "Определение в речник",
				"document": "Документ",
				"email": "Електронна поща",
				"encyclopediaArticle": "Статия в енциклопедия",
				"film": "Филм",
				"forumPost": "Съобщение във форум",
				"hearing": "Заседание",
				"instantMessage": "Бързо съобщение",
				"interview": "Интервю",
				"journalArticle": "Статия в научно списание",
				"letter": "Писмо",
				"magazineArticle": "Статия в списание",
				"manuscript": "Ръкопис",
				"map": "Карта",
				"newspaperArticle": "Статия във вестник",
				"note": "Бележка",
				"patent": "Патент",
				"podcast": "Подкаст",
				"presentation": "Презентация",
				"radioBroadcast": "Радио излъчване",
				"report": "Отчет",
				"statute": "Подзаконов акт",
				"thesis": "Дисертация",
				"tvBroadcast": "Телевизионно излъчване",
				"videoRecording": "Видео",
				"webpage": "Интернет страница"
			},
			"fields": {
				"abstractNote": "Извлечение",
				"accessDate": "Отворен на",
				"applicationNumber": "Номер на молба",
				"archive": "Archive",
				"archiveLocation": "Позиция в архива",
				"artworkMedium": "Медия на произведението:",
				"artworkSize": "Размер на произведението:",
				"assignee": "Изпълнител",
				"audioFileType": "Вид файл",
				"audioRecordingFormat": "Формат",
				"billNumber": "Номер на закон",
				"blogTitle": "Заглавие на блог",
				"bookTitle": "Заглавие на книга",
				"callNumber": "Телефонен номер",
				"caseName": "Име на партида",
				"code": "Кодекс",
				"codeNumber": "Код",
				"codePages": "Страници на законодателство",
				"codeVolume": "Том на законодателство",
				"committee": "Комитет",
				"company": "Компания",
				"conferenceName": "Име на конференцията",
				"country": "Страна",
				"court": "Съд",
				"date": "Дата",
				"dateAdded": "Добавен на:",
				"dateDecided": "Дата на решение",
				"dateEnacted": "Дата на влизане в сила",
				"dateModified": "Променен на:",
				"dictionaryTitle": "Заглавие на речник",
				"distributor": "Дистрибутор",
				"docketNumber": "Номер в регистър",
				"documentNumber": "Номер на документ",
				"DOI": "DOI",
				"edition": "Издание",
				"encyclopediaTitle": "Заглавие на енциклопедия",
				"episodeNumber": "Номер на епизод",
				"extra": "Допълнителни",
				"filingDate": "Дата на архивиране",
				"firstPage": "Първа страница",
				"forumTitle": "Заглавия на форум/listserv",
				"genre": "Жанр",
				"history": "История",
				"institution": "Институция",
				"interviewMedium": "Медия",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Брой",
				"issueDate": "Дата на издаване",
				"issuingAuthority": "Издаден от",
				"itemType": "Тип",
				"journalAbbreviation": "Съкратено име на списанието",
				"label": "Етикет",
				"language": "Език",
				"legalStatus": "Законов статут",
				"legislativeBody": "Законодателно тяло",
				"letterType": "Вид",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Вид",
				"mapType": "Вид",
				"meetingName": "Име на срещата",
				"nameOfAct": "Име на закон",
				"network": "Мрежа",
				"number": "Номер",
				"numberOfVolumes": "Номера на томовете",
				"numPages": "брой страници",
				"pages": "Страници",
				"patentNumber": "Номер на патент",
				"place": "Място",
				"postType": "Вид съобщението",
				"presentationType": "Вид",
				"priorityNumbers": "Приоритетни номера",
				"proceedingsTitle": "Заглавие на протокол",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Заглавие на орграма",
				"publicationTitle": "Издание",
				"publicLawNumber": "Номер на закон",
				"publisher": "Издател",
				"references": "Отпратки",
				"reporter": "Журналист",
				"reporterVolume": "Том на стенограмата",
				"reportNumber": "Номер на отчет",
				"reportType": "Вид отчета",
				"rights": "Права",
				"runningTime": "Продължителност",
				"scale": "Скала",
				"section": "Глава",
				"series": "Поредица",
				"seriesNumber": "Номер на поредицата",
				"seriesText": "Текст на поредицата",
				"seriesTitle": "Заглавие на поредицата",
				"session": "Сесия",
				"shortTitle": "Късо заглавие",
				"studio": "Студио",
				"subject": "Тема",
				"system": "Система",
				"thesisType": "Вид",
				"title": "Заглавие",
				"university": "Университет",
				"url": "Адрес",
				"versionNumber": "Version",
				"videoRecordingFormat": "Формат",
				"volume": "Том",
				"websiteTitle": "Заглавие на интернет страница",
				"websiteType": "Вид интернет страницата"
			},
			"creatorTypes": {
				"artist": "Създател",
				"attorneyAgent": "Адвокат/Пълномощтник",
				"author": "Автор",
				"bookAuthor": "Автор на книга",
				"cartographer": "Картограф",
				"castMember": "Член на Трупата",
				"commenter": "Коментатор",
				"composer": "Композитор",
				"contributor": "Сътрудник",
				"cosponsor": "Коспонсор",
				"counsel": "Адвокат",
				"director": "Режисьор",
				"editor": "Редактор",
				"guest": "Гост",
				"interviewee": "Интервю с",
				"interviewer": "Интервюиращ",
				"inventor": "Откривател",
				"performer": "Изпълнител",
				"podcaster": "Автор на подкаст",
				"presenter": "Изнесен от",
				"producer": "Продуцент",
				"programmer": "Програмист",
				"recipient": "Получател",
				"reviewedAuthor": "Рецензиран Автор",
				"scriptwriter": "Сценарист",
				"seriesEditor": "Редактор на Поредицата",
				"sponsor": "Спонсор",
				"translator": "Преводач",
				"wordsBy": "Текст"
			}
		},
		"br": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Skeudennadur",
				"attachment": "Pezh-stag",
				"audioRecording": "Enrolladenn aodio",
				"bill": "Raktres/kinnig lezenn",
				"blogPost": "Embannadenn blog",
				"book": "Levr",
				"bookSection": "Chabistr levr",
				"case": "Afer",
				"computerProgram": "Meziant",
				"conferencePaper": "Pennad koñferañs",
				"dictionaryEntry": "Pennger geriadur",
				"document": "Teuliad",
				"email": "Postel",
				"encyclopediaArticle": "Pennad hollouiziegezh",
				"film": "Film",
				"forumPost": "Evezhiadenn forom",
				"hearing": "Odiañs",
				"instantMessage": "Kemennadenn flapva",
				"interview": "Atersadenn",
				"journalArticle": "Pennad kelaouenn",
				"letter": "Lizher",
				"magazineArticle": "Pennad magazin",
				"manuscript": "Dornskrid",
				"map": "Kartenn",
				"newspaperArticle": "Pennad kazetenn",
				"note": "Notenn",
				"patent": "Breved",
				"podcast": "Podskignañ",
				"presentation": "Kinnigadenn",
				"radioBroadcast": "Abadenn radio",
				"report": "Danevell",
				"statute": "Akta lezennel",
				"thesis": "Tezenn",
				"tvBroadcast": "Abadenn TV",
				"videoRecording": "Enrolladenn video",
				"webpage": "Lec'hienn Web"
			},
			"fields": {
				"abstractNote": "Berradenn",
				"accessDate": "Gwelet d'an/ar/al",
				"applicationNumber": "Niver arload",
				"archive": "Diell",
				"archiveLocation": "Lec'hiadur en diell",
				"artworkMedium": "Harp ar skeudenn",
				"artworkSize": "Ment ar skeudennadur",
				"assignee": "Dilezer",
				"audioFileType": "Doare restr",
				"audioRecordingFormat": "Stumm",
				"billNumber": "Niverenn prezegenn",
				"blogTitle": "Titl blog",
				"bookTitle": "Titl al levr",
				"callNumber": "Niver-envel",
				"caseName": "Anv an afer",
				"code": "Kod",
				"codeNumber": "Niver kod",
				"codePages": "Kod ar pajennoù",
				"codeVolume": "Kod al levrenn",
				"committee": "Komite",
				"company": "Kumpaniezh",
				"conferenceName": "Anv prezegenn",
				"country": "Bro",
				"court": "Lez-varn",
				"date": "Deiziad",
				"dateAdded": "Deiziad ouzhpennañ",
				"dateDecided": "Deiziad an diviz",
				"dateEnacted": "Embannet d'an/ar/al",
				"dateModified": "Deiziad kemmañ",
				"dictionaryTitle": "Titl ar geriadur",
				"distributor": "Dasparzher",
				"docketNumber": "Niver reked",
				"documentNumber": "Niver an teuliad",
				"DOI": "DOI",
				"edition": "Embannadur",
				"encyclopediaTitle": "Titl hollouiziegezh",
				"episodeNumber": "Niver ar rann",
				"extra": "Traoù dibarr",
				"filingDate": "Deiziad leuniadur",
				"firstPage": "Pajenn gentañ",
				"forumTitle": "Titl ar forom/Listserv",
				"genre": "Doare",
				"history": "Istor",
				"institution": "Ensavadur",
				"interviewMedium": "Media",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Niverenn",
				"issueDate": "Deiziad embann",
				"issuingAuthority": "Aozadur a embann",
				"itemType": "Doare",
				"journalAbbreviation": "Berradur anv kelaouenn",
				"label": "Label",
				"language": "Yezh",
				"legalStatus": "Statudoù lezennel",
				"legislativeBody": "Korf lezennel",
				"letterType": "Doare",
				"libraryCatalog": "Dastumad levraoueg",
				"manuscriptType": "Doare",
				"mapType": "Doare",
				"meetingName": "Anv an emvod",
				"nameOfAct": "Anv an akta",
				"network": "Kenrouedad",
				"number": "Niver",
				"numberOfVolumes": "# a levrennoù",
				"numPages": "# a bajennoù",
				"pages": "Pajennoù",
				"patentNumber": "Niver breved",
				"place": "Lec'h",
				"postType": "Doare embannadenn",
				"presentationType": "Doare",
				"priorityNumbers": "Niver priorelezh",
				"proceedingsTitle": "Titl an aktoù",
				"programmingLanguage": "Yezh brogrammiñ",
				"programTitle": "Titl ar program",
				"publicationTitle": "Embannadenn",
				"publicLawNumber": "Niver ofisiel an akta",
				"publisher": "Embanner",
				"references": "Daveennoù",
				"reporter": "Teskad",
				"reporterVolume": "Levrenn dastumad",
				"reportNumber": "Niver an danevell",
				"reportType": "Doare danevell",
				"rights": "Aotreadurioù",
				"runningTime": "Padelezh",
				"scale": "Skeul",
				"section": "Kevrenn",
				"series": "Dastumad",
				"seriesNumber": "Niverenn en dastumad",
				"seriesText": "Testenn an dastumad",
				"seriesTitle": "Titl an dastumad",
				"session": "Dalc'h",
				"shortTitle": "Titl berr",
				"studio": "Studio",
				"subject": "Sujed",
				"system": "Sistem",
				"thesisType": "Doare",
				"title": "Titl",
				"university": "Skol-veur",
				"url": "URL",
				"versionNumber": "Stumm",
				"videoRecordingFormat": "Stumm",
				"volume": "Levrenn",
				"websiteTitle": "Titl al lec'hienn",
				"websiteType": "Doare lec'hienn Web"
			},
			"creatorTypes": {
				"artist": "Arzour",
				"attorneyAgent": "Dileuriad/Ajant",
				"author": "Aozer",
				"bookAuthor": "Aozer al levr",
				"cartographer": "Kartenner",
				"castMember": "Ezel ar c'homedianeta",
				"commenter": "Displeger",
				"composer": "Kompozer",
				"contributor": "Kendaoler",
				"cosponsor": "Ken-tad-paeron",
				"counsel": "Kuzul",
				"director": "Rener",
				"editor": "Embanner",
				"guest": "Den pedet",
				"interviewee": "Aterset gant",
				"interviewer": "Aterser",
				"inventor": "Ijiner",
				"performer": "Jubenner",
				"podcaster": "Podskigner",
				"presenter": "Kinniger",
				"producer": "Produer",
				"programmer": "Programmer",
				"recipient": "Resever",
				"reviewedAuthor": "Aozer niverennet",
				"scriptwriter": "Skriver-skriptoù",
				"seriesEditor": "Embanner heuliadennoù",
				"sponsor": "Sponsor",
				"translator": "Troer",
				"wordsBy": "Gerioù gant"
			}
		},
		"ca-AD": {
			"itemTypes": {
				"annotation": "Anotació",
				"artwork": "Peça artística",
				"attachment": "Fitxer adjunt",
				"audioRecording": "Enregistrament d'àudio",
				"bill": "Llei",
				"blogPost": "Entrada de bloc",
				"book": "Llibre",
				"bookSection": "Capítol d'un llibre",
				"case": "Cas",
				"computerProgram": "Programari",
				"conferencePaper": "Text d'una conferència",
				"dictionaryEntry": "Entrada de diccionari",
				"document": "Document",
				"email": "Correu electrònic",
				"encyclopediaArticle": "Article enciclopèdic",
				"film": "Pel·lícula",
				"forumPost": "Comentari en un fòrum",
				"hearing": "Audició",
				"instantMessage": "Missatge instantani",
				"interview": "Entrevista",
				"journalArticle": "Article de revista acadèmica",
				"letter": "Carta",
				"magazineArticle": "Article de revista",
				"manuscript": "Manuscrit",
				"map": "Mapa",
				"newspaperArticle": "Article de premsa",
				"note": "Nota",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentació",
				"radioBroadcast": "Emissió de ràdio",
				"report": "Informe",
				"statute": "Estatut",
				"thesis": "Tesi",
				"tvBroadcast": "Emissió de televisió",
				"videoRecording": "Enregistrament de vídeo",
				"webpage": "Pàgina web"
			},
			"fields": {
				"abstractNote": "Resum",
				"accessDate": "Últim accés",
				"applicationNumber": "Número d'aplicació",
				"archive": "Arxiu",
				"archiveLocation": "Localització a l'arxiu",
				"artworkMedium": "Mitjà artístic",
				"artworkSize": "Mida",
				"assignee": "Assignatari",
				"audioFileType": "Tipus de fitxer",
				"audioRecordingFormat": "Format",
				"billNumber": "Número de llei",
				"blogTitle": "Títol del blog",
				"bookTitle": "Títol del llibre",
				"callNumber": "Número de catàleg",
				"caseName": "Nom del cas",
				"code": "Codi",
				"codeNumber": "Número del codi",
				"codePages": "Pàgines del codi",
				"codeVolume": "Volum del codi",
				"committee": "Comitè",
				"company": "Empresa",
				"conferenceName": "Títol de la conferència",
				"country": "País",
				"court": "Tribunal",
				"date": "Data",
				"dateAdded": "Afegit",
				"dateDecided": "Data de decisió",
				"dateEnacted": "Data d'aprovació",
				"dateModified": "Modificat",
				"dictionaryTitle": "Títol del diccionari",
				"distributor": "Distribuïdor",
				"docketNumber": "Número d'expedient",
				"documentNumber": "Número de document",
				"DOI": "DOI",
				"edition": "Edició",
				"encyclopediaTitle": "Títol de l'enciclopèdia",
				"episodeNumber": "Número d'episodi",
				"extra": "Extra",
				"filingDate": "Data de presentació",
				"firstPage": "Primera pàgina",
				"forumTitle": "Títol de fòrum/llista",
				"genre": "Gènere",
				"history": "Història",
				"institution": "Institució",
				"interviewMedium": "Mitjà",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Número",
				"issueDate": "Data d'emissió",
				"issuingAuthority": "Autoritat emissora",
				"itemType": "Tipus",
				"journalAbbreviation": "Abreviatura de la revista",
				"label": "Etiqueta",
				"language": "Llengua",
				"legalStatus": "Estatus jurídic",
				"legislativeBody": "Cos legislatiu",
				"letterType": "Tipus",
				"libraryCatalog": "Catàleg de la biblioteca",
				"manuscriptType": "Tipus",
				"mapType": "Tipus",
				"meetingName": "Nom de la trobada",
				"nameOfAct": "Nom de la llei",
				"network": "Xarxa",
				"number": "Número",
				"numberOfVolumes": "Nre. de volums",
				"numPages": "Nre. de pàgines",
				"pages": "Pàgines",
				"patentNumber": "Número de patent",
				"place": "Lloc",
				"postType": "Tipus d'escrit",
				"presentationType": "Tipus",
				"priorityNumbers": "Números de prioritat",
				"proceedingsTitle": "Títol de la ponència",
				"programmingLanguage": "Llenguatge de prog.",
				"programTitle": "Títol del programa",
				"publicationTitle": "Publicació",
				"publicLawNumber": "Número de dret públic",
				"publisher": "Editorial",
				"references": "Referències",
				"reporter": "Reporter",
				"reporterVolume": "Volum del reporter",
				"reportNumber": "Número d'informe",
				"reportType": "Tipus d'informe",
				"rights": "Drets",
				"runningTime": "Durada",
				"scale": "Escala",
				"section": "Secció",
				"series": "Sèrie",
				"seriesNumber": "Número de la sèrie",
				"seriesText": "Text de la sèrie",
				"seriesTitle": "Títol de la sèrie",
				"session": "Sessió",
				"shortTitle": "Títol curt",
				"studio": "Estudi",
				"subject": "Tema",
				"system": "Sistema",
				"thesisType": "Tipus",
				"title": "Títol",
				"university": "Universitat",
				"url": "URL",
				"versionNumber": "Versió",
				"videoRecordingFormat": "Format",
				"volume": "Volum",
				"websiteTitle": "Títol del web",
				"websiteType": "Tipus de pàgina web"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Representant/Agent",
				"author": "Autor",
				"bookAuthor": "Autor del llibre",
				"cartographer": "Cartògraf",
				"castMember": "Membre del repartiment",
				"commenter": "Comentarista",
				"composer": "Compositor",
				"contributor": "Col·laborador",
				"cosponsor": "Copatrocinador",
				"counsel": "Conseller",
				"director": "Director",
				"editor": "Editor",
				"guest": "Convidat",
				"interviewee": "Entrevistat",
				"interviewer": "Entrevistador",
				"inventor": "Inventor",
				"performer": "Intèrpret",
				"podcaster": "Podcaster",
				"presenter": "Presentador",
				"producer": "Productor",
				"programmer": "Programador",
				"recipient": "Receptor",
				"reviewedAuthor": "Autor revisat",
				"scriptwriter": "Guionista",
				"seriesEditor": "Editor de la sèrie",
				"sponsor": "Esponsor",
				"translator": "Traductor",
				"wordsBy": "Lletrista"
			}
		},
		"cs-CZ": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Umělecké dílo",
				"attachment": "Příloha",
				"audioRecording": "Audio nahrávka",
				"bill": "Návrh zákona",
				"blogPost": "Příspěvek v blogu",
				"book": "Kniha",
				"bookSection": "Kapitola knihy",
				"case": "Případ",
				"computerProgram": "Software",
				"conferencePaper": "Konferenční příspěvek",
				"dictionaryEntry": "Záznam ve slovníku",
				"document": "Dokument",
				"email": "E-mail",
				"encyclopediaArticle": "Článek v encyklopedii",
				"film": "Film",
				"forumPost": "Příspěvek ve fóru",
				"hearing": "Slyšení",
				"instantMessage": "Zpráva IM",
				"interview": "Rozhovor",
				"journalArticle": "Článek v časopise",
				"letter": "Dopis",
				"magazineArticle": "Článek v magazínu",
				"manuscript": "Rukopis",
				"map": "Mapa",
				"newspaperArticle": "Článek v novinách",
				"note": "Poznámka",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Prezentace",
				"radioBroadcast": "Pořad v rádiu",
				"report": "Zpráva",
				"statute": "Nařízení",
				"thesis": "Vysokoškolská kvalifikační práce",
				"tvBroadcast": "Pořad v TV",
				"videoRecording": "Video nahrávka",
				"webpage": "Webová stránka"
			},
			"fields": {
				"abstractNote": "Abstrakt",
				"accessDate": "Přístup",
				"applicationNumber": "Číslo žádosti",
				"archive": "Archiv",
				"archiveLocation": "Místo v archivu",
				"artworkMedium": "Médium",
				"artworkSize": "Velikost díla",
				"assignee": "Pověřený",
				"audioFileType": "Typ souboru",
				"audioRecordingFormat": "Formát",
				"billNumber": "Číslo zákona",
				"blogTitle": "Název blogu",
				"bookTitle": "Jméno knihy",
				"callNumber": "Signatura",
				"caseName": "Jméno případu",
				"code": "Zákoník",
				"codeNumber": "Kódové číslo",
				"codePages": "Stránky zákoníku",
				"codeVolume": "Ročník zákoníku",
				"committee": "Výbor",
				"company": "Společnost",
				"conferenceName": "Jméno konference",
				"country": "Země",
				"court": "Soud",
				"date": "Datum",
				"dateAdded": "Datum přidání",
				"dateDecided": "Datum rozhodnutí",
				"dateEnacted": "Datum schválení",
				"dateModified": "Upraveno",
				"dictionaryTitle": "Název slovníku",
				"distributor": "Distributor",
				"docketNumber": "Číslo spisu",
				"documentNumber": "Číslo dokumentu",
				"DOI": "DOI",
				"edition": "Vydání",
				"encyclopediaTitle": "Jméno encyklopedie",
				"episodeNumber": "Číslo epizody",
				"extra": "Extra",
				"filingDate": "Datum zápisu",
				"firstPage": "První strana",
				"forumTitle": "Název fóra",
				"genre": "Žánr",
				"history": "Historie",
				"institution": "Instituce",
				"interviewMedium": "Médium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Číslo",
				"issueDate": "Datum vydání",
				"issuingAuthority": "Vydávající úřad",
				"itemType": "Typ",
				"journalAbbreviation": "Zkrácený název časopisu",
				"label": "Označení",
				"language": "Jazyk",
				"legalStatus": "Zákonný status",
				"legislativeBody": "Zákonodárný orgán",
				"letterType": "Typ",
				"libraryCatalog": "Katalog knihovny",
				"manuscriptType": "Typ",
				"mapType": "Typ",
				"meetingName": "Název setkání",
				"nameOfAct": "Název zákona",
				"network": "Síť",
				"number": "Číslo",
				"numberOfVolumes": "Počet ročníků",
				"numPages": "# stran",
				"pages": "Rozsah",
				"patentNumber": "Číslo patentů",
				"place": "Místo",
				"postType": "Typ příspěvku",
				"presentationType": "Typ",
				"priorityNumbers": "Číslo priority",
				"proceedingsTitle": "Jméno sborníku",
				"programmingLanguage": "Prog. jazyk",
				"programTitle": "Název programu",
				"publicationTitle": "Publikace",
				"publicLawNumber": "Číslo zákona",
				"publisher": "Vydavatel",
				"references": "Reference",
				"reporter": "Sbírka soudních rozhodnutí",
				"reporterVolume": "Ročník sbírky",
				"reportNumber": "Číslo zprávy",
				"reportType": "Druhy zprávy",
				"rights": "Práva",
				"runningTime": "Čas",
				"scale": "Měřítko",
				"section": "Sekce",
				"series": "Série",
				"seriesNumber": "Číslo série",
				"seriesText": "Text série",
				"seriesTitle": "Název série",
				"session": "Zasedání",
				"shortTitle": "Krátký název",
				"studio": "Studio",
				"subject": "Subjekt",
				"system": "Systém",
				"thesisType": "Typ",
				"title": "Název",
				"university": "Univerzita",
				"url": "URL",
				"versionNumber": "Verze",
				"videoRecordingFormat": "Formát",
				"volume": "Ročník",
				"websiteTitle": "Název stránky",
				"websiteType": "Typ webové stránky"
			},
			"creatorTypes": {
				"artist": "Výtvarník",
				"attorneyAgent": "Advokát/zástupce",
				"author": "Autor",
				"bookAuthor": "Autor knihy",
				"cartographer": "Kartograf",
				"castMember": "Člen obsazení",
				"commenter": "Komentátor",
				"composer": "Skladatel",
				"contributor": "Přispěvatel",
				"cosponsor": "Spolusponzor",
				"counsel": "Právní zástupce",
				"director": "Režisér",
				"editor": "Editor",
				"guest": "Host",
				"interviewee": "Rozhovor s",
				"interviewer": "Tazatel",
				"inventor": "Vynálezce",
				"performer": "Účinkující",
				"podcaster": "Autor podcastu",
				"presenter": "Prezentující",
				"producer": "Producent",
				"programmer": "Programátor",
				"recipient": "Příjemce",
				"reviewedAuthor": "Autor revize",
				"scriptwriter": "Scénárista",
				"seriesEditor": "Editor série",
				"sponsor": "Sponzor",
				"translator": "Překladatel",
				"wordsBy": "Texty"
			}
		},
		"da-DK": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Billede/skulptur",
				"attachment": "Vedhæftning",
				"audioRecording": "Lydoptagelse",
				"bill": "Lovforslag",
				"blogPost": "Blog-indlæg",
				"book": "Bog",
				"bookSection": "Bidrag til bog",
				"case": "Retssag/Dom",
				"computerProgram": "Software",
				"conferencePaper": "Konferencebidrag",
				"dictionaryEntry": "Ordbogsopslag",
				"document": "Dokument",
				"email": "E-mail",
				"encyclopediaArticle": "Leksikonartikel",
				"film": "Film",
				"forumPost": "Forum-indlæg",
				"hearing": "Høring",
				"instantMessage": "Besked",
				"interview": "Interview",
				"journalArticle": "Tidsskriftsartikel",
				"letter": "Brev",
				"magazineArticle": "Artikel i blad",
				"manuscript": "Manuskript",
				"map": "Kort",
				"newspaperArticle": "Avisartikel",
				"note": "Note",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Præsentation",
				"radioBroadcast": "Radioudsendelse",
				"report": "Rapport",
				"statute": "Lov (vedtaget)",
				"thesis": "Afhandling",
				"tvBroadcast": "TV-udsendelse",
				"videoRecording": "Videooptagelse",
				"webpage": "Web-side"
			},
			"fields": {
				"abstractNote": "Resumé",
				"accessDate": "Set d.",
				"applicationNumber": "Ansøgning nr.",
				"archive": "Samling",
				"archiveLocation": "Placering i samlingen",
				"artworkMedium": "Medium",
				"artworkSize": "Værkets størrelse",
				"assignee": "Ansvarlig",
				"audioFileType": "Filtype",
				"audioRecordingFormat": "Format",
				"billNumber": "Lovforslagets nr.",
				"blogTitle": "Bloggens titel",
				"bookTitle": "Bogens titel",
				"callNumber": "Opstillingssignatur",
				"caseName": "Sagens navn",
				"code": "Lovsamling",
				"codeNumber": "Nummer",
				"codePages": "Sider",
				"codeVolume": "Bind",
				"committee": "Udvalg",
				"company": "Selskab",
				"conferenceName": "Konferencens navn",
				"country": "Land",
				"court": "Domstol",
				"date": "Tidspunkt",
				"dateAdded": "Tilføjet d.",
				"dateDecided": "Dom afsagt d.",
				"dateEnacted": "Vedtaget d.",
				"dateModified": "Ændret d.",
				"dictionaryTitle": "Ordbogens titel",
				"distributor": "Selskab",
				"docketNumber": "Dossier nr.",
				"documentNumber": "Dokument nr.",
				"DOI": "DOI",
				"edition": "Udgave",
				"encyclopediaTitle": "Leksikonets titel",
				"episodeNumber": "Afsnit nr.",
				"extra": "Ekstra",
				"filingDate": "Indlemmet d.",
				"firstPage": "Første side",
				"forumTitle": "Titel på Forum/Listserv",
				"genre": "Genre",
				"history": "Historie",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Nummer",
				"issueDate": "Udstedt d.",
				"issuingAuthority": "Myndighed",
				"itemType": "Type",
				"journalAbbreviation": "Tidsskr.forkort.",
				"label": "Pladeselskab",
				"language": "Sprog",
				"legalStatus": "Juridisk status",
				"legislativeBody": "Lovgivende organ",
				"letterType": "Type",
				"libraryCatalog": "Bibliotekskatalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Mødets navn",
				"nameOfAct": "Lovens navn",
				"network": "Station (radio/TV)",
				"number": "Nummer",
				"numberOfVolumes": "Antal bind",
				"numPages": "Antal sider",
				"pages": "Sider",
				"patentNumber": "Patentnummer",
				"place": "Sted",
				"postType": "Type (post)",
				"presentationType": "Type",
				"priorityNumbers": "Prioritetsnumre",
				"proceedingsTitle": "Titel på proceedings",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Programmets titel",
				"publicationTitle": "Publikationens titel",
				"publicLawNumber": "Public Law Number (USA)",
				"publisher": "Udgiver/Forlag",
				"references": "Referencer",
				"reporter": "Referat-samling",
				"reporterVolume": "Referat-bind",
				"reportNumber": "Rapportens nr.",
				"reportType": "Rapporttype",
				"rights": "Rettigheder",
				"runningTime": "Længde (tid)",
				"scale": "Skala",
				"section": "Paragraf",
				"series": "Serie",
				"seriesNumber": "Nummer i serien",
				"seriesText": "Serie: suppl. tekst",
				"seriesTitle": "Serietitel",
				"session": "Behandlet",
				"shortTitle": "Forkortet titel",
				"studio": "Studie",
				"subject": "Emne",
				"system": "System",
				"thesisType": "Type",
				"title": "Titel",
				"university": "Universitet",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Bind/Årgang",
				"websiteTitle": "Webstedets titel",
				"websiteType": "Type (websted)"
			},
			"creatorTypes": {
				"artist": "Kunstner/Ophav",
				"attorneyAgent": "Advokat",
				"author": "Forfatter/Ophav",
				"bookAuthor": "Bogens forfatter",
				"cartographer": "Kartograf",
				"castMember": "Medvirkende",
				"commenter": "Kommentator",
				"composer": "Komponist",
				"contributor": "Anden bidragyder",
				"cosponsor": "Medforslagsstiller",
				"counsel": "Advokat",
				"director": "Instruktør/Ophav",
				"editor": "Redaktør",
				"guest": "Gæst",
				"interviewee": "Inverview med",
				"interviewer": "Interviewer",
				"inventor": "Opfinder",
				"performer": "Udøver",
				"podcaster": "Ophav til podcast",
				"presenter": "Forelæser/Ophav",
				"producer": "Producent",
				"programmer": "Programmør",
				"recipient": "Modtager",
				"reviewedAuthor": "Anmeldt forfatter",
				"scriptwriter": "Manuskriptforfatter",
				"seriesEditor": "Seriens redaktør",
				"sponsor": "Forslagsstiller",
				"translator": "Oversætter",
				"wordsBy": "Tekster af"
			}
		},
		"de": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Kunstwerk",
				"attachment": "Anhang",
				"audioRecording": "Tonaufnahme",
				"bill": "Gesetzentwurf",
				"blogPost": "Blog-Post",
				"book": "Buch",
				"bookSection": "Buchteil",
				"case": "Fall",
				"computerProgram": "Software",
				"conferencePaper": "Konferenz-Paper",
				"dictionaryEntry": "Wörterbucheintrag",
				"document": "Dokument",
				"email": "E-Mail",
				"encyclopediaArticle": "Enzyklopädieartikel",
				"film": "Film",
				"forumPost": "Foren-Eintrag",
				"hearing": "Anhörung",
				"instantMessage": "Instant-Message",
				"interview": "Interview",
				"journalArticle": "Zeitschriftenartikel",
				"letter": "Brief",
				"magazineArticle": "Magazin-Artikel",
				"manuscript": "Manuskript",
				"map": "Karte",
				"newspaperArticle": "Zeitungsartikel",
				"note": "Notiz",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Vortrag",
				"radioBroadcast": "Radiosendung",
				"report": "Bericht",
				"statute": "Gesetz",
				"thesis": "Dissertation",
				"tvBroadcast": "Fernsehsendung",
				"videoRecording": "Videoaufnahme",
				"webpage": "Webseite"
			},
			"fields": {
				"abstractNote": "Zusammenfassung",
				"accessDate": "Heruntergeladen am",
				"applicationNumber": "Bewerbungsnummer",
				"archive": "Archiv",
				"archiveLocation": "Standort im Archiv",
				"artworkMedium": "Medium",
				"artworkSize": "Größe des Kunstwerks",
				"assignee": "Abtretungsempfänger",
				"audioFileType": "Dateityp",
				"audioRecordingFormat": "Format",
				"billNumber": "Nummer des Gesetzentwurfs",
				"blogTitle": "Titel des Blogs",
				"bookTitle": "Buchtitel",
				"callNumber": "Signatur",
				"caseName": "Name des Falls",
				"code": "Code",
				"codeNumber": "Codenummer",
				"codePages": "Seiten des Codes",
				"codeVolume": "Band des Codes",
				"committee": "Ausschuss",
				"company": "Firma",
				"conferenceName": "Name der Konferenz",
				"country": "Land",
				"court": "Gericht",
				"date": "Datum",
				"dateAdded": "Hinzugefügt am",
				"dateDecided": "Beschlussdatum",
				"dateEnacted": "Datum des Inkrafttretens",
				"dateModified": "Geändert am",
				"dictionaryTitle": "Titel des Wörterbuchs",
				"distributor": "Verleih",
				"docketNumber": "Aktenzeichen",
				"documentNumber": "Dokumentennummer",
				"DOI": "DOI",
				"edition": "Auflage",
				"encyclopediaTitle": "Titel der Enzyklopädie",
				"episodeNumber": "Nummer der Folge",
				"extra": "Extra",
				"filingDate": "Datum der Einreichung",
				"firstPage": "Erste Seite",
				"forumTitle": "Titel des Forums/Listservs",
				"genre": "Genre",
				"history": "Geschichte",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Ausgabe",
				"issueDate": "Erscheinungsdatum",
				"issuingAuthority": "Herausgeber",
				"itemType": "Typ",
				"journalAbbreviation": "Zeitschriften-Abkürzung",
				"label": "Label",
				"language": "Sprache",
				"legalStatus": "Rechtsstatus",
				"legislativeBody": "Gesetzgebende Körperschaft",
				"letterType": "Art",
				"libraryCatalog": "Bibliothekskatalog",
				"manuscriptType": "Art",
				"mapType": "Art",
				"meetingName": "Name der Sitzung",
				"nameOfAct": "Name des Erlasses",
				"network": "Netzwerk",
				"number": "Nummer",
				"numberOfVolumes": "# von Bänden",
				"numPages": "Anzahl der Seiten",
				"pages": "Seiten",
				"patentNumber": "Patentnummer",
				"place": "Ort",
				"postType": "Art von Eintrag",
				"presentationType": "Art",
				"priorityNumbers": "Prioritätsnummern",
				"proceedingsTitle": "Titel des Konferenzbandes",
				"programmingLanguage": "Programmiersprache",
				"programTitle": "Name des Programms",
				"publicationTitle": "Publikation",
				"publicLawNumber": "Öffentliche Gesetzesnummer",
				"publisher": "Verlag",
				"references": "Quellenangaben",
				"reporter": "Gesetzessammlung",
				"reporterVolume": "Nummer der Gesetzessammlung",
				"reportNumber": "Nummer des Berichts",
				"reportType": "Art von Bericht",
				"rights": "Rechte",
				"runningTime": "Laufzeit",
				"scale": "Maßstab",
				"section": "Teil",
				"series": "Reihe",
				"seriesNumber": "Nummer der Reihe",
				"seriesText": "Reihe Text",
				"seriesTitle": "Titel der Reihe",
				"session": "Sitzung",
				"shortTitle": "Kurztitel",
				"studio": "Studio",
				"subject": "Betreff",
				"system": "System",
				"thesisType": "Art",
				"title": "Titel",
				"university": "Universität",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Band",
				"websiteTitle": "Titel der Website",
				"websiteType": "Art der Webseite"
			},
			"creatorTypes": {
				"artist": "Künstler",
				"attorneyAgent": "Anwalt/Agent",
				"author": "Autor",
				"bookAuthor": "Buchautor",
				"cartographer": "Kartograph",
				"castMember": "Ensemble",
				"commenter": "Kommentator",
				"composer": "Komponist",
				"contributor": "Mitarbeiter",
				"cosponsor": "Mitunterzeichner",
				"counsel": "Anwalt",
				"director": "Regisseur",
				"editor": "Herausgeber",
				"guest": "Gast",
				"interviewee": "Interview mit",
				"interviewer": "Interviewer",
				"inventor": "Erfinder",
				"performer": "Darsteller",
				"podcaster": "Podcaster",
				"presenter": "Vortragender",
				"producer": "Produzent",
				"programmer": "Programmierer",
				"recipient": "Empfänger",
				"reviewedAuthor": "Rezensierter Autor",
				"scriptwriter": "Drehbuchautor",
				"seriesEditor": "Hrsg. der Reihe",
				"sponsor": "Sponsor",
				"translator": "Übersetzer",
				"wordsBy": "Text von"
			}
		},
		"el-GR": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Έργο τέχνης",
				"attachment": "Συνημμένο",
				"audioRecording": "Εγγραφή ήχου",
				"bill": "Λογαριασμός",
				"blogPost": "Ανάρτηση",
				"book": "Βιβλίο",
				"bookSection": "Ενότητα Βιβλίου",
				"case": "Υπόθεση",
				"computerProgram": "Λογισμικό",
				"conferencePaper": "Άρθρο Συνεδρίου",
				"dictionaryEntry": "Εισαγωγή λεξικού",
				"document": "Έγγραφο",
				"email": "ηλ. μήνυμα",
				"encyclopediaArticle": "Άρθρο εγκυκλοπαίδειας",
				"film": "Ταινία",
				"forumPost": "Δημοσίευση φόρουμ",
				"hearing": "Ακρόαση",
				"instantMessage": "Άμεσο Μήνυμα",
				"interview": "Συνέντευξη",
				"journalArticle": "Άρθρο Επιστημονικού Περιοδικού",
				"letter": "Επιστολή",
				"magazineArticle": "Άρθρο Περιοδικού",
				"manuscript": "Χειρόγραφο",
				"map": "Χάρτης",
				"newspaperArticle": "Άρθρο Εφημερίδας",
				"note": "Σημείωση",
				"patent": "Ευρεσιτεχνία",
				"podcast": "Podcast",
				"presentation": "Παρουσίαση",
				"radioBroadcast": "Ραδιοφωνική Μετάδοση",
				"report": "Αναφορά",
				"statute": "Νόμος",
				"thesis": "Διατριβή",
				"tvBroadcast": "Τηλεοπτική Μετάδοση",
				"videoRecording": "Εγγραφή Βίντεο",
				"webpage": "Ιστοσελίδα"
			},
			"fields": {
				"abstractNote": "Περίληψη",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Αρχείο",
				"archiveLocation": "Τοπ. στο Αρχείο",
				"artworkMedium": "Medium",
				"artworkSize": "Μέγεθος έργου τέχνης",
				"assignee": "Assignee",
				"audioFileType": "Τύπος Αρχείου",
				"audioRecordingFormat": "Μορφή",
				"billNumber": "Αριθμός Λογαριασμού",
				"blogTitle": "Blog Title",
				"bookTitle": "Τίτλος Βιβλίου",
				"callNumber": "Αριθμός κλήσης",
				"caseName": "Case Name",
				"code": "Κώδικας",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Επιτροπή",
				"company": "Εταιρία",
				"conferenceName": "Όνομα Συνεδρίου",
				"country": "Χώρα",
				"court": "Δικαστήριο",
				"date": "Ημερομηνία",
				"dateAdded": "Ημερομηνία προσθήκης",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Τροποποιήθηκε",
				"dictionaryTitle": "Τίτλος Λεξικού",
				"distributor": "Διανομέας",
				"docketNumber": "Docket Number",
				"documentNumber": "Document Number",
				"DOI": "DOI",
				"edition": "Έκδοση",
				"encyclopediaTitle": "Τίτλος Εγκυκλοπαίδειας",
				"episodeNumber": "Αριθμός Επεισοδίου",
				"extra": "Επιπλέον",
				"filingDate": "Filing Date",
				"firstPage": "Πρώτη Σελίδα",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "History",
				"institution": "Ίδρυμα",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Τεύχος",
				"issueDate": "Ημερομηνία Έκδοσης",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Τύπος",
				"journalAbbreviation": "Journal Abbr",
				"label": "Ετικέτα",
				"language": "Γλώσσα",
				"legalStatus": "Νομική Υπόσταση",
				"legislativeBody": "Νομοθετικό Σώμα",
				"letterType": "Τύπος",
				"libraryCatalog": "Κατάλογος Βιβλιοθήκης",
				"manuscriptType": "Τύπος",
				"mapType": "Τύπος",
				"meetingName": "Όνομα Σύσκεψης",
				"nameOfAct": "Name of Act",
				"network": "Δίκτυο",
				"number": "Αριθμός",
				"numberOfVolumes": "# Τόμων",
				"numPages": "# από Σελίδες",
				"pages": "Σελίδες",
				"patentNumber": "Αριθμός Ευρεσιτεχνίας",
				"place": "Τόπος",
				"postType": "Post Type",
				"presentationType": "Τύπος",
				"priorityNumbers": "Αριθμοί προτεραιότητας",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Γλώσσα προγραμ.",
				"programTitle": "Program Title",
				"publicationTitle": "Δημοσίευμα",
				"publicLawNumber": "Public Law Number",
				"publisher": "Εκδότης",
				"references": "Αναφορές",
				"reporter": "Ρεπόρτερ",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Αριθμός Αναφοράς",
				"reportType": "Τύπος Αναφοράς",
				"rights": "Δικαιώματα",
				"runningTime": "Running Time",
				"scale": "Κλίμακα",
				"section": "Ενότητα",
				"series": "Σειρά",
				"seriesNumber": "Αριθμός Σειράς",
				"seriesText": "Series Text",
				"seriesTitle": "Τίτλος Σειράς",
				"session": "Ενότητα",
				"shortTitle": "Short Title",
				"studio": "Στούντιο",
				"subject": "Θέμα",
				"system": "Σύστημα",
				"thesisType": "Τύπος",
				"title": "Τίτλος",
				"university": "Πανεπιστήμιο",
				"url": "URL",
				"versionNumber": "Έκδοση",
				"videoRecordingFormat": "Μορφή",
				"volume": "Τόμος",
				"websiteTitle": "Τίτλος Ιστότοπου",
				"websiteType": "Τύπος Ιστότοπου"
			},
			"creatorTypes": {
				"artist": "Καλλιτέχνης",
				"attorneyAgent": "Attorney/Agent",
				"author": "Συγγραφέας",
				"bookAuthor": "Συγγραφέας Βιβλίου",
				"cartographer": "Χαρτογράφος",
				"castMember": "Μέλος του καστ",
				"commenter": "Σχολιαστής",
				"composer": "Συνθέτης",
				"contributor": "Contributor",
				"cosponsor": "Συντονιστής",
				"counsel": "Counsel",
				"director": "Σκηνοθέτης",
				"editor": "Συντάκτης",
				"guest": "Guest",
				"interviewee": "Συνέντευξη με",
				"interviewer": "Δημοσιογράφος",
				"inventor": "Inventor",
				"performer": "Ερμηνευτής",
				"podcaster": "Podcaster",
				"presenter": "Παρουσιαστής",
				"producer": "Παραγωγός",
				"programmer": "Προγραμματιστής",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Sponsor",
				"translator": "Μετάφραση",
				"wordsBy": "Words By"
			}
		},
		"en-GB": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Artwork",
				"attachment": "Attachment",
				"audioRecording": "Audio Recording",
				"bill": "Bill",
				"blogPost": "Blog Post",
				"book": "Book",
				"bookSection": "Book Section",
				"case": "Case",
				"computerProgram": "Software",
				"conferencePaper": "Conference Paper",
				"dictionaryEntry": "Dictionary Entry",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Encyclopedia Article",
				"film": "Film",
				"forumPost": "Forum Post",
				"hearing": "Hearing",
				"instantMessage": "Instant Message",
				"interview": "Interview",
				"journalArticle": "Journal Article",
				"letter": "Letter",
				"magazineArticle": "Magazine Article",
				"manuscript": "Manuscript",
				"map": "Map",
				"newspaperArticle": "Newspaper Article",
				"note": "Note",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentation",
				"radioBroadcast": "Radio Broadcast",
				"report": "Report",
				"statute": "Statute",
				"thesis": "Thesis",
				"tvBroadcast": "TV Broadcast",
				"videoRecording": "Video Recording",
				"webpage": "Web Page"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "Loc. in Archive",
				"artworkMedium": "Medium",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "File Type",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "Blog Title",
				"bookTitle": "Book Title",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "Code",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Committee",
				"company": "Company",
				"conferenceName": "Conference Name",
				"country": "Country",
				"court": "Court",
				"date": "Date",
				"dateAdded": "Date Added",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Modified",
				"dictionaryTitle": "Dictionary Title",
				"distributor": "Distributor",
				"docketNumber": "Docket Number",
				"documentNumber": "Document Number",
				"DOI": "DOI",
				"edition": "Edition",
				"encyclopediaTitle": "Encyclopedia Title",
				"episodeNumber": "Episode Number",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "First Page",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "History",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Issue",
				"issueDate": "Issue Date",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Type",
				"journalAbbreviation": "Journal Abbr",
				"label": "Label",
				"language": "Language",
				"legalStatus": "Legal Status",
				"legislativeBody": "Legislative Body",
				"letterType": "Type",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Meeting Name",
				"nameOfAct": "Name of Act",
				"network": "Network",
				"number": "Number",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "Pages",
				"patentNumber": "Patent Number",
				"place": "Place",
				"postType": "Post Type",
				"presentationType": "Type",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Publication",
				"publicLawNumber": "Public Law Number",
				"publisher": "Publisher",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Report Number",
				"reportType": "Report Type",
				"rights": "Rights",
				"runningTime": "Running Time",
				"scale": "Scale",
				"section": "Section",
				"series": "Series",
				"seriesNumber": "Series Number",
				"seriesText": "Series Text",
				"seriesTitle": "Series Title",
				"session": "Session",
				"shortTitle": "Short Title",
				"studio": "Studio",
				"subject": "Subject",
				"system": "System",
				"thesisType": "Type",
				"title": "Title",
				"university": "University",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Website Title",
				"websiteType": "Website Type"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Attorney/Agent",
				"author": "Author",
				"bookAuthor": "Book Author",
				"cartographer": "Cartographer",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "Composer",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "Director",
				"editor": "Editor",
				"guest": "Guest",
				"interviewee": "Interview With",
				"interviewer": "Interviewer",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Producer",
				"programmer": "Programmer",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Sponsor",
				"translator": "Translator",
				"wordsBy": "Words By"
			}
		},
		"en-US": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Artwork",
				"attachment": "Attachment",
				"audioRecording": "Audio Recording",
				"bill": "Bill",
				"blogPost": "Blog Post",
				"book": "Book",
				"bookSection": "Book Section",
				"case": "Case",
				"computerProgram": "Software",
				"conferencePaper": "Conference Paper",
				"dictionaryEntry": "Dictionary Entry",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Encyclopedia Article",
				"film": "Film",
				"forumPost": "Forum Post",
				"hearing": "Hearing",
				"instantMessage": "Instant Message",
				"interview": "Interview",
				"journalArticle": "Journal Article",
				"letter": "Letter",
				"magazineArticle": "Magazine Article",
				"manuscript": "Manuscript",
				"map": "Map",
				"newspaperArticle": "Newspaper Article",
				"note": "Note",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentation",
				"radioBroadcast": "Radio Broadcast",
				"report": "Report",
				"statute": "Statute",
				"thesis": "Thesis",
				"tvBroadcast": "TV Broadcast",
				"videoRecording": "Video Recording",
				"webpage": "Web Page"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "Loc. in Archive",
				"artworkMedium": "Medium",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "File Type",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "Blog Title",
				"bookTitle": "Book Title",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "Code",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Committee",
				"company": "Company",
				"conferenceName": "Conference Name",
				"country": "Country",
				"court": "Court",
				"date": "Date",
				"dateAdded": "Date Added",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Modified",
				"dictionaryTitle": "Dictionary Title",
				"distributor": "Distributor",
				"docketNumber": "Docket Number",
				"documentNumber": "Document Number",
				"DOI": "DOI",
				"edition": "Edition",
				"encyclopediaTitle": "Encyclopedia Title",
				"episodeNumber": "Episode Number",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "First Page",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "History",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Issue",
				"issueDate": "Issue Date",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Type",
				"journalAbbreviation": "Journal Abbr",
				"label": "Label",
				"language": "Language",
				"legalStatus": "Legal Status",
				"legislativeBody": "Legislative Body",
				"letterType": "Type",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Meeting Name",
				"nameOfAct": "Name of Act",
				"network": "Network",
				"number": "Number",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "Pages",
				"patentNumber": "Patent Number",
				"place": "Place",
				"postType": "Post Type",
				"presentationType": "Type",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Publication",
				"publicLawNumber": "Public Law Number",
				"publisher": "Publisher",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Report Number",
				"reportType": "Report Type",
				"rights": "Rights",
				"runningTime": "Running Time",
				"scale": "Scale",
				"section": "Section",
				"series": "Series",
				"seriesNumber": "Series Number",
				"seriesText": "Series Text",
				"seriesTitle": "Series Title",
				"session": "Session",
				"shortTitle": "Short Title",
				"studio": "Studio",
				"subject": "Subject",
				"system": "System",
				"thesisType": "Type",
				"title": "Title",
				"university": "University",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Website Title",
				"websiteType": "Website Type"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Attorney/Agent",
				"author": "Author",
				"bookAuthor": "Book Author",
				"cartographer": "Cartographer",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "Composer",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "Director",
				"editor": "Editor",
				"guest": "Guest",
				"interviewee": "Interview With",
				"interviewer": "Interviewer",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Producer",
				"programmer": "Programmer",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Sponsor",
				"translator": "Translator",
				"wordsBy": "Words By"
			}
		},
		"es-ES": {
			"itemTypes": {
				"annotation": "Anotación",
				"artwork": "Obra de arte",
				"attachment": "Adjunto",
				"audioRecording": "Grabación de sonido",
				"bill": "Propuesta de ley",
				"blogPost": "Entrada de blog",
				"book": "Libro",
				"bookSection": "Sección de un libro",
				"case": "Caso",
				"computerProgram": "Software",
				"conferencePaper": "Artículo en conferencia",
				"dictionaryEntry": "Entrada de diccionario",
				"document": "Documento",
				"email": "Correo electrónico",
				"encyclopediaArticle": "Artículo de enciclopedia",
				"film": "Película",
				"forumPost": "Mensaje en un foro",
				"hearing": "Audiencia",
				"instantMessage": "Mensaje instantáneo",
				"interview": "Entrevista",
				"journalArticle": "Artículo de revista académica",
				"letter": "Carta",
				"magazineArticle": "Artículo de revista",
				"manuscript": "Manuscrito",
				"map": "Mapa",
				"newspaperArticle": "Artículo de periódico",
				"note": "Nota",
				"patent": "Patente",
				"podcast": "Pódcast",
				"presentation": "Presentación",
				"radioBroadcast": "Emisión de radio",
				"report": "Informe",
				"statute": "Estatuto",
				"thesis": "Tesis",
				"tvBroadcast": "Emisión de TV",
				"videoRecording": "Grabación de vídeo",
				"webpage": "Página web"
			},
			"fields": {
				"abstractNote": "Resumen",
				"accessDate": "Accedido",
				"applicationNumber": "Número de solicitud",
				"archive": "Archivo",
				"archiveLocation": "Posición en archivo",
				"artworkMedium": "Medio",
				"artworkSize": "Tamaño de la obra",
				"assignee": "Responsable",
				"audioFileType": "Tipo de archivo",
				"audioRecordingFormat": "Formato",
				"billNumber": "Número de propuesta de ley",
				"blogTitle": "Título del blog",
				"bookTitle": "Título del libro",
				"callNumber": "Signatura",
				"caseName": "Nombre del caso",
				"code": "Código",
				"codeNumber": "Número de código",
				"codePages": "Páginas del código",
				"codeVolume": "Volumen del código",
				"committee": "Comité",
				"company": "Compañía",
				"conferenceName": "Nombre de la conferencia",
				"country": "País",
				"court": "Juzgado",
				"date": "Fecha",
				"dateAdded": "Fecha de adición",
				"dateDecided": "Fecha de sentencia",
				"dateEnacted": "Fecha de entrada en vigor",
				"dateModified": "Modificado",
				"dictionaryTitle": "Título del diccionario",
				"distributor": "Distribuidor",
				"docketNumber": "Número de expediente",
				"documentNumber": "Número de documento",
				"DOI": "DOI",
				"edition": "Edición",
				"encyclopediaTitle": "Título de la enciclopedia",
				"episodeNumber": "Número de episodio",
				"extra": "Adicional",
				"filingDate": "Fecha de solicitud",
				"firstPage": "Primera página",
				"forumTitle": "Título de foro o lista de correo",
				"genre": "Género",
				"history": "Historia",
				"institution": "Institución",
				"interviewMedium": "Medio",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Ejemplar",
				"issueDate": "Fecha de salida",
				"issuingAuthority": "Entidad emisora",
				"itemType": "Tipo",
				"journalAbbreviation": "Abrev. de revista",
				"label": "Etiqueta",
				"language": "Idioma",
				"legalStatus": "Estado legal",
				"legislativeBody": "Cuerpo legislativo",
				"letterType": "Tipo",
				"libraryCatalog": "Catálogo de biblioteca",
				"manuscriptType": "Tipo",
				"mapType": "Tipo",
				"meetingName": "Nombre de la reunión",
				"nameOfAct": "Nombre de la ley",
				"network": "Red",
				"number": "Número",
				"numberOfVolumes": "Número de volúmenes",
				"numPages": "Número de páginas",
				"pages": "Páginas",
				"patentNumber": "Número de patente",
				"place": "Lugar",
				"postType": "Tipo de mensaje",
				"presentationType": "Tipo",
				"priorityNumbers": "Números de prioridad",
				"proceedingsTitle": "Título de las actas",
				"programmingLanguage": "Lenguaje de programación",
				"programTitle": "Título del programa",
				"publicationTitle": "Publicación",
				"publicLawNumber": "Número de ley pública",
				"publisher": "Editorial",
				"references": "Referencias",
				"reporter": "Acta judicial",
				"reporterVolume": "Volumen de las actas",
				"reportNumber": "Número de informe",
				"reportType": "Tipo de informe",
				"rights": "Derechos",
				"runningTime": "Duración",
				"scale": "Escala",
				"section": "Sección",
				"series": "Serie",
				"seriesNumber": "Número de la serie",
				"seriesText": "Texto de la serie",
				"seriesTitle": "Título de la serie",
				"session": "Sesión",
				"shortTitle": "Título corto",
				"studio": "Estudio",
				"subject": "Asunto",
				"system": "Sistema",
				"thesisType": "Tipo",
				"title": "Título",
				"university": "Universidad",
				"url": "URL",
				"versionNumber": "Versión",
				"videoRecordingFormat": "Formato",
				"volume": "Volumen",
				"websiteTitle": "Título de página web",
				"websiteType": "Tipo de página Web"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Abogado/Representante",
				"author": "Autor",
				"bookAuthor": "Autor del libro",
				"cartographer": "Cartógrafo",
				"castMember": "Miembro del reparto",
				"commenter": "Comentador",
				"composer": "Compositor",
				"contributor": "Contribuidor",
				"cosponsor": "Copatrocinador",
				"counsel": "Consejero",
				"director": "Director",
				"editor": "Editor",
				"guest": "Invitado",
				"interviewee": "Entrevista con",
				"interviewer": "Entrevistador",
				"inventor": "Inventor",
				"performer": "Intérprete",
				"podcaster": "Podcaster",
				"presenter": "Presentador",
				"producer": "Productor",
				"programmer": "Programador",
				"recipient": "Receptor",
				"reviewedAuthor": "Autor revisado",
				"scriptwriter": "Guionista",
				"seriesEditor": "Editor de la serie",
				"sponsor": "Patrocinador",
				"translator": "Traductor",
				"wordsBy": "Palabras de"
			}
		},
		"et-EE": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Kunstiteos",
				"attachment": "Manus",
				"audioRecording": "Helisalvestis",
				"bill": "Arve",
				"blogPost": "Blogipostitus",
				"book": "Raamat",
				"bookSection": "Osa raamatust",
				"case": "Kaasus",
				"computerProgram": "Software",
				"conferencePaper": "Ettekanne",
				"dictionaryEntry": "Kirje sõnaraamatus",
				"document": "Dokument",
				"email": "E-mail",
				"encyclopediaArticle": "Entsüklopeediaartikkel",
				"film": "Film",
				"forumPost": "Foorumi postitus",
				"hearing": "Istung",
				"instantMessage": "Välksõnum",
				"interview": "Intervjuu",
				"journalArticle": "Artikkel",
				"letter": "Kiri",
				"magazineArticle": "Ajakirjaartikkel (mitteakad.)",
				"manuscript": "Käsikiri",
				"map": "Kaart",
				"newspaperArticle": "Ajaleheartikkel",
				"note": "Märkus",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Esitlus",
				"radioBroadcast": "Raadiosaade",
				"report": "Raport",
				"statute": "Statuut",
				"thesis": "Väitekiri",
				"tvBroadcast": "Telesaade",
				"videoRecording": "Videosalvestis",
				"webpage": "Veebilehekülg"
			},
			"fields": {
				"abstractNote": "Abstrakt",
				"accessDate": "Vaadatud",
				"applicationNumber": "Taotluse number",
				"archive": "Arhiiv",
				"archiveLocation": "Asukoht arhiivis",
				"artworkMedium": "Kandja",
				"artworkSize": "Kunstiteose suurus",
				"assignee": "Ülesande täitja",
				"audioFileType": "Faili tüüp",
				"audioRecordingFormat": "Formaat",
				"billNumber": "Arvenumber",
				"blogTitle": "Blogi nimi",
				"bookTitle": "Raamatupealkiri",
				"callNumber": "Kohaviit",
				"caseName": "Kaasuse nimi",
				"code": "Koodeks",
				"codeNumber": "Koodeksinumber",
				"codePages": "Koodeksi leheküljed",
				"codeVolume": "Koodeksi köide",
				"committee": "Kommitee",
				"company": "Firma",
				"conferenceName": "Konverentsi nimi",
				"country": "Maa",
				"court": "Kohus",
				"date": "Aeg",
				"dateAdded": "Lisamise aeg",
				"dateDecided": "Otsustamise aeg",
				"dateEnacted": "Jõustumise kuupäev",
				"dateModified": "Muudetud",
				"dictionaryTitle": "Sõnaraamatu pealkiri",
				"distributor": "Levitaja",
				"docketNumber": "Päevakorra number",
				"documentNumber": "Dokumendi number",
				"DOI": "DOI",
				"edition": "Trükk",
				"encyclopediaTitle": "Entsüklopeedia pealkiri",
				"episodeNumber": "Episoodi number",
				"extra": "Lisa",
				"filingDate": "Arhiveerimiskuupäev",
				"firstPage": "Esimene lehekülg",
				"forumTitle": "Foorumi/Listi pealkir",
				"genre": "Žanr",
				"history": "Ajalugu",
				"institution": "Asutus",
				"interviewMedium": "Kandja",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Väljalase",
				"issueDate": "Väljalaske aeg",
				"issuingAuthority": "Väljaandja",
				"itemType": "Tüüp",
				"journalAbbreviation": "Ajakirja lüh.",
				"label": "Silt",
				"language": "Keel",
				"legalStatus": "Õiguslik seis",
				"legislativeBody": "Seadusandlik keha",
				"letterType": "Tüüp",
				"libraryCatalog": "Raamatukogukataloog",
				"manuscriptType": "Tüüp",
				"mapType": "Tüüp",
				"meetingName": "Kohtumise nimi",
				"nameOfAct": "Akti nimi",
				"network": "Võrk",
				"number": "Number",
				"numberOfVolumes": "# köidet",
				"numPages": "# lk",
				"pages": "Leheküljed",
				"patentNumber": "Patendi number",
				"place": "Koht",
				"postType": "Postituse tüüp",
				"presentationType": "Tüüp",
				"priorityNumbers": "Prioriteedi numbrid",
				"proceedingsTitle": "Toimetise pealkiri",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Programmi nimi",
				"publicationTitle": "Trükis",
				"publicLawNumber": "Avaliku seaduse number(?)",
				"publisher": "Väljaandja",
				"references": "Viited",
				"reporter": "Teavitaja",
				"reporterVolume": "Raporteerija köide(?)",
				"reportNumber": "Raportinumber",
				"reportType": "Raportitüüp",
				"rights": "Õigused",
				"runningTime": "Kestvus",
				"scale": "Suurus",
				"section": "Osa",
				"series": "Seeria",
				"seriesNumber": "Seeria number",
				"seriesText": "Seeria tekst",
				"seriesTitle": "Seeria pealkiri",
				"session": "Sessioon",
				"shortTitle": "Lühendatud pealkiri",
				"studio": "Stuudio",
				"subject": "Subjekt",
				"system": "Süsteem",
				"thesisType": "Tüüp",
				"title": "Pealkiri",
				"university": "Ülikool",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Formaat",
				"volume": "Köide",
				"websiteTitle": "Veebilehekülje pealkiri",
				"websiteType": "Veebilehe tüüp"
			},
			"creatorTypes": {
				"artist": "Kunstnik",
				"attorneyAgent": "Esindaja/Agent",
				"author": "Autor",
				"bookAuthor": "Raamatu autor",
				"cartographer": "Kartograaf",
				"castMember": "Osatäitja",
				"commenter": "Kommentaator",
				"composer": "Helilooja",
				"contributor": "Kaastööline",
				"cosponsor": "Kaas-sponsor",
				"counsel": "Nõustaja",
				"director": "Režissöör",
				"editor": "Toimetaja",
				"guest": "Külaline",
				"interviewee": "Intervjueeritav",
				"interviewer": "Intervjueerija",
				"inventor": "Leiutaja",
				"performer": "Esitaja",
				"podcaster": "Podcaster",
				"presenter": "Esitaja",
				"producer": "Produtsent",
				"programmer": "Programmeerija",
				"recipient": "Saaja",
				"reviewedAuthor": "Arvustatud autor",
				"scriptwriter": "Käsikirja autor",
				"seriesEditor": "Seeria toimetaja",
				"sponsor": "Sponsor",
				"translator": "Tõlkija",
				"wordsBy": "Sõnade autor"
			}
		},
		"eu-ES": {
			"itemTypes": {
				"annotation": "Oharpena",
				"artwork": "Arte lan",
				"attachment": "Eranskina",
				"audioRecording": "Audio Grabaketa",
				"bill": "Lege proiektu",
				"blogPost": "Blog Albistea",
				"book": "Liburu",
				"bookSection": "Kapitulu",
				"case": "Kasu legal",
				"computerProgram": "Softwarea",
				"conferencePaper": "Kongresu paper",
				"dictionaryEntry": "Hiztegi-sarrera",
				"document": "Dokumentu",
				"email": "E-posta",
				"encyclopediaArticle": "Entziklopedia artikulua",
				"film": "Filma",
				"forumPost": "Forum Posta",
				"hearing": "Bista",
				"instantMessage": "Zuzeneko mezularitza",
				"interview": "Elkarrizketa",
				"journalArticle": "Aldizkaria artikulu",
				"letter": "Gutun",
				"magazineArticle": "Prentsa artikulu",
				"manuscript": "Eskuizkribu",
				"map": "Mapa",
				"newspaperArticle": "Egunkaria albiste",
				"note": "Oharra",
				"patent": "Patente",
				"podcast": "Podcasta",
				"presentation": "Aurkezpena",
				"radioBroadcast": "Irrati saioa",
				"report": "Txosten",
				"statute": "Estatutua",
				"thesis": "Tesi",
				"tvBroadcast": "Telebista saioa",
				"videoRecording": "Video Grabaketa",
				"webpage": "Web orri"
			},
			"fields": {
				"abstractNote": "Laburpena",
				"accessDate": "Atzipen data",
				"applicationNumber": "Aplikazio zenbakia",
				"archive": "Artxiboa",
				"archiveLocation": "Aurkibidea artxiboan",
				"artworkMedium": "Hedabidea",
				"artworkSize": "Artelanaren neurriak",
				"assignee": "Nori esleitua",
				"audioFileType": "Fitxategi mota",
				"audioRecordingFormat": "Formatua",
				"billNumber": "Proiektuaren zbk",
				"blogTitle": "Blog izenburua",
				"bookTitle": "Liburuaren izenburua",
				"callNumber": "Erref. Zenbakia",
				"caseName": "Kasu izena",
				"code": "Kodea",
				"codeNumber": "Kode zenbakia",
				"codePages": "Kode orrialdeak",
				"codeVolume": "Kodearen Alea",
				"committee": "Batzordea",
				"company": "Enpresa",
				"conferenceName": "Kongresuaren titulua",
				"country": "Herrialdea",
				"court": "Auzitegia",
				"date": "Data",
				"dateAdded": "Noiz gehitua",
				"dateDecided": "Erabakiaren data",
				"dateEnacted": "Noiz antzeztua",
				"dateModified": "Noiz aldatua",
				"dictionaryTitle": "Hiztegiaren izenburua",
				"distributor": "Banatzailea",
				"docketNumber": "Docket zbk.",
				"documentNumber": "Dokumentu zbk.",
				"DOI": "DOI",
				"edition": "Edizioa",
				"encyclopediaTitle": "Entziklopediaren izenburua",
				"episodeNumber": "Saio zbk.",
				"extra": "Estra",
				"filingDate": "Noiz aurkeztua",
				"firstPage": "Lehen orrialdea",
				"forumTitle": "Forum/Listserv Izenburua",
				"genre": "Generoa",
				"history": "Historia",
				"institution": "Instituzioa",
				"interviewMedium": "Komunikabidea",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Zenbakia",
				"issueDate": "Zenbakiaren data",
				"issuingAuthority": "Agintaritza jaulkitzailea",
				"itemType": "Mota",
				"journalAbbreviation": "Aldizkaria labur.",
				"label": "Disketxea",
				"language": "Hizkuntza",
				"legalStatus": "Egoera legala",
				"legislativeBody": "Erakunde legegintzailea",
				"letterType": "Mota",
				"libraryCatalog": "Liburutegi katalogoa",
				"manuscriptType": "Mota",
				"mapType": "Mota",
				"meetingName": "Bilkuraren titulua",
				"nameOfAct": "Ekitaldiaren izena",
				"network": "Irrati/TB-sare",
				"number": "Zenbakia",
				"numberOfVolumes": "Ale kopurua",
				"numPages": "orr.kopurua",
				"pages": "Orrialdeak",
				"patentNumber": "Patente zenbakia",
				"place": "Tokia",
				"postType": "Posta mota",
				"presentationType": "Mota",
				"priorityNumbers": "Lehentasun zenbakiak",
				"proceedingsTitle": "Proceedings izenburua",
				"programmingLanguage": "Prog. lengoaia",
				"programTitle": "Programaren izenburua",
				"publicationTitle": "Agerkaria",
				"publicLawNumber": "Lege publiko zenbakia",
				"publisher": "Argitaratzailea",
				"references": "Erreferentziak",
				"reporter": "Erreportaria",
				"reporterVolume": "Berriemailearen alea",
				"reportNumber": "Txostenaren zbk",
				"reportType": "Txosten mota",
				"rights": "Eskubideak",
				"runningTime": "Luzapena",
				"scale": "Eskala",
				"section": "Atala",
				"series": "Serie",
				"seriesNumber": "Serie-zenbakia",
				"seriesText": "Serie-testua",
				"seriesTitle": "Serie-izenburua",
				"session": "Saioa",
				"shortTitle": "izenburu laburra",
				"studio": "Estudioa",
				"subject": "Gaia",
				"system": "Sistema",
				"thesisType": "Mota",
				"title": "Izenburua",
				"university": "Unibertsitatea",
				"url": "URLa",
				"versionNumber": "Bertsioa",
				"videoRecordingFormat": "Formatua",
				"volume": "Alea",
				"websiteTitle": "Web-orriaren izenburua",
				"websiteType": "Orrialde mota"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Abokatua/Agentea",
				"author": "Egile",
				"bookAuthor": "Liburuaren egilea",
				"cartographer": "Kartografialaria",
				"castMember": "Aktorea",
				"commenter": "Esataria",
				"composer": "Konposatzailea",
				"contributor": "Kolaboratzaile",
				"cosponsor": "Laguntzailea",
				"counsel": "Kontseilua",
				"director": "Zuzendaria",
				"editor": "Editore",
				"guest": "Gonbidatua",
				"interviewee": "Elkarrizketatua",
				"interviewer": "Elkarrizketatzaile",
				"inventor": "Asmatzailea",
				"performer": "Antzezlea",
				"podcaster": "Podcast egilea",
				"presenter": "Aurkezlea",
				"producer": "Ekoizlea",
				"programmer": "Programatzailea",
				"recipient": "Hartzailea",
				"reviewedAuthor": "Berrikusitako egilea",
				"scriptwriter": "Gidoi-idazle",
				"seriesEditor": "Seriearen editore",
				"sponsor": "Babeslea",
				"translator": "Itzultzaile",
				"wordsBy": "Hitzak"
			}
		},
		"fa": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "اثر هنری",
				"attachment": "پیوست",
				"audioRecording": "صدای ضبط شده",
				"bill": "قانون",
				"blogPost": "پست بلاگ",
				"book": "کتاب",
				"bookSection": "فصل کتاب",
				"case": "پرونده",
				"computerProgram": "Software",
				"conferencePaper": "مقاله کنفرانس",
				"dictionaryEntry": "مدخل لغتنامه",
				"document": "سند",
				"email": "ایمیل",
				"encyclopediaArticle": "مقاله دانشنامه",
				"film": "فیلم",
				"forumPost": "پست فروم",
				"hearing": "استماع",
				"instantMessage": "پیام فوری",
				"interview": "مصاحبه",
				"journalArticle": "مقاله",
				"letter": "نامه",
				"magazineArticle": "مقاله مجله",
				"manuscript": "دست‌نوشته",
				"map": "نقشه",
				"newspaperArticle": "مقاله روزنامه",
				"note": "یادداشت",
				"patent": "ثبت اختراع",
				"podcast": "پادکست",
				"presentation": "ارائه",
				"radioBroadcast": "برنامه رادیویی",
				"report": "گزارش",
				"statute": "مجسمه",
				"thesis": "پایان‌نامه",
				"tvBroadcast": "برنامه تلویزیونی",
				"videoRecording": "تصویر ضبط شده",
				"webpage": "صفحه وب"
			},
			"fields": {
				"abstractNote": "چکیده",
				"accessDate": "تاریخ دسترسی",
				"applicationNumber": "شماره درخواست",
				"archive": "آرشیو",
				"archiveLocation": "محل در آرشیو",
				"artworkMedium": "رسانه",
				"artworkSize": "اندازه کار هنری",
				"assignee": "نماینده قانونی",
				"audioFileType": "نوع پرونده",
				"audioRecordingFormat": "قالب",
				"billNumber": "Bill Number",
				"blogTitle": "عنوان بلاگ",
				"bookTitle": "عنوان کتاب",
				"callNumber": "شماره فراخوانی",
				"caseName": "نام پرونده",
				"code": "کد",
				"codeNumber": "شماره کد",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "کمیته",
				"company": "شرکت",
				"conferenceName": "نام کنفرانس",
				"country": "کشور",
				"court": "دادگاه",
				"date": "تاریخ",
				"dateAdded": "تاریخ افزودن",
				"dateDecided": "Date Decided",
				"dateEnacted": "تاریخ تصویب",
				"dateModified": "تاریخ اصلاح",
				"dictionaryTitle": "عنوان لغتنامه",
				"distributor": "‌توزیع‌کننده",
				"docketNumber": "شماره دفتر",
				"documentNumber": "شماره سند",
				"DOI": "شناسه DOI",
				"edition": "ویرایش",
				"encyclopediaTitle": "عنوان دانشنامه",
				"episodeNumber": "شماره اپیزود",
				"extra": "اطلاعات اضافه",
				"filingDate": "تاریخ بایگانی",
				"firstPage": "صفحه اول",
				"forumTitle": "عنوان انجمن گفتگو",
				"genre": "ژانر",
				"history": "تاریخچه",
				"institution": "موسسه",
				"interviewMedium": "رسانه",
				"ISBN": "شابک",
				"ISSN": "شابن",
				"issue": "شماره",
				"issueDate": "تاریخ شماره",
				"issuingAuthority": "مرجع صادر کننده",
				"itemType": "نوع",
				"journalAbbreviation": "نام مختصر مجله",
				"label": "برچسب",
				"language": "زبان",
				"legalStatus": "وضعیت حقوقی",
				"legislativeBody": "هیات قانون‌گذاری",
				"letterType": "نوع",
				"libraryCatalog": "فهرست کتابخانه",
				"manuscriptType": "نوع",
				"mapType": "نوع",
				"meetingName": "نام نشست",
				"nameOfAct": "نام قانون",
				"network": "شبکه",
				"number": "عدد",
				"numberOfVolumes": "تعداد جلد",
				"numPages": "تعداد صفحه",
				"pages": "صفحات",
				"patentNumber": "شماره ثبت اختراع",
				"place": "مکان",
				"postType": "نوع پست",
				"presentationType": "نوع",
				"priorityNumbers": "شماره‌های اولویت",
				"proceedingsTitle": "عنوان مجموعه مقالات",
				"programmingLanguage": "Prog. Language",
				"programTitle": "عنوان برنامه",
				"publicationTitle": "انتشار",
				"publicLawNumber": "شماره قانون عمومی",
				"publisher": "ناشر",
				"references": "مراجع",
				"reporter": "گزارشگر",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "شماره گزارش",
				"reportType": "نوع گزارش",
				"rights": "حقوق",
				"runningTime": "زمان اجرا",
				"scale": "مقیاس",
				"section": "بخش",
				"series": "مجموعه (سری)",
				"seriesNumber": "شماره مجموعه",
				"seriesText": "متن مجموعه",
				"seriesTitle": "عنوان مجموعه",
				"session": "جلسه",
				"shortTitle": "عنوان کوتاه",
				"studio": "استودیو",
				"subject": "موضوع",
				"system": "سامانه",
				"thesisType": "نوع",
				"title": "عنوان",
				"university": "دانشگاه",
				"url": "نشانی وب",
				"versionNumber": "Version",
				"videoRecordingFormat": "قالب",
				"volume": "جلد",
				"websiteTitle": "عنوان وب‌گاه",
				"websiteType": "نوع وب‌گاه"
			},
			"creatorTypes": {
				"artist": "هنرمند",
				"attorneyAgent": "وکیل/نماینده",
				"author": "نویسنده",
				"bookAuthor": "نویسنده کتاب",
				"cartographer": "نقشه‌کش",
				"castMember": "عضو گروه",
				"commenter": "مفسر",
				"composer": "آهنگساز",
				"contributor": "پدیدآور",
				"cosponsor": "پشتیبان دوم",
				"counsel": "مشاور",
				"director": "کارگردان",
				"editor": "ویرایشگر",
				"guest": "مهمان",
				"interviewee": "مصاحبه با",
				"interviewer": "مصاحبه‌گر",
				"inventor": "مخترع",
				"performer": "مجری",
				"podcaster": "Podcaster",
				"presenter": "ارائه‌دهنده",
				"producer": "تولیدکننده",
				"programmer": "برنامه‌نویس",
				"recipient": "گیرنده",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "نمایشنامه‌نویس",
				"seriesEditor": "ویرایشگر مجموعه",
				"sponsor": "پشتیبان",
				"translator": "مترجم",
				"wordsBy": "کلام از"
			}
		},
		"fi-FI": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Taideteos",
				"attachment": "Liite",
				"audioRecording": "Äänite",
				"bill": "Lasku",
				"blogPost": "Blogikirjoitus",
				"book": "Kirja",
				"bookSection": "Kirjan osa",
				"case": "Oikeusjuttu",
				"computerProgram": "Ohjelmisto",
				"conferencePaper": "Konferenssiartikkeli",
				"dictionaryEntry": "Sanakirjan hakusana",
				"document": "Asiakirja",
				"email": "S-posti",
				"encyclopediaArticle": "Tietosanakirja-artikkeli",
				"film": "Filmi",
				"forumPost": "Foorumiviesti",
				"hearing": "Kuuleminen",
				"instantMessage": "Pikaviestimen viesti",
				"interview": "Haastattelu",
				"journalArticle": "Aikakausjulkaisun artikkeli",
				"letter": "Kirje",
				"magazineArticle": "Aikakauslehden artikkeli",
				"manuscript": "Käsikirjoitus",
				"map": "Kartta",
				"newspaperArticle": "Sanomalehden artikkeli",
				"note": "Muistiinpano",
				"patent": "Patentti",
				"podcast": "Podcast",
				"presentation": "Esitelmä",
				"radioBroadcast": "Radiolähetys",
				"report": "Raportti",
				"statute": "Säädös",
				"thesis": "Opinnäytetyö",
				"tvBroadcast": "Tv-lähetys",
				"videoRecording": "Videotallenne",
				"webpage": "Web-sivu"
			},
			"fields": {
				"abstractNote": "Tiivistelmä",
				"accessDate": "Luettu",
				"applicationNumber": "Hakemusnumero",
				"archive": "Arkisto",
				"archiveLocation": "Paik. arkistossa",
				"artworkMedium": "Materiaali",
				"artworkSize": "Teoksen koko",
				"assignee": "Valtuutettu",
				"audioFileType": "Tiedostomuoto",
				"audioRecordingFormat": "Muoto",
				"billNumber": "Lasku numero",
				"blogTitle": "Blogin nimi",
				"bookTitle": "Kirjan nimi",
				"callNumber": "Hyllypaikka",
				"caseName": "Tapauksen nimi",
				"code": "Koodi",
				"codeNumber": "Koodinumero",
				"codePages": "Koodin sivut",
				"codeVolume": "Koodisarja",
				"committee": "Komitea",
				"company": "Yritys",
				"conferenceName": "Konferenssin nimi",
				"country": "Maa",
				"court": "Tuomioistuin",
				"date": "Päiväys",
				"dateAdded": "Lisäyspäivä",
				"dateDecided": "Päätöspäivämäärä",
				"dateEnacted": "Täytäntöönpanopäivä",
				"dateModified": "Muokattu",
				"dictionaryTitle": "Sanakirjan otsake",
				"distributor": "Jakelija",
				"docketNumber": "Esityslistan numero",
				"documentNumber": "Asiakirjan numero",
				"DOI": "DOI",
				"edition": "Painos",
				"encyclopediaTitle": "Tietosanakirjan otsake",
				"episodeNumber": "Jakson numero",
				"extra": "Ylim.",
				"filingDate": "Arkistointipäivä",
				"firstPage": "Ensimmäinen sivu",
				"forumTitle": "Foorumin/listan nimi",
				"genre": "Genre",
				"history": "Historia",
				"institution": "Laitos",
				"interviewMedium": "Tallennusväline",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Numero",
				"issueDate": "Hakupäiväys",
				"issuingAuthority": "Myöntänyt viranomainen",
				"itemType": "Tyyppi",
				"journalAbbreviation": "Julkaisun lyhenne",
				"label": "Otsake",
				"language": "Kieli",
				"legalStatus": "Statustiedot",
				"legislativeBody": "Lainsäätäjä",
				"letterType": "Tyyppi",
				"libraryCatalog": "Kirjastokatalogi",
				"manuscriptType": "Käsikirjoitustyyppi",
				"mapType": "Tyyppi",
				"meetingName": "Tapaamisen nimi",
				"nameOfAct": "Laki",
				"network": "Verkko",
				"number": "Määrä",
				"numberOfVolumes": "Niteiden lkm.",
				"numPages": "Sivumäärä",
				"pages": "Sivut",
				"patentNumber": "Patenttinumero",
				"place": "Paikka",
				"postType": "Tyyppi",
				"presentationType": "Tyyppi",
				"priorityNumbers": "Etuoikeusnumerot",
				"proceedingsTitle": "Konferenssijulkaisun otsikko",
				"programmingLanguage": "Ohjelmointikieli",
				"programTitle": "Ohjelman nimi",
				"publicationTitle": "Julkaisu",
				"publicLawNumber": "Lakinumero",
				"publisher": "Julkaisija",
				"references": "Viittaukset",
				"reporter": "Toimittaja",
				"reporterVolume": "Raporttivuosikerta",
				"reportNumber": "Raportti numero",
				"reportType": "Raportin tyyppi",
				"rights": "Oikeudet",
				"runningTime": "Soittoaika",
				"scale": "Mittakaava",
				"section": "Osio",
				"series": "Sarja",
				"seriesNumber": "Sarjan numero",
				"seriesText": "Sarjan teksti",
				"seriesTitle": "Sarjan nimi",
				"session": "Istunto",
				"shortTitle": "Lyhyt nimi",
				"studio": "Studio",
				"subject": "Aihe",
				"system": "Järjestelmä",
				"thesisType": "Tyyppi",
				"title": "Otsake",
				"university": "Yliopisto",
				"url": "URL",
				"versionNumber": "Versio",
				"videoRecordingFormat": "Muoto",
				"volume": "Vuosikerta",
				"websiteTitle": "Websivu",
				"websiteType": "Web-sivustotyyppi"
			},
			"creatorTypes": {
				"artist": "Taiteilija",
				"attorneyAgent": "Asianajaja/agentti",
				"author": "Tekijä",
				"bookAuthor": "Kirjan tekijä",
				"cartographer": "Kartoittaja",
				"castMember": "Näyttelijä",
				"commenter": "Kommentoija",
				"composer": "Säveltäjä",
				"contributor": "Muu tekijä",
				"cosponsor": "Osasponsori",
				"counsel": "Oikeusavustaja",
				"director": "Ohjaaja",
				"editor": "Toimittaja",
				"guest": "Vieras",
				"interviewee": "Haastattelussa",
				"interviewer": "Haastattelija",
				"inventor": "Keksijä",
				"performer": "Esiintyjä",
				"podcaster": "Podcastin tekijä",
				"presenter": "Esittäjä",
				"producer": "Tuottaja",
				"programmer": "Ohjelmoija",
				"recipient": "Vastaanottaja",
				"reviewedAuthor": "Arvostelun kohde",
				"scriptwriter": "Käsikirjoittaja",
				"seriesEditor": "Sarjan toimittaja",
				"sponsor": "Sponsori",
				"translator": "Kääntäjä",
				"wordsBy": "Sanoittaja"
			}
		},
		"fr-FR": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Illustration",
				"attachment": "Pièce jointe",
				"audioRecording": "Enregistrement audio",
				"bill": "Projet/proposition de loi",
				"blogPost": "Billet de blog",
				"book": "Livre",
				"bookSection": "Chapitre de livre",
				"case": "Affaire",
				"computerProgram": "Logiciel",
				"conferencePaper": "Article de colloque",
				"dictionaryEntry": "Entrée de dictionnaire",
				"document": "Document",
				"email": "Courriel",
				"encyclopediaArticle": "Article d'encyclopédie",
				"film": "Film",
				"forumPost": "Message de forum",
				"hearing": "Audience",
				"instantMessage": "Message instantané",
				"interview": "Interview",
				"journalArticle": "Article de revue",
				"letter": "Lettre",
				"magazineArticle": "Article de magazine",
				"manuscript": "Manuscrit",
				"map": "Carte",
				"newspaperArticle": "Article de journal",
				"note": "Note",
				"patent": "Brevet",
				"podcast": "Balado (Podcast)",
				"presentation": "Présentation",
				"radioBroadcast": "Émission de radio",
				"report": "Rapport",
				"statute": "Acte juridique",
				"thesis": "Thèse",
				"tvBroadcast": "Émission de TV",
				"videoRecording": "Enregistrement vidéo",
				"webpage": "Page Web"
			},
			"fields": {
				"abstractNote": "Résumé",
				"accessDate": "Consulté le",
				"applicationNumber": "N° d'application",
				"archive": "Archive",
				"archiveLocation": "Loc. dans l'archive",
				"artworkMedium": "Support de l'illustration",
				"artworkSize": "Taille d'illustration",
				"assignee": "Cessionnaire",
				"audioFileType": "Type de fichier",
				"audioRecordingFormat": "Format",
				"billNumber": "N° de projet",
				"blogTitle": "Titre du blog",
				"bookTitle": "Titre du livre",
				"callNumber": "Cote",
				"caseName": "Nom de l'affaire",
				"code": "Code",
				"codeNumber": "N° de code",
				"codePages": "Pages de code",
				"codeVolume": "Volume de code",
				"committee": "Commission",
				"company": "Société",
				"conferenceName": "Intitulé du colloque",
				"country": "Pays",
				"court": "Tribunal",
				"date": "Date",
				"dateAdded": "Date d'ajout",
				"dateDecided": "Date de décision",
				"dateEnacted": "Promulgué le",
				"dateModified": "Modifié le",
				"dictionaryTitle": "Titre du dict.",
				"distributor": "Distributeur",
				"docketNumber": "N° de requête",
				"documentNumber": "N° du document",
				"DOI": "DOI",
				"edition": "Édition",
				"encyclopediaTitle": "Titre de l'encycl.",
				"episodeNumber": "N° de l'épisode",
				"extra": "Extra",
				"filingDate": "Date d'archivage",
				"firstPage": "Première page",
				"forumTitle": "Titre du forum/listserv",
				"genre": "Genre",
				"history": "Histoire",
				"institution": "Institution",
				"interviewMedium": "Média",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Numéro",
				"issueDate": "Date de parution",
				"issuingAuthority": "Autorité émettrice",
				"itemType": "Type",
				"journalAbbreviation": "Abrév. de revue",
				"label": "Label",
				"language": "Langue",
				"legalStatus": "Statut légal",
				"legislativeBody": "Corps législatif",
				"letterType": "Type",
				"libraryCatalog": "Catalogue de bibl.",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Intitulé de la réunion",
				"nameOfAct": "Nom de l'acte",
				"network": "Réseau",
				"number": "Numéro",
				"numberOfVolumes": "Nb de volumes",
				"numPages": "Nb de pages",
				"pages": "Pages",
				"patentNumber": "N° de brevet",
				"place": "Lieu",
				"postType": "Type d'article",
				"presentationType": "Type",
				"priorityNumbers": "Numéros de priorité",
				"proceedingsTitle": "Titre des actes",
				"programmingLanguage": "Langage de programmation",
				"programTitle": "Titre du programme",
				"publicationTitle": "Publication",
				"publicLawNumber": "N° officiel de l'acte",
				"publisher": "Maison d’édition",
				"references": "Références",
				"reporter": "Recueil",
				"reporterVolume": "Volume de recueil",
				"reportNumber": "N° du rapport",
				"reportType": "Type de rapport",
				"rights": "Autorisations",
				"runningTime": "Durée",
				"scale": "Échelle",
				"section": "Section",
				"series": "Collection",
				"seriesNumber": "N° ds la coll.",
				"seriesText": "Texte de la coll.",
				"seriesTitle": "Titre de la coll.",
				"session": "Session",
				"shortTitle": "Titre abrégé",
				"studio": "Studio",
				"subject": "Sujet",
				"system": "Système",
				"thesisType": "Type",
				"title": "Titre",
				"university": "Université",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Titre du site Web",
				"websiteType": "Type de site Web"
			},
			"creatorTypes": {
				"artist": "Artiste",
				"attorneyAgent": "Mandataire/Agent",
				"author": "Auteur",
				"bookAuthor": "Auteur du livre",
				"cartographer": "Cartographe",
				"castMember": "Membre de la distribution",
				"commenter": "Commentateur",
				"composer": "Compositeur",
				"contributor": "Collaborateur",
				"cosponsor": "Co-parrain",
				"counsel": "Conseiller",
				"director": "Metteur en scène",
				"editor": "Éditeur",
				"guest": "Invité",
				"interviewee": "Interviewé",
				"interviewer": "Reporter",
				"inventor": "Inventeur",
				"performer": "Interprète",
				"podcaster": "Diffuseur",
				"presenter": "Présentateur",
				"producer": "Producteur",
				"programmer": "Programmeur",
				"recipient": "Destinataire",
				"reviewedAuthor": "Auteur recensé",
				"scriptwriter": "Scénariste",
				"seriesEditor": "Directeur de coll.",
				"sponsor": "Auteur",
				"translator": "Traducteur",
				"wordsBy": "Paroles de"
			}
		},
		"gl-ES": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Arte",
				"attachment": "Anexo",
				"audioRecording": "Gravación de son",
				"bill": "Factura",
				"blogPost": "Entrada de blogue",
				"book": "Libro",
				"bookSection": "Sección de libro",
				"case": "Caso",
				"computerProgram": "Software",
				"conferencePaper": "Publicación de congreso",
				"dictionaryEntry": "Entrada de dicionario",
				"document": "Documento",
				"email": "Correo electrónico",
				"encyclopediaArticle": "Artigo enciclopédico",
				"film": "Película",
				"forumPost": "Entrada de foro",
				"hearing": "Audiencia",
				"instantMessage": "Mensaxe instantáneo",
				"interview": "Entrevista",
				"journalArticle": "Artigo de publicación",
				"letter": "Carta",
				"magazineArticle": "Artigo de revista",
				"manuscript": "Manuscrito",
				"map": "Mapa",
				"newspaperArticle": "Artigo de xornal",
				"note": "Nota",
				"patent": "Patente",
				"podcast": "Podcast",
				"presentation": "Presentación",
				"radioBroadcast": "Emisión de Radio",
				"report": "Informe",
				"statute": "Estatuto",
				"thesis": "Tese",
				"tvBroadcast": "Emisión de TV",
				"videoRecording": "Gravación de vídeo",
				"webpage": "Páxina web"
			},
			"fields": {
				"abstractNote": "Resumen",
				"accessDate": "Consultado",
				"applicationNumber": "Número da solicitude",
				"archive": "Arquivo",
				"archiveLocation": "Loc. no arquivo",
				"artworkMedium": "Mediano",
				"artworkSize": "Tamaño das ilustracións",
				"assignee": "Cesionario",
				"audioFileType": "Tipo de ficheiro",
				"audioRecordingFormat": "Formato",
				"billNumber": "Número da factura",
				"blogTitle": "Título do blogue",
				"bookTitle": "Título do libro",
				"callNumber": "Número de catálogo",
				"caseName": "Número do caso",
				"code": "Código",
				"codeNumber": "Número de código",
				"codePages": "Páxinas de códigos",
				"codeVolume": "Tomo de códigos",
				"committee": "Comité",
				"company": "Compañía",
				"conferenceName": "Nome da congreso",
				"country": "País",
				"court": "Tribunal",
				"date": "Data",
				"dateAdded": "Data de alta",
				"dateDecided": "Data decidida",
				"dateEnacted": "Data de promulgación",
				"dateModified": "Modificado",
				"dictionaryTitle": "Título do dicionario",
				"distributor": "Distribuidor",
				"docketNumber": "Número de expediente",
				"documentNumber": "Número do documento",
				"DOI": "DOI",
				"edition": "Edición",
				"encyclopediaTitle": "Título da enciclopedia",
				"episodeNumber": "Número de episodio",
				"extra": "Extra",
				"filingDate": "Data de entrada en arquivo",
				"firstPage": "Primeira páxina",
				"forumTitle": "Título do foro/Listserv",
				"genre": "Xénero",
				"history": "Historia",
				"institution": "Institución",
				"interviewMedium": "Medio",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Número",
				"issueDate": "Data do elemento",
				"issuingAuthority": "Autoridade emisora",
				"itemType": "Tipo",
				"journalAbbreviation": "Abreviatura da publicación",
				"label": "Rótulo",
				"language": "Lingua",
				"legalStatus": "Estatuto xurídico",
				"legislativeBody": "Corpo lexislativo",
				"letterType": "Tipo",
				"libraryCatalog": "Catálogo da biblioteca",
				"manuscriptType": "Tipo",
				"mapType": "Tipo",
				"meetingName": "Nome da Reunión",
				"nameOfAct": "Número da acta",
				"network": "Rede",
				"number": "Número",
				"numberOfVolumes": "Nº de tomos",
				"numPages": "Nº de Páxinas",
				"pages": "Páxinas",
				"patentNumber": "Número da patente",
				"place": "Lugar",
				"postType": "Tipo de entrada",
				"presentationType": "Tipo",
				"priorityNumbers": "Números de prioridade",
				"proceedingsTitle": "Título das medidas legais",
				"programmingLanguage": "Ling. de programación",
				"programTitle": "Título do programa",
				"publicationTitle": "Publicación",
				"publicLawNumber": "Número de dereito público",
				"publisher": "Editorial",
				"references": "Referencias",
				"reporter": "Reporteiro",
				"reporterVolume": "Tomo de reporteiros",
				"reportNumber": "Número do informe",
				"reportType": "Tipo de informe",
				"rights": "Dereitos",
				"runningTime": "Duración",
				"scale": "Escala",
				"section": "Sección",
				"series": "Serie",
				"seriesNumber": "Número da serie",
				"seriesText": "Texto da serie",
				"seriesTitle": "Título da serie",
				"session": "Sesión",
				"shortTitle": "Título curto",
				"studio": "Estudio",
				"subject": "Asunto",
				"system": "Sistema",
				"thesisType": "Tipo",
				"title": "Título",
				"university": "Universidade",
				"url": "URL",
				"versionNumber": "Versión",
				"videoRecordingFormat": "Formato",
				"volume": "Tomo",
				"websiteTitle": "Título da páxina web",
				"websiteType": "Tipo de páxina web"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Procurador/Axente",
				"author": "Autor",
				"bookAuthor": "Autor do libro",
				"cartographer": "Cartógrafo",
				"castMember": "Membro do elenco",
				"commenter": "Comentarista",
				"composer": "Compositor",
				"contributor": "Colaborador",
				"cosponsor": "Copatrocinador",
				"counsel": "Avogado",
				"director": "Director",
				"editor": "Editor",
				"guest": "Invitado",
				"interviewee": "Entrevista con",
				"interviewer": "Entrevista",
				"inventor": "Inventor",
				"performer": "Intérprete",
				"podcaster": "Fonte do podcast",
				"presenter": "Presentador",
				"producer": "Produtor",
				"programmer": "Programador",
				"recipient": "Receptor",
				"reviewedAuthor": "Autor reseñado",
				"scriptwriter": "Guionista",
				"seriesEditor": "Editor da serie",
				"sponsor": "Patrocinador",
				"translator": "Tradutor",
				"wordsBy": "Guión de"
			}
		},
		"he-IL": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "יצירת אומנות",
				"attachment": "קובץ מצורף",
				"audioRecording": "הקלטת אודיו",
				"bill": "Bill",
				"blogPost": "רשומת יומן רשת",
				"book": "ספר",
				"bookSection": "פרק מספר",
				"case": "תיק",
				"computerProgram": "Software",
				"conferencePaper": "Conference Paper",
				"dictionaryEntry": "ערך במילון",
				"document": "מסמך",
				"email": "דואר אלקטרוני",
				"encyclopediaArticle": "מאמר מאנציקלופדיה",
				"film": "סרט",
				"forumPost": "הודעה בפורום",
				"hearing": "שימוע",
				"instantMessage": "מסר מיידי",
				"interview": "ראיון",
				"journalArticle": "מאמר מכתב עת",
				"letter": "מכתב",
				"magazineArticle": "מאמר ממגזין",
				"manuscript": "כתב-יד",
				"map": "מפה",
				"newspaperArticle": "מאמר מעיתון",
				"note": "הערה",
				"patent": "פטנט",
				"podcast": "פודקאסט",
				"presentation": "מצגת",
				"radioBroadcast": "שידור רדיו",
				"report": "דוח",
				"statute": "Statute",
				"thesis": "תזה",
				"tvBroadcast": "שידור טלוויזיוני",
				"videoRecording": "הקלטת וידאו",
				"webpage": "דף אינטרנט"
			},
			"fields": {
				"abstractNote": "תקציר",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "מיקום בארכיב",
				"artworkMedium": "Medium",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "סוג קובץ",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "שם בלוג",
				"bookTitle": "שם ספר",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "קוד",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "ועדה",
				"company": "חברה",
				"conferenceName": "שם כנס",
				"country": "מדינה",
				"court": "Court",
				"date": "תאריך",
				"dateAdded": "תאריך הוספה",
				"dateDecided": "תאריך החלטה",
				"dateEnacted": "Date Enacted",
				"dateModified": "שונה",
				"dictionaryTitle": "שם מילון",
				"distributor": "מפיץ",
				"docketNumber": "Docket Number",
				"documentNumber": "מספר מסמך",
				"DOI": "DOI",
				"edition": "מהדורה",
				"encyclopediaTitle": "שם אינציקלופדיה",
				"episodeNumber": "מספר פרק",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "עמוד ראשון",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "היסטוריה",
				"institution": "מוסד",
				"interviewMedium": "מדיום",
				"ISBN": "מסת\"ב",
				"ISSN": "ISSN",
				"issue": "גיליון",
				"issueDate": "תאריך הנפקה",
				"issuingAuthority": "Issuing Authority",
				"itemType": "סוג",
				"journalAbbreviation": "Journal Abbr",
				"label": "תווית",
				"language": "שפה",
				"legalStatus": "מצב משפטי",
				"legislativeBody": "גוף מחוקק",
				"letterType": "סוג",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "סוג",
				"mapType": "סוג",
				"meetingName": "שם פגישה",
				"nameOfAct": "Name of Act",
				"network": "רשת",
				"number": "מספר",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "עמודים",
				"patentNumber": "מספר פטנט",
				"place": "מקום",
				"postType": "Post Type",
				"presentationType": "סוג",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "הוצאה לאור",
				"publicLawNumber": "Public Law Number",
				"publisher": "מו\"ל",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "מספר דו\"ח",
				"reportType": "סוג דו\"ח",
				"rights": "זכויות",
				"runningTime": "Running Time",
				"scale": "קנה מידה",
				"section": "Section",
				"series": "סידרה",
				"seriesNumber": "מספר סידרה",
				"seriesText": "טקסט סידרה",
				"seriesTitle": "שם סידרה",
				"session": "Session",
				"shortTitle": "כותרת קצרה",
				"studio": "סטודיו",
				"subject": "נושא",
				"system": "מערכת",
				"thesisType": "סוג",
				"title": "כותרת",
				"university": "אוניברסיטה",
				"url": "כתובת אינטרנט",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "כרך",
				"websiteTitle": "כותרת אתר",
				"websiteType": "סוג אתר"
			},
			"creatorTypes": {
				"artist": "אומן",
				"attorneyAgent": "עורך דין/סוכן",
				"author": "מחבר",
				"bookAuthor": "Book Author",
				"cartographer": "קרטוגראף",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "מלחין",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "במאי",
				"editor": "עורך",
				"guest": "אורח",
				"interviewee": "ראיון עם",
				"interviewer": "מראיין",
				"inventor": "ממציא",
				"performer": "מבצע",
				"podcaster": "Podcaster",
				"presenter": "מציג",
				"producer": "מפיק",
				"programmer": "מתכנת",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "תסריטאי",
				"seriesEditor": "עורך סידרה",
				"sponsor": "נותן חסות",
				"translator": "מתרגם",
				"wordsBy": "Words By"
			}
		},
		"hr-HR": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Artwork",
				"attachment": "Attachment",
				"audioRecording": "Audio Recording",
				"bill": "Bill",
				"blogPost": "Blog Post",
				"book": "Book",
				"bookSection": "Book Section",
				"case": "Case",
				"computerProgram": "Software",
				"conferencePaper": "Conference Paper",
				"dictionaryEntry": "Dictionary Entry",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Encyclopedia Article",
				"film": "Film",
				"forumPost": "Forum Post",
				"hearing": "Hearing",
				"instantMessage": "Instant Message",
				"interview": "Interview",
				"journalArticle": "Journal Article",
				"letter": "Letter",
				"magazineArticle": "Magazine Article",
				"manuscript": "Manuscript",
				"map": "Map",
				"newspaperArticle": "Newspaper Article",
				"note": "Note",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentation",
				"radioBroadcast": "Radio Broadcast",
				"report": "Report",
				"statute": "Statute",
				"thesis": "Thesis",
				"tvBroadcast": "TV Broadcast",
				"videoRecording": "Video Recording",
				"webpage": "Web Page"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "Loc. in Archive",
				"artworkMedium": "Medium",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "File Type",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "Blog Title",
				"bookTitle": "Book Title",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "Code",
				"codeNumber": "Code Number",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Committee",
				"company": "Company",
				"conferenceName": "Conference Name",
				"country": "Country",
				"court": "Court",
				"date": "Date",
				"dateAdded": "Date Added",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Modified",
				"dictionaryTitle": "Dictionary Title",
				"distributor": "Distributor",
				"docketNumber": "Docket Number",
				"documentNumber": "Document Number",
				"DOI": "DOI",
				"edition": "Edition",
				"encyclopediaTitle": "Encyclopedia Title",
				"episodeNumber": "Episode Number",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "First Page",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "History",
				"institution": "Institution",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Issue",
				"issueDate": "Issue Date",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Type",
				"journalAbbreviation": "Journal Abbr",
				"label": "Label",
				"language": "Language",
				"legalStatus": "Legal Status",
				"legislativeBody": "Legislative Body",
				"letterType": "Type",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Meeting Name",
				"nameOfAct": "Name of Act",
				"network": "Network",
				"number": "Number",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "Pages",
				"patentNumber": "Patent Number",
				"place": "Place",
				"postType": "Post Type",
				"presentationType": "Type",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Publication",
				"publicLawNumber": "Public Law Number",
				"publisher": "Publisher",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Report Number",
				"reportType": "Report Type",
				"rights": "Rights",
				"runningTime": "Running Time",
				"scale": "Scale",
				"section": "Section",
				"series": "Series",
				"seriesNumber": "Series Number",
				"seriesText": "Series Text",
				"seriesTitle": "Series Title",
				"session": "Session",
				"shortTitle": "Short Title",
				"studio": "Studio",
				"subject": "Subject",
				"system": "System",
				"thesisType": "Type",
				"title": "Title",
				"university": "University",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Website Title",
				"websiteType": "Website Type"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Attorney/Agent",
				"author": "Author",
				"bookAuthor": "Book Author",
				"cartographer": "Cartographer",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "Composer",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "Director",
				"editor": "Editor",
				"guest": "Guest",
				"interviewee": "Interview With",
				"interviewer": "Interviewer",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Producer",
				"programmer": "Programmer",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Sponsor",
				"translator": "Translator",
				"wordsBy": "Words By"
			}
		},
		"hu-HU": {
			"itemTypes": {
				"annotation": "Megjegyzés",
				"artwork": "Műalkotás",
				"attachment": "Kapcsolat",
				"audioRecording": "Hangfelvétel",
				"bill": "Törvényjavaslat",
				"blogPost": "Blogbejegyzés",
				"book": "Könyv",
				"bookSection": "Könyvfejezet",
				"case": "Eset",
				"computerProgram": "Szoftver",
				"conferencePaper": "Dolgozat",
				"dictionaryEntry": "Szótár szócikk",
				"document": "Dokumentum",
				"email": "Email",
				"encyclopediaArticle": "Lexikon szócikk",
				"film": "Film",
				"forumPost": "Fórumbejegyzés",
				"hearing": "Meghallgatás",
				"instantMessage": "Azonnali üzenet",
				"interview": "Interjú",
				"journalArticle": "Folyóiratcikk",
				"letter": "Levél",
				"magazineArticle": "Magazincikk",
				"manuscript": "Kézirat",
				"map": "Térkép",
				"newspaperArticle": "Újságcikk",
				"note": "Jegyzet",
				"patent": "Szabadalom",
				"podcast": "Podcast",
				"presentation": "Előadás",
				"radioBroadcast": "Rádiós program",
				"report": "Jelentés",
				"statute": "Jogszabály",
				"thesis": "Szakdolgozat",
				"tvBroadcast": "Televíziós program",
				"videoRecording": "Videófelvétel",
				"webpage": "Weboldal"
			},
			"fields": {
				"abstractNote": "Kivonat",
				"accessDate": "Hozzáférés",
				"applicationNumber": "Bejelentés száma",
				"archive": "Archívum",
				"archiveLocation": "Pontos lelőhely",
				"artworkMedium": "Műalkotás médiuma",
				"artworkSize": "Műalkotás mérete",
				"assignee": "Felelős",
				"audioFileType": "Fájl típusa",
				"audioRecordingFormat": "Formátum",
				"billNumber": "Törvényjavaslat száma",
				"blogTitle": "Blog címe",
				"bookTitle": "Könyv címe",
				"callNumber": "Raktári jelzet",
				"caseName": "Eset neve",
				"code": "Törvénykönyv",
				"codeNumber": "Törvénykönyv száma",
				"codePages": "Oldalszám",
				"codeVolume": "Kötet",
				"committee": "Bizottság",
				"company": "Cég",
				"conferenceName": "Konferencia címe",
				"country": "Ország",
				"court": "Bíróság",
				"date": "Dátum",
				"dateAdded": "Hozzáadás dátuma",
				"dateDecided": "Döntés dátuma",
				"dateEnacted": "Hatálybalépés dátuma",
				"dateModified": "Módosítás dátuma",
				"dictionaryTitle": "Szótár címe",
				"distributor": "Terjesztő",
				"docketNumber": "Ügyiratszám",
				"documentNumber": "Dokumentum száma",
				"DOI": "DOI",
				"edition": "Kiadás",
				"encyclopediaTitle": "Lexikon címe",
				"episodeNumber": "Epizód száma",
				"extra": "Egyéb",
				"filingDate": "Iktatás időpontja",
				"firstPage": "Első oldal",
				"forumTitle": "Fórum/listserv címe",
				"genre": "Típus",
				"history": "Történet",
				"institution": "Intézmény",
				"interviewMedium": "Médium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Szám",
				"issueDate": "Kiadás dátuma",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Típus",
				"journalAbbreviation": "Folyóirat rövid neve",
				"label": "Címke",
				"language": "Nyelv",
				"legalStatus": "Állapot",
				"legislativeBody": "Törvényhozó szerv",
				"letterType": "Típus",
				"libraryCatalog": "Könyvtár Katalógus",
				"manuscriptType": "Típus",
				"mapType": "Típus",
				"meetingName": "Találkozó neve",
				"nameOfAct": "Törvény címe",
				"network": "TV társaság",
				"number": "Sorszám",
				"numberOfVolumes": "Kötetek száma",
				"numPages": "Terjedelem",
				"pages": "Oldalszám",
				"patentNumber": "Szabadalom száma",
				"place": "Hely",
				"postType": "Bejegyzés típusa",
				"presentationType": "Típus",
				"priorityNumbers": "Elsőbbségi sorrend",
				"proceedingsTitle": "Kiadvány címe",
				"programmingLanguage": "Prog. nyelv",
				"programTitle": "Program címe",
				"publicationTitle": "Kiadvány",
				"publicLawNumber": "Törvény száma",
				"publisher": "Kiadó",
				"references": "Hivatkozások",
				"reporter": "Döntvénytár",
				"reporterVolume": "Kötet",
				"reportNumber": "Jelentés száma",
				"reportType": "Jelentés típusa",
				"rights": "Jogok",
				"runningTime": "Lejátszási idő",
				"scale": "Arány",
				"section": "Fejezet",
				"series": "Sorozat",
				"seriesNumber": "Sorozatbeli sorszám",
				"seriesText": "Sorozat szövege",
				"seriesTitle": "Sorozat címe",
				"session": "Ülésszak",
				"shortTitle": "Rövid cím",
				"studio": "Stúdió",
				"subject": "Téma",
				"system": "Rendszer",
				"thesisType": "Típus",
				"title": "Cím",
				"university": "Egyetem",
				"url": "URL",
				"versionNumber": "Verzió",
				"videoRecordingFormat": "Formátum",
				"volume": "Kötet",
				"websiteTitle": "Website címe",
				"websiteType": "Weboldal típusa"
			},
			"creatorTypes": {
				"artist": "Művész",
				"attorneyAgent": "Ügyvéd",
				"author": "Szerző",
				"bookAuthor": "Könyv szerzője",
				"cartographer": "Térképész",
				"castMember": "Szereplő",
				"commenter": "Hozzászóló",
				"composer": "Zeneszerző",
				"contributor": "Közreműködő",
				"cosponsor": "Cosponsor",
				"counsel": "Ügyvéd",
				"director": "Rendező",
				"editor": "Szerkesztő",
				"guest": "Vendég",
				"interviewee": "Interjú alanya",
				"interviewer": "Interjú készítője",
				"inventor": "Feltaláló",
				"performer": "Előadó",
				"podcaster": "Podcaster",
				"presenter": "Előadó",
				"producer": "Producer",
				"programmer": "Programozó",
				"recipient": "Alperes",
				"reviewedAuthor": "Recenzált mű szerzője",
				"scriptwriter": "Forgatókönyvíró",
				"seriesEditor": "Sorozatszerkesztő",
				"sponsor": "Benyújtó",
				"translator": "Fordító",
				"wordsBy": "Szövegíró"
			}
		},
		"id-ID": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Karya Seni",
				"attachment": "Lampiran",
				"audioRecording": "Rekaman Audio",
				"bill": "Tagihan",
				"blogPost": "Pos Blog",
				"book": "Buku",
				"bookSection": "Seksi Buku",
				"case": "Kasus",
				"computerProgram": "Software",
				"conferencePaper": "Dokumen Konferensi",
				"dictionaryEntry": "Entri Kamus",
				"document": "Dokumen",
				"email": "E-mail",
				"encyclopediaArticle": "Artikel Ensiklopedia",
				"film": "Film",
				"forumPost": "Pos Forum",
				"hearing": "Pemeriksaan",
				"instantMessage": "Pesan Instan",
				"interview": "Wawancara",
				"journalArticle": "Artikel Jurnal",
				"letter": "Surat",
				"magazineArticle": "Artikel Majalah",
				"manuscript": "Manuskrip",
				"map": "Peta",
				"newspaperArticle": "Artikel Koran",
				"note": "Catatan",
				"patent": "Paten",
				"podcast": "Podcast",
				"presentation": "Presentasi",
				"radioBroadcast": "Siaran Radio",
				"report": "Laporan",
				"statute": "Statuta",
				"thesis": "Tesis",
				"tvBroadcast": "Siaran TV",
				"videoRecording": "Rekaman Video",
				"webpage": "Laman Web"
			},
			"fields": {
				"abstractNote": "Abstrak",
				"accessDate": "Diakses",
				"applicationNumber": "Nomor Aplikasi",
				"archive": "Arsip",
				"archiveLocation": "Lokasi di dalam Arsip",
				"artworkMedium": "Media",
				"artworkSize": "Ukuran Karya Seni",
				"assignee": "Penerima",
				"audioFileType": "Jenis Berkas",
				"audioRecordingFormat": "Format",
				"billNumber": "Nomor Tagihan",
				"blogTitle": "Judul Blog",
				"bookTitle": "Judul Buku",
				"callNumber": "Nomor Panggilan",
				"caseName": "Nama Kasus",
				"code": "Kode",
				"codeNumber": "Nomor Kode",
				"codePages": "Halaman Kode",
				"codeVolume": "Volume Kode",
				"committee": "Komite",
				"company": "Perusahaan",
				"conferenceName": "Nama Konferensi",
				"country": "Negara",
				"court": "Pengadilan",
				"date": "Tanggal",
				"dateAdded": "Tanggal Ditambahkan",
				"dateDecided": "Tanggal Diputuskan",
				"dateEnacted": "Tanggal DIundangkan",
				"dateModified": "Dimodifikasi",
				"dictionaryTitle": "Judul Kamus",
				"distributor": "Distributor",
				"docketNumber": "Nomor Acara Pengadilan",
				"documentNumber": "Nomor Dokumen",
				"DOI": "DOI",
				"edition": "Edisi",
				"encyclopediaTitle": "Judul Ensiklopedia",
				"episodeNumber": "Nomor Episode",
				"extra": "Ekstra",
				"filingDate": "Tanggal Pembuatan",
				"firstPage": "Halaman Pertama",
				"forumTitle": "Judul Forum/Listserv",
				"genre": "Genre",
				"history": "RIwayat",
				"institution": "Institusi",
				"interviewMedium": "Media",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Isu",
				"issueDate": "Tanngal Keluar",
				"issuingAuthority": "Otoritas yang Mengeluarkan",
				"itemType": "Jenis",
				"journalAbbreviation": "Singkatan Jurnal",
				"label": "Label",
				"language": "Bahasa",
				"legalStatus": "Status Legal",
				"legislativeBody": "Badan Legislatif",
				"letterType": "Jenis",
				"libraryCatalog": "Katalog Perpustakaan",
				"manuscriptType": "Jenis",
				"mapType": "Jenis",
				"meetingName": "Nama Pertemuan",
				"nameOfAct": "Nama Putusan",
				"network": "Jaringan",
				"number": "Nomor",
				"numberOfVolumes": "# Volume",
				"numPages": "# halaman",
				"pages": "Halaman",
				"patentNumber": "Nomor Paten",
				"place": "Tempat",
				"postType": "Jenis Pos",
				"presentationType": "Jenis",
				"priorityNumbers": "Nomor Prioritas",
				"proceedingsTitle": "Judul Rapat",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Judul Program",
				"publicationTitle": "Publikasi",
				"publicLawNumber": "Nomor Hukum Publik",
				"publisher": "Penerbit",
				"references": "Referensi",
				"reporter": "Reporter",
				"reporterVolume": "Volume Reporter",
				"reportNumber": "Nomor Laporan",
				"reportType": "Jenis Laporan",
				"rights": "Hak",
				"runningTime": "Waktu Berjalan",
				"scale": "Skala",
				"section": "Bagian",
				"series": "Seri",
				"seriesNumber": "Nomor Seri",
				"seriesText": "Teks Seri",
				"seriesTitle": "Judul Seri",
				"session": "Sesi",
				"shortTitle": "Judul Singkat",
				"studio": "Studio",
				"subject": "Subjek",
				"system": "Sistem",
				"thesisType": "Jenis",
				"title": "Judul",
				"university": "Universitas",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Judul Website",
				"websiteType": "Jenis Website"
			},
			"creatorTypes": {
				"artist": "Seniman",
				"attorneyAgent": "Pengacara/Agen",
				"author": "Penulis",
				"bookAuthor": "Penulis Buku",
				"cartographer": "Pembuat Peta",
				"castMember": "Pemain",
				"commenter": "Pemberi Komentar",
				"composer": "Komposer",
				"contributor": "Kontributor",
				"cosponsor": "Kosponsor",
				"counsel": "Advokat",
				"director": "Direktur",
				"editor": "Editor",
				"guest": "Bintang Tamu",
				"interviewee": "Wawancara dengan",
				"interviewer": "Pewawancara",
				"inventor": "Penemu",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Produser",
				"programmer": "Programer",
				"recipient": "Penerima",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Penulis Skrip",
				"seriesEditor": "Editor Seri",
				"sponsor": "Sponsor",
				"translator": "Penerjemah",
				"wordsBy": "Teks Oleh"
			}
		},
		"is-IS": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Listaverk",
				"attachment": "Viðhengi",
				"audioRecording": "Hljóðupptaka",
				"bill": "Tilkynning",
				"blogPost": "Bloggfærsla",
				"book": "Bók",
				"bookSection": "Bókarhluti",
				"case": "Mál",
				"computerProgram": "Software",
				"conferencePaper": "Ráðstefnugrein",
				"dictionaryEntry": "Færsla í orðabók",
				"document": "Skjal",
				"email": "Tölvupóstur",
				"encyclopediaArticle": "Færsla í alfræðiriti",
				"film": "Kvikmynd",
				"forumPost": "Færsla á samskiptasvæði",
				"hearing": "Réttarhöld",
				"instantMessage": "Skyndiskilaboð",
				"interview": "Viðtal",
				"journalArticle": "Fræðigrein í tímariti",
				"letter": "Bréf",
				"magazineArticle": "Tímaritsgrein",
				"manuscript": "Handrit",
				"map": "Kort",
				"newspaperArticle": "Grein í dagblaði",
				"note": "Athugasemd",
				"patent": "Einkaleyfi",
				"podcast": "Hljóðvarp",
				"presentation": "Kynning",
				"radioBroadcast": "Útvarpsútsending",
				"report": "Skýrsla",
				"statute": "Lög",
				"thesis": "Ritgerð",
				"tvBroadcast": "Sjónvarpsútsending",
				"videoRecording": "Myndskeið",
				"webpage": "Vefsíða"
			},
			"fields": {
				"abstractNote": "Ágrip",
				"accessDate": "Sótt",
				"applicationNumber": "Umsókn númer",
				"archive": "Safnvista",
				"archiveLocation": "Staðsetning í safni",
				"artworkMedium": "Miðill",
				"artworkSize": "Stærð verks",
				"assignee": "Málstaki",
				"audioFileType": "Skráartegund",
				"audioRecordingFormat": "Hljóðupptökusnið",
				"billNumber": "Tilkynningarnúmer",
				"blogTitle": "Titill á Bloggi",
				"bookTitle": "Titill bókar",
				"callNumber": "Hillumerking",
				"caseName": "Nafn máls",
				"code": "Kóði",
				"codeNumber": "Kóðanúmer",
				"codePages": "Síður kóða",
				"codeVolume": "Magn kóða",
				"committee": "Nefnd",
				"company": "Fyrirtæki",
				"conferenceName": "Heiti ráðstefnu",
				"country": "Land",
				"court": "Réttur",
				"date": "Dagsetning",
				"dateAdded": "Dagsetning viðbótar",
				"dateDecided": "Dagsetning ákvörðunar",
				"dateEnacted": "Virkjunardagsetning",
				"dateModified": "Breytt",
				"dictionaryTitle": "Nafn orðabókar",
				"distributor": "Dreifingaraðili",
				"docketNumber": "Málsnúmer",
				"documentNumber": "Skjalanúmer",
				"DOI": "DOI",
				"edition": "Útgáfa",
				"encyclopediaTitle": "Nafn alfræðirits",
				"episodeNumber": "Þáttur númer",
				"extra": "Viðbót",
				"filingDate": "Dagsetning skráningar",
				"firstPage": "Fyrsta síða",
				"forumTitle": "Titill samskiptasvæðis/Listserv",
				"genre": "Tegund",
				"history": "Saga",
				"institution": "Stofnun",
				"interviewMedium": "Miðill",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Hefti",
				"issueDate": "Útgáfudagsetning",
				"issuingAuthority": "Útgefandi",
				"itemType": "Tegund",
				"journalAbbreviation": "Skammstöfun fræðarits",
				"label": "Merki",
				"language": "Tungumál",
				"legalStatus": "Lagaleg staða",
				"legislativeBody": "Lagastofnun",
				"letterType": "Tegund leturs",
				"libraryCatalog": "Færslusafnskrá",
				"manuscriptType": "Tegund ritverks",
				"mapType": "Tegund korts",
				"meetingName": "Heiti fundar",
				"nameOfAct": "Nafn laga",
				"network": "Gagnanet",
				"number": "Númer",
				"numberOfVolumes": "Fjöldi binda",
				"numPages": "# síður",
				"pages": "Blaðsíður",
				"patentNumber": "Einkaleyfi nr.",
				"place": "Staðsetning",
				"postType": "Póstsnið",
				"presentationType": "Tegund",
				"priorityNumbers": "Forgangsnúmer",
				"proceedingsTitle": "Titill málstofu",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Titill forrits",
				"publicationTitle": "Útgáfa",
				"publicLawNumber": "Lög númer",
				"publisher": "Útgefandi",
				"references": "Tilvísanir",
				"reporter": "Blaðamaður",
				"reporterVolume": "Fjöldi blaðamanna",
				"reportNumber": "Skýrslunúmer",
				"reportType": "Tegund skýrslu",
				"rights": "Réttindi",
				"runningTime": "Lengd",
				"scale": "Skali",
				"section": "Hluti",
				"series": "Ritröð",
				"seriesNumber": "Númer ritraðar",
				"seriesText": "Nafn eintaks í ritröð",
				"seriesTitle": "Titill ritraðar",
				"session": "Seta",
				"shortTitle": "Stuttur titill",
				"studio": "Stúdíó",
				"subject": "Efni",
				"system": "Kerfi",
				"thesisType": "Tegund",
				"title": "Titill",
				"university": "Háskóli",
				"url": "Slóð",
				"versionNumber": "Útgáfa",
				"videoRecordingFormat": "Upptökusnið",
				"volume": "Bindi",
				"websiteTitle": "Titill vefsíðu",
				"websiteType": "Tegund vefs"
			},
			"creatorTypes": {
				"artist": "Listamaður",
				"attorneyAgent": "Lögfræðingur/fulltrúi",
				"author": "Höfundur",
				"bookAuthor": "Höfundur bókar",
				"cartographer": "Kortagerð",
				"castMember": "Leikari",
				"commenter": "Athugasemdir",
				"composer": "Höfundur",
				"contributor": "Aðili að verki",
				"cosponsor": "Stuðningsþátttakandi",
				"counsel": "Ráðgjöf",
				"director": "Leikstjóri",
				"editor": "Ritstjóri",
				"guest": "Gestur",
				"interviewee": "Viðtal við",
				"interviewer": "Hver tók viðtalið",
				"inventor": "Uppfinningamaður",
				"performer": "Leikari",
				"podcaster": "Hlaðvörpun",
				"presenter": "Kynnandi",
				"producer": "Framleiðandi",
				"programmer": "Forritari",
				"recipient": "Viðtakandi",
				"reviewedAuthor": "Yfirlestrarhöfundur",
				"scriptwriter": "Handritshöfundur",
				"seriesEditor": "Ritstjóri ritraðar",
				"sponsor": "Stuðningsaðili",
				"translator": "Þýðandi",
				"wordsBy": "Textahöfundur"
			}
		},
		"it-IT": {
			"itemTypes": {
				"annotation": "Annotazione",
				"artwork": "Grafica",
				"attachment": "Allegato",
				"audioRecording": "Registrazione audio",
				"bill": "Legge",
				"blogPost": "Messaggio di blog",
				"book": "Libro",
				"bookSection": "Sezione di un libro",
				"case": "Sentenza",
				"computerProgram": "Software",
				"conferencePaper": "Atto di convegno",
				"dictionaryEntry": "Voce di dizionario",
				"document": "Documento",
				"email": "E-mail",
				"encyclopediaArticle": "Voce di enciclopedia",
				"film": "Film",
				"forumPost": "Messaggio di forum",
				"hearing": "Udienza",
				"instantMessage": "Messaggio immediato",
				"interview": "Intervista",
				"journalArticle": "Articolo di periodico specializzato",
				"letter": "Lettera",
				"magazineArticle": "Articolo di rivista",
				"manuscript": "Manoscritto",
				"map": "Mappa",
				"newspaperArticle": "Articolo di giornale",
				"note": "Nota",
				"patent": "Brevetto",
				"podcast": "Podcast",
				"presentation": "Presentazione",
				"radioBroadcast": "Trasmissione radiofonica",
				"report": "Report",
				"statute": "Statuto",
				"thesis": "Tesi",
				"tvBroadcast": "Trasmissione televisiva",
				"videoRecording": "Registrazione video",
				"webpage": "Pagina web"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Consultato",
				"applicationNumber": "Numero di applicazione",
				"archive": "Archivio",
				"archiveLocation": "Collocazione in archivio",
				"artworkMedium": "Mezzo grafico",
				"artworkSize": "Dimensioni grafiche",
				"assignee": "Cessionario",
				"audioFileType": "Tipo di file",
				"audioRecordingFormat": "Formato",
				"billNumber": "Numero della legge",
				"blogTitle": "Nome del blog",
				"bookTitle": "Titolo del libro",
				"callNumber": "Segnatura",
				"caseName": "Nome del caso",
				"code": "Codice",
				"codeNumber": "Numero di codice",
				"codePages": "Pagine del codice",
				"codeVolume": "Volume del codice",
				"committee": "Commissione",
				"company": "Società",
				"conferenceName": "Titolo di conferenza",
				"country": "Nazione",
				"court": "Corte",
				"date": "Data",
				"dateAdded": "Aggiunto in data",
				"dateDecided": "Data della sentenza",
				"dateEnacted": "Data di emanazione",
				"dateModified": "Modificato in data",
				"dictionaryTitle": "Titolo di dizionario",
				"distributor": "Distribuzione",
				"docketNumber": "Numero di inventario della sentenza",
				"documentNumber": "Numero di documento",
				"DOI": "DOI",
				"edition": "Edizione",
				"encyclopediaTitle": "Titolo di enciclopedia",
				"episodeNumber": "Numero di episodio",
				"extra": "Extra",
				"filingDate": "Data di sottomissione",
				"firstPage": "Prima pagina",
				"forumTitle": "Nome del forum o listserv",
				"genre": "Genere",
				"history": "Cronologia",
				"institution": "Istituzione",
				"interviewMedium": "Mezzo",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Fascicolo",
				"issueDate": "Data di pubblicazione",
				"issuingAuthority": "Autorità emettente",
				"itemType": "Tipo",
				"journalAbbreviation": "Sigla rivista",
				"label": "Etichetta",
				"language": "Lingua",
				"legalStatus": "Stato legale",
				"legislativeBody": "Corpo legislativo",
				"letterType": "Tipo",
				"libraryCatalog": "Catalogo della biblioteca",
				"manuscriptType": "Tipo",
				"mapType": "Tipo",
				"meetingName": "Nome della riunione",
				"nameOfAct": "Nome dell'atto",
				"network": "Rete",
				"number": "Numero",
				"numberOfVolumes": "Numero di volumi",
				"numPages": "# di Pagine",
				"pages": "Pagine",
				"patentNumber": "Numero di brevetto",
				"place": "Luogo di edizione",
				"postType": "Tipo di messaggio",
				"presentationType": "Tipo",
				"priorityNumbers": "Numero di priorità",
				"proceedingsTitle": "Titolo degli atti",
				"programmingLanguage": "Linguaggio di progr.",
				"programTitle": "Titolo del programma",
				"publicationTitle": "Titolo della pubblicazione",
				"publicLawNumber": "Numero di legge pubblica",
				"publisher": "Editore",
				"references": "Riferimenti bibliografici",
				"reporter": "Cancelliere",
				"reporterVolume": "Volume di report",
				"reportNumber": "Numero di report",
				"reportType": "Tipo di report",
				"rights": "Diritti",
				"runningTime": "Tempo di esercizio",
				"scale": "Scala",
				"section": "Sezione",
				"series": "Serie",
				"seriesNumber": "Numero della serie",
				"seriesText": "Testo della serie",
				"seriesTitle": "Titolo della serie",
				"session": "Sessione",
				"shortTitle": "Titolo breve",
				"studio": "Studio",
				"subject": "Oggetto",
				"system": "Sistema",
				"thesisType": "Tipo",
				"title": "Titolo",
				"university": "Università",
				"url": "URL",
				"versionNumber": "Versione",
				"videoRecordingFormat": "Formato",
				"volume": "Volume",
				"websiteTitle": "Nome del sito",
				"websiteType": "Tipo di sito web"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Procuratore o agente",
				"author": "Autore",
				"bookAuthor": "Autore del libro",
				"cartographer": "Cartografo",
				"castMember": "Componente del cast",
				"commenter": "Commentatore",
				"composer": "Compositore",
				"contributor": "Collaboratore",
				"cosponsor": "Co-finanziatore",
				"counsel": "Consulente",
				"director": "Regista",
				"editor": "Curatore",
				"guest": "Ospite",
				"interviewee": "Intervista con",
				"interviewer": "Intervistatore",
				"inventor": "Inventore",
				"performer": "Interprete",
				"podcaster": "Autore del podcast",
				"presenter": "Presentatore",
				"producer": "Produttore",
				"programmer": "Programmatore",
				"recipient": "Beneficiario",
				"reviewedAuthor": "Autore recensito",
				"scriptwriter": "Sceneggiatore",
				"seriesEditor": "Curatore della serie",
				"sponsor": "Finanziatore",
				"translator": "Convertitore",
				"wordsBy": "Parole di"
			}
		},
		"ja-JP": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "芸術作品",
				"attachment": "添付ファイル",
				"audioRecording": "録音",
				"bill": "議案",
				"blogPost": "ブログ記事",
				"book": "書籍",
				"bookSection": "書籍の章",
				"case": "訴訟",
				"computerProgram": "Software",
				"conferencePaper": "学会発表",
				"dictionaryEntry": "辞書項目",
				"document": "文書",
				"email": "電子メール",
				"encyclopediaArticle": "百科事典項目",
				"film": "映画",
				"forumPost": "掲示板への投稿",
				"hearing": "公聴会",
				"instantMessage": "インスタントメッセージ",
				"interview": "インタビュー",
				"journalArticle": "学術論文",
				"letter": "手紙",
				"magazineArticle": "雑誌記事",
				"manuscript": "原稿",
				"map": "地図",
				"newspaperArticle": "新聞記事",
				"note": "メモ",
				"patent": "特許",
				"podcast": "ポッドキャスト",
				"presentation": "プレゼンテーション",
				"radioBroadcast": "ラジオ放送",
				"report": "レポート",
				"statute": "法律",
				"thesis": "学位論文",
				"tvBroadcast": "テレビ放送",
				"videoRecording": "録画",
				"webpage": "ウェブページ"
			},
			"fields": {
				"abstractNote": "抄録",
				"accessDate": "アクセス日時",
				"applicationNumber": "商標登録号",
				"archive": "アーカイブ",
				"archiveLocation": "アーカイブ番号",
				"artworkMedium": "素材・技法",
				"artworkSize": "芸術作品のサイズ",
				"assignee": "譲受人",
				"audioFileType": "ファイルの種類",
				"audioRecordingFormat": "フォーマット",
				"billNumber": "法規番号",
				"blogTitle": "ブログ名",
				"bookTitle": "書籍名",
				"callNumber": "図書整理番号",
				"caseName": "事件名",
				"code": "コード",
				"codeNumber": "コード番号",
				"codePages": "ページ",
				"codeVolume": "巻",
				"committee": "委員会",
				"company": "会社名",
				"conferenceName": "学会名",
				"country": "国名",
				"court": "裁判所",
				"date": "出版年月日",
				"dateAdded": "追加日時",
				"dateDecided": "決定期日",
				"dateEnacted": "施行期日",
				"dateModified": "更新日時",
				"dictionaryTitle": "辞書名",
				"distributor": "配布／販売している会社",
				"docketNumber": "ドケット番号",
				"documentNumber": "文書番号",
				"DOI": "DOI",
				"edition": "版",
				"encyclopediaTitle": "百科事典名",
				"episodeNumber": "エピソードの番号",
				"extra": "その他",
				"filingDate": "出願日",
				"firstPage": "最初のページ",
				"forumTitle": "掲示板／Listserv の名称",
				"genre": "ジャンル",
				"history": "歴史",
				"institution": "機関",
				"interviewMedium": "メディア",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "号",
				"issueDate": "発行日",
				"issuingAuthority": "発行機関",
				"itemType": "種類",
				"journalAbbreviation": "雑誌略誌名",
				"label": "レーベル",
				"language": "言語",
				"legalStatus": "法的地位",
				"legislativeBody": "立法機関",
				"letterType": "種類",
				"libraryCatalog": "書誌情報",
				"manuscriptType": "種類",
				"mapType": "種類",
				"meetingName": "会議名",
				"nameOfAct": "法令名",
				"network": "ネットワーク",
				"number": "番号",
				"numberOfVolumes": "巻数",
				"numPages": "#ページ",
				"pages": "ページ数",
				"patentNumber": "特許番号",
				"place": "都市",
				"postType": "書き込みの種類",
				"presentationType": "種類",
				"priorityNumbers": "優先番号",
				"proceedingsTitle": "紀要名",
				"programmingLanguage": "Prog. Language",
				"programTitle": "プログラム名",
				"publicationTitle": "雑誌",
				"publicLawNumber": "法律番号",
				"publisher": "出版社",
				"references": "文献",
				"reporter": "レポーター",
				"reporterVolume": "リポーター巻",
				"reportNumber": "レポート番号",
				"reportType": "レポートの種類",
				"rights": "権利",
				"runningTime": "経過時間",
				"scale": "縮尺",
				"section": "章",
				"series": "叢書",
				"seriesNumber": "叢書番号",
				"seriesText": "叢書テキスト",
				"seriesTitle": "叢書名",
				"session": "セッション",
				"shortTitle": "題名(略)",
				"studio": "スタジオ",
				"subject": "話題",
				"system": "システム",
				"thesisType": "種類",
				"title": "題名",
				"university": "大学名",
				"url": "URL",
				"versionNumber": "ヴァージョン",
				"videoRecordingFormat": "フォーマット",
				"volume": "巻",
				"websiteTitle": "ウェブサイト名",
				"websiteType": "ウェブサイトの種類"
			},
			"creatorTypes": {
				"artist": "アーティスト",
				"attorneyAgent": "弁護士／代理人",
				"author": "著者名",
				"bookAuthor": "書籍著者名",
				"cartographer": "製図家",
				"castMember": "キャスト",
				"commenter": "評論家",
				"composer": "作曲家",
				"contributor": "寄稿者名",
				"cosponsor": "共同スポンサー",
				"counsel": "議会",
				"director": "監督",
				"editor": "編集者名",
				"guest": "ゲスト",
				"interviewee": "インタビュー対象:",
				"interviewer": "インタビュアー",
				"inventor": "発明者名",
				"performer": "演者",
				"podcaster": "ポッドキャスト送信者",
				"presenter": "発表者",
				"producer": "プロデューサー",
				"programmer": "プログラマー",
				"recipient": "受取人",
				"reviewedAuthor": "レビューされた著者",
				"scriptwriter": "脚本家",
				"seriesEditor": "叢書編集者名",
				"sponsor": "スポンサー",
				"translator": "翻訳者名",
				"wordsBy": "作詞"
			}
		},
		"km": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "សិល្បៈ",
				"attachment": "ឯកសារកម្ជាប់",
				"audioRecording": "ខ្សែអាត់សំឡេង",
				"bill": "គម្រោងច្បាប់",
				"blogPost": "ការផ្សាយគេហទំព័រប្លុក",
				"book": "សៀវភៅ",
				"bookSection": "ផ្នែកនៃសៀវភៅ",
				"case": "ករណី/រឿងក្តី",
				"computerProgram": "Software",
				"conferencePaper": "ឯកសារសន្និសីទ",
				"dictionaryEntry": "វចនានុក្រម",
				"document": "ឯកសារ",
				"email": "សារអេឡិចត្រូនិច",
				"encyclopediaArticle": "សព្វវចនាធិប្បាយ",
				"film": "ភាពយន្ត",
				"forumPost": "ការផ្សាយវេទិកា",
				"hearing": "សវនាការ",
				"instantMessage": "សារបន្ទាន់",
				"interview": "បទសម្ភាសន៍",
				"journalArticle": "អត្ថបទទនានុប្បវត្តិ",
				"letter": "សំបុត្រ",
				"magazineArticle": "អត្ថបទទស្សនាវដ្តី",
				"manuscript": "ហត្ថាអត្ថបទ",
				"map": "ផែនទី",
				"newspaperArticle": "អត្ថបទកាសែត",
				"note": "កំណត់ចំណាំ",
				"patent": "តត្តកម្ម",
				"podcast": "ផដខាស្ត៍",
				"presentation": "ឧទ្ទេសបទ",
				"radioBroadcast": "កម្មវិធីវិទ្យុ",
				"report": "របាយការណ៍",
				"statute": "ច្បាប់",
				"thesis": "និក្ខេបបទ",
				"tvBroadcast": "កម្មវិធីទូរទស្សន៍",
				"videoRecording": "ខ្សែអាត់វីដេអូ",
				"webpage": "គេហទំព័រ"
			},
			"fields": {
				"abstractNote": "ខ្លឹមសារសង្ខេប",
				"accessDate": "ចូលមើល",
				"applicationNumber": "ពាក្យសុំលេខ",
				"archive": "បណ្ណាសារ",
				"archiveLocation": "ទីតាំងក្នុងបណ្ណាសារ",
				"artworkMedium": "សារព័ត៌មាន",
				"artworkSize": "សិល្បៈ",
				"assignee": "អ្នទទួលភារកិច្ច",
				"audioFileType": "ប្រភេទឯកសារ",
				"audioRecordingFormat": "ទម្រង់",
				"billNumber": "លេខគម្រោងច្បាប់",
				"blogTitle": "ចំណងជើងគេហទំព័រប្លុក",
				"bookTitle": "ចំណងជើងសៀវភៅ",
				"callNumber": "លេខហៅ",
				"caseName": "ចំណងជើងករណី/រឿងក្តី",
				"code": "ក្រម",
				"codeNumber": "លេខក្រម",
				"codePages": "ទំព័រក្រម",
				"codeVolume": "វ៉ុលក្រម",
				"committee": "គណៈកម្មាធិការ",
				"company": "ក្រុមហ៊ុន",
				"conferenceName": "ឈ្មោះសន្និសីទកាសែត",
				"country": "ប្រទេស",
				"court": "តុលាការ",
				"date": "កាលបរិច្ឆេទ",
				"dateAdded": "កាលបរិច្ឆេទបញ្ចូល",
				"dateDecided": "កាលបរិច្ឆេទសម្រេច",
				"dateEnacted": "កាលបរិច្ឆេទអនុម័ត",
				"dateModified": "កែតម្រូវ",
				"dictionaryTitle": "ចំណងជើងវចនានុក្រម",
				"distributor": "ចែកចាយ",
				"docketNumber": "លេខសំណុំរឿង",
				"documentNumber": "លេខឯកសារ",
				"DOI": "ឌីអូអាយ",
				"edition": "កំណែតម្រូវ",
				"encyclopediaTitle": "ចំណងជើងសព្វវចនាធិប្បាយ",
				"episodeNumber": "លេខអង្គហេតុ",
				"extra": "បន្ថែម",
				"filingDate": "កាលបរិច្ឆេទតម្កល់",
				"firstPage": "ទំព័រទីមួយ",
				"forumTitle": "ចំណងជើងវេទិកា",
				"genre": "អត្តសញ្ញាណពិសេស",
				"history": "ប្រវត្តិសាស្ត្រ",
				"institution": "ស្ថាប័ន",
				"interviewMedium": "សារព័ត៌មាន",
				"ISBN": "អាយអេសប៊ីអិន",
				"ISSN": "អាយអេសអេសអិន",
				"issue": "ចេញផ្សាយ",
				"issueDate": "កាលបរិច្ឆទចេញផ្សាយ",
				"issuingAuthority": "អាជ្ញាធរចេញផ្សាយ",
				"itemType": "ប្រភេទ",
				"journalAbbreviation": "ពាក្យកាត់ទនានុបុ្បវត្តិ",
				"label": "ស្លាក",
				"language": "ភាសា",
				"legalStatus": "លក្ខខណ្ឌច្បាប់",
				"legislativeBody": "អង្គនីតិប្បញ្ញត្តិ",
				"letterType": "ប្រភេទ",
				"libraryCatalog": "កាតាឡុកបណ្ណាល័យ",
				"manuscriptType": "ប្រភេទ",
				"mapType": "ប្រភេទ",
				"meetingName": "ឈ្មោះកិច្ចប្រជុំ",
				"nameOfAct": "ចំណងជើងច្បាប់",
				"network": "បណ្តាញ",
				"number": "លេខ",
				"numberOfVolumes": "លេខវ៉ុល",
				"numPages": "លេខទំព័រ",
				"pages": "ទំព័រ",
				"patentNumber": "លេខតត្តកម្ម",
				"place": "ទីតាំង",
				"postType": "ប្រភេទផ្សាយ",
				"presentationType": "ប្រភេទ",
				"priorityNumbers": "លេខអាទិភាព",
				"proceedingsTitle": "ចំណងជើងនីតិវិធី",
				"programmingLanguage": "Prog. Language",
				"programTitle": "ចំណងជើងកម្មវិធី",
				"publicationTitle": "បោះពុម្ពផ្សាយ",
				"publicLawNumber": "លេខច្បាប់",
				"publisher": "អ្នកបោះពុម្ព",
				"references": "កំណត់យោង",
				"reporter": "អ្នកធ្វើរបាយការណ៍",
				"reporterVolume": "វ៉ុលរបាយការណ៍",
				"reportNumber": "លេខរបាយការណ៍",
				"reportType": "ប្រភេទរបាយការណ៍",
				"rights": "សិទ្ធិ",
				"runningTime": "ម៉ោងដំណើរការ",
				"scale": "កម្រិត",
				"section": "ផ្នែក",
				"series": "លេខរៀង",
				"seriesNumber": "លំដាប់លេខរៀង",
				"seriesText": "អត្ថបទលេខរៀង",
				"seriesTitle": "ចំណងជើងលេខរៀង",
				"session": "សម័យកាល",
				"shortTitle": "ចំណងជើងខ្លី",
				"studio": "ស្ថានីយថតសំឡេង",
				"subject": "ប្រធានបទ",
				"system": "ប្រព័ន្ធ",
				"thesisType": "ប្រភេទ",
				"title": "ចំណងជើង",
				"university": "សាកលវិទ្យាល័យ",
				"url": "គេហទំព័រ",
				"versionNumber": "Version",
				"videoRecordingFormat": "ទម្រង់",
				"volume": "វ៉ុល",
				"websiteTitle": "ចំណងជើងគេហទំព័រ",
				"websiteType": "ប្រភេទគេហទំព័រ"
			},
			"creatorTypes": {
				"artist": "សិល្បករ",
				"attorneyAgent": "មេធាវី/ភ្នាក់ងារ",
				"author": "អ្នកនិពន្ធ",
				"bookAuthor": "អ្នកនិពន្ធសៀវភៅ",
				"cartographer": "អ្នករៀបចំប្លង់",
				"castMember": "តួសំដែង",
				"commenter": "អ្នកផ្តល់មតិយោបល់",
				"composer": "អ្នកនិពន្ធទំនុក",
				"contributor": "អ្នកចូលរួម",
				"cosponsor": "សហអ្នកឧបត្ថម្ភ",
				"counsel": "ក្រុមប្រឹក្សា",
				"director": "នាយក",
				"editor": "អ្នកកែតម្រូវ",
				"guest": "ភ្ញៀវ",
				"interviewee": "សម្ភាសន៍ជាមួយ",
				"interviewer": "អ្នកសម្ភាសន៍",
				"inventor": "អ្នកបង្កើត",
				"performer": "អ្នកសំដែង",
				"podcaster": "អ្នកថតសំឡេងផដខាស្ត៍",
				"presenter": "អ្នកអត្ថាធិប្បាយ",
				"producer": "ផលិតករ",
				"programmer": "អ្នករៀបចំកម្មវិធី",
				"recipient": "អ្នកទទួល",
				"reviewedAuthor": "អ្នកនិពន្ធត្រួតពិនិត្យ",
				"scriptwriter": "អ្នកសរសេរអត្ថបទ",
				"seriesEditor": "អ្នកកែតម្រូវតាមលេខរៀង",
				"sponsor": "អ្នកឧបត្ថម្ភ",
				"translator": "អ្នកបកប្រែ",
				"wordsBy": "ពាក្យដោយ"
			}
		},
		"ko-KR": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "예술품",
				"attachment": "첨부",
				"audioRecording": "녹음",
				"bill": "의안",
				"blogPost": "블로그 게시물",
				"book": "서적",
				"bookSection": "책 소개 면",
				"case": "소송",
				"computerProgram": "소프트웨어",
				"conferencePaper": "회의문",
				"dictionaryEntry": "사전 항목",
				"document": "문서",
				"email": "이메일",
				"encyclopediaArticle": "백과사전 항목",
				"film": "영화",
				"forumPost": "토론 게시물",
				"hearing": "공청회",
				"instantMessage": "긴급 메시지",
				"interview": "인터뷰",
				"journalArticle": "저널 기사",
				"letter": "편지",
				"magazineArticle": "잡지 기사",
				"manuscript": "원고",
				"map": "지도",
				"newspaperArticle": "신문 기사",
				"note": "노트",
				"patent": "특허",
				"podcast": "팟캐스트",
				"presentation": "발표",
				"radioBroadcast": "라디오 방송",
				"report": "보고서",
				"statute": "법령",
				"thesis": "학위논문",
				"tvBroadcast": "TV 방송",
				"videoRecording": "녹화",
				"webpage": "웹 페이지"
			},
			"fields": {
				"abstractNote": "요약",
				"accessDate": "접근일",
				"applicationNumber": "출원 번호",
				"archive": "아카이브",
				"archiveLocation": "아카이브 위치",
				"artworkMedium": "소재·기법",
				"artworkSize": "예술품의 크기",
				"assignee": "양수인",
				"audioFileType": "파일 형식",
				"audioRecordingFormat": "형식",
				"billNumber": "의안 번호",
				"blogTitle": "블로그 제목",
				"bookTitle": "책 제목",
				"callNumber": "도서 번호",
				"caseName": "사건명",
				"code": "코드",
				"codeNumber": "코드 번호",
				"codePages": "법전 페이지",
				"codeVolume": "법제목",
				"committee": "위원회",
				"company": "회사명",
				"conferenceName": "협의 명",
				"country": "국가",
				"court": "법원",
				"date": "날짜",
				"dateAdded": "입력일",
				"dateDecided": "판결일",
				"dateEnacted": "제정일",
				"dateModified": "변경일",
				"dictionaryTitle": "사전 명",
				"distributor": "배급자",
				"docketNumber": "사건 번호",
				"documentNumber": "문서 번호",
				"DOI": "DOI",
				"edition": "판본",
				"encyclopediaTitle": "백과사전 명",
				"episodeNumber": "에피소드 번호",
				"extra": "추가사항",
				"filingDate": "Filing Date",
				"firstPage": "첫 페이지",
				"forumTitle": "토론/Listserv 제목",
				"genre": "장르",
				"history": "역사",
				"institution": "학회",
				"interviewMedium": "매체",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "호",
				"issueDate": "발행일",
				"issuingAuthority": "Issuing Authority",
				"itemType": "형식",
				"journalAbbreviation": "저널 약어",
				"label": "레이블",
				"language": "언어",
				"legalStatus": "법적지위",
				"legislativeBody": "입법 기관",
				"letterType": "형식",
				"libraryCatalog": "도서 목록",
				"manuscriptType": "형식",
				"mapType": "형식",
				"meetingName": "회의명",
				"nameOfAct": "법령 이름",
				"network": "네트워크",
				"number": "번호",
				"numberOfVolumes": "권수",
				"numPages": "총 페이지수",
				"pages": "쪽",
				"patentNumber": "특허 번호",
				"place": "발행지",
				"postType": "게시물 종류",
				"presentationType": "형식",
				"priorityNumbers": "우선 번호",
				"proceedingsTitle": "의사록",
				"programmingLanguage": "프로그래밍 언어",
				"programTitle": "프로그램 제목",
				"publicationTitle": "간행",
				"publicLawNumber": "공법 번호",
				"publisher": "출판사",
				"references": "참조",
				"reporter": "보고자",
				"reporterVolume": "보고서 권수",
				"reportNumber": "보고서 번호",
				"reportType": "보고 형식",
				"rights": "소유권",
				"runningTime": "동작 시간",
				"scale": "축척",
				"section": "구역",
				"series": "시리즈",
				"seriesNumber": "시리즈 번호",
				"seriesText": "시리즈 텍스트",
				"seriesTitle": "시리즈 제목",
				"session": "세션",
				"shortTitle": "짧은 제목",
				"studio": "스튜디오",
				"subject": "부제",
				"system": "시스템",
				"thesisType": "형식",
				"title": "제목",
				"university": "대학",
				"url": "URL",
				"versionNumber": "버전",
				"videoRecordingFormat": "형식",
				"volume": "권",
				"websiteTitle": "웹사이트 명",
				"websiteType": "웹사이트 형식"
			},
			"creatorTypes": {
				"artist": "예술가",
				"attorneyAgent": "변호사/대리인",
				"author": "저자",
				"bookAuthor": "책 저자",
				"cartographer": "지도 제작자",
				"castMember": "출연자",
				"commenter": "비평가",
				"composer": "작곡가",
				"contributor": "공헌자",
				"cosponsor": "Cosponsor",
				"counsel": "회의",
				"director": "감독",
				"editor": "편집자",
				"guest": "손님",
				"interviewee": "인터뷰：",
				"interviewer": "인터뷰진행자",
				"inventor": "발명자",
				"performer": "연주자",
				"podcaster": "팟캐스터",
				"presenter": "제출자",
				"producer": "제작자",
				"programmer": "프로그래머",
				"recipient": "수령인",
				"reviewedAuthor": "평론가",
				"scriptwriter": "각본가",
				"seriesEditor": "시리즈 편집자",
				"sponsor": "후원자",
				"translator": "번역자",
				"wordsBy": "작사"
			}
		},
		"lt-LT": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Iliustracija",
				"attachment": "Priedas",
				"audioRecording": "Garso įrašas",
				"bill": "Įstatymo projektas",
				"blogPost": "Tinklaraščio įrašas",
				"book": "Knyga",
				"bookSection": "Knygos skyrius",
				"case": "Byla",
				"computerProgram": "Programinė įranga",
				"conferencePaper": "Pranešimas konferencijoje",
				"dictionaryEntry": "Žodyno įrašas",
				"document": "Dokumentas",
				"email": "El. laiškas",
				"encyclopediaArticle": "Straipsnelis enciklopedijoje",
				"film": "Filmas",
				"forumPost": "Žinutė forume",
				"hearing": "Bylos nagrinėjimas, svarstymas",
				"instantMessage": "Žinutė",
				"interview": "Pokalbis",
				"journalArticle": "Žurnalo straipsnis",
				"letter": "Laiškas",
				"magazineArticle": "Periodinio žurnalo straipsnis",
				"manuscript": "Rankraštis",
				"map": "Žemėlapis",
				"newspaperArticle": "Laikraščio straipsnis",
				"note": "Pastaba",
				"patent": "Patentas",
				"podcast": "Tinklalaidė",
				"presentation": "Pateiktis",
				"radioBroadcast": "Radijo laida",
				"report": "Ataskaita/pranešimas",
				"statute": "Įstatymas",
				"thesis": "Disertacija, baigiamasis darbas",
				"tvBroadcast": "TV laida",
				"videoRecording": "Vaizdo įrašas",
				"webpage": "Tinklalapis"
			},
			"fields": {
				"abstractNote": "Santrauka",
				"accessDate": "Žiūrėta",
				"applicationNumber": "Pareiškimo numeris",
				"archive": "Archyvas",
				"archiveLocation": "Vieta archyve",
				"artworkMedium": "Laikmena",
				"artworkSize": "Paveikslo dydis",
				"assignee": "Atstovas",
				"audioFileType": "Failo tipas",
				"audioRecordingFormat": "Formatas",
				"billNumber": "Įstatymo numeris",
				"blogTitle": "Tinklaraščio pavadinimas",
				"bookTitle": "Knygos pavad.",
				"callNumber": "Šifras",
				"caseName": "Bylos pavadinimas",
				"code": "Kodas",
				"codeNumber": "Kodekso numeris",
				"codePages": "Puslapiai kodekse",
				"codeVolume": "Kodekso tomas",
				"committee": "Komisija",
				"company": "Įmonė",
				"conferenceName": "Konferencijos pavadinimas",
				"country": "Valstybė",
				"court": "Teismas",
				"date": "Data",
				"dateAdded": "Pridėta",
				"dateDecided": "Sprendimo data",
				"dateEnacted": "Paskelbimo data",
				"dateModified": "Pakeista",
				"dictionaryTitle": "Žodyno pavadinimas",
				"distributor": "Platintojas",
				"docketNumber": "Priedo numeris",
				"documentNumber": "Dokumento numeris",
				"DOI": "DOI",
				"edition": "Leidimas, Nr.",
				"encyclopediaTitle": "Enciklopedijos pavadinimas",
				"episodeNumber": "Epizodo numeris",
				"extra": "Papildoma",
				"filingDate": "Užpildymo data",
				"firstPage": "Pirmas puslapis",
				"forumTitle": "Forumo pavadinimas",
				"genre": "Žanras",
				"history": "Istorija",
				"institution": "Įstaiga",
				"interviewMedium": "Laikmena",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Numeris",
				"issueDate": "Išleidimo data",
				"issuingAuthority": "Išdavusi įstaiga",
				"itemType": "Tipas",
				"journalAbbreviation": "Žurnalo santrumpa",
				"label": "Etiketė",
				"language": "Kalba",
				"legalStatus": "Teisinis statusas",
				"legislativeBody": "Įstatymų leidžiamoji valdžia",
				"letterType": "Tipas",
				"libraryCatalog": "Bibl. katalogas",
				"manuscriptType": "Tipas",
				"mapType": "Tipas",
				"meetingName": "Susitikimo pavadinimas",
				"nameOfAct": "Akto pavadinimas",
				"network": "Tinklas",
				"number": "Numeris",
				"numberOfVolumes": "Tomų skaičius",
				"numPages": "Puslapių kiekis",
				"pages": "Puslapiai",
				"patentNumber": "Patento numeris",
				"place": "Vieta",
				"postType": "Pranešimo tipas",
				"presentationType": "Tipas",
				"priorityNumbers": "Prioriteto numeris",
				"proceedingsTitle": "Įvykio pavadinimas",
				"programmingLanguage": "Prog. kalba",
				"programTitle": "Programos pavadinimas",
				"publicationTitle": "Leidinys",
				"publicLawNumber": "Oficialaus rašto numeris",
				"publisher": "Leidėjas",
				"references": "Nuorodos",
				"reporter": "Pranešėjas",
				"reporterVolume": "Pranešimo tomas",
				"reportNumber": "Pranešimo numeris",
				"reportType": "Pranešimo tipas",
				"rights": "Teisės",
				"runningTime": "Trukmė",
				"scale": "Skalė",
				"section": "Skirsnis",
				"series": "Serija",
				"seriesNumber": "Serijos Nr.",
				"seriesText": "Serijos tekstas",
				"seriesTitle": "Serijos pavadinimas",
				"session": "Sesija",
				"shortTitle": "Trumpas pavad.",
				"studio": "Dirbtuvė/studija",
				"subject": "Tema",
				"system": "Sistema",
				"thesisType": "Tipas",
				"title": "Pavadinimas",
				"university": "Universitetas",
				"url": "URL",
				"versionNumber": "Versija",
				"videoRecordingFormat": "Formatas",
				"volume": "Tomas",
				"websiteTitle": "Svetainės pavadinimas",
				"websiteType": "Svetainės tipas"
			},
			"creatorTypes": {
				"artist": "Menininkas/aktorius",
				"attorneyAgent": "Įgaliotasis asmuo",
				"author": "Autorius",
				"bookAuthor": "Knygos autorius",
				"cartographer": "Kartografas",
				"castMember": "Atlikėjas/artistas",
				"commenter": "Komentuotojas",
				"composer": "Kūrėjas/kompozitorius",
				"contributor": "Bendradarbis",
				"cosponsor": "Kitas rėmėjas",
				"counsel": "Advokatas",
				"director": "Vadovas",
				"editor": "Redaktorius",
				"guest": "Svečias",
				"interviewee": "Pokalbis su",
				"interviewer": "Apklausėjas",
				"inventor": "išradėjas",
				"performer": "Atlikėjas",
				"podcaster": "Transliuotojas",
				"presenter": "Vedėjas",
				"producer": "Prodiuseris",
				"programmer": "Programuotojas",
				"recipient": "Gavėjas",
				"reviewedAuthor": "Peržiūrėtas autorius",
				"scriptwriter": "Scenaristas",
				"seriesEditor": "Serijos sudarytojas",
				"sponsor": "Rėmėjas",
				"translator": "Vertėjas",
				"wordsBy": "Žodžiai"
			}
		},
		"mn-MN": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Artwork",
				"attachment": "Хавсралт",
				"audioRecording": "Audio Recording",
				"bill": "Bill",
				"blogPost": "Блог бичлэг",
				"book": "Ном",
				"bookSection": "Номын хэсэг",
				"case": "Case",
				"computerProgram": "Software",
				"conferencePaper": "Хурлын өгүүлэл",
				"dictionaryEntry": "Dictionary Entry",
				"document": "Баримт",
				"email": "Электрон шуудан",
				"encyclopediaArticle": "Тайлбар толийн өгүүлэл",
				"film": "Кино",
				"forumPost": "Forum Post",
				"hearing": "Hearing",
				"instantMessage": "Instant Message",
				"interview": "Ярилцлага",
				"journalArticle": "Сэтгүүлийн өгүүлэл",
				"letter": "Захиа",
				"magazineArticle": "Magazine Article",
				"manuscript": "Manuscript",
				"map": "Газрын зураг",
				"newspaperArticle": "Сонины нийтлэл",
				"note": "Тэмдэглэл",
				"patent": "Патент",
				"podcast": "Podcast",
				"presentation": "Presentation",
				"radioBroadcast": "Radio Broadcast",
				"report": "Тайлан",
				"statute": "Statute",
				"thesis": "Thesis",
				"tvBroadcast": "TV Broadcast",
				"videoRecording": "Video Recording",
				"webpage": "Вэб хуудас"
			},
			"fields": {
				"abstractNote": "Abstract",
				"accessDate": "Accessed",
				"applicationNumber": "Application Number",
				"archive": "Archive",
				"archiveLocation": "Loc. in Archive",
				"artworkMedium": "Дунд",
				"artworkSize": "Artwork Size",
				"assignee": "Assignee",
				"audioFileType": "Файлын төрөл",
				"audioRecordingFormat": "Format",
				"billNumber": "Bill Number",
				"blogTitle": "Блог гарчиг",
				"bookTitle": "Номын гарчиг",
				"callNumber": "Call Number",
				"caseName": "Case Name",
				"code": "Код",
				"codeNumber": "Кодын дугаар",
				"codePages": "Code Pages",
				"codeVolume": "Code Volume",
				"committee": "Committee",
				"company": "Компани",
				"conferenceName": "Хурлын нэр",
				"country": "Улс",
				"court": "Court",
				"date": "Он сар өдөр",
				"dateAdded": "Date Added",
				"dateDecided": "Date Decided",
				"dateEnacted": "Date Enacted",
				"dateModified": "Modified",
				"dictionaryTitle": "Толь бичгийн гарчиг",
				"distributor": "Distributor",
				"docketNumber": "Docket Number",
				"documentNumber": "Баримт бичгийн дугаар",
				"DOI": "DOI",
				"edition": "Edition",
				"encyclopediaTitle": "Нэвтэрхий тольны гарчиг",
				"episodeNumber": "Episode Number",
				"extra": "Extra",
				"filingDate": "Filing Date",
				"firstPage": "Эхний хуудас",
				"forumTitle": "Forum/Listserv Title",
				"genre": "Genre",
				"history": "Түүх",
				"institution": "Хүрээлэн/Албан байгууллага",
				"interviewMedium": "Дунд",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Issue",
				"issueDate": "Issue Date",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Төрөл",
				"journalAbbreviation": "Journal Abbr",
				"label": "Шошго",
				"language": "Хэл",
				"legalStatus": "Legal Status",
				"legislativeBody": "Legislative Body",
				"letterType": "Төрөл",
				"libraryCatalog": "Library Catalog",
				"manuscriptType": "Төрөл",
				"mapType": "Төрөл",
				"meetingName": "Уулзалтын нэр",
				"nameOfAct": "Name of Act",
				"network": "Сүлжээ",
				"number": "Тоо",
				"numberOfVolumes": "# of Volumes",
				"numPages": "# of Pages",
				"pages": "Хуудасууд",
				"patentNumber": "Патентын дугаар",
				"place": "Place",
				"postType": "Бичлэгийн төрөл",
				"presentationType": "Төрөл",
				"priorityNumbers": "Priority Numbers",
				"proceedingsTitle": "Proceedings Title",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Хэвлэл",
				"publicLawNumber": "Public Law Number",
				"publisher": "Хэвлэгч",
				"references": "References",
				"reporter": "Reporter",
				"reporterVolume": "Reporter Volume",
				"reportNumber": "Тайлангийн дугаар",
				"reportType": "Тайлангийн төрөл",
				"rights": "Эрхүүд",
				"runningTime": "Running Time",
				"scale": "Масштаб",
				"section": "Section",
				"series": "Series",
				"seriesNumber": "Series Number",
				"seriesText": "Series Text",
				"seriesTitle": "Series Title",
				"session": "Session",
				"shortTitle": "Богино гарчиг",
				"studio": "Studio",
				"subject": "Subject",
				"system": "Систем",
				"thesisType": "Төрөл",
				"title": "Гарчиг",
				"university": "Их сургууль",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volume",
				"websiteTitle": "Вэб сайтын гарчиг",
				"websiteType": "Вэб сайтын төрөл"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Attorney/Agent",
				"author": "Зохиогч",
				"bookAuthor": "Book Author",
				"cartographer": "Cartographer",
				"castMember": "Cast Member",
				"commenter": "Commenter",
				"composer": "Composer",
				"contributor": "Contributor",
				"cosponsor": "Cosponsor",
				"counsel": "Counsel",
				"director": "Захирал",
				"editor": "Editor",
				"guest": "Guest",
				"interviewee": "Interview With",
				"interviewer": "Interviewer",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Podcaster",
				"presenter": "Presenter",
				"producer": "Producer",
				"programmer": "Programmer",
				"recipient": "Recipient",
				"reviewedAuthor": "Reviewed Author",
				"scriptwriter": "Scriptwriter",
				"seriesEditor": "Series Editor",
				"sponsor": "Ивээн тэтгэгч",
				"translator": "Орчуулагч",
				"wordsBy": "Words By"
			}
		},
		"nb-NO": {
			"itemTypes": {
				"annotation": "Merknad",
				"artwork": "Kunstverk",
				"attachment": "Vedlegg",
				"audioRecording": "Lydopptak",
				"bill": "Lovforslag",
				"blogPost": "Blogginnlegg",
				"book": "Bok",
				"bookSection": "Del av bok",
				"case": "Sak",
				"computerProgram": "Programvare",
				"conferencePaper": "Konferanseinnlegg",
				"dictionaryEntry": "Oppslag i ordbok",
				"document": "Dokument",
				"email": "E-post",
				"encyclopediaArticle": "Artikkel i oppslagsverk",
				"film": "Film",
				"forumPost": "Foruminnlegg",
				"hearing": "Høring",
				"instantMessage": "Hurtigmelding",
				"interview": "Intervju",
				"journalArticle": "Tidsskriftsartikkel",
				"letter": "Brev",
				"magazineArticle": "Magasinartikkel",
				"manuscript": "Manuskript",
				"map": "Kart",
				"newspaperArticle": "Avisartikkel",
				"note": "Notat",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Presentasjon",
				"radioBroadcast": "Radiosending",
				"report": "Rapport",
				"statute": "Statutt",
				"thesis": "Avhandling",
				"tvBroadcast": "TV-sending",
				"videoRecording": "Videoopptak",
				"webpage": "Nettside"
			},
			"fields": {
				"abstractNote": "Sammendrag",
				"accessDate": "Lest",
				"applicationNumber": "Programvarenummer",
				"archive": "Arkiv",
				"archiveLocation": "Lokalisering i arkiv",
				"artworkMedium": "Medium",
				"artworkSize": "Kunstverk-størrelse",
				"assignee": "Fullmektig",
				"audioFileType": "Filtype",
				"audioRecordingFormat": "Format",
				"billNumber": "Lov-nummer",
				"blogTitle": "Bloggtittel",
				"bookTitle": "Boktittel",
				"callNumber": "Plass-signatur",
				"caseName": "Saksnavn",
				"code": "Kode",
				"codeNumber": "Tekst nummer",
				"codePages": "Sider i tekst",
				"codeVolume": "Lov-volum",
				"committee": "Komité",
				"company": "Selskap",
				"conferenceName": "Konferansens navn",
				"country": "Land",
				"court": "Rett",
				"date": "Dato",
				"dateAdded": "Dato lagt til",
				"dateDecided": "Avgjort (dato)",
				"dateEnacted": "Innført (dato)",
				"dateModified": "Endret",
				"dictionaryTitle": "Ordbok",
				"distributor": "Distributør",
				"docketNumber": "Registreringsnummer",
				"documentNumber": "Dokumentnummer",
				"DOI": "DOI",
				"edition": "Utgave",
				"encyclopediaTitle": "Oppslagverk",
				"episodeNumber": "Episodenummer",
				"extra": "Ekstra",
				"filingDate": "Innleveringsdato",
				"firstPage": "Første side",
				"forumTitle": "Forum-/listserv-tittel",
				"genre": "Sjanger",
				"history": "Historie",
				"institution": "Institusjon",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Nummer",
				"issueDate": "Kjennelsesdato",
				"issuingAuthority": "Utstedende myndighet",
				"itemType": "Type",
				"journalAbbreviation": "Tidsskriftsforkortelse",
				"label": "Plateselskap",
				"language": "Språk",
				"legalStatus": "Rettslig status",
				"legislativeBody": "Lovgivende organ",
				"letterType": "Type",
				"libraryCatalog": "Bibliotekskatalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Møtenavn",
				"nameOfAct": "Navn på loven",
				"network": "Nettverk",
				"number": "Nummer",
				"numberOfVolumes": "# av volumer",
				"numPages": "# av sider",
				"pages": "Sider",
				"patentNumber": "Patentnummer",
				"place": "Sted",
				"postType": "Posttype",
				"presentationType": "Type",
				"priorityNumbers": "Prioritetsnummer",
				"proceedingsTitle": "Sakstittel",
				"programmingLanguage": "Programmeringsspråk",
				"programTitle": "Program tittel",
				"publicationTitle": "Publikasjon",
				"publicLawNumber": "Offentlig lov, nummer",
				"publisher": "Utgiver",
				"references": "Referanser",
				"reporter": "Reporter",
				"reporterVolume": "Reporter volum",
				"reportNumber": "Rapportnummer",
				"reportType": "Rapporttype",
				"rights": "Rettigheter",
				"runningTime": "Lengde",
				"scale": "Skala",
				"section": "Seksjon",
				"series": "Serie",
				"seriesNumber": "Serienummer",
				"seriesText": "Serietekst",
				"seriesTitle": "Serietittel",
				"session": "Sesjon",
				"shortTitle": "Kort tittel",
				"studio": "Studio",
				"subject": "Emne",
				"system": "System",
				"thesisType": "Type",
				"title": "Tittel",
				"university": "Universitet",
				"url": "URL",
				"versionNumber": "Versjon",
				"videoRecordingFormat": "Format",
				"volume": "Volum",
				"websiteTitle": "Nettsidens tittel",
				"websiteType": "Nettstedtype"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Advokat/Agent",
				"author": "Forfatter",
				"bookAuthor": "Bokforfatter",
				"cartographer": "Kartograf",
				"castMember": "Skuespiller",
				"commenter": "Kommentator",
				"composer": "Komponist",
				"contributor": "Medforfatter",
				"cosponsor": "Medsponsor",
				"counsel": "Rådgiver",
				"director": "Instruktør",
				"editor": "Redaktør",
				"guest": "Gjest",
				"interviewee": "Intervju med",
				"interviewer": "Intervjuer",
				"inventor": "Oppfinner",
				"performer": "Utøver",
				"podcaster": "Podcaster",
				"presenter": "Presentatør",
				"producer": "Produsent",
				"programmer": "Programmerer",
				"recipient": "Mottaker",
				"reviewedAuthor": "Anmeldt forfatter",
				"scriptwriter": "Manusforfatter",
				"seriesEditor": "Serieredaktør",
				"sponsor": "Sponsor",
				"translator": "Oversetter",
				"wordsBy": "Tekster av"
			}
		},
		"nl-NL": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Kunstwerk",
				"attachment": "Bijlage",
				"audioRecording": "Geluidsopname",
				"bill": "Kamerstukken / Handelingen / Wetsvoorstel",
				"blogPost": "Blogbericht",
				"book": "Boek",
				"bookSection": "Boek-sectie",
				"case": "Rechtszaak",
				"computerProgram": "Software",
				"conferencePaper": "Conferentiebijdrage",
				"dictionaryEntry": "Lemma",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Encyclopedie-artikel",
				"film": "Film",
				"forumPost": "Forumbericht",
				"hearing": "Hoorzitting",
				"instantMessage": "Instant message",
				"interview": "Interview",
				"journalArticle": "Artikel in academisch tijdschrift",
				"letter": "Brief",
				"magazineArticle": "Artikel in magazine",
				"manuscript": "Manuscript",
				"map": "Kaart",
				"newspaperArticle": "Krantenartikel",
				"note": "Aantekening",
				"patent": "Patent / Octrooi",
				"podcast": "Podcast",
				"presentation": "Presentatie",
				"radioBroadcast": "Radio-uitzending",
				"report": "Rapport",
				"statute": "Wet",
				"thesis": "Proefschrift",
				"tvBroadcast": "Televisie-uitzending",
				"videoRecording": "Video-opname",
				"webpage": "Webpagina"
			},
			"fields": {
				"abstractNote": "Samenvatting",
				"accessDate": "Geraadpleegd",
				"applicationNumber": "Aanvraagnummer",
				"archive": "Archief",
				"archiveLocation": "Locatie in archief",
				"artworkMedium": "Medium",
				"artworkSize": "Afmetingen kunstwerk",
				"assignee": "Rechthebbende",
				"audioFileType": "Bestandstype",
				"audioRecordingFormat": "Formaat",
				"billNumber": "(Dossier)nummer",
				"blogTitle": "Titel blog",
				"bookTitle": "Boektitel",
				"callNumber": "Indexnummer",
				"caseName": "Naam rechtszaak",
				"code": "Publicatiemedium",
				"codeNumber": "... tranche van wet",
				"codePages": "Nummer (binnen dossier)",
				"codeVolume": "Wetgevingsjaargang",
				"committee": "Commissie",
				"company": "Bedrijf",
				"conferenceName": "Conferentienaam",
				"country": "Land",
				"court": "Rechtbank",
				"date": "Datum",
				"dateAdded": "Datum toegevoegd",
				"dateDecided": "Datum beslissing",
				"dateEnacted": "Datum publicatie",
				"dateModified": "Bewerkt",
				"dictionaryTitle": "Woordenboek-titel",
				"distributor": "Distributeur",
				"docketNumber": "ECLI / Zaaknummer",
				"documentNumber": "Documentnummer",
				"DOI": "DOI",
				"edition": "Druk",
				"encyclopediaTitle": "Encyclopedie-titel",
				"episodeNumber": "Afleveringsnummer",
				"extra": "Extra",
				"filingDate": "Indieningsdatum",
				"firstPage": "Eerste pagina",
				"forumTitle": "Naam forum",
				"genre": "Genre",
				"history": "Geschiedenis",
				"institution": "(Onderzoeks)instituut",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Editie",
				"issueDate": "Publicatiedatum",
				"issuingAuthority": "Uitgevende authoriteit",
				"itemType": "Type",
				"journalAbbreviation": "Tijdschrift-afkorting",
				"label": "Label",
				"language": "Taal",
				"legalStatus": "Wettelijke status",
				"legislativeBody": "(Wetgevend) orgaan",
				"letterType": "Type",
				"libraryCatalog": "Bibliotheekscatalogus",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Naam bijeenkomst",
				"nameOfAct": "Citeertitel wet",
				"network": "Netwerk",
				"number": "Nummer",
				"numberOfVolumes": "# delen",
				"numPages": "Aantal pagina's",
				"pages": "Pagina's",
				"patentNumber": "Patentnummer",
				"place": "Plaats",
				"postType": "Type post",
				"presentationType": "Type",
				"priorityNumbers": "Prioriteitsnummers",
				"proceedingsTitle": "Titel notulen",
				"programmingLanguage": "Prog. taal",
				"programTitle": "Programmatitel",
				"publicationTitle": "Titel uitgave",
				"publicLawNumber": "Public Law Number",
				"publisher": "Uitgever",
				"references": "Verwijzingen",
				"reporter": "Jurisprudentietijdschrift",
				"reporterVolume": "Jaargang(, Editie)",
				"reportNumber": "Nummer (van het rapport)",
				"reportType": "Rapport-type",
				"rights": "Rechten",
				"runningTime": "Duur",
				"scale": "Schaal",
				"section": "Sectie",
				"series": "Reeks",
				"seriesNumber": "Nummer (binnen de reeks)",
				"seriesText": "Reeks (extra tekst)",
				"seriesTitle": "Titel van de reeks",
				"session": "Sessie",
				"shortTitle": "Korte Titel",
				"studio": "Studio",
				"subject": "Onderwerp",
				"system": "Systeem",
				"thesisType": "Type",
				"title": "Titel",
				"university": "Universiteit",
				"url": "URL",
				"versionNumber": "Versie",
				"videoRecordingFormat": "Formaat",
				"volume": "Deel",
				"websiteTitle": "Titel webpagina",
				"websiteType": "Type website"
			},
			"creatorTypes": {
				"artist": "Kunstenaar",
				"attorneyAgent": "Advocaat/Agent",
				"author": "Auteur",
				"bookAuthor": "Auteur van het boek",
				"cartographer": "Cartograaf",
				"castMember": "Cast-member",
				"commenter": "Commentator",
				"composer": "Componist",
				"contributor": "Coauteur",
				"cosponsor": "Cosponsor",
				"counsel": "Raad",
				"director": "Regisseur",
				"editor": "Redacteur",
				"guest": "Gast",
				"interviewee": "Interview met",
				"interviewer": "Interviewer",
				"inventor": "Uitvinder",
				"performer": "Uitvoerder",
				"podcaster": "Podcaster",
				"presenter": "Presentator",
				"producer": "Producent",
				"programmer": "Programmeur",
				"recipient": "Ontvanger",
				"reviewedAuthor": "Besproken auteur",
				"scriptwriter": "Tekstschrijver",
				"seriesEditor": "Redacteur van de reeks",
				"sponsor": "Sponsor",
				"translator": "Vertaler",
				"wordsBy": "Tekst door"
			}
		},
		"nn-NO": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Kunstverk",
				"attachment": "Vedlegg",
				"audioRecording": "Lydopptak",
				"bill": "Lovforslag",
				"blogPost": "Blogginnlegg",
				"book": "Bok",
				"bookSection": "Del av bok",
				"case": "Sak",
				"computerProgram": "Software",
				"conferencePaper": "Konferanseinnlegg",
				"dictionaryEntry": "Ordbokoppslag",
				"document": "Dokument",
				"email": "E-post",
				"encyclopediaArticle": "Artikkel i oppslagsverk",
				"film": "Film",
				"forumPost": "Foruminnlegg",
				"hearing": "Høyring",
				"instantMessage": "Snøggmelding",
				"interview": "Intervju",
				"journalArticle": "Tidsskriftartikkel",
				"letter": "Brev",
				"magazineArticle": "Magasinartikkel",
				"manuscript": "Manuskript",
				"map": "Kart",
				"newspaperArticle": "Avisartikkel",
				"note": "Notat",
				"patent": "Patent",
				"podcast": "Podkast",
				"presentation": "Presentasjon",
				"radioBroadcast": "Radiosending",
				"report": "Rapport",
				"statute": "Statutt",
				"thesis": "Avhandling",
				"tvBroadcast": "TV-sending",
				"videoRecording": "Videoopptak",
				"webpage": "Nettside"
			},
			"fields": {
				"abstractNote": "Samandrag",
				"accessDate": "Lest",
				"applicationNumber": "Program-nummer",
				"archive": "Arkiv\\n",
				"archiveLocation": "Lokalisering i arkiv",
				"artworkMedium": "Medium",
				"artworkSize": "Kunstverk-storleik",
				"assignee": "Fullmektig",
				"audioFileType": "Filtype",
				"audioRecordingFormat": "Format",
				"billNumber": "Lov-nummer",
				"blogTitle": "Bloggtittel",
				"bookTitle": "Boktittel",
				"callNumber": "Plass-signatur",
				"caseName": "Saksnamn",
				"code": "Kode",
				"codeNumber": "Tekst nummer",
				"codePages": "Sider i tekst",
				"codeVolume": "Lov-volum",
				"committee": "Komité",
				"company": "Selskap",
				"conferenceName": "Namnet på konferansen",
				"country": "Land",
				"court": "Rett",
				"date": "Dato",
				"dateAdded": "Lagt til",
				"dateDecided": "Avgjort (dato)",
				"dateEnacted": "Innført (dato)",
				"dateModified": "Sist endra",
				"dictionaryTitle": "Ordbok",
				"distributor": "Distributør",
				"docketNumber": "Docket Number",
				"documentNumber": "Dokumentnummer",
				"DOI": "DOI",
				"edition": "Utgåve",
				"encyclopediaTitle": "Oppslagverk",
				"episodeNumber": "Episodenummer",
				"extra": "Ekstra",
				"filingDate": "Arkiveringsdato",
				"firstPage": "Første side",
				"forumTitle": "Forum-/listserv-tittel",
				"genre": "Sjanger",
				"history": "Historie",
				"institution": "Institusjon",
				"interviewMedium": "Medium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Nummer",
				"issueDate": "Kjennelsesdato",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Type",
				"journalAbbreviation": "Tidsskriftforkorting",
				"label": "Plateselskap",
				"language": "Språk",
				"legalStatus": "Rettsleg status",
				"legislativeBody": "Lovgjevande organ",
				"letterType": "Type",
				"libraryCatalog": "Bibliotek-katalog",
				"manuscriptType": "Type",
				"mapType": "Type",
				"meetingName": "Møtenamn",
				"nameOfAct": "Namn på lova",
				"network": "Nettverk",
				"number": "Nummer",
				"numberOfVolumes": "# av volum",
				"numPages": "Tal sider",
				"pages": "Sidetal",
				"patentNumber": "Patentnummer",
				"place": "Stad",
				"postType": "Posttype",
				"presentationType": "Type",
				"priorityNumbers": "Prioritetnummer",
				"proceedingsTitle": "Sakstittel",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Programtittel",
				"publicationTitle": "Publikasjon",
				"publicLawNumber": "Offentleg lov, nummer",
				"publisher": "Utgjevar",
				"references": "Referansar",
				"reporter": "Reporter",
				"reporterVolume": "Reporter volum",
				"reportNumber": "Rapportnummer",
				"reportType": "Rapporttype",
				"rights": "Rettar",
				"runningTime": "Lengd",
				"scale": "Skala",
				"section": "Seksjon",
				"series": "Serie",
				"seriesNumber": "Serienummer",
				"seriesText": "Serietekst",
				"seriesTitle": "Serietittel",
				"session": "Sesjon",
				"shortTitle": "Kort tittel",
				"studio": "Studio",
				"subject": "Emne",
				"system": "System",
				"thesisType": "Type",
				"title": "Tittel",
				"university": "Universitet",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Volum",
				"websiteTitle": "Nettstadtittel",
				"websiteType": "Nettstadtype"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Advokat/Agent",
				"author": "Forfattar",
				"bookAuthor": "Bokforfattar",
				"cartographer": "Kartograf",
				"castMember": "Skodespelar",
				"commenter": "Kommentator",
				"composer": "Komponist",
				"contributor": "Medforfatter",
				"cosponsor": "Medsponsor",
				"counsel": "Rådgjevar",
				"director": "Instruktør",
				"editor": "Redaktør",
				"guest": "Gjest",
				"interviewee": "Intervju med",
				"interviewer": "Intervjuar",
				"inventor": "Oppfinnar",
				"performer": "Utøvar",
				"podcaster": "Podkastar",
				"presenter": "Presentatør",
				"producer": "Produsent",
				"programmer": "Programmerar",
				"recipient": "Mottakar",
				"reviewedAuthor": "Meld forfattar",
				"scriptwriter": "Manusforfattar",
				"seriesEditor": "Serieredaktør",
				"sponsor": "Sponsor",
				"translator": "Omsetjar",
				"wordsBy": "Tekstar av"
			}
		},
		"pl-PL": {
			"itemTypes": {
				"annotation": "Adnotacja",
				"artwork": "Dzieło sztuki",
				"attachment": "Załącznik",
				"audioRecording": "Nagranie audio",
				"bill": "Projekt ustawy",
				"blogPost": "Wpis w blogu",
				"book": "Książka",
				"bookSection": "Rozdział",
				"case": "Sprawa sądowa",
				"computerProgram": "Oprogramowanie",
				"conferencePaper": "Materiał konferencyjny",
				"dictionaryEntry": "Hasło słownikowe",
				"document": "Dokument",
				"email": "E-mail",
				"encyclopediaArticle": "Artykuł w encyklopedii",
				"film": "Film",
				"forumPost": "Wpis na forum",
				"hearing": "Rozprawa",
				"instantMessage": "Krótka wiadomość",
				"interview": "Wywiad",
				"journalArticle": "Artykuł z czasopisma",
				"letter": "List",
				"magazineArticle": "Artykuł z magazynu",
				"manuscript": "Rękopis",
				"map": "Mapa",
				"newspaperArticle": "Artykuł z gazety",
				"note": "Notatka",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Prezentacja",
				"radioBroadcast": "Audycja radiowa",
				"report": "Raport",
				"statute": "Statut",
				"thesis": "Praca dyplomowa",
				"tvBroadcast": "Program telewizyjny",
				"videoRecording": "Nagranie wideo",
				"webpage": "Strona internetowa"
			},
			"fields": {
				"abstractNote": "Krótki opis",
				"accessDate": "Dostęp",
				"applicationNumber": "Numer zgłoszenia",
				"archive": "Archiwum",
				"archiveLocation": "Miejsce w archiwum",
				"artworkMedium": "Technika",
				"artworkSize": "Wielkość dzieła",
				"assignee": "Beneficjent",
				"audioFileType": "Typ pliku",
				"audioRecordingFormat": "Format",
				"billNumber": "Numer projektu ustawy",
				"blogTitle": "Tytuł blogu",
				"bookTitle": "Tytuł książki",
				"callNumber": "Nr klasyfikacyjny",
				"caseName": "Tytuł sprawy",
				"code": "Kod",
				"codeNumber": "Numer kodu",
				"codePages": "Kod stron",
				"codeVolume": "Kod tomu",
				"committee": "Komitet",
				"company": "Firma",
				"conferenceName": "Nazwa konferencji",
				"country": "Kraj",
				"court": "Sąd",
				"date": "Data",
				"dateAdded": "Data dodania",
				"dateDecided": "Data decyzji",
				"dateEnacted": "Data wydania",
				"dateModified": "Zmodyfikowany",
				"dictionaryTitle": "Tytuł słownika",
				"distributor": "Dystrybutor",
				"docketNumber": "Numer wokandy",
				"documentNumber": "Numer dokumentu",
				"DOI": "DOI",
				"edition": "Wydanie",
				"encyclopediaTitle": "Tytuł encyklopedii",
				"episodeNumber": "Numer odcinka",
				"extra": "Dodatkowe",
				"filingDate": "Data wypełnienia",
				"firstPage": "Pierwsza strona",
				"forumTitle": "Nazwa Forum/Listserv",
				"genre": "Rodzaj",
				"history": "Historia",
				"institution": "Instytucja",
				"interviewMedium": "Nośnik",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Numer",
				"issueDate": "Data wydania",
				"issuingAuthority": "Organ wydający",
				"itemType": "Typ",
				"journalAbbreviation": "Skrót czasopisma",
				"label": "Etykieta",
				"language": "Język",
				"legalStatus": "Status prawny",
				"legislativeBody": "Ciało ustawodawcze",
				"letterType": "Typ",
				"libraryCatalog": "Usługa katalogowa",
				"manuscriptType": "Typ",
				"mapType": "Typ",
				"meetingName": "Nazwa spotkania",
				"nameOfAct": "Tytuł aktu",
				"network": "Sieć",
				"number": "Numer",
				"numberOfVolumes": "Liczba tomów",
				"numPages": "Liczba stron",
				"pages": "Strony",
				"patentNumber": "Numer patentu",
				"place": "Miejsce",
				"postType": "Typ wpisu",
				"presentationType": "Typ",
				"priorityNumbers": "Numery priorytetu",
				"proceedingsTitle": "Tytuł sprawozdania",
				"programmingLanguage": "Jęz. programowania",
				"programTitle": "Tytuł programu",
				"publicationTitle": "Publikacja",
				"publicLawNumber": "Numer prawa publicznego",
				"publisher": "Wydawca",
				"references": "Referencje",
				"reporter": "Zbiór orzecznictwa",
				"reporterVolume": "Tom w zbiorze orzecznictwa",
				"reportNumber": "Numer raportu",
				"reportType": "Typ raportu",
				"rights": "Przepisy/Prawa",
				"runningTime": "Czas trwania",
				"scale": "Skala",
				"section": "Sekcja",
				"series": "Seria",
				"seriesNumber": "Numer serii",
				"seriesText": "Tekst serii",
				"seriesTitle": "Tytuł serii",
				"session": "Sesja",
				"shortTitle": "Krótki tytuł",
				"studio": "Studio",
				"subject": "Temat",
				"system": "System",
				"thesisType": "Typ",
				"title": "Tytuł",
				"university": "Uniwersytet",
				"url": "Adres URL",
				"versionNumber": "Wersja",
				"videoRecordingFormat": "Format",
				"volume": "Tom",
				"websiteTitle": "Tytuł strony",
				"websiteType": "Typ witryny"
			},
			"creatorTypes": {
				"artist": "Artysta",
				"attorneyAgent": "Adwokat/Agent",
				"author": "Autor",
				"bookAuthor": "Autor książki",
				"cartographer": "Kartograf",
				"castMember": "Aktor",
				"commenter": "Komentator",
				"composer": "Kompozytor",
				"contributor": "Współautor",
				"cosponsor": "Współsponsor",
				"counsel": "Prawnik",
				"director": "Reżyser",
				"editor": "Redaktor",
				"guest": "Gość",
				"interviewee": "Wywiad z",
				"interviewer": "Prowadzący wywiad",
				"inventor": "Wynalazca",
				"performer": "Odtwórca",
				"podcaster": "Autor podcastu",
				"presenter": "Prezenter",
				"producer": "Producent",
				"programmer": "Programista",
				"recipient": "Odbiorca",
				"reviewedAuthor": "Autor recenzji",
				"scriptwriter": "Scenarzysta",
				"seriesEditor": "Redaktor serii",
				"sponsor": "Fundator",
				"translator": "Tłumacz",
				"wordsBy": "Autor słów"
			}
		},
		"pt-BR": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Obra de arte",
				"attachment": "Anexo",
				"audioRecording": "Gravação de áudio",
				"bill": "Legislação",
				"blogPost": "Envio de blog",
				"book": "Livro",
				"bookSection": "Seção de livro",
				"case": "Caso",
				"computerProgram": "Programa",
				"conferencePaper": "Conferência",
				"dictionaryEntry": "Verbete de dicionário",
				"document": "Documento",
				"email": "Correio eletrônico",
				"encyclopediaArticle": "Verbete de enciclopédia",
				"film": "Filme",
				"forumPost": "Envio de fórum",
				"hearing": "Audiência",
				"instantMessage": "Mensagem instantânea",
				"interview": "Entrevista",
				"journalArticle": "Artigo de periódico",
				"letter": "Carta",
				"magazineArticle": "Artigo de revista",
				"manuscript": "Manuscrito",
				"map": "Mapa",
				"newspaperArticle": "Artigo de jornal",
				"note": "Nota",
				"patent": "Patente",
				"podcast": "Podcast",
				"presentation": "Apresentação",
				"radioBroadcast": "Transmissão de rádio",
				"report": "Relatório",
				"statute": "Estatuto",
				"thesis": "Tese",
				"tvBroadcast": "Transmissão de TV",
				"videoRecording": "Gravação de vídeo",
				"webpage": "Página web"
			},
			"fields": {
				"abstractNote": "Resumo",
				"accessDate": "Data de acesso",
				"applicationNumber": "Número da inscrição",
				"archive": "Arquivo",
				"archiveLocation": "Localização no arquivo",
				"artworkMedium": "Suporte",
				"artworkSize": "Dimensões",
				"assignee": "Beneficiário",
				"audioFileType": "Tipo de arquivo",
				"audioRecordingFormat": "Formato",
				"billNumber": "Número da lei",
				"blogTitle": "Título do blog",
				"bookTitle": "Título do livro",
				"callNumber": "Número de chamada",
				"caseName": "Nome do caso",
				"code": "Código",
				"codeNumber": "Número de código",
				"codePages": "Páginas do código",
				"codeVolume": "Volume do código",
				"committee": "Comitê",
				"company": "Companhia",
				"conferenceName": "Nome da conferência",
				"country": "País",
				"court": "Corte",
				"date": "Data",
				"dateAdded": "Data de adição",
				"dateDecided": "Data da decisão",
				"dateEnacted": "Data de aprovação",
				"dateModified": "Data de modificação",
				"dictionaryTitle": "Título do dicionário",
				"distributor": "Distribuidor",
				"docketNumber": "Número da ata",
				"documentNumber": "Número do documento",
				"DOI": "DOI",
				"edition": "Número da edição",
				"encyclopediaTitle": "Título da enciclopédia",
				"episodeNumber": "Número do episódio",
				"extra": "Extra",
				"filingDate": "Data de arquivamento",
				"firstPage": "Primeira página",
				"forumTitle": "Título do fórum",
				"genre": "Gênero",
				"history": "Histórico",
				"institution": "Instituição",
				"interviewMedium": "Suporte",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Edição",
				"issueDate": "Data da edição",
				"issuingAuthority": "Autoridade emissora",
				"itemType": "Tipo",
				"journalAbbreviation": "Abreviatura do periódico",
				"label": "Etiqueta",
				"language": "Idioma",
				"legalStatus": "Estatuto legal",
				"legislativeBody": "Corpo legislativo",
				"letterType": "Tipo",
				"libraryCatalog": "Catálogo de biblioteca",
				"manuscriptType": "Tipo",
				"mapType": "Tipo",
				"meetingName": "Nome do evento",
				"nameOfAct": "Nome da lei",
				"network": "Rede",
				"number": "Número",
				"numberOfVolumes": "# de volumes",
				"numPages": "# de páginas",
				"pages": "Páginas",
				"patentNumber": "Número da Patente",
				"place": "Lugar",
				"postType": "Tipo de envio",
				"presentationType": "Tipo",
				"priorityNumbers": "Números prioritários",
				"proceedingsTitle": "Título dos anais",
				"programmingLanguage": "Linguagem de programação",
				"programTitle": "Título do programa",
				"publicationTitle": "Título da publicação",
				"publicLawNumber": "Número da lei",
				"publisher": "Editor",
				"references": "Referências",
				"reporter": "Relator",
				"reporterVolume": "Volume do Reporter",
				"reportNumber": "Número do relatório",
				"reportType": "Tipo de relatório",
				"rights": "Direitos",
				"runningTime": "Tempo de execução",
				"scale": "Escala",
				"section": "Seção",
				"series": "Série",
				"seriesNumber": "Número na série",
				"seriesText": "Texto da série",
				"seriesTitle": "Título da série",
				"session": "Sessão",
				"shortTitle": "Título curto",
				"studio": "Estúdio",
				"subject": "Assunto",
				"system": "Sistema",
				"thesisType": "Tipo",
				"title": "Título",
				"university": "Universidade",
				"url": "URL",
				"versionNumber": "Versão",
				"videoRecordingFormat": "Formato",
				"volume": "Volume",
				"websiteTitle": "Título do site",
				"websiteType": "Tipo de site"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Procurador/Agente",
				"author": "Autor",
				"bookAuthor": "Autor do livro",
				"cartographer": "Cartógrafo",
				"castMember": "Membro do elenco",
				"commenter": "Comentarista",
				"composer": "Compositor",
				"contributor": "Contribuidor",
				"cosponsor": "Co-patrocinador",
				"counsel": "Conselho",
				"director": "Diretor",
				"editor": "Organizador",
				"guest": "Convidado",
				"interviewee": "Entrevista com",
				"interviewer": "Entrevistador",
				"inventor": "Inventor",
				"performer": "Ator",
				"podcaster": "Fonte do podcast",
				"presenter": "Apresentador",
				"producer": "Produtor",
				"programmer": "Programador",
				"recipient": "Destinatário",
				"reviewedAuthor": "Autor resenhado",
				"scriptwriter": "Roteirista",
				"seriesEditor": "Editor da série",
				"sponsor": "Propositor",
				"translator": "Tradutor",
				"wordsBy": "Escrito por"
			}
		},
		"pt-PT": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Obra de Arte",
				"attachment": "Anexo",
				"audioRecording": "Gravação Áudio",
				"bill": "Diploma Legal",
				"blogPost": "Entrada em Blogue",
				"book": "Livro",
				"bookSection": "Secção de Livro",
				"case": "Caso",
				"computerProgram": "Programa de Computador",
				"conferencePaper": "Artigo em Conferência",
				"dictionaryEntry": "Verbete de Dicionário",
				"document": "Documento",
				"email": "Mensagem de Correio Electrónico",
				"encyclopediaArticle": "Artigo de Enciclopédia",
				"film": "Filme",
				"forumPost": "Entrada em Fórum",
				"hearing": "Audição",
				"instantMessage": "Mensagem Instantânea",
				"interview": "Entrevista",
				"journalArticle": "Artigo em Revista Científica",
				"letter": "Carta",
				"magazineArticle": "Artigo em Revista",
				"manuscript": "Manuscrito",
				"map": "Mapa",
				"newspaperArticle": "Artigo em Jornal",
				"note": "Nota",
				"patent": "Patente",
				"podcast": "Emissão Pod",
				"presentation": "Apresentação",
				"radioBroadcast": "Emissão Radiofónica",
				"report": "Relatório",
				"statute": "Estatuto",
				"thesis": "Dissertação",
				"tvBroadcast": "Emissão Televisiva",
				"videoRecording": "Gravação Vídeo",
				"webpage": "Página Web"
			},
			"fields": {
				"abstractNote": "Resumo",
				"accessDate": "Acedido",
				"applicationNumber": "Número da Candidatura",
				"archive": "Arquivo",
				"archiveLocation": "Localização no Arquivo",
				"artworkMedium": "Suporte Artístico",
				"artworkSize": "Dimensão da Obra de Arte",
				"assignee": "Responsável",
				"audioFileType": "Tipo do Arquivo",
				"audioRecordingFormat": "Formato",
				"billNumber": "Número do Diploma Legal",
				"blogTitle": "Título do Blogue",
				"bookTitle": "Título do Livro",
				"callNumber": "Número de Chamada",
				"caseName": "Nome do Caso",
				"code": "Código",
				"codeNumber": "Número de Código",
				"codePages": "Páginas do Código",
				"codeVolume": "Volume do Código",
				"committee": "Comité",
				"company": "Empresa",
				"conferenceName": "Nome da Conferência",
				"country": "País",
				"court": "Tribunal",
				"date": "Data",
				"dateAdded": "Data de Adição",
				"dateDecided": "Data da Decisão",
				"dateEnacted": "Data de Promulgação",
				"dateModified": "Modificado",
				"dictionaryTitle": "Título do Dicionário",
				"distributor": "Distribuidor",
				"docketNumber": "Número de Expediente",
				"documentNumber": "Número do Documento",
				"DOI": "DOI",
				"edition": "Edição",
				"encyclopediaTitle": "Título da Enciclopédia",
				"episodeNumber": "Número do Episódio",
				"extra": "Extra",
				"filingDate": "Data de Arquivo",
				"firstPage": "Primeira Página",
				"forumTitle": "Título do Fórum/Lista de Correio Electrónico",
				"genre": "Género",
				"history": "História",
				"institution": "Instituição",
				"interviewMedium": "Suporte",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Número",
				"issueDate": "Data de Emissão",
				"issuingAuthority": "Autoridade Emissora",
				"itemType": "Tipo",
				"journalAbbreviation": "Abreviatura da Publicação",
				"label": "Etiqueta",
				"language": "Língua",
				"legalStatus": "Estado Legal",
				"legislativeBody": "Entidade Legislativa",
				"letterType": "Tipo",
				"libraryCatalog": "Catálogo de Biblioteca",
				"manuscriptType": "Tipo",
				"mapType": "Tipo",
				"meetingName": "Nome da Reunião",
				"nameOfAct": "Nome do Decreto",
				"network": "Rede",
				"number": "Número",
				"numberOfVolumes": "N.º de Volumes",
				"numPages": "N.º de Páginas",
				"pages": "Páginas",
				"patentNumber": "Número de Patente",
				"place": "Local",
				"postType": "Pós-Tipo",
				"presentationType": "Tipo",
				"priorityNumbers": "Números de Prioridade",
				"proceedingsTitle": "Título das Actas",
				"programmingLanguage": "Linguagem de Programação",
				"programTitle": "Título do Programa",
				"publicationTitle": "Publicação",
				"publicLawNumber": "Número da Lei Pública",
				"publisher": "Editora",
				"references": "Referências",
				"reporter": "Relator",
				"reporterVolume": "Volume do Relator",
				"reportNumber": "Número do Relatório",
				"reportType": "Tipo de Relatório",
				"rights": "Direitos",
				"runningTime": "Duração",
				"scale": "Escala",
				"section": "Secção",
				"series": "Série",
				"seriesNumber": "Número da Série",
				"seriesText": "Texto da Série",
				"seriesTitle": "Título da Série",
				"session": "Sessão",
				"shortTitle": "Título Curto",
				"studio": "Estúdio",
				"subject": "Assunto",
				"system": "Sistema",
				"thesisType": "Tipo",
				"title": "Título",
				"university": "Universidade",
				"url": "URL",
				"versionNumber": "Versão",
				"videoRecordingFormat": "Formato",
				"volume": "Volume",
				"websiteTitle": "Título da Página Web",
				"websiteType": "Tipo de Página Web"
			},
			"creatorTypes": {
				"artist": "Artista",
				"attorneyAgent": "Advogado/Agente",
				"author": "Autor",
				"bookAuthor": "Autor do Livro",
				"cartographer": "Cartógrafo",
				"castMember": "Membro do Elenco",
				"commenter": "Comentador",
				"composer": "Compositor",
				"contributor": "Colaborador",
				"cosponsor": "Co-patrocinador",
				"counsel": "Conselho",
				"director": "Realizador",
				"editor": "Editor",
				"guest": "Convidado",
				"interviewee": "Entrevista Com",
				"interviewer": "Entrevistador",
				"inventor": "Inventor",
				"performer": "Performer",
				"podcaster": "Locutor de Emissão Pod",
				"presenter": "Apresentador",
				"producer": "Produtor",
				"programmer": "Programador",
				"recipient": "Receptor",
				"reviewedAuthor": "Autor Revisto",
				"scriptwriter": "Guionista",
				"seriesEditor": "Editor da Série",
				"sponsor": "Patrocinador",
				"translator": "Tradutor",
				"wordsBy": "Texto De"
			}
		},
		"ro-RO": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Lucrare de artă",
				"attachment": "Anexă",
				"audioRecording": "Înregistrare audio",
				"bill": "Proiect de lege",
				"blogPost": "Articol blog",
				"book": "Carte",
				"bookSection": "Secțiune de carte",
				"case": "Proces",
				"computerProgram": "Software",
				"conferencePaper": "Conferință",
				"dictionaryEntry": "Articol de dicționar",
				"document": "Document",
				"email": "E-mail",
				"encyclopediaArticle": "Articol de enciclopedie",
				"film": "Film",
				"forumPost": "Comentariu forum",
				"hearing": "Audiere",
				"instantMessage": "Mesaj instantaneu",
				"interview": "Interviu",
				"journalArticle": "Articol de revistă",
				"letter": "Scrisoare",
				"magazineArticle": "Articol de revistă magazin",
				"manuscript": "Manuscris",
				"map": "Hartă",
				"newspaperArticle": "Articol de ziar",
				"note": "Notă",
				"patent": "Brevet",
				"podcast": "Multimedia",
				"presentation": "Prezentare",
				"radioBroadcast": "Emisiune radio",
				"report": "Raport",
				"statute": "Statut",
				"thesis": "Teză",
				"tvBroadcast": "Emisiune TV",
				"videoRecording": "Înregistrare video",
				"webpage": "Pagină web"
			},
			"fields": {
				"abstractNote": "Rezumat",
				"accessDate": "Data accesării",
				"applicationNumber": "Număr aplicație",
				"archive": "Arhivă",
				"archiveLocation": "Locație în arhivă",
				"artworkMedium": "Mediu",
				"artworkSize": "Mărime lucrare de artă",
				"assignee": "Reprezentant",
				"audioFileType": "Tip de fișier",
				"audioRecordingFormat": "Formatare",
				"billNumber": "Număr proiect de lege",
				"blogTitle": "Titlu blog",
				"bookTitle": "Titlu carte",
				"callNumber": "Număr de tel.",
				"caseName": "Nume proces",
				"code": "Cod",
				"codeNumber": "Număr de cod",
				"codePages": "Cod pagini",
				"codeVolume": "Cod volum",
				"committee": "Comitet",
				"company": "Companie",
				"conferenceName": "Titlu conferință",
				"country": "Țară",
				"court": "Curte",
				"date": "Dată",
				"dateAdded": "Adăugat la data",
				"dateDecided": "Data deciziei",
				"dateEnacted": "Dată decret",
				"dateModified": "Modificat",
				"dictionaryTitle": "Titlu dicționar",
				"distributor": "Distribuitor",
				"docketNumber": "Număr agendă de birou",
				"documentNumber": "Număr document",
				"DOI": "DOI",
				"edition": "Ediție",
				"encyclopediaTitle": "Titlu enciclopedie",
				"episodeNumber": "Număr episod",
				"extra": "Extra",
				"filingDate": "Data completării",
				"firstPage": "Prima pagină",
				"forumTitle": "Titlu forum",
				"genre": "Gen",
				"history": "Istorie",
				"institution": "Instituție",
				"interviewMedium": "Mediu",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Număr",
				"issueDate": "Data apariției",
				"issuingAuthority": "Autoritate emitentă",
				"itemType": "Tip",
				"journalAbbreviation": "Abreviere jurnal",
				"label": "Etichetă",
				"language": "Limbă",
				"legalStatus": "Statut legal",
				"legislativeBody": "Corp legislativ",
				"letterType": "Tip",
				"libraryCatalog": "Catalog bibliotecă",
				"manuscriptType": "Tip",
				"mapType": "Tip",
				"meetingName": "Nume de contact",
				"nameOfAct": "Nume act",
				"network": "Rețea",
				"number": "Număr",
				"numberOfVolumes": "Nr. de volume",
				"numPages": "Nr. de pagini",
				"pages": "Pagini",
				"patentNumber": "Număr brevet",
				"place": "Loc",
				"postType": "Tip de comentariu",
				"presentationType": "Tip",
				"priorityNumbers": "Numere de prioritate",
				"proceedingsTitle": "Titlu dare de seamă",
				"programmingLanguage": "Limbaj de programare",
				"programTitle": "Titlu program",
				"publicationTitle": "Publicație",
				"publicLawNumber": "Număr lege",
				"publisher": "Editură",
				"references": "Referințe",
				"reporter": "Reporter",
				"reporterVolume": "Volum reporter",
				"reportNumber": "Număr raport",
				"reportType": "Tip de raport",
				"rights": "Drepturi",
				"runningTime": "Timp de funcționare",
				"scale": "Scară",
				"section": "Secțiune",
				"series": "Colecție",
				"seriesNumber": "Număr colecție",
				"seriesText": "Text colecție",
				"seriesTitle": "Titlu colecție",
				"session": "Sesiune",
				"shortTitle": "Titlu scurt",
				"studio": "Studio",
				"subject": "Subiect",
				"system": "Sistem",
				"thesisType": "Tip",
				"title": "Titlu",
				"university": "Universitate",
				"url": "URL",
				"versionNumber": "Versiune",
				"videoRecordingFormat": "Formatare",
				"volume": "Volum",
				"websiteTitle": "Titlu site web",
				"websiteType": "Tip de site web"
			},
			"creatorTypes": {
				"artist": "Artist",
				"attorneyAgent": "Avocat/Agent",
				"author": "Autor",
				"bookAuthor": "Autor carte",
				"cartographer": "Cartograf",
				"castMember": "Membru în distribuție",
				"commenter": "Comentator",
				"composer": "Compozitor",
				"contributor": "Colaborator",
				"cosponsor": "Sponsor asociat",
				"counsel": "Consiliu",
				"director": "Director",
				"editor": "Editor (coord.)",
				"guest": "Oaspete",
				"interviewee": "Interviu cu",
				"interviewer": "Interviu de",
				"inventor": "Inventator",
				"performer": "Interpret",
				"podcaster": "Autor multimedia",
				"presenter": "Prezentator",
				"producer": "Producător",
				"programmer": "Programator",
				"recipient": "Recipient",
				"reviewedAuthor": "Autor recenzat",
				"scriptwriter": "Scriitor (de mână)",
				"seriesEditor": "Coordonator colecție",
				"sponsor": "Sponsor",
				"translator": "Traducător",
				"wordsBy": "Cuvinte de"
			}
		},
		"ru-RU": {
			"itemTypes": {
				"annotation": "Аннотация",
				"artwork": "Художественная работа",
				"attachment": "Вложение",
				"audioRecording": "Звукозапись",
				"bill": "Законопроект",
				"blogPost": "Сообщение в блоге",
				"book": "Книга",
				"bookSection": "Раздел книги",
				"case": "Дело",
				"computerProgram": "Компьютерная программа",
				"conferencePaper": "Документ конференции",
				"dictionaryEntry": "Словарная статья",
				"document": "Документ",
				"email": "Электронная почта",
				"encyclopediaArticle": "Статья из энциклопедии",
				"film": "Фильм",
				"forumPost": "Сообщение на форуме",
				"hearing": "Слушание",
				"instantMessage": "Мгновенное сообщение",
				"interview": "Интервью",
				"journalArticle": "Статья из рецензируемого журнала",
				"letter": "Письмо",
				"magazineArticle": "Статья из прочей периодики",
				"manuscript": "Рукопись",
				"map": "Карта",
				"newspaperArticle": "Газетная статья",
				"note": "Заметка",
				"patent": "Патент",
				"podcast": "Подкаст",
				"presentation": "Презентация",
				"radioBroadcast": "Радиопередача",
				"report": "Отчет",
				"statute": "Норм. прав. акт",
				"thesis": "Диссертация",
				"tvBroadcast": "Телепередача",
				"videoRecording": "Видеозапись",
				"webpage": "Веб-страница"
			},
			"fields": {
				"abstractNote": "Аннотация",
				"accessDate": "Дата доступа",
				"applicationNumber": "Номер заявки",
				"archive": "Архив",
				"archiveLocation": "Место в архиве",
				"artworkMedium": "Худож. средство",
				"artworkSize": "Размер работы",
				"assignee": "Представитель",
				"audioFileType": "Тип файла",
				"audioRecordingFormat": "Формат",
				"billNumber": "Номер законопр.",
				"blogTitle": "Название блога",
				"bookTitle": "Название книги",
				"callNumber": "Шифр (номер вызова)",
				"caseName": "Номер дела",
				"code": "Кодекс/сборник",
				"codeNumber": "Том кодекса",
				"codePages": "Страницы кодекса",
				"codeVolume": "Том кодекса",
				"committee": "Комитет",
				"company": "Компания",
				"conferenceName": "Назв. конфер.",
				"country": "Страна",
				"court": "Суд",
				"date": "Дата",
				"dateAdded": "Добавлен",
				"dateDecided": "Дата решения",
				"dateEnacted": "Дата акта",
				"dateModified": "Изменён",
				"dictionaryTitle": "Назв. словаря",
				"distributor": "Распределитель",
				"docketNumber": "Номер выписки",
				"documentNumber": "Номер документа",
				"DOI": "ЦИО/DOI",
				"edition": "Издание",
				"encyclopediaTitle": "Назв. энцикл.",
				"episodeNumber": "Номер эпизода",
				"extra": "Дополнительно",
				"filingDate": "Дата заявки",
				"firstPage": "Первая стр.",
				"forumTitle": "Форум/Listserv",
				"genre": "Жанр",
				"history": "История",
				"institution": "Учреждение",
				"interviewMedium": "Средство",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Выпуск",
				"issueDate": "Дата выпуска",
				"issuingAuthority": "Кем выдан",
				"itemType": "Тип",
				"journalAbbreviation": "Сокращ. журнала",
				"label": "Надпись",
				"language": "Язык",
				"legalStatus": "Правовой статус",
				"legislativeBody": "Законод. орган",
				"letterType": "Тип письма",
				"libraryCatalog": "Библ. каталог",
				"manuscriptType": "Тип рукописи",
				"mapType": "Тип карты",
				"meetingName": "Назв. встречи",
				"nameOfAct": "Назв. акта.",
				"network": "Сеть",
				"number": "Номер",
				"numberOfVolumes": "Кол-во томов",
				"numPages": "Число страниц",
				"pages": "Страницы",
				"patentNumber": "Номер патента",
				"place": "Место",
				"postType": "Тип сообщения",
				"presentationType": "Тип",
				"priorityNumbers": "Номера приоритетов",
				"proceedingsTitle": "Назв. трудов",
				"programmingLanguage": "Прогр. яз.",
				"programTitle": "Назв. программы",
				"publicationTitle": "Заголовок публикации",
				"publicLawNumber": "Номер акта",
				"publisher": "Издатель",
				"references": "Ссылки",
				"reporter": "Сборник суд. реш.",
				"reporterVolume": "Том отчета",
				"reportNumber": "Номер отчета",
				"reportType": "Тип отчета",
				"rights": "Права",
				"runningTime": "Продолжит.",
				"scale": "Масштаб",
				"section": "Раздел",
				"series": "Серия",
				"seriesNumber": "Номер в серии",
				"seriesText": "Текст серии",
				"seriesTitle": "Название серии",
				"session": "Сессия",
				"shortTitle": "Краткое назв.",
				"studio": "Студия",
				"subject": "Тема",
				"system": "Система",
				"thesisType": "Тип",
				"title": "Название",
				"university": "Университет",
				"url": "URL-адрес",
				"versionNumber": "Версия",
				"videoRecordingFormat": "Формат",
				"volume": "Том",
				"websiteTitle": "Назв. веб-сайта",
				"websiteType": "Тип веб-сайта"
			},
			"creatorTypes": {
				"artist": "Художник",
				"attorneyAgent": "Адвокат/Агент",
				"author": "Автор",
				"bookAuthor": "Автор книги",
				"cartographer": "Картограф",
				"castMember": "Актер",
				"commenter": "Комментатор",
				"composer": "Композитор",
				"contributor": "Соавтор",
				"cosponsor": "Совм. спонсор",
				"counsel": "Советник",
				"director": "Режиссер",
				"editor": "Редактор",
				"guest": "Гость",
				"interviewee": "Интервью с",
				"interviewer": "Интервьюер",
				"inventor": "Изобретатель",
				"performer": "Исполнитель",
				"podcaster": "Подкастер",
				"presenter": "Докладчик",
				"producer": "Продюсер",
				"programmer": "Программист",
				"recipient": "Получатель",
				"reviewedAuthor": "Реценз. автор",
				"scriptwriter": "Сценарист",
				"seriesEditor": "Редактор серии",
				"sponsor": "Спонсор",
				"translator": "Переводчик",
				"wordsBy": "Автор слов"
			}
		},
		"sk-SK": {
			"itemTypes": {
				"annotation": "Anotácia",
				"artwork": "Umelecké dielo",
				"attachment": "Príloha",
				"audioRecording": "Audionahrávka",
				"bill": "Legislatívny dokument",
				"blogPost": "Príspevok na blogu",
				"book": "Kniha",
				"bookSection": "Časť knihy",
				"case": "Prípad (súdny)",
				"computerProgram": "Počítačový program",
				"conferencePaper": "Príspevok na konferenciu",
				"dictionaryEntry": "Heslo v slovníku",
				"document": "Dokument",
				"email": "E-mail",
				"encyclopediaArticle": "Článok v encyklopédii",
				"film": "Film",
				"forumPost": "Príspevok do fóra",
				"hearing": "Výsluch (konanie)",
				"instantMessage": "Chatová správa",
				"interview": "Osobná komunikácia",
				"journalArticle": "Článok v odbornom časopise",
				"letter": "List",
				"magazineArticle": "Článok v populárnom časopise",
				"manuscript": "Rukopis",
				"map": "Mapa",
				"newspaperArticle": "Článok v novinách",
				"note": "Poznámka",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Prezentácia",
				"radioBroadcast": "Rádio",
				"report": "Správa",
				"statute": "Nariadenie",
				"thesis": "Záverečná práca",
				"tvBroadcast": "Televízne vysielanie",
				"videoRecording": "Videonahrávka",
				"webpage": "Webová stránka"
			},
			"fields": {
				"abstractNote": "Abstrakt",
				"accessDate": "Citované",
				"applicationNumber": "Číslo prihlášky",
				"archive": "Archív",
				"archiveLocation": "Lokácia",
				"artworkMedium": "Médium",
				"artworkSize": "Rozmery diela",
				"assignee": "Prihlasovateľ",
				"audioFileType": "Typ súboru",
				"audioRecordingFormat": "Formát",
				"billNumber": "Číslo",
				"blogTitle": "Názov blogu",
				"bookTitle": "Názov knihy",
				"callNumber": "Signatúra",
				"caseName": "Názov prípadu",
				"code": "Zákonník",
				"codeNumber": "Kódové číslo",
				"codePages": "Strany",
				"codeVolume": "Ročník",
				"committee": "Výbor/porota",
				"company": "Spoločnosť",
				"conferenceName": "Názov konferencie",
				"country": "Štát",
				"court": "Súd",
				"date": "Dátum",
				"dateAdded": "Pridané",
				"dateDecided": "Dátum rozhodnutia",
				"dateEnacted": "D. vstúp. do platnosti",
				"dateModified": "Zmenené",
				"dictionaryTitle": "Názov slovníka",
				"distributor": "Distribútor",
				"docketNumber": "Číslo konania",
				"documentNumber": "Číslo dokumentu",
				"DOI": "DOI",
				"edition": "Vydanie",
				"encyclopediaTitle": "Názov encyklopédie",
				"episodeNumber": "Číslo epizódy",
				"extra": "Extra",
				"filingDate": "Dátum zápisu",
				"firstPage": "Prvá strana",
				"forumTitle": "Názov fóra/disk. sk.",
				"genre": "Žáner",
				"history": "História",
				"institution": "Inštitúcia",
				"interviewMedium": "Médium",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Číslo",
				"issueDate": "Dátum vydania",
				"issuingAuthority": "Vydávajúci úrad",
				"itemType": "Typ",
				"journalAbbreviation": "Skratka časopisu",
				"label": "Vydavateľstvo",
				"language": "Jazyk",
				"legalStatus": "Právny status",
				"legislativeBody": "Legislatívny orgán",
				"letterType": "Druh listu",
				"libraryCatalog": "Knižničný katalóg",
				"manuscriptType": "Druh rukopisu",
				"mapType": "Druh mapy",
				"meetingName": "Názov stretnutia",
				"nameOfAct": "Názov zákona",
				"network": "Sieť",
				"number": "Číslo",
				"numberOfVolumes": "Počet zväzkov",
				"numPages": "Počet strán",
				"pages": "Strany",
				"patentNumber": "Číslo patentu",
				"place": "Miesto",
				"postType": "Druh príspevku",
				"presentationType": "Typ prezentácie",
				"priorityNumbers": "Čísla priority",
				"proceedingsTitle": "Názov zborníka",
				"programmingLanguage": "Program. jazyk",
				"programTitle": "Názov programu",
				"publicationTitle": "Názov publikácie",
				"publicLawNumber": "Číslo zákona",
				"publisher": "Vydavateľ",
				"references": "Odkazy",
				"reporter": "Zbierka súd. rozhodnutí",
				"reporterVolume": "Ročník",
				"reportNumber": "Číslo správy",
				"reportType": "Druh správy",
				"rights": "Práva",
				"runningTime": "Dĺžka",
				"scale": "Mierka",
				"section": "Sekcia",
				"series": "Edícia",
				"seriesNumber": "Číslo edície",
				"seriesText": "Text edície",
				"seriesTitle": "Názov edície",
				"session": "Zasadnutie",
				"shortTitle": "Krátky názov",
				"studio": "Štúdio",
				"subject": "Predmet",
				"system": "Operačný systém",
				"thesisType": "Druh záv. práce",
				"title": "Názov",
				"university": "Univerzita",
				"url": "URL",
				"versionNumber": "Verzia",
				"videoRecordingFormat": "Formát",
				"volume": "Zväzok",
				"websiteTitle": "Názov stránky",
				"websiteType": "Druh sídla"
			},
			"creatorTypes": {
				"artist": "Umelec",
				"attorneyAgent": "Advokát/Zástupca",
				"author": "Autor",
				"bookAuthor": "Autor knihy",
				"cartographer": "Kartograf",
				"castMember": "Účinkujúci",
				"commenter": "Komentátor",
				"composer": "Skladateľ",
				"contributor": "Prispievateľ",
				"cosponsor": "Spolusponzor",
				"counsel": "Právny zástupca",
				"director": "Režisér",
				"editor": "Zostavovateľ",
				"guest": "Hosť",
				"interviewee": "Rozhovor s",
				"interviewer": "Spytujúci sa",
				"inventor": "Vynálezca",
				"performer": "Interpret",
				"podcaster": "Autor podcastu",
				"presenter": "Prezentujúci",
				"producer": "Producent",
				"programmer": "Programátor",
				"recipient": "Príjemca",
				"reviewedAuthor": "Recenzent",
				"scriptwriter": "Scenárista",
				"seriesEditor": "Zostavovateľ edície",
				"sponsor": "Navrhovateľ",
				"translator": "Prekladateľ",
				"wordsBy": "Autor textu"
			}
		},
		"sl-SI": {
			"itemTypes": {
				"annotation": "Zaznamek",
				"artwork": "Umetniško delo",
				"attachment": "Priponka",
				"audioRecording": "Zvočni posnetek",
				"bill": "Gledališki list",
				"blogPost": "Objava na blogu",
				"book": "Knjiga",
				"bookSection": "Odsek knjige",
				"case": "Primer",
				"computerProgram": "Programje",
				"conferencePaper": "Konferenčni članek",
				"dictionaryEntry": "Slovarski vnos",
				"document": "Dokument",
				"email": "E-pismo",
				"encyclopediaArticle": "Enciklopedični članek",
				"film": "Film",
				"forumPost": "Objava na forumu",
				"hearing": "Zaslišanje",
				"instantMessage": "Neposredno sporočilo",
				"interview": "Intervju",
				"journalArticle": "Strokovni članek",
				"letter": "Pismo",
				"magazineArticle": "Revijalni članek",
				"manuscript": "Rokopis",
				"map": "Zemljevid",
				"newspaperArticle": "Časopisni članek",
				"note": "Opomba",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Predstavitev",
				"radioBroadcast": "Radijska oddaja",
				"report": "Poročilo",
				"statute": "Statut",
				"thesis": "Teza",
				"tvBroadcast": "TV oddaja",
				"videoRecording": "Videoposnetek",
				"webpage": "Spletna stran"
			},
			"fields": {
				"abstractNote": "Povzetek",
				"accessDate": "Dostopano",
				"applicationNumber": "Številka vloge",
				"archive": "Arhiv",
				"archiveLocation": "Mesto v arhivu",
				"artworkMedium": "Medij",
				"artworkSize": "Velikost umetniškega dela",
				"assignee": "Dodeljeni",
				"audioFileType": "Vrsta datoteke",
				"audioRecordingFormat": "Zapis",
				"billNumber": "Številka računa",
				"blogTitle": "Naslov bloga",
				"bookTitle": "Naslov knjige",
				"callNumber": "Številka klica",
				"caseName": "Ime primera",
				"code": "Koda",
				"codeNumber": "Številka kode",
				"codePages": "Strani kode",
				"codeVolume": "Zbirka kode",
				"committee": "Odbor",
				"company": "Družba",
				"conferenceName": "Ime konference",
				"country": "Država",
				"court": "Sodišče",
				"date": "Datum",
				"dateAdded": "Dodano dne",
				"dateDecided": "Datum odločbe",
				"dateEnacted": "Datum uveljavitve",
				"dateModified": "Spremenjeno",
				"dictionaryTitle": "Naslov slovarja",
				"distributor": "Distributer",
				"docketNumber": "Seznamska številka",
				"documentNumber": "Številka dokumenta",
				"DOI": "DOI",
				"edition": "Edicija",
				"encyclopediaTitle": "Naslov enciklopedije",
				"episodeNumber": "Številka epizode",
				"extra": "Dodatno",
				"filingDate": "Datum vknjižbe",
				"firstPage": "Naslovnica",
				"forumTitle": "Naslov foruma",
				"genre": "Žanr",
				"history": "Zgodovina",
				"institution": "Ustanova",
				"interviewMedium": "Medij",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Številka",
				"issueDate": "Datum objave",
				"issuingAuthority": "Izdajatelj",
				"itemType": "Vrsta",
				"journalAbbreviation": "Okraj. revije",
				"label": "Vrsta",
				"language": "Jezik",
				"legalStatus": "Pravni status",
				"legislativeBody": "Zakonodajno telo",
				"letterType": "Vrsta",
				"libraryCatalog": "Knjižnični katalog",
				"manuscriptType": "Vrsta",
				"mapType": "Vrsta",
				"meetingName": "Ime srečanja",
				"nameOfAct": "Ime akta",
				"network": "TV postaja",
				"number": "Številka",
				"numberOfVolumes": "Št. letnikov",
				"numPages": "Št. strani",
				"pages": "Strani",
				"patentNumber": "Številka patenta",
				"place": "Kraj",
				"postType": "Vrsta objave",
				"presentationType": "Vrsta",
				"priorityNumbers": "Številke prioritete",
				"proceedingsTitle": "Naslov zapisnika razprave",
				"programmingLanguage": "Programski jezik",
				"programTitle": "Naslov programa",
				"publicationTitle": "Publikacija",
				"publicLawNumber": "Javna pravna številka",
				"publisher": "Izdajatelj",
				"references": "Sklici",
				"reporter": "Poročevalec",
				"reporterVolume": "Zbirka poročil",
				"reportNumber": "Številka poročila",
				"reportType": "Vrsta poročila",
				"rights": "Pravice",
				"runningTime": "Dolžina",
				"scale": "Merilo",
				"section": "Odsek",
				"series": "Zbirka",
				"seriesNumber": "Številka zbirke",
				"seriesText": "Besedilo zbirke",
				"seriesTitle": "Naslov zbirke",
				"session": "Zasedanje",
				"shortTitle": "Kratki naslov",
				"studio": "Studio",
				"subject": "Zadeva",
				"system": "Sistem",
				"thesisType": "Vrsta",
				"title": "Naslov",
				"university": "Univerza",
				"url": "URL",
				"versionNumber": "Različica",
				"videoRecordingFormat": "Zapis",
				"volume": "Letnik",
				"websiteTitle": "Naslov spletne strani",
				"websiteType": "Vrsta spletnega mesta"
			},
			"creatorTypes": {
				"artist": "Umetnik",
				"attorneyAgent": "Odvetnik/agent",
				"author": "Avtor",
				"bookAuthor": "Avtor knjige",
				"cartographer": "Kartograf",
				"castMember": "Nastopajoči",
				"commenter": "Komentator",
				"composer": "Skladatelj",
				"contributor": "Avtor prispevka",
				"cosponsor": "Sosponzor",
				"counsel": "Odvetnik",
				"director": "Režiser",
				"editor": "Urednik",
				"guest": "Gost",
				"interviewee": "Intervju z",
				"interviewer": "Intervju opravil",
				"inventor": "Izumitelj",
				"performer": "Izvajalec",
				"podcaster": "Avtor podcasta",
				"presenter": "Predstavitelj",
				"producer": "Producent",
				"programmer": "Programer",
				"recipient": "Prejemnik",
				"reviewedAuthor": "Ocenjeni avtor",
				"scriptwriter": "Scenarist",
				"seriesEditor": "Urednik zbirke",
				"sponsor": "Sponzor",
				"translator": "Prevajalec",
				"wordsBy": "Pisec besedila"
			}
		},
		"sr-RS": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Уметничко дело",
				"attachment": "Прилог",
				"audioRecording": "Звучни снимак",
				"bill": "Рачун",
				"blogPost": "Блог порука",
				"book": "Књига",
				"bookSection": "Поглавље у књизи",
				"case": "Случај",
				"computerProgram": "Software",
				"conferencePaper": "Папир са конференције",
				"dictionaryEntry": "Унос из речника",
				"document": "Документ",
				"email": "Е-пошта",
				"encyclopediaArticle": "Чланак из енциклопедије",
				"film": "Филм",
				"forumPost": "Порука на форуму",
				"hearing": "Саслушање",
				"instantMessage": "Брза порука",
				"interview": "Интервју",
				"journalArticle": "Чланак у часопису",
				"letter": "Писмо",
				"magazineArticle": "Чланак у магазину",
				"manuscript": "Рукопис",
				"map": "Мапа",
				"newspaperArticle": "Чланак из новина",
				"note": "Белешка",
				"patent": "Патент",
				"podcast": "Подкаст",
				"presentation": "Презентација",
				"radioBroadcast": "Радио пренос",
				"report": "Извештај",
				"statute": "Уредба",
				"thesis": "Теза",
				"tvBroadcast": "ТВ пренос",
				"videoRecording": "Видео снимак",
				"webpage": "Веб страница"
			},
			"fields": {
				"abstractNote": "Сажетак",
				"accessDate": "Приступљено",
				"applicationNumber": "Број апликације",
				"archive": "Архива",
				"archiveLocation": "Место у архиви",
				"artworkMedium": "Медијум",
				"artworkSize": "Величина уметничког дела",
				"assignee": "Пуномоћник",
				"audioFileType": "Врста датотеке",
				"audioRecordingFormat": "Формат",
				"billNumber": "Број рачуна",
				"blogTitle": "Наслов блога",
				"bookTitle": "Наслов књиге",
				"callNumber": "Сигнатура",
				"caseName": "Број случаја",
				"code": "Код",
				"codeNumber": "Број кода",
				"codePages": "Код страница",
				"codeVolume": "Код тома",
				"committee": "Комисија",
				"company": "Предузеће",
				"conferenceName": "Име конференције",
				"country": "Држава",
				"court": "Суд",
				"date": "Датум",
				"dateAdded": "Датум додавања",
				"dateDecided": "Датум одлуке",
				"dateEnacted": "Датум озакоњена",
				"dateModified": "Датум измене",
				"dictionaryTitle": "Наслов речника",
				"distributor": "Дистрибутер",
				"docketNumber": "Docket Number",
				"documentNumber": "Број документа",
				"DOI": "ДОИ",
				"edition": "Едиција",
				"encyclopediaTitle": "Наслов енциклопедије",
				"episodeNumber": "Број епизоде",
				"extra": "Додатни подаци",
				"filingDate": "Filing Date",
				"firstPage": "Прва страница",
				"forumTitle": "Наслов форума или listserv-а",
				"genre": "Жанр",
				"history": "Историја",
				"institution": "Институција",
				"interviewMedium": "Медијум",
				"ISBN": "ИСБН",
				"ISSN": "ИССН",
				"issue": "Брoj издања",
				"issueDate": "Датум издања",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Врста",
				"journalAbbreviation": "Скраћеница часописа",
				"label": "Ознака",
				"language": "Језик",
				"legalStatus": "Правни статус",
				"legislativeBody": "Законодавно тело",
				"letterType": "Врста",
				"libraryCatalog": "Каталог библиотеке",
				"manuscriptType": "Врста",
				"mapType": "Врста",
				"meetingName": "Име састанка",
				"nameOfAct": "Име указа",
				"network": "Мрежа",
				"number": "Број",
				"numberOfVolumes": "Бр. томова",
				"numPages": "Брoj страница",
				"pages": "Странице",
				"patentNumber": "Број патента",
				"place": "Место",
				"postType": "Врста поруке",
				"presentationType": "Врста",
				"priorityNumbers": "Бројеви приоритета",
				"proceedingsTitle": "Наслов зборника",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Назив програма",
				"publicationTitle": "Издање",
				"publicLawNumber": "Број јавног закона",
				"publisher": "Издавач",
				"references": "Референце",
				"reporter": "Известилац",
				"reporterVolume": "Том известиоца",
				"reportNumber": "Број извештаја",
				"reportType": "Врста извештаја",
				"rights": "Права",
				"runningTime": "Дужина трајања",
				"scale": "Опсег",
				"section": "Секција",
				"series": "Серије",
				"seriesNumber": "Број серије",
				"seriesText": "Текст серије",
				"seriesTitle": "Наслов серије",
				"session": "Сесија",
				"shortTitle": "Скраћени наслов",
				"studio": "Студио",
				"subject": "Субјекат",
				"system": "Систем",
				"thesisType": "Врста",
				"title": "Наслов",
				"university": "Универзитет",
				"url": "УРЛ",
				"versionNumber": "Version",
				"videoRecordingFormat": "Формат",
				"volume": "Том",
				"websiteTitle": "Наслов веб странице",
				"websiteType": "Врста веб места"
			},
			"creatorTypes": {
				"artist": "Уметник",
				"attorneyAgent": "Адвокат/Представник",
				"author": "Аутор",
				"bookAuthor": "Аутор књиге",
				"cartographer": "Картограф",
				"castMember": "Глумац",
				"commenter": "Коментатор",
				"composer": "Композитор",
				"contributor": "Сарадник",
				"cosponsor": "Cosponsor",
				"counsel": "Савет",
				"director": "Директор",
				"editor": "Уредник",
				"guest": "Гост",
				"interviewee": "Разговор са",
				"interviewer": "Водич интервјуа",
				"inventor": "Проналазач",
				"performer": "Извођач",
				"podcaster": "Подкастер",
				"presenter": "Презентер",
				"producer": "Продуцент",
				"programmer": "Програмер",
				"recipient": "Прималац",
				"reviewedAuthor": "Оцењени аутор",
				"scriptwriter": "Сценариста",
				"seriesEditor": "Уредник серије",
				"sponsor": "Спонзор",
				"translator": "Преводилац",
				"wordsBy": "Речи написао"
			}
		},
		"sv-SE": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Konstverk",
				"attachment": "Bilaga",
				"audioRecording": "Ljudinspelning",
				"bill": "Lagförarbete",
				"blogPost": "Blogginlägg",
				"book": "Bok",
				"bookSection": "Bokavsnitt",
				"case": "Rättsfall",
				"computerProgram": "Programvara",
				"conferencePaper": "Konferensartikel",
				"dictionaryEntry": "Uppslag i ordbok",
				"document": "Dokument",
				"email": "E-postbrev",
				"encyclopediaArticle": "Artikel i uppslagsverk",
				"film": "Film",
				"forumPost": "Foruminlägg",
				"hearing": "Offentlig utfrågning",
				"instantMessage": "Chattmeddelande",
				"interview": "Intervju",
				"journalArticle": "Tidskriftsartikel",
				"letter": "Brev",
				"magazineArticle": "Magasinsartikel",
				"manuscript": "Manuskript",
				"map": "Karta",
				"newspaperArticle": "Dagstidningsartikel",
				"note": "Anteckning",
				"patent": "Patent",
				"podcast": "Poddsändning",
				"presentation": "Presentation",
				"radioBroadcast": "Radiosändning",
				"report": "Rapport",
				"statute": "Författning",
				"thesis": "Uppsats",
				"tvBroadcast": "TV-sändning",
				"videoRecording": "Videoinspelning",
				"webpage": "Webbsida"
			},
			"fields": {
				"abstractNote": "Sammanfattning",
				"accessDate": "Hämtad den",
				"applicationNumber": "Anmälningsnummer",
				"archive": "Arkiv",
				"archiveLocation": "Plats i arkiv",
				"artworkMedium": "Medium för konstverk",
				"artworkSize": "Storlek på konstverk",
				"assignee": "Representant",
				"audioFileType": "Filtyp",
				"audioRecordingFormat": "Format",
				"billNumber": "Förarbetets ref. nr.",
				"blogTitle": "Bloggnamn",
				"bookTitle": "Boktitel",
				"callNumber": "Hyllkod",
				"caseName": "Rättsfallsnamn",
				"code": "Författningssamling",
				"codeNumber": "Författningsnummer",
				"codePages": "Sidkod",
				"codeVolume": "Lagband",
				"committee": "Kommitté",
				"company": "Företag",
				"conferenceName": "Namn på konferens",
				"country": "Land",
				"court": "Domstol",
				"date": "Datum",
				"dateAdded": "Tillagd den",
				"dateDecided": "Avgörandedatum",
				"dateEnacted": "Datum för ikraftträdande",
				"dateModified": "Ändrad den",
				"dictionaryTitle": "Ordbokstitel",
				"distributor": "Distributör",
				"docketNumber": "Målnummer",
				"documentNumber": "Dokumentnummer",
				"DOI": "DOI",
				"edition": "Upplaga",
				"encyclopediaTitle": "Uppslagsverkstitel",
				"episodeNumber": "Avsnittsnummer",
				"extra": "Extra",
				"filingDate": "Ansökningsdatum",
				"firstPage": "Första sida",
				"forumTitle": "Titel på forum/listserver",
				"genre": "Genre",
				"history": "Historia",
				"institution": "Institution",
				"interviewMedium": "Media",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Nummer",
				"issueDate": "Utgivningsdatum",
				"issuingAuthority": "Utgivare",
				"itemType": "Typ",
				"journalAbbreviation": "Tidskriftsförkortning",
				"label": "Stämpel",
				"language": "Språk",
				"legalStatus": "Rättslig status",
				"legislativeBody": "Lagstiftande organ",
				"letterType": "Brevtyp",
				"libraryCatalog": "Bibliotekskatalog",
				"manuscriptType": "Manustyp",
				"mapType": "Karttyp",
				"meetingName": "Namn på möte",
				"nameOfAct": "Författningens namn",
				"network": "Nätverk",
				"number": "Nummer",
				"numberOfVolumes": "# volymer",
				"numPages": "# sidor",
				"pages": "Sidor",
				"patentNumber": "Patentnummer",
				"place": "Ort",
				"postType": "Källtyp",
				"presentationType": "Presentationstyp",
				"priorityNumbers": "Prioritetsnummer",
				"proceedingsTitle": "Protokolltitel",
				"programmingLanguage": "Prog. språk",
				"programTitle": "Programtitel",
				"publicationTitle": "Publikation",
				"publicLawNumber": "Public Law Number",
				"publisher": "Utgivare",
				"references": "Källhänvisningar",
				"reporter": "Referatsamling",
				"reporterVolume": "Referattyp",
				"reportNumber": "Rapportnummer",
				"reportType": "Rapporttyp",
				"rights": "Rättigheter",
				"runningTime": "Inspelningslängd",
				"scale": "Skala",
				"section": "Avsnitt",
				"series": "Bokserie",
				"seriesNumber": "Nummer i bokserie",
				"seriesText": "Bokseries text",
				"seriesTitle": "Titel på bokserie",
				"session": "Session",
				"shortTitle": "Kort titel",
				"studio": "Studio",
				"subject": "Ämne",
				"system": "System",
				"thesisType": "Uppsatstyp",
				"title": "Titel",
				"university": "Lärosäte",
				"url": "Webbadress",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Band/Årgång",
				"websiteTitle": "Titel på webbplats",
				"websiteType": "Webbplatstyp"
			},
			"creatorTypes": {
				"artist": "Konstnär",
				"attorneyAgent": "Ombud/Agent",
				"author": "Författare",
				"bookAuthor": "Bokförfattare",
				"cartographer": "Kartograf",
				"castMember": "Skådespelare",
				"commenter": "Kommentator",
				"composer": "Kompositör",
				"contributor": "Medarbetare",
				"cosponsor": "Medsponsor",
				"counsel": "Handledare",
				"director": "Regissör",
				"editor": "Redaktör",
				"guest": "Gäst",
				"interviewee": "Intervju med",
				"interviewer": "Intervjuare",
				"inventor": "Uppfinnare",
				"performer": "Artist",
				"podcaster": "Poddsändare",
				"presenter": "Presentatör",
				"producer": "Producent",
				"programmer": "Programmerare",
				"recipient": "Mottagare",
				"reviewedAuthor": "Recenserad författare",
				"scriptwriter": "Manusförfattare",
				"seriesEditor": "Redaktör för bokserie",
				"sponsor": "Förslagsläggare",
				"translator": "Översättare",
				"wordsBy": "Text av"
			}
		},
		"th-TH": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "งานศิลป์",
				"attachment": "แฟ้มแนบ",
				"audioRecording": "โสตวัสดุ",
				"bill": "เอกสารกฎหมาย",
				"blogPost": "บทความบล็อก",
				"book": "หนังสือ",
				"bookSection": "บทหนึ่งในหนังสือ",
				"case": "เหตุการณ์/คดี",
				"computerProgram": "Software",
				"conferencePaper": "เอกสารประชุมวิชาการ",
				"dictionaryEntry": "พจนานุกรม",
				"document": "เอกสาร",
				"email": "อีเมล",
				"encyclopediaArticle": "บทความสารานุกรม",
				"film": "ภาพยนตร์",
				"forumPost": "ข้อความในฟอรั่ม",
				"hearing": "การฟังความคิดเห็น",
				"instantMessage": "ข้อความด่วน",
				"interview": "บทสัมภาษณ์",
				"journalArticle": "บทความวารสาร",
				"letter": "จดหมาย",
				"magazineArticle": "บทความนิตยสาร",
				"manuscript": "เอกสารต้นฉบับ",
				"map": "แผนที่",
				"newspaperArticle": "บทความหนังสือพิมพ์",
				"note": "บันทึก",
				"patent": "สิทธิบัตร",
				"podcast": "พอดคาสต์",
				"presentation": "เอกสารการนำเสนอ",
				"radioBroadcast": "รายการวิทยุ",
				"report": "รายงาน",
				"statute": "บัญญัติ",
				"thesis": "วิทยานิพนธ์",
				"tvBroadcast": "รายการโทรทัศน์",
				"videoRecording": "วีดิทัศน์",
				"webpage": "หน้าเว็บ"
			},
			"fields": {
				"abstractNote": "บทคัดย่อ",
				"accessDate": "สืบค้นเมื่อ",
				"applicationNumber": "หมายเลขคำขอ",
				"archive": "เอกสารสำคัญ",
				"archiveLocation": "ที่เก็บเอกสารสำคัญ",
				"artworkMedium": "สื่อ",
				"artworkSize": "ขนาดอาร์ตเวิร์ก",
				"assignee": "ผู้รับโอนสิทธิ์",
				"audioFileType": "ประเภทแฟ้ม",
				"audioRecordingFormat": "รูปแบบ",
				"billNumber": "หมายเลขเอกสารกฎหมาย",
				"blogTitle": "ชื่อบล็อก",
				"bookTitle": "ชื่อหนังสือ",
				"callNumber": "หมายเลขหิ้งหนังสือ",
				"caseName": "หมายเลขคดี",
				"code": "ประมวลกฎหมาย",
				"codeNumber": "หมายเลขประมวลกฎหมาย",
				"codePages": "เลขหน้าประมวลกฎหมาย",
				"codeVolume": "หมวดประมวลกฎหมาย",
				"committee": "คณะกรรมการ",
				"company": "บริษัท",
				"conferenceName": "ชื่อการประชุม",
				"country": "ประเทศ",
				"court": "ศาล",
				"date": "วันที่",
				"dateAdded": "วันที่เพิ่ม",
				"dateDecided": "วันที่พิจารณา",
				"dateEnacted": "วันที่ออกกฎหมาย",
				"dateModified": "แก้ไข",
				"dictionaryTitle": "ชื่อพจนานุกรม",
				"distributor": "ผู้แจกจ่าย",
				"docketNumber": "หมายเลขคำพิพากษา",
				"documentNumber": "หมายเลขเอกสาร",
				"DOI": "DOI",
				"edition": "ครั้งที่พิมพ์",
				"encyclopediaTitle": "ชื่อสารานุกรม",
				"episodeNumber": "ตอนที่",
				"extra": "สิ่งที่เพิ่มเติม",
				"filingDate": "วันที่เข้าแฟ้ม",
				"firstPage": "หน้าแรก",
				"forumTitle": "ชื่อฟอรั่ม",
				"genre": "ประเภท",
				"history": "ประวัติ",
				"institution": "สถาบัน",
				"interviewMedium": "สื่อ",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "ฉบับที่",
				"issueDate": "วันที่ออกหนังสือ",
				"issuingAuthority": "อำนาจการออกเอกสาร",
				"itemType": "ประเภท",
				"journalAbbreviation": "ชื่อย่อวารสาร",
				"label": "ป้าย",
				"language": "ภาษา",
				"legalStatus": "สถานภาพตามกฎหมาย",
				"legislativeBody": "สภานิติบัญญัติ",
				"letterType": "ประเภท",
				"libraryCatalog": "ฐานข้อมูลห้องสมุด",
				"manuscriptType": "ประเภท",
				"mapType": "ประเภท",
				"meetingName": "ชื่อการประชุม",
				"nameOfAct": "ชื่อพ.ร.บ.",
				"network": "เครือข่าย",
				"number": "หมายเลข",
				"numberOfVolumes": "จำนวนเล่ม",
				"numPages": "จำนวนหน้า",
				"pages": "เลขหน้า",
				"patentNumber": "หมายเลขสิทธิบัตร",
				"place": "สถานที่พิมพ์",
				"postType": "ประเภทข้อความ",
				"presentationType": "ประเภท",
				"priorityNumbers": "หมายเลขลำดับก่อน",
				"proceedingsTitle": "ชื่อเอกสารการประชุม",
				"programmingLanguage": "Prog. Language",
				"programTitle": "ชื่อโปรแกรม",
				"publicationTitle": "สิ่งพิมพ์เผยแพร่",
				"publicLawNumber": "หมายเลขกฎหมายมหาชน",
				"publisher": "สำนักพิมพ์",
				"references": "เอกสารอ้างอิง",
				"reporter": "ผู้รายงาน",
				"reporterVolume": "รายงานเล่มที่",
				"reportNumber": "หมายเลขรายงาน",
				"reportType": "ประเภทรายงาน",
				"rights": "ลิขสิทธิ์",
				"runningTime": "ระยะเวลาเล่นต่อเนื่อง",
				"scale": "มาตราส่วน",
				"section": "ส่วน",
				"series": "ชุด",
				"seriesNumber": "หมายเลขชุด",
				"seriesText": "หัวข้อชุด",
				"seriesTitle": "ชื่อชุด",
				"session": "สมัยประชุม",
				"shortTitle": "ชื่อย่อเรื่อง",
				"studio": "สตูดิโอ",
				"subject": "เรื่อง",
				"system": "ระบบ",
				"thesisType": "ประเภท",
				"title": "ชื่อเรื่อง",
				"university": "มหาวิทยาลัย",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "รูปแบบ",
				"volume": "ปีที่พิมพ์",
				"websiteTitle": "ชื่อเว็บไซต์",
				"websiteType": "ประเภทเว็บไซต์"
			},
			"creatorTypes": {
				"artist": "ศิลปิน",
				"attorneyAgent": "ผู้รับมอบอำนาจ/ตัวแทน",
				"author": "ผู้แต่ง",
				"bookAuthor": "ผู้แต่งหนังสือ",
				"cartographer": "ผู้ทำแผนที่",
				"castMember": "นักแสดง",
				"commenter": "ผู้ออกความเห็น",
				"composer": "นักแต่งเพลง",
				"contributor": "ผู้ช่วยเหลือ",
				"cosponsor": "ผู้อุปถัมภ์ร่วม",
				"counsel": "ทนายความ",
				"director": "ผู้อำนวยการ",
				"editor": "บรรณาธิการ",
				"guest": "ผู้รับเชิญ",
				"interviewee": "สัมภาษณ์กับ",
				"interviewer": "ผู้สัมภาษณ์",
				"inventor": "ผู้ประดิษฐ์",
				"performer": "นักแสดง",
				"podcaster": "ผู้สร้างพอดคาสต์",
				"presenter": "ผู้นำเสนอ",
				"producer": "ผู้กำกับ",
				"programmer": "นักเขียนโปรแกรม",
				"recipient": "ผู้รับ",
				"reviewedAuthor": "ผู้เขียนบทวิจารณ์",
				"scriptwriter": "ผู้เขียนบท",
				"seriesEditor": "บรรณาธิการชุดย่อย",
				"sponsor": "ผู้อุปถัมภ์",
				"translator": "ผู้แปล",
				"wordsBy": "สุนทรพจน์โดย"
			}
		},
		"tr-TR": {
			"itemTypes": {
				"annotation": "Ek Açıklama",
				"artwork": "Sanat eseri",
				"attachment": "Ek",
				"audioRecording": "Ses Kaydı",
				"bill": "Kanun önergesi",
				"blogPost": "Günlük Yazısı",
				"book": "Kitap",
				"bookSection": "Kitap Bölümü",
				"case": "Dava",
				"computerProgram": "Yazılım",
				"conferencePaper": "Konferans Bildirisi",
				"dictionaryEntry": "Sözlük Girdisi",
				"document": "Doküman",
				"email": "E-posta",
				"encyclopediaArticle": "Ansiklopedi Makalesi",
				"film": "Film",
				"forumPost": "Forum İletisi",
				"hearing": "Kurul ifadesi",
				"instantMessage": "Anlık İleti",
				"interview": "Görüşme",
				"journalArticle": "Bilimsel Dergi Makalesi",
				"letter": "Mektup",
				"magazineArticle": "Genel Dergi Makalesi",
				"manuscript": "El Yazması",
				"map": "Harita",
				"newspaperArticle": "Gazete Makalesi",
				"note": "Not",
				"patent": "Patent",
				"podcast": "Podcast",
				"presentation": "Sunum",
				"radioBroadcast": "Radyo Yayını",
				"report": "Rapor",
				"statute": "Kanun",
				"thesis": "Tez",
				"tvBroadcast": "TV Yayını",
				"videoRecording": "Video Kaydı",
				"webpage": "Web Sayfası"
			},
			"fields": {
				"abstractNote": "Özet",
				"accessDate": "Son Erişim",
				"applicationNumber": "Uygulama Numarası",
				"archive": "Arşiv",
				"archiveLocation": "Arşivdeki Yeri",
				"artworkMedium": "Sanat Eseri Ortamı",
				"artworkSize": "Sanat Eserinin Boyutu",
				"assignee": "Devralan",
				"audioFileType": "Dosya Türü",
				"audioRecordingFormat": "Biçim",
				"billNumber": "Kanun Önerge Numarası",
				"blogTitle": "Blog Başlığı",
				"bookTitle": "Kitap Başlığı",
				"callNumber": "Yer Numarası",
				"caseName": "Dava Adı",
				"code": "Kanun",
				"codeNumber": "Kanun Numarası",
				"codePages": "Kanun Sayfası",
				"codeVolume": "Kanun Cildi",
				"committee": "Kurul",
				"company": "Şirket",
				"conferenceName": "Konferans Adı",
				"country": "Ülke",
				"court": "Mahkeme",
				"date": "Tarih",
				"dateAdded": "Eklendiği Tarih",
				"dateDecided": "Kesin Tarih",
				"dateEnacted": "Kabul Tarihi",
				"dateModified": "Değiştirme",
				"dictionaryTitle": "Sözlük Başlığı",
				"distributor": "Dağıtımcı",
				"docketNumber": "Dava Numarası",
				"documentNumber": "Doküman Numarası",
				"DOI": "DOI",
				"edition": "Baskı",
				"encyclopediaTitle": "Ansiklopedi Başlığı",
				"episodeNumber": "Bölüm Numarası",
				"extra": "İlave",
				"filingDate": "Başvuru Tarihi",
				"firstPage": "İlk Sayfa",
				"forumTitle": "Forum/Liste Başlığı",
				"genre": "Çeşit",
				"history": "Tarihçe",
				"institution": "Kurum",
				"interviewMedium": "Ortam",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Sayı",
				"issueDate": "Yayın Tarihi",
				"issuingAuthority": "Düzenleyen Makam",
				"itemType": "Tür",
				"journalAbbreviation": "Dergi Kısaltması",
				"label": "Plak şirketi",
				"language": "Dil",
				"legalStatus": "Hukuki Durum",
				"legislativeBody": "Yasama Organı",
				"letterType": "Tür",
				"libraryCatalog": "Kütüphane Kataloğu",
				"manuscriptType": "Tür",
				"mapType": "Tür",
				"meetingName": "Toplantı Adı",
				"nameOfAct": "Kanun Adı",
				"network": "Ağ",
				"number": "Numara",
				"numberOfVolumes": "Cilt Sayısı",
				"numPages": "Sayfa Sayısı",
				"pages": "Sayfa",
				"patentNumber": "Patent Numarası",
				"place": "Yayın Yeri",
				"postType": "Post Türü",
				"presentationType": "Tür",
				"priorityNumbers": "Rüçhan Numarası",
				"proceedingsTitle": "Bildiriler Başlığı",
				"programmingLanguage": "Prog. Dili",
				"programTitle": "Program Başlığı",
				"publicationTitle": "Yayın",
				"publicLawNumber": "Kamu Hukuku Numarası",
				"publisher": "Yayıncı",
				"references": "Kaynakça",
				"reporter": "Raporlayan Kitap",
				"reporterVolume": "Raporlayon Kitabın Cildi",
				"reportNumber": "Rapor Numarası",
				"reportType": "Rapor Türü",
				"rights": "Telif",
				"runningTime": "Çalma Süresi",
				"scale": "Boyutları",
				"section": "Bölüm",
				"series": "Dizi",
				"seriesNumber": "Dizi Numarası",
				"seriesText": "Dizi Metni",
				"seriesTitle": "Dizi Başlığı",
				"session": "Oturum",
				"shortTitle": "Kısa Başlık",
				"studio": "Stüdyo",
				"subject": "Konu",
				"system": "Sistem",
				"thesisType": "Tür",
				"title": "Başlık",
				"university": "Üniversite",
				"url": "URL",
				"versionNumber": "Sürüm",
				"videoRecordingFormat": "Biçim",
				"volume": "Cilt",
				"websiteTitle": "Web sitesi Başlığı",
				"websiteType": "Website Türü"
			},
			"creatorTypes": {
				"artist": "Sanatçı",
				"attorneyAgent": "Avukat/Vekil",
				"author": "Yazar",
				"bookAuthor": "Kitap Yazarı",
				"cartographer": "Haritacı",
				"castMember": "Oyuncu",
				"commenter": "Yorumcu",
				"composer": "Besteci",
				"contributor": "Katkıda Bulunan",
				"cosponsor": "Birlikte Destekleyen",
				"counsel": "Avukat",
				"director": "Yönetmen",
				"editor": "Editör",
				"guest": "Konuk",
				"interviewee": "Görüşme Yapılan",
				"interviewer": "Görüşmeci",
				"inventor": "Buluş Sahibi",
				"performer": "Yorumcu",
				"podcaster": "Podcast yapan",
				"presenter": "Sunucu",
				"producer": "Yapımcı",
				"programmer": "Programcı",
				"recipient": "Alıcı",
				"reviewedAuthor": "Eleştirilen Yazar",
				"scriptwriter": "Senaryo Yazarı",
				"seriesEditor": "Dizi Editörü",
				"sponsor": "Destekleyen",
				"translator": "Çevirmen",
				"wordsBy": "Yazan"
			}
		},
		"uk-UA": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Витвір мистецтва",
				"attachment": "Вкладення",
				"audioRecording": "Аудіозапис",
				"bill": "Законопроект",
				"blogPost": "Запис в блозі",
				"book": "Книга",
				"bookSection": "Глава книги",
				"case": "Справа",
				"computerProgram": "Software",
				"conferencePaper": "Документ конференції",
				"dictionaryEntry": "Стаття зі словника",
				"document": "Документ",
				"email": "E-mail",
				"encyclopediaArticle": "Стаття з екциклопедії",
				"film": "Фільм",
				"forumPost": "Запис на форумі",
				"hearing": "Слухання",
				"instantMessage": "Миттєве повідомлення",
				"interview": "Інтерв'ю",
				"journalArticle": "Стаття з журналу",
				"letter": "Лист",
				"magazineArticle": "Стаття з періодики",
				"manuscript": "Рукопис",
				"map": "Карта",
				"newspaperArticle": "Стаття з газети",
				"note": "Примітка",
				"patent": "Патент",
				"podcast": "Подкаст",
				"presentation": "Презентація",
				"radioBroadcast": "Радіо передача",
				"report": "Звіт",
				"statute": "Статут",
				"thesis": "Дисертація",
				"tvBroadcast": "Телевізійна передача",
				"videoRecording": "Відео запис",
				"webpage": "Веб сторінка"
			},
			"fields": {
				"abstractNote": "Анотація",
				"accessDate": "Дата доступу",
				"applicationNumber": "Номер заявки",
				"archive": "Архів",
				"archiveLocation": "Місце в архіві",
				"artworkMedium": "Художній засіб",
				"artworkSize": "Розмір роботи",
				"assignee": "Представник",
				"audioFileType": "Тип файлу",
				"audioRecordingFormat": "Формат",
				"billNumber": "Номер законопр.",
				"blogTitle": "Назва блогу",
				"bookTitle": "Назва книги",
				"callNumber": "Шифр",
				"caseName": "Номер справи",
				"code": "Код",
				"codeNumber": "Номер коду",
				"codePages": "Код сторінки",
				"codeVolume": "Код тому",
				"committee": "Комітет",
				"company": "Компанія",
				"conferenceName": "Назва конфер.",
				"country": "Країна",
				"court": "Суд",
				"date": "Дата",
				"dateAdded": "Дата додавання",
				"dateDecided": "Дата рішення",
				"dateEnacted": "Дата постанови",
				"dateModified": "Дата зміни",
				"dictionaryTitle": "Назва словника",
				"distributor": "Бібл. каталог",
				"docketNumber": "Номер виставки",
				"documentNumber": "Номер документа",
				"DOI": "DOI",
				"edition": "Видання",
				"encyclopediaTitle": "Назва енцикл.",
				"episodeNumber": "Номер епізоду",
				"extra": "Додатково",
				"filingDate": "Дата заявки",
				"firstPage": "Перша стор.",
				"forumTitle": "Форум/Listserv",
				"genre": "Жанр",
				"history": "Історія",
				"institution": "Заклад",
				"interviewMedium": "Засіб",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Випуск",
				"issueDate": "Дата випуску",
				"issuingAuthority": "Ким видана",
				"itemType": "Тип",
				"journalAbbreviation": "Журнал скор.",
				"label": "Напис",
				"language": "Мова",
				"legalStatus": "Правовий статус",
				"legislativeBody": "Законотвор. орган",
				"letterType": "Тип",
				"libraryCatalog": "Бібл. каталог",
				"manuscriptType": "Тип",
				"mapType": "Тип",
				"meetingName": "Назва зустрічі",
				"nameOfAct": "Назва постанови",
				"network": "Мережа",
				"number": "Номер",
				"numberOfVolumes": "Кільк. томів",
				"numPages": "Кільк. сторінок",
				"pages": "Сторінки",
				"patentNumber": "Номер патенту",
				"place": "Місце",
				"postType": "Тип повідомлення",
				"presentationType": "Тип",
				"priorityNumbers": "Номер пріоритету",
				"proceedingsTitle": "Назва праць",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Назва програми",
				"publicationTitle": "Публікація",
				"publicLawNumber": "Номер закону",
				"publisher": "Видавник",
				"references": "Посилання",
				"reporter": "Репортер",
				"reporterVolume": "Том звіту",
				"reportNumber": "Номер звіту",
				"reportType": "Тип звіту",
				"rights": "Права",
				"runningTime": "Тривалість",
				"scale": "Масштаб",
				"section": "Розділ",
				"series": "Серія",
				"seriesNumber": "Номер серії",
				"seriesText": "Текст серії",
				"seriesTitle": "Назва серії",
				"session": "Сесія",
				"shortTitle": "Скор. назва",
				"studio": "Студія",
				"subject": "Тема",
				"system": "Система",
				"thesisType": "Тип",
				"title": "Назва",
				"university": "Університет",
				"url": "URL",
				"versionNumber": "Версія",
				"videoRecordingFormat": "Формат",
				"volume": "Том",
				"websiteTitle": "Назва веб-сайту\\n",
				"websiteType": "Тип веб-сайту"
			},
			"creatorTypes": {
				"artist": "Художник",
				"attorneyAgent": "Адвокат/Агент",
				"author": "Автор",
				"bookAuthor": "Автор книги",
				"cartographer": "Картограф",
				"castMember": "Актор",
				"commenter": "Коментатор",
				"composer": "Композитор",
				"contributor": "Співавтор",
				"cosponsor": "Спонсор",
				"counsel": "Радник",
				"director": "Режисер",
				"editor": "Редактор",
				"guest": "Гість",
				"interviewee": "Співбесіда з",
				"interviewer": "Інтерв'юер",
				"inventor": "Винахідник",
				"performer": "Виконавець",
				"podcaster": "Підкастер",
				"presenter": "Доповідач",
				"producer": "Продюсер",
				"programmer": "Програміст",
				"recipient": "Отримувач",
				"reviewedAuthor": "Реценз. автор",
				"scriptwriter": "Сценарист",
				"seriesEditor": "Редактор серії",
				"sponsor": "Спонсор",
				"translator": "Перекладач",
				"wordsBy": "Автор слів"
			}
		},
		"vi-VN": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "Minh họa",
				"attachment": "Phần đính kèm",
				"audioRecording": "Ghi âm",
				"bill": "Dự thảo luật",
				"blogPost": "Bài viết trên Blog",
				"book": "Sách",
				"bookSection": "Đoạn Sách",
				"case": "Vụ việc",
				"computerProgram": "Software",
				"conferencePaper": "Báo cáo Hội thảo",
				"dictionaryEntry": "Mục từ trong Từ điển",
				"document": "Tài liệu",
				"email": "Thư điện tử",
				"encyclopediaArticle": "Bài viết trong Bách khoa toàn thư",
				"film": "Phim",
				"forumPost": "Bài viết trên Diễn đàn",
				"hearing": "Phiên xét xử",
				"instantMessage": "Tin nhắn",
				"interview": "Phỏng vấn",
				"journalArticle": "Bài viết trong Tập san",
				"letter": "Thư",
				"magazineArticle": "Bài viết trong Tạp chí",
				"manuscript": "Bản thảo",
				"map": "Bản đồ",
				"newspaperArticle": "Bài viết trên Báo",
				"note": "Ghi chép",
				"patent": "Bằng sáng chế/Giấy phép độc quyền",
				"podcast": "Podcast",
				"presentation": "Trình bày",
				"radioBroadcast": "Tiết mục Truyền thanh",
				"report": "Phóng sự/Báo cáo",
				"statute": "Quy chế",
				"thesis": "Luận văn",
				"tvBroadcast": "Tiết mục Truyền hình",
				"videoRecording": "Ghi hình",
				"webpage": "Trang Web"
			},
			"fields": {
				"abstractNote": "Tóm tắt",
				"accessDate": "Ngày truy cập",
				"applicationNumber": "Số đơn",
				"archive": "Lưu",
				"archiveLocation": "Vị trí trong Lưu trữ",
				"artworkMedium": "Chất liệu của tác phẩm",
				"artworkSize": "Kích thước tác phẩm",
				"assignee": "Bên được ủy quyền",
				"audioFileType": "Kiểu Tập tin",
				"audioRecordingFormat": "Format",
				"billNumber": "Số Dự thảo",
				"blogTitle": "Nhan đề Blog",
				"bookTitle": "Nhan đề sách",
				"callNumber": "Ký hiệu Xếp giá",
				"caseName": "Tên của Vụ việc",
				"code": "Mã",
				"codeNumber": "Code Number",
				"codePages": "Trang",
				"codeVolume": "Số tập trong Bộ luật",
				"committee": "Ủy ban",
				"company": "Công ty",
				"conferenceName": "Tên Hội thảo",
				"country": "Quốc gia",
				"court": "Tòa án",
				"date": "Ngày",
				"dateAdded": "Ngày Tạo lập",
				"dateDecided": "Ngày Phán Xử",
				"dateEnacted": "Ngày có Hiệu lực",
				"dateModified": "Ngày Thay đổi",
				"dictionaryTitle": "Tên của Từ Điển",
				"distributor": "Nhà phân phối",
				"docketNumber": "Docket Number",
				"documentNumber": "Số Văn bản",
				"DOI": "DOI",
				"edition": "Ấn bản",
				"encyclopediaTitle": "Tên của Bách Khoa Toàn Thư",
				"episodeNumber": "Số Hồi",
				"extra": "Phần bổ sung",
				"filingDate": "Filing Date",
				"firstPage": "Trang đầu",
				"forumTitle": "Tên của Diễn đàn/Danh sách Thư điện tử",
				"genre": "Thể loại",
				"history": "Lịch sử",
				"institution": "Tổ chức/Cơ quan",
				"interviewMedium": "Phương tiện",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "Lần phát hành",
				"issueDate": "Ngày cấp",
				"issuingAuthority": "Issuing Authority",
				"itemType": "Kiểu",
				"journalAbbreviation": "Tên rút ngắn của Tập san",
				"label": "Nhãn",
				"language": "Ngôn ngữ",
				"legalStatus": "Tình trạng Pháp lý",
				"legislativeBody": "Cơ quan Lập pháp",
				"letterType": "Kiểu",
				"libraryCatalog": "Loại thư viện",
				"manuscriptType": "Kiểu",
				"mapType": "Kiểu",
				"meetingName": "Tên Cuộc họp",
				"nameOfAct": "Tên của Bộ Luật",
				"network": "Mạng",
				"number": "Số",
				"numberOfVolumes": "Số Tập",
				"numPages": "# of Pages",
				"pages": "Trang",
				"patentNumber": "Số Bắng sáng chế",
				"place": "Nơi xuất bản",
				"postType": "Kiểu Bài viết",
				"presentationType": "Kiểu",
				"priorityNumbers": "Số Ưu tiên",
				"proceedingsTitle": "Nhan đề của Kỷ yếu Hội nghị",
				"programmingLanguage": "Prog. Language",
				"programTitle": "Program Title",
				"publicationTitle": "Ấn phẩm",
				"publicLawNumber": "Số Luật Dân sự",
				"publisher": "Nhà xuất bản",
				"references": "Tham khảo",
				"reporter": "Phóng viên",
				"reporterVolume": "Tập Báo cáo Luật",
				"reportNumber": "Số Phóng sự/Báo cáo",
				"reportType": "Kiểu Phóng sự/Báo cáo",
				"rights": "Quyền hạn",
				"runningTime": "Độ dài Thời gian",
				"scale": "Tỷ lệ",
				"section": "Đoạn",
				"series": "Tùng thư",
				"seriesNumber": "Số Tùng thư",
				"seriesText": "Miêu tả Tùng thư",
				"seriesTitle": "Nhan đề của Tùng thư",
				"session": "Phiên",
				"shortTitle": "Nhan đề thu gọn",
				"studio": "Xưởng sản xuất",
				"subject": "Chủ đề",
				"system": "Hệ thống",
				"thesisType": "Kiểu",
				"title": "Nhan đề",
				"university": "Trường Đại Học",
				"url": "URL",
				"versionNumber": "Version",
				"videoRecordingFormat": "Format",
				"volume": "Tập",
				"websiteTitle": "Tên Website",
				"websiteType": "Kiểu Website"
			},
			"creatorTypes": {
				"artist": "Nghệ sĩ",
				"attorneyAgent": "Luật sư/Đại diện pháp lý",
				"author": "Tác giả",
				"bookAuthor": "Book Author",
				"cartographer": "Người vẽ bản đồ",
				"castMember": "Thành viên đoàn kịch",
				"commenter": "Bình luận viên",
				"composer": "Nhà soạn nhạc",
				"contributor": "Cộng tác viên",
				"cosponsor": "Cosponsor",
				"counsel": "Nhà tư vấn",
				"director": "Giám đốc",
				"editor": "Biên tập viên",
				"guest": "Khách mời",
				"interviewee": "Người được phỏng vấn",
				"interviewer": "Người phỏng vấn",
				"inventor": "Nhà phát minh",
				"performer": "Người biểu diễn",
				"podcaster": "Podcaster",
				"presenter": "Dẫn chương trình",
				"producer": "Nhà sản xuất",
				"programmer": "Lập trình viên",
				"recipient": "Người nhận",
				"reviewedAuthor": "Nhà phê bình/Người phản biện",
				"scriptwriter": "Tác giả Kịch bản",
				"seriesEditor": "Biên tập viên của Tùng thư",
				"sponsor": "Tài trợ/Đỡ đầu",
				"translator": "Biên dịch viên",
				"wordsBy": "Viết lời"
			}
		},
		"zh-CN": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "艺术品",
				"attachment": "附件",
				"audioRecording": "音频剪辑",
				"bill": "帐单",
				"blogPost": "博客帖子",
				"book": "书籍",
				"bookSection": "图书章节",
				"case": "案例",
				"computerProgram": "软件",
				"conferencePaper": "会议论文",
				"dictionaryEntry": "词条",
				"document": "文档",
				"email": "E-mail",
				"encyclopediaArticle": "百科全书文章",
				"film": "电影",
				"forumPost": "论坛帖子",
				"hearing": "听证会",
				"instantMessage": "即时讯息",
				"interview": "采访稿",
				"journalArticle": "期刊文章",
				"letter": "信件",
				"magazineArticle": "杂志文章",
				"manuscript": "手稿",
				"map": "地图",
				"newspaperArticle": "报纸文章",
				"note": "笔记",
				"patent": "专利",
				"podcast": "播客",
				"presentation": "演示文档",
				"radioBroadcast": "电台广播",
				"report": "报告",
				"statute": "法规",
				"thesis": "学位论文",
				"tvBroadcast": "TV 广播",
				"videoRecording": "视频剪辑",
				"webpage": "网页"
			},
			"fields": {
				"abstractNote": "摘要",
				"accessDate": "访问时间",
				"applicationNumber": "申请号",
				"archive": "档案",
				"archiveLocation": "存档位置",
				"artworkMedium": "艺术品媒介",
				"artworkSize": "艺术品尺寸",
				"assignee": "受托人",
				"audioFileType": "音频文件类型",
				"audioRecordingFormat": "音频格式",
				"billNumber": "案例编号",
				"blogTitle": "博客标题",
				"bookTitle": "书名",
				"callNumber": "引用次数",
				"caseName": "案例名称",
				"code": "代码",
				"codeNumber": "区号",
				"codePages": "代码页码",
				"codeVolume": "代码卷",
				"committee": "委员会",
				"company": "公司",
				"conferenceName": "学术会议名称",
				"country": "国家",
				"court": "法庭",
				"date": "日期",
				"dateAdded": "添加日期",
				"dateDecided": "确认日期",
				"dateEnacted": "制定日期",
				"dateModified": "修改日期",
				"dictionaryTitle": "词典标题",
				"distributor": "分发者",
				"docketNumber": "案卷号",
				"documentNumber": "文档编号",
				"DOI": "DOI",
				"edition": "图书版本",
				"encyclopediaTitle": "百科全书标题",
				"episodeNumber": "集数",
				"extra": "其它",
				"filingDate": "申请日期",
				"firstPage": "首页",
				"forumTitle": "论坛/列表服务标题",
				"genre": "流派",
				"history": "历史",
				"institution": "机构组织",
				"interviewMedium": "采访媒体",
				"ISBN": "ISBN",
				"ISSN": "ISSN",
				"issue": "期",
				"issueDate": "签发日期",
				"issuingAuthority": "颁发机构",
				"itemType": "类型",
				"journalAbbreviation": "刊名缩写",
				"label": "标记",
				"language": "语言",
				"legalStatus": "法律地位",
				"legislativeBody": "立法机构",
				"letterType": "信件类型",
				"libraryCatalog": "馆藏目录",
				"manuscriptType": "手稿类型",
				"mapType": "地图类型",
				"meetingName": "会议名称",
				"nameOfAct": "法令名称",
				"network": "网络",
				"number": "号码",
				"numberOfVolumes": "总卷数",
				"numPages": "总页数",
				"pages": "页码",
				"patentNumber": "专利号",
				"place": "地点",
				"postType": "帖子类型",
				"presentationType": "演稿类型",
				"priorityNumbers": "优先申请号",
				"proceedingsTitle": "投递标题",
				"programmingLanguage": "Prog. 语言",
				"programTitle": "节目名称",
				"publicationTitle": "期刊",
				"publicLawNumber": "国际公法号",
				"publisher": "出版社",
				"references": "参考文献",
				"reporter": "报告人",
				"reporterVolume": "报告卷",
				"reportNumber": "报告编号",
				"reportType": "报告类型",
				"rights": "版权",
				"runningTime": "时长",
				"scale": "比例",
				"section": "章节",
				"series": "系列",
				"seriesNumber": "系列编号",
				"seriesText": "系列文本",
				"seriesTitle": "系列标题",
				"session": "会期",
				"shortTitle": "短标题",
				"studio": "工作室",
				"subject": "主题",
				"system": "系统",
				"thesisType": "论文类型",
				"title": "标题",
				"university": "大学",
				"url": "URL",
				"versionNumber": "版本",
				"videoRecordingFormat": "视频格式",
				"volume": "卷",
				"websiteTitle": "网站标题",
				"websiteType": "网站类型"
			},
			"creatorTypes": {
				"artist": "艺术家",
				"attorneyAgent": "律师/代理人",
				"author": "作者",
				"bookAuthor": "图书作者",
				"cartographer": "制图人",
				"castMember": "演员阵容",
				"commenter": "评论人",
				"composer": "创作者",
				"contributor": "贡献者",
				"cosponsor": "合作作者",
				"counsel": "顾问",
				"director": "导演",
				"editor": "编辑",
				"guest": "宾客",
				"interviewee": "采访对象",
				"interviewer": "采访者",
				"inventor": "发明人",
				"performer": "表演者",
				"podcaster": "播客",
				"presenter": "报告人",
				"producer": "制片人",
				"programmer": "程序员",
				"recipient": "接收者",
				"reviewedAuthor": "审稿人",
				"scriptwriter": "编剧",
				"seriesEditor": "丛书编辑",
				"sponsor": "赞助商",
				"translator": "译者",
				"wordsBy": "作词"
			}
		},
		"zh-TW": {
			"itemTypes": {
				"annotation": "Annotation",
				"artwork": "藝術作品",
				"attachment": "附件檔",
				"audioRecording": "錄音",
				"bill": "法案",
				"blogPost": "部落格貼文",
				"book": "書",
				"bookSection": "書的章節",
				"case": "案例",
				"computerProgram": "軟體",
				"conferencePaper": "會議論文",
				"dictionaryEntry": "字典條目",
				"document": "文件",
				"email": "電子郵件",
				"encyclopediaArticle": "百科全書文章",
				"film": "影片",
				"forumPost": "論壇貼文",
				"hearing": "聽證會",
				"instantMessage": "即時訊息",
				"interview": "訪談",
				"journalArticle": "期刊文章",
				"letter": "信件",
				"magazineArticle": "雜誌文章",
				"manuscript": "手稿",
				"map": "地圖",
				"newspaperArticle": "報紙文章",
				"note": "筆記",
				"patent": "專利",
				"podcast": "播客",
				"presentation": "簡報",
				"radioBroadcast": "電台廣播",
				"report": "報告",
				"statute": "法規",
				"thesis": "碩博士論文",
				"tvBroadcast": "電視廣播",
				"videoRecording": "錄影",
				"webpage": "網頁"
			},
			"fields": {
				"abstractNote": "摘要",
				"accessDate": "取用",
				"applicationNumber": "申請號碼",
				"archive": "存庫",
				"archiveLocation": "文件庫中的位置",
				"artworkMedium": "中",
				"artworkSize": "藝術作品大小",
				"assignee": "代理人",
				"audioFileType": "檔案類型",
				"audioRecordingFormat": "格式",
				"billNumber": "法案編號",
				"blogTitle": "部落格標題",
				"bookTitle": "書名",
				"callNumber": "索書號",
				"caseName": "案件名稱",
				"code": "法規(code)",
				"codeNumber": "法規(code)編號",
				"codePages": "法規(code)頁次",
				"codeVolume": "法規(code)卷次",
				"committee": "委員會",
				"company": "公司",
				"conferenceName": "會議名稱",
				"country": "國家",
				"court": "法庭",
				"date": "日期",
				"dateAdded": "加入日期",
				"dateDecided": "決定日期",
				"dateEnacted": "頒布日期",
				"dateModified": "修改日期",
				"dictionaryTitle": "字典名稱",
				"distributor": "發行人",
				"docketNumber": "表件號碼",
				"documentNumber": "文件號碼",
				"DOI": "數位物件識別號(DOI)",
				"edition": "版本",
				"encyclopediaTitle": "百科全書書名",
				"episodeNumber": "劇集號碼",
				"extra": "額外增列",
				"filingDate": "歸檔日期",
				"firstPage": "起始頁",
				"forumTitle": "論壇/郵寄服務標題",
				"genre": "流派",
				"history": "歷史",
				"institution": "機構",
				"interviewMedium": "中",
				"ISBN": "國際標準書號(ISBN)",
				"ISSN": "國際標準期刊號(ISSN)",
				"issue": "期號",
				"issueDate": "發刊日期",
				"issuingAuthority": "發行當局",
				"itemType": "類型",
				"journalAbbreviation": "期刊簡寫",
				"label": "商標",
				"language": "語言",
				"legalStatus": "法律地位",
				"legislativeBody": "立法機構",
				"letterType": "類型",
				"libraryCatalog": "文獻庫目錄",
				"manuscriptType": "類型",
				"mapType": "類型",
				"meetingName": "會議名稱",
				"nameOfAct": "法令(Act)名稱",
				"network": "網路",
				"number": "號碼",
				"numberOfVolumes": "總卷數",
				"numPages": "頁數",
				"pages": "頁",
				"patentNumber": "專利號",
				"place": "所在地",
				"postType": "貼文類型",
				"presentationType": "類型",
				"priorityNumbers": "優先權案號",
				"proceedingsTitle": "會議論文集標題",
				"programmingLanguage": "Prog. Language",
				"programTitle": "節目標題",
				"publicationTitle": "著作",
				"publicLawNumber": "公法號碼",
				"publisher": "出版者",
				"references": "參考文獻",
				"reporter": "報告人",
				"reporterVolume": "報告人卷次",
				"reportNumber": "報告編號",
				"reportType": "報告類型",
				"rights": "權利",
				"runningTime": "播放時間",
				"scale": "比例",
				"section": "章節",
				"series": "系列",
				"seriesNumber": "系列號數",
				"seriesText": "系列文",
				"seriesTitle": "系列標題",
				"session": "會議時程",
				"shortTitle": "短名",
				"studio": "工作室",
				"subject": "主題",
				"system": "系統",
				"thesisType": "類型",
				"title": "標題",
				"university": "大學",
				"url": "URL",
				"versionNumber": "版本",
				"videoRecordingFormat": "格式",
				"volume": "卷次",
				"websiteTitle": "網站標題",
				"websiteType": "網站類型"
			},
			"creatorTypes": {
				"artist": "藝術家",
				"attorneyAgent": "律師/代理人",
				"author": "作者",
				"bookAuthor": "書籍作者",
				"cartographer": "製圖者",
				"castMember": "演員陣容",
				"commenter": "評論家",
				"composer": "作曲者",
				"contributor": "貢獻者",
				"cosponsor": "共同贊助者",
				"counsel": "顧問",
				"director": "導演",
				"editor": "編輯者",
				"guest": "來賓",
				"interviewee": "受訪者",
				"interviewer": "訪談者",
				"inventor": "發明人",
				"performer": "表演者",
				"podcaster": "播客",
				"presenter": "簡報者",
				"producer": "製作人",
				"programmer": "節目設計者",
				"recipient": "領受者",
				"reviewedAuthor": "所評論的作者",
				"scriptwriter": "編劇",
				"seriesEditor": "系列編輯者",
				"sponsor": "贊助者",
				"translator": "翻譯者",
				"wordsBy": "敘述者"
			}
		}
	}
}
