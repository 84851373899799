<div *ngIf="funder">
    <h2 mat-dialog-title>Add funder</h2>
    <mat-dialog-content>
        <div [formGroup]="funder" class="py1">
            <div formGroupName="institution">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Institution</mat-label>
                    <input matInput formControlName="name">
                    <mat-hint>The funding institution</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Identifier type</mat-label>
                    <input matInput formControlName="type">
                    <mat-hint>The type of identifier used for the institution (e.g. doi, ringgold, ..)</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Identifier id</mat-label>
                    <input matInput formControlName="id">
                    <mat-hint>The identifier of the institution (e.g. a doi: http://dx.doi.org/..)</mat-hint>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select formControlName="countryCode">
                    <mat-option *ngFor="let data of countryList" value={{data.code}}>{{data.name}}</mat-option>
                </mat-select>
            </mat-form-field>

              
            <mat-form-field appearance="outline" class="col-12">
                <mat-label>Awards</mat-label>
                <input matInput formControlName="awardIds">
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt2" align="end">
        <button mat-button mat-dialog-close class="uppercase">Discard changes</button>
        <button mat-button color="primary" class="uppercase" (click)="save()" cdkFocusInitial
            [disabled]="funder.pristine || funder.invalid">Save</button>
    </mat-dialog-actions>
</div>
