import { NgModule } from '@angular/core';
import { SharedComponent } from './shared.component';
import { TruncatePipe } from './truncate.pipe';
import { YamlPipe } from './yaml.pipe';

@NgModule({
  declarations: [
    SharedComponent,
    TruncatePipe,
    YamlPipe
  ],
  imports: [
  ],
  exports: [
    SharedComponent,
    TruncatePipe,
    YamlPipe
  ]
})
export class SharedModule { }
