import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable, catchError, map, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private http: HttpClient = inject(HttpClient);

  getSchema(kind: string): Observable<{kind: string; schema: any}> {
    return this.http.get(`/template/configuration/v2/${kind}.json`).pipe(
      map((schema) => ({
        kind,
        schema: schema?.['properties']?.spec,
      })),
      catchError(this.handleError),
    );
  }

  getTemplateSchema(kind: string, locale: string): Observable<object> {
    let url: string = `/template/schema/${kind}.json`;
    if (locale) {
      url += `?locale=${locale}`;
    }

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    return of({} as {kind: string; schema: any});
  }
}
