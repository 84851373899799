import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

  constructor(private apollo: Apollo, private store: Store) { }

  addAuthor(input) {
    return this.apollo.mutate({
      mutation: gql`mutation AddAuthor($input: AddAuthorInput!) {
          addAuthor(input: $input) {
            clientMutationId
          }
        }`,
      variables: { input }
    }).toPromise();
  }

  getInviteUrls(documentId) {
    return this.apollo.query({
      query: gql`query GetInviteUrls {
        document(key: "${documentId}") {
          id
          inviteUrl(roles: [Admin,Author,CommentOnly]) {
            role
            url
          }
        }
      }`
    }).pipe(map(({ data }: any) => ({
      ...data?.document,
      documentId
    })));
  }
}
