/*
 * Public API Surface of editor
 */

export * from './lib/editor.service';
export * from './lib/editor.module';

export * from './lib/editor.reducer';
export * from './lib/editor.actions';

export * from './lib/text-elements/text-elements.module';
export * from './lib/prosemirror/prosemirror.module';
export * from './lib/prosemirror/pm-editor.component';
export * from './lib/menu.service';