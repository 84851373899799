<div class="flex" *ngIf="selection$ | async; let data">
    <!-- <button mat-icon-button matTooltip="Write a comment">
        <mat-icon>chat_bubble_outline</mat-icon>
    </button> -->

    <div class="flex" *ngIf="data?.selectedAnchors">
        <a mat-icon-button [href]="data.selectedAnchors[0].anchor.attrs.href" target="_blank" rel="noopener"
        *ngIf="data.selectedAnchors[0]" [matTooltip]="'Open link (or Ctrl+Click link)'">
            <mat-icon>link</mat-icon>
        </a>

        <button mat-icon-button *ngIf="data.selectedAnchors[0]" [matMenuTriggerFor]="menu">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editLink(data.selectedAnchors[0])" *ngIf="data.selectedAnchors[0]" [matTooltip]="'Edit Link'">
                <mat-icon>link</mat-icon>
                <span>Edit link</span>
            </button>
        </mat-menu>
    </div>
</div>