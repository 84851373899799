import { Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateCitationCommand } from './commands';

import { ReferenceManagementService, RenderingService } from 'reference-management';
import { Citation, SourceField } from '@sciflow/cite';
import { ActivatedRoute, Router } from '@angular/router';
import { NodeView } from 'prosemirror-view';

const citeIcon = `<span spellcheck="false" class="material-icons align-middle">link</span>`;

/**
 * Citation editing.
 */
export class CitationView implements NodeView {
  /** The main dom element. */
  dom: HTMLElement;

  id = Math.random().toString(36).substr(2, 10);
  textContent: string;
  referenceService: ReferenceManagementService = this.injector.get(ReferenceManagementService);
  renderingService: RenderingService = this.injector.get(RenderingService);
  store: Store = this.injector.get(Store);
  router: Router = this.injector.get(Router);
  activatedRoute: ActivatedRoute = this.injector.get(ActivatedRoute);

  constructor(private node: any, private view: any, private getPos, private injector: Injector) {
    this.dom = document.createElement('cite');
    this.textContent = node.textContent;

    this.dom.innerHTML = `<span spellcheck="false">${this.textContent}</span>`;
    this.dom.setAttribute('data-id', this.id);
    this.dom.setAttribute('id', node.attrs.id);
    this.dom.classList.add('js-citation');
    this.dom.contentEditable = 'false';

    this.dom.addEventListener('drop', async (event) => {
      const newReference = this.getCitationFromDropEvent(event);
      const previousCitation = SourceField.fromString(this.node.attrs?.source);

      const citation: Citation = {
        ...previousCitation,
        citationItems: [
          ...(previousCitation?.citationItems ?? []),
          ...(newReference?.citationItems ?? [])]
          .filter((cit1, index, list) => index === list.findIndex((cit2) => cit1.id === cit2.id))
      };

      const source = encodeURI(SourceField.toString(citation));

      const csls = await this.referenceService.getCSL(citation.citationItems);
      const renderedCitation = this.renderingService.render(citation.citationItems, csls);
      this.textContent = renderedCitation;

      this.dom.innerHTML = `<span spellcheck="false">${this.textContent}</span>`;
      updateCitationCommand(this.textContent, source, this.getPos(), this.node.nodeSize)(this.view.state, this.view.dispatch);

      event.preventDefault();
      event.stopPropagation();
    });
  }

  getCitationFromDropEvent(event) {
    const htmlTag = event.dataTransfer?.getData('text/html');
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlTag, 'text/html');
    const citation = doc.getElementsByTagName('cite');
    if (!citation) { return; }
    const source = citation.item(0)?.getAttribute('data-source');
    if (!source) { return; }
    return SourceField.fromString(source);
  }

  /**
   * Called when a node is updated within the editor.
   * @param node The updated node.
   * @param decorations The decorations currently applied to the node.
   * @return whether the change can go ahead.
   */
  update(node, decorations): boolean {
    if (!node.sameMarkup(this.node)) { return false; }
    this.node = node;
    this.textContent = node.textContent;
    return true;
  }

  selectNode() {
    this.router.navigate([{ outlets: { context: ['references', 'citation', this.node.attrs.id] } }], { relativeTo:  this.activatedRoute });
    this.dom.classList.add('js-citation-active');
  }

  deselectNode() {
    this.dom.classList.remove('js-citation-active');
  }

  destroy() {
  }
}
