import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
import {concatMap, map, of, switchMap, withLatestFrom} from 'rxjs';
import * as ExportActions from './export.actions';
import {ExportState, selectExportDcaMaximize, selectExportMaximize} from './export.reducer';

@Injectable()
export class ExportEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{export: ExportState}>,
  ) {}

  toggleExportSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportActions.toggleExportSettings),
      withLatestFrom(this.store.pipe(select(selectExportMaximize))),
      map(([action, isExportSettingsMax]) => {
        if (isExportSettingsMax) {
          return ExportActions.minimizeExportSettings();
        } else {
          return ExportActions.maximizeExportSettings();
        }
      }),
    ),
  );

  handleExportDcaMaximize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportActions.minimizeExportSettings),
      map((action) => {
        return ExportActions.maximizeExportDca();
      }),
    ),
  );

  handlExportDcaMinimize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportActions.maximizeExportSettings),
      map((action) => {
        return ExportActions.minimizeExportDca();
      }),
    ),
  );
}
