import { insertPoint } from 'prosemirror-transform';
import { TextSelection } from 'prosemirror-state';
import { SFNodeType } from '@sciflow/schema';

export const insertOutline = (attrs, text = '@') => {
    return (state, dispatch): boolean => {
        const insertAt = insertPoint(state.doc, state.selection.from, state.schema.nodes.link);
        if (insertAt === null) {
            return false;
        }

        if (dispatch) {
            const tr = state.tr;
            tr.insert(
                state.selection.to,
                state.schema.node(SFNodeType.link,
                    { type: attrs.type, href: attrs.href },
                    [state.schema.text(text)]
                ));
            tr.setSelection(TextSelection.create(tr.doc, state.selection.to + 1));
            dispatch(tr);
        }
        return true;
    };
};
