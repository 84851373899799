import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HelperService {
  constructor(private router: Router) { }

  getProjectAndDocument(): Observable<{ documentId: string; projectId: string; }> {
    try {
    return of({ documentId: '', projectId: '' });
    } catch (err) {
      // TODO: find a better solution to this
      return of({ documentId: '', projectId: '' });
    }
  }
}
