
import { getDefaults } from './csl';
import { ReferenceRenderer } from './references';
import { CSLReference } from './types';

const { localeXML } = getDefaults();

const createFormat = (layout: string) => {
  return `<?xml version="1.0" encoding="utf-8"?>
    <style xmlns="http://purl.org/net/xbiblio/csl" class="in-text" version="1.0" demote-non-dropping-particle="never">
      <!-- This style was edited with the Visual CSL Editor (https://editor.citationstyles.org/visualEditor/) -->
      <info>
        <title>No Title</title>
        <id>http://www.zotero.org/styles/no-title</id>
        <link rel="self" href="http://www.zotero.org/styles/no-title"/>
        <updated>2023-04-22T09:20:06+00:00</updated>
      </info>
      <citation>
        <layout font-variant="small-caps" delimiter=", "/>
      </citation>
      <bibliography>
        ${layout}
      </bibliography>
    </style>`;
}

const defaultFormats = {
  authorsEtAl: createFormat(`<layout>
    <group>
      <names variable="author">
        <name and="symbol" et-al-min="2" et-al-use-first="1"/>
      </names>
    </group>
  </layout>`),
  authorsEtAl3: createFormat(`<layout>
    <group>
      <names variable="author" delimiter=",">
        <name et-al-min="3" et-al-use-first="3"/>
      </names>
    </group>
  </layout>`),
  authorsEtAl3Caps: createFormat(`<layout>
    <group>
      <names variable="author" delimiter="," font-variant="small-caps">
        <name et-al-min="3" et-al-use-first="3"/>
      </names>
    </group>
  </layout>`),
  authorsEtAl4: createFormat(`<layout>
    <group>
      <names variable="author" delimiter=",">
        <name et-al-min="4" et-al-use-first="4"/>
      </names>
    </group>
  </layout>`),
  authorsEtAlCaps: createFormat(`<layout>
    <group>
      <names variable="author" font-variant="small-caps">
        <name and="symbol" et-al-min="2" et-al-use-first="1"/>
      </names>
    </group>
  </layout>`),
  authorsEtAlLastNameCaps: createFormat(`<layout>
  <group>
    <names variable="author" font-variant="normal">
      <name and="symbol" et-al-min="2" et-al-use-first="1">
        <name-part name="given"/>
        <name-part name="family" font-variant="small-caps"/>
      </name>
    </names>
  </group>
</layout>`),
  editorsOnly: createFormat(`
    <layout>
      <names variable="editor"/>
    </layout>`),
  filename: createFormat(`<layout>
  <group>
    <text variable="container-title" form="short" suffix="-"/>
  </group>
  <group delimiter="-">
    <text variable="volume"/>
    <text variable="issue"/>
  </group>
  <group delimiter="-">
    <date date-parts="year" form="text" variable="issued"/>
    <group delimiter="-">
      <names variable="author" font-variant="normal" vertical-align="baseline" delimiter="-">
        <name form="short" delimiter="-" sort-separator="-"/>
      </names>
    </group>
    <text variable="DOI" form="short" text-case="lowercase"/>
  </group>
</layout>`)
}

/**
 * Returns a (HTML) string of the reference.
 */
export const renderAuthorList = (format: 'authorsEtAl' | 'authorsEtAlCaps' | 'authorsEtAlLastNameCaps' | 'editorsOnly' | 'filename', reference: CSLReference, stripHTML = false): string => {
  if (!defaultFormats[format]) { format = 'authorsEtAl'; }
  const renderer = new ReferenceRenderer(defaultFormats[format], localeXML, [reference]);
  const bibliography = renderer.getBibliographyFromAll();
  if (!bibliography.references?.[0]) { return 'Unknown author'; }
  const list = bibliography.references[0].trim()
    .replace('<div class="csl-entry">', '').replace('</div>', '');
  return stripHTML ? list.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '') : list;
};