import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthors } from 'projects/reference-app/src/app/app-state.reducer';
import { map, withLatestFrom } from 'rxjs/operators';
import { getUser } from '../author-management.reducer';
import { PortalRef } from '../portal-ref';
import { hasWriteAccess } from '../roles';

@Component({
  selector: 'sf-author-management-navigation',
  templateUrl: './author-management-navigation.component.html',
  styleUrls: ['./author-management-navigation.component.scss']
})
export class AuthorManagementNavigationComponent implements OnInit {

  constructor(public ref: PortalRef<AuthorManagementNavigationComponent, any>, private store: Store) { }

  author$ = this.store.select(selectAuthors).pipe(
    withLatestFrom(this.store.select(getUser).pipe(map((user) => user?.user.userId))),
    map(([authors, userId]) => authors?.find(author => userId && author.userId === userId))
  );

  access$ = this.author$.pipe(map((author) => ({ write: author?.roles && hasWriteAccess(author.roles) })));

  ngOnInit() { }
}
