import { insertPoint } from 'prosemirror-transform';
import { TextSelection, NodeSelection } from 'prosemirror-state';
import { CitationWithId, SourceField } from '@sciflow/cite';
import { SFNodeType } from '@sciflow/schema';

export const updateCitationByIdCommand = (citation: CitationWithId, text) => {
    return (state, dispatch) => {
        state.doc.nodesBetween(0, state.doc.nodeSize - 2, (node, pos) => {
            if (node.attrs?.id !== undefined && node.attrs?.id === citation.citationID) {
                const tr = state.tr;
                const citationNode = state.schema.node(SFNodeType.citation, {
                    source: encodeURI(SourceField.toString(citation)), id: citation.citationID
                }, [state.schema.text(text)]);
                tr.replaceWith(pos, pos + node.nodeSize, citationNode);
                tr.setSelection(NodeSelection.create(tr.doc, pos));
                tr.scrollIntoView();

                if (dispatch) {
                    dispatch(tr);
                }
            }
        });

        return true;
    };
};

export const updateCitationCommand = (text: string, source, pos, nodeSize) => {
    return (state, dispatch) => {
        const tr = state.tr;
        const from = pos;
        const to = pos + nodeSize;

        if (!dispatch) { return from != null; }

        tr.replaceWith(from, to, state.schema.node(SFNodeType.citation, { source }, [state.schema.text(text)]));
        tr.scrollIntoView();
        dispatch(tr);

        return true;
    };
};

export const insertCitationCommand = (source, renderedCitation = '@') => {
    return (state, dispatch): boolean => {
        const insertAt = insertPoint(state.doc, state.selection.from, state.schema.nodes.citation);
        if (insertAt === null) {
            return false;
        }

        if (dispatch) {
            const tr = state.tr;
            tr.insert(
                state.selection.to,
                state.schema.node(SFNodeType.citation,
                    { source: typeof source !== 'string' ? Math.random().toString(source) : source, style: null },
                    [state.schema.text(renderedCitation)]
                ));
            tr.setSelection(TextSelection.create(tr.doc, state.selection.to + 1));
            dispatch(tr);
        }
        return true;
    };
};
