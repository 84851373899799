/**
 * Schema for ProseMirror.
 */
import { Schema, NodeSpec, MarkSpec, Node, DOMSerializer } from 'prosemirror-model';
import { marks as basicMarks, nodes as basicNodes } from 'prosemirror-schema-basic';
import { listItem, bulletList, orderedList } from 'prosemirror-schema-list';
import { tableNodes } from 'prosemirror-tables';

import { SFNodeType, SFMarkType } from './types';

export const createId = (): string => 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26)) + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 52)) + Math.random().toString(36).substr(2, 12);

let sciflowBaseSchema, baseSchema, freeSchema, manuscriptSchema, inlineSchema, documentTitleSchema, titleOnlySchema, docx;

try {

  // all ids must start with a letter (for CSS styling to be able to target IDs)
  const idAttr = { default: null }; // ids are generated by the id plugin
  const readID = (dom) => ({ id: dom.getAttribute('id') || dom.getAttribute('data-id') });

  const extendObj = (obj, fields) => {
    return Object.assign({}, obj, fields);
  }
  // function extendObj(obj, fields) {
  //   return Object.assign({}, obj, fields);
  // }

  const freeDocument: NodeSpec = {
    attrs: { type: { default: 'article' }, lang: { default: '' } },
    toDOM() { return ['section', 0]; },
    parseDOM: [{ tag: 'section' }],
    content: 'block*'
  };

  const documentWithHeading: NodeSpec = {
    attrs: {
      type: { default: 'article' },
      lang: { default: undefined },
      role: { default: undefined },
      schema: { default: undefined },
      pageBreak: { default: undefined },
      placement: { default: undefined },
      numbering: { default: undefined }
    },
    toDOM() { return ['section', 0]; },
    parseDOM: [{ tag: 'section' }],
    content: 'heading block*',
    marks: '_'
  };

  const documentWithOptionalHeader: NodeSpec = {
    attrs: {
      type: { default: 'article' },
      lang: { default: undefined },
      role: { default: undefined },
      schema: { default: undefined },
      pageBreak: { default: undefined },
      placement: { default: undefined },
      numbering: { default: undefined }
    },
    toDOM() { return ['section', 0]; },
    parseDOM: [{ tag: 'section' }],
    content: 'header? (structural | block)*',
    marks: '_'
  };

  const header: NodeSpec = {
    content: 'heading subtitle?',
    marks: 'sub sup em strong bdi tags',
    isolating: true,
    toDOM() { return ['header', 0]; },
    parseDOM: [{ tag: 'header' }]
  };

  const heading = (content = 'text*'): NodeSpec => ({
    content,
    group: 'block',
    marks: 'sub sup em strong bdi tags',
    defining: false,
    
    selectable: false,
    draggable: true,
    attrs: { id: idAttr, level: { default: 1 }, role: { default: undefined }, type: { default: 'chapter' }, numbering: { default: undefined }, placement: { default: undefined } },
    toDOM(node) {
      return ['h' + node.attrs.level, {
        id: node.attrs.id,
        'data-id': node.attrs.id,
        'data-level': node.attrs.level,
        'data-type': node.attrs.type,
        'data-placement': node.attrs.placement,
        'data-role': node.attrs.role,
        'data-numbering': node.attrs.numbering
      }, 0];
    },
    parseDOM: [
      {
        tag: 'h1',
        // @ts-ignore can not be string
        getAttrs: (dom: HTMLElement) => ({
          ...readID(dom),
          level: 1,
          type: dom.getAttribute('data-type'),
          numbering: dom.getAttribute('data-numbering'),
          role: dom.getAttribute('data-role')
        })
      },
      { tag: 'h2', getAttrs: (dom) => ({ ...readID(dom), level: 2 }) },
      { tag: 'h3', getAttrs: (dom) => ({ ...readID(dom), level: 3 }) },
      { tag: 'h4', getAttrs: (dom) => ({ ...readID(dom), level: 4 }) },
      { tag: 'h5', getAttrs: (dom) => ({ ...readID(dom), level: 5 }) },
      { tag: 'h6', getAttrs: (dom) => ({ ...readID(dom), level: 6 }) }
    ]
  });

  const subtitle: NodeSpec = {
    content: 'inline*',
    marks: '_',
    toDOM() { return ['h2', { 'data-type': 'subtitle' }, 0]; },
    parseDOM: [{ tag: 'h2[data-type=subtitle]' }]
  };


  const part: NodeSpec = {
    content: 'heading? block*',
    marks: '_',
    group: 'structural',
    selectable: true,
    draggable: false,
    defining: false,
    isolating: false,
    attrs: { id: idAttr, 'text-direction': { default: undefined }, 'class': { default: undefined } },
    toDOM(node) {
      let attrs = {
        'data-type': 'part',
        'data-id': node.attrs.id,
        id: node.attrs.id,
        'data-class': node.attrs.class,
        'text-direction': node.attrs['text-direction'] ? node.attrs['text-direction'] : null
      };
      for (let key of Object.keys(attrs)) {
        if (attrs[key] == undefined) {
          delete attrs[key];
        }
      }
      return ['section', attrs, 0];
    },
    parseDOM: [{ tag: 'section[data-type="part"]', getAttrs: readID }]
  };

  const paragraph: NodeSpec = {
    content: 'inline*',
    marks: '_',
    group: 'block',
    attrs: { id: idAttr, 'text-align': { default: undefined }, 'text-direction': { default: undefined }, 'class': { default: undefined } },
    toDOM(node) {
      let attrs = {
        'data-id': node.attrs.id,
        id: node.attrs.id,
        'data-class': node.attrs.class,
        'text-direction': node.attrs['text-direction'] ? node.attrs['text-direction'] : null,
        'text-align': node.attrs['text-align'] ? node.attrs['text-align'] : null
      };
      if (node.attrs['text-align']) {
        attrs['style'] = `text-align: ${node.attrs['text-align']}`;
      }
      for (let key of Object.keys(attrs)) {
        if (attrs[key] == undefined) {
          delete attrs[key];
        }
      }
      return ['p', attrs, 0];
    },
    parseDOM: [{ tag: 'p', getAttrs: readID }]
  };

  const sidebar: NodeSpec = {
    content: 'heading? block+',
    toDOM() { return ['aside', { 'data-type': 'sidebar', class: 'htmlbook-box' }, 0]; },
    parseDOM: [{ tag: 'aside' }]
  };

  // export const admonitionTypes = ['note', 'warning', 'tip', 'caution', 'important'];

  // const admonition = {
  //   content: 'heading? block+',
  //   group: 'block',
  //   attrs: { type: {} },
  //   toDOM(node) { return ['div', { 'data-type': node.attrs.type, class: 'htmlbook-box' }, 0]; },
  //   parseDOM: admonitionTypes.map(type => ({ tag: `div[data-type=${type}]`, getAttrs: () => ({ type }) }))
  // };

  const footnote: NodeSpec = {
    group: 'inline',
    content: 'inline*',
    inline: true,
    selectable: false,
    // setting draggable to false since true will expose odd behavior in safari @see https://github.com/ProseMirror/website/issues/86
    draggable: false,
    // This makes the view treat the node as a leaf, even though it
    // technically has content
    atom: true,
    attrs: { id: idAttr, type: { default: 'footnote' } },
    toDOM(node) { return ['span', { 'data-type': 'footnote', 'data-id': node.attrs.id, id: node.attrs.id }, 0]; },
    parseDOM: [{ tag: 'span[data-type=\'footnote\']', getAttrs: readID }]
  };

  /**
   * A figure that may contain tables, images or other environments.
   * type should be one of: image, native-table, image-table
   */
  const figure: NodeSpec = {
    content: '(table|code_block)? caption',
    group: 'block',
    draggable: true,
    selectable: true,
    defining: true,
    isolating: true,
    marks: 'tags',
    attrs: {
      id: idAttr,
      src: { default: '' },
      alt: { default: '' },
      width: { default: undefined },
      height: { default: undefined },
      type: { default: 'figure' },
      environment: { default: undefined },
      orientation: { default: 'portrait' },
      'scale-width': { default: 1 },
      'float-placement': { default: undefined },
      'float-reference': { default: undefined },
      'float-defer-page': { default: undefined },
      'float-modifier': { default: undefined }
    },
    toDOM(node) {

      if (node.attrs['src']?.length > 0) {
        return ['figure', {
          'data-id': node.attrs['id'],
          id: node.attrs.id,
          'data-type': node.attrs['type'],
          'data-alt': node.attrs['alt'],
          'data-src': node.attrs['src'],
          'data-orientation': node.attrs['orientation']
        }, ['img', {
          src: node.attrs['src'],
          alt: node.attrs['alt'],
          title: node.attrs['title'],
        }], ['div', 0]];
      } else {
        return ['figure', {
          'data-id': node.attrs['id'],
          id: node.attrs.id,
          'data-type': node.attrs['type'],
          'data-alt': node.attrs['alt'],
          'data-src': node.attrs['src'],
          'data-orientation': node.attrs['orientation']
        }, 0];
      }
    },
    parseDOM: [{
      tag: 'figure',
      // @ts-ignore can not be string
      getAttrs: (dom: HTMLElement) => {
        return {
          id: dom.getAttribute('data-id'),
          src: dom.getAttribute('data-src'),
          alt: dom.getAttribute('data-alt'),
          orientation: dom.getAttribute('data-orientation'),
          type: dom.getAttribute('data-type')
        };
      }
    }],

  };

  const label: NodeSpec = {
    content: 'text*',
    marks: '_',
    toDOM() { return ['label', 0]; },
    parseDOM: [{ tag: 'label' }]
  };

  const caption: NodeSpec = {
    content: 'label? block*',
    marks: '_',
    toDOM() { return ['figcaption', 0]; },
    parseDOM: [{ tag: 'figcaption' }]
  };

  const pageBreak: NodeSpec = {
    group: 'block',
    selectable: false,
    draggable: true,
    toDOM() { return ['div', { 'data-type': 'page-break' }] },
    // @ts-ignore can not be string
    parseDOM: [{ tag: 'div[data-type="page-break"]', getAttrs: (dom: HTMLElement) => ({ 'data-type': dom.getAttribute('data-type') }) }]
  };

  const code: NodeSpec = {
    content: 'text*',
    marks: '',
    code: true,
    defining: true,
    group: "block",
    attrs: { id: idAttr, text: { default: "" }, 'type': { default: 'code' }, language: { default: "text/plain" } },
    parseDOM: [
      // @ts-ignore can not be string
      { tag: "pre", getAttrs: (dom: HTMLElement) => ({ text: dom.textContent, language: dom.getAttribute("data-language") || "text/plain" }) },
    ],
    toDOM(node) {
      return ["pre", { "data-language": node.attrs.language }, node.attrs.text]
    }
  };

  const math: NodeSpec = {
    group: 'inline',
    content: 'text*',
    inline: true,
    code: true,
    draggable: true,
    defining: true,
    atom: true,
    attrs: { id: idAttr, tex: { default: '' }, style: { default: 'inline' }, label: { default: undefined } },
    toDOM(node) {
      return ['math', { 'data-id': node.attrs.id, id: node.attrs.id, 'data-tex': node.attrs.tex, 'data-style': node.attrs.style, 'data-label': node.attrs.label }, 0];
    },
    // @ts-ignore can not be string
    parseDOM: [{ tag: 'math', getAttrs: (dom: HTMLElement) => ({ id: dom.getAttribute('data-id'), tex: dom.getAttribute('data-tex'), style: dom.getAttribute('data-style') }), preserveWhitespace: 'full' }]
  };

  const citation: NodeSpec = {
    attrs: { source: { default: null }, style: { default: 'apa' }, id: idAttr },
    inline: true,
    content: 'inline*',
    marks: '_',
    draggable: true,
    selectable: true,
    isolating: false,
    atom: true,
    group: 'inline',
    toDOM(node) {
      return ['cite', { 'data-source': node.attrs.source, 'data-style': node.attrs.style }, 0];
    },
    parseDOM: [{
      tag: 'cite[data-source]',
      // @ts-ignore can not be string
      getAttrs(dom: HTMLElement) {
        return { source: dom.getAttribute('data-source'), style: dom.getAttribute('data-style') };
      }
    }]
  };

  const anchorMark: MarkSpec = {
    attrs: { href: { default: null }, title: { default: null }, id: { default: null } },
    content: 'inline*',
    inline: true,
    selectable: false,
    defining: true,
    group: 'inline',
    toDOM(node) {
      return ['a', { 'href': node.attrs.href, title: node.attrs.title, 'data-id': node.attrs.id }, 0];
    },
    parseDOM: [{
      tag: 'a[href]:not([data-type=xref])',
      // @ts-ignore can not be string
      getAttrs(dom: HTMLElement) {
        return { href: dom.getAttribute('href'), title: dom.getAttribute('title'), id: dom.getAttribute('data-id') };
      }
    }]
  };

  const image: NodeSpec = {
    inline: true,
    attrs: {
      src: { default: null },
      alt: { default: null },
      title: { default: null },
      width: { default: null },
      height: { default: null },
      id: idAttr,
      metaData: { default: null }
    },
    group: 'inline',
    draggable: true,
    parseDOM: [{
      // @ts-ignore can not be string
      tag: 'img[src]', getAttrs(dom: HTMLElement) {
        return {
          src: dom.getAttribute('src'),
          title: dom.getAttribute('title'),
          alt: dom.getAttribute('alt'),
          ...readID(dom)
        }
      }
    }],
    toDOM(node) { let { src, alt, title, id, width, height } = node.attrs; return ['img', { src, alt, title, id, width, height }] }
  };

  const blockquote: NodeSpec = {
    content: 'block+',
    group: 'block',
    marks: '_',
    defining: true,
    parseDOM: [{ tag: 'blockquote' }],
    toDOM() { return ['blockquote', 0] }
  };

  const placeHolder: NodeSpec = {
    content: '',
    selectable: true,
    inline: false,
    draggable: true,
    isolating: false,
    atom: true,
    group: 'block',
    attrs: { id: idAttr, type: { default: 'logo' }, label: { default: 'Logo' } },
    toDOM(node) { return ['div', { id: node.attrs.id, type: node.attrs.type, label: node.attrs.label }]; }
  };

  const link: NodeSpec = {
    attrs: { type: {}, href: {} },
    inline: true,
    content: 'text*',
    group: 'inline',
    selectable: false,
    draggable: true,
    toDOM(node) {
      return ['a', { 'data-type': node.attrs.type, 'href': node.attrs.href, 'reference-format': node.attrs['reference-format'] }, 0];
    },
    parseDOM: [{
      tag: 'a[href][data-type=xref]',
      // @ts-ignore
      getAttrs(dom: HTMLElement) {
        return { type: dom.getAttribute('data-type'), href: dom.getAttribute('href'), 'reference-format': dom.getAttribute('reference-format') };
      }
    }]
  };

  const horizontal_rule: NodeSpec = {
    group: 'block',
    parseDOM: [{ tag: 'hr' }],
    toDOM() { return ['hr'] }
  };

  // Marks
  const superscriptMark: MarkSpec = {
    toDOM() { return ['sup']; },
    parseDOM: [{ tag: 'sup' }]
  };

  const subscriptMark: MarkSpec = {
    toDOM() { return ['sub']; },
    parseDOM: [{ tag: 'sub' }]
  };

  const bdiMark: MarkSpec = {
    toDOM() { return ['bdi']; },
    parseDOM: [{ tag: 'bdi' }]
  };

  const tagsMark: MarkSpec = {
    attrs: { tags: { default: [] } },
    inclusives: false,
    toDOM(mark) { return ['span', { 'data-tags': JSON.stringify(mark.attrs.tags.map(tag => tag.key).join(' ')) }]; },
    parseDOM: [{ tag: 'span[data-tags]' }]
  };

  titleOnlySchema = new Schema({
    nodes: {
      doc: extendObj(freeDocument, { content: 'header' }),
      header,
      heading: heading(),
      subtitle,
      text: basicNodes.text
    },
    marks: {
      [SFMarkType.superscript]: superscriptMark,
      [SFMarkType.subscript]: subscriptMark,
      [SFMarkType.emphasis]: basicMarks.em,
      [SFMarkType.strong]: basicMarks.strong,
      [SFMarkType.bdi]: bdiMark,
      [SFMarkType.tags]: tagsMark
    }
  } as any);

  const tableNodeList = tableNodes({
    tableGroup: 'block',
    cellContent: '(paragraph | ordered_list | bullet_list | figure | blockquote)*',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom: Element) { return (dom as HTMLElement).style.backgroundColor || null },
        setDOMAttr(value, attrs) { if (value) attrs.style = (attrs.style || '') + `background-color: ${value};` }
      }
    }
  });
  
  tableNodeList[SFNodeType.table].attrs = {
    ...(tableNodeList.table.attrs || {}),
    id: idAttr
  };

  tableNodeList[SFNodeType.table_row].attrs = {
    ...tableNodeList[SFNodeType.table_row].attrs,
    id: idAttr,
  };
  
  tableNodeList[SFNodeType.table].marks = '_';
  tableNodeList[SFNodeType.table_cell].marks = '_';
  tableNodeList[SFNodeType.table_header].marks = '_';

  const marks: { [key: string]: MarkSpec } = {
    [SFMarkType.tags]: tagsMark,
    [SFMarkType.anchor]: anchorMark,
    [SFMarkType.emphasis]: basicMarks.em,
    [SFMarkType.strong]: basicMarks.strong,
    [SFMarkType.superscript]: superscriptMark,
    [SFMarkType.subscript]: subscriptMark,
    [SFMarkType.bdi]: bdiMark,
  };

  const nodes: { [key: string]: NodeSpec } = {
    [SFNodeType.paragraph]: paragraph, // should be first to be the default type
    [SFNodeType.part]: part,
    [SFNodeType.heading]: heading('(text | footnote)*'),
    [SFNodeType.subtitle]: subtitle,
    [SFNodeType.header]: header,
    [SFNodeType.document]: documentWithHeading,
    [SFNodeType.image]: image,
    [SFNodeType.horizontalRule]: horizontal_rule,
    [SFNodeType.blockquote]: blockquote,
    [SFNodeType.pageBreak]: pageBreak,
    [SFNodeType.placeholder]: placeHolder,
    // sidebar,
    // admonition,
    // iframe,
    [SFNodeType.label]: label,
    [SFNodeType.caption]: caption,
    // mention,
    // example,
    [SFNodeType.code]: code,
    [SFNodeType.math]: math,
    [SFNodeType.text]: basicNodes.text,
    [SFNodeType.hardBreak]: basicNodes.hard_break,
    [SFNodeType.citation]: citation,
    [SFNodeType.link]: link,
    [SFNodeType.footnote]: footnote,
    [SFNodeType.list_item]: { ...listItem, content: 'block*', marks: '_' },
    [SFNodeType.bullet_list]: { ...bulletList, content: 'list_item+', group: 'block' },
    [SFNodeType.ordered_list]: { ...orderedList, content: 'list_item+', group: 'block' }
  };

  baseSchema = new Schema({
    nodes,
    marks
  });

  sciflowBaseSchema
    = new Schema({
      nodes: baseSchema.spec.nodes
        // @ts-ignore table repo not typed
        .append(tableNodeList)
        .append({ figure }),
      marks
    });

  freeSchema = new Schema({
    nodes: sciflowBaseSchema.spec.nodes
      .update(SFNodeType.document, freeDocument),
    marks
  });

  /** Manuscript has an optional title and is mainly used for imports (or unstructured documents) */
  manuscriptSchema
    = new Schema({
      nodes: baseSchema.spec.nodes
        .update(SFNodeType.document, documentWithOptionalHeader)
        // @ts-ignore tables not typed
        .append(tableNodeList)
        .append({ figure }),
      marks
    });

  documentTitleSchema = new Schema({
    nodes: {
      doc: header,
      heading: heading(),
      subtitle,
      text: basicNodes.text
    },
    marks: {
      sup: superscriptMark,
      sub: subscriptMark,
      em: basicMarks.em,
      strong: basicMarks.strong,
      [SFMarkType.tags]: tagsMark,
      bdi: bdiMark
    }
  } as any);

  inlineSchema = new Schema({
    nodes: {
      doc: paragraph,
      text: basicNodes.text,
      citation
    },
    marks: {
      sup: superscriptMark,
      sub: subscriptMark,
      em: basicMarks.em,
      strong: basicMarks.strong,
      bdi: bdiMark,
      [SFMarkType.tags]: tagsMark
    }
  } as any);

  /**
   * A schema that retains docx style information for further processing.
   * @internal
   */
  docx = new Schema({
    nodes: {
      doc: {
        content: 'paragraph*',
        marks: '_',
        toDOM(node) {
          return ['div', 0];
        }
      },
      paragraph: {
        marks: '_',
        attrs: {
          id: { default: null },
          indent: { default: null },
          alignment: { default: null },
          level: { default: null },
          tagList: { default: [] },
          classList: { default: [] }
        },
        toDOM(node) {
          const style = `${node.attrs.indent ? `text-indent: ${node.attrs.indent}em;` : ''}${node.attrs.alignment ? `text-align: ${node.attrs.alignment};` : ''}`;
          const attrs: { style?: string; id?: string; } = {};
          if (style?.length > 0) { attrs.style = style; }
          if (node.attrs.level) {
            attrs['data-level'] = node.attrs.level;
          }
          if (node.attrs.tagList?.length > 0) {
            attrs['data-tags'] = node.attrs.tagList?.join(' ');
          }
          if (node.attrs.classList?.length > 0) {
            attrs['class'] = node.attrs.classList?.join(' ');
          }
          if (node.attrs.id) {
            attrs['id'] = node.attrs.id;
          }
          return ['p', attrs, 0];
        },
        parseDOM: [{
          tag: 'p',
          getAttrs(dom) {
            return {
              indent: dom.style.textIndent ? parseFloat(dom.style.textIndent) : undefined,
              alignment: dom.style.textAlign,
              level: dom.getAttribute('data-level'),
            };
          }
        }],
        content: 'text*'
      },
      text: { inline: true }
    },
    marks: {
      bold: { toDOM() { return ['b']; }, parseDOM: [{ tag: 'b' }] },
      italic: { toDOM() { return ['i']; }, parseDOM: [{ tag: 'i' }] },
      sup: {
        toDOM() { return ['sup']; },
        parseDOM: [{ tag: 'sup' }]
      },
      sub: {
        toDOM() { return ['sub']; },
        parseDOM: [{ tag: 'sub' }]
      },
      style: {
        attrs: {
          size: { default: null },
          indent: { default: null },
          marginTop: { default: null },
          marginBottom: { default: null },
          direction: { default: null },
          align: { default: null },
          lang: { default: null }
        },
        toDOM(mark) {
          let style = '';
          let attrs: any = {};
          if (mark.attrs.size) style += `font-size: ${mark.attrs.size};`;
          if (mark.attrs.indent) style += `text-indent: ${mark.attrs.indent};`;
          if (mark.attrs.marginTop) style += `margin-top: ${mark.attrs.marginTop};`;
          if (mark.attrs.marginBottom) style += `margin-bottom: ${mark.attrs.marginBottom};`;
          if (mark.attrs.direction) style += `direction: ${mark.attrs.direction};`;
          if (style?.length > 0) { attrs.style = style; }
          return ['span', attrs, 0];
        },
        parseDOM: [{
          tag: 'span[style]',
          getAttrs(dom) {
            const style = dom.getAttribute('style') || '';
            const attrs: any = {};
            const sizeMatch = style.match(/font-size:\s*(\d+)px;/);
            const indentMatch = style.match(/text-indent:\s*(\d+)em;/);
            const marginTopMatch = style.match(/margin-top:\s*(\d+)em;/);
            const marginBottomMatch = style.match(/margin-bottom:\s*(\d+)em;/);
            const directionMatch = style.match(/direction:\s*(ltr|rtl);/);

            if (sizeMatch) attrs.size = parseInt(sizeMatch[1], 10);
            if (indentMatch) attrs.indent = parseInt(indentMatch[1], 10);
            if (marginTopMatch) attrs.marginTop = parseInt(marginTopMatch[1], 10);
            if (marginBottomMatch) attrs.marginBottom = parseInt(marginBottomMatch[1], 10);
            if (directionMatch) attrs.direction = directionMatch[1];

            return attrs;
          }
        }]
      },
      lang: {
        attrs: { lang: {} },
        toDOM(mark) { return ['span', { 'lang': mark.attrs.lang }, 0]; },
        parseDOM: [{
          tag: 'span[lang]',
          getAttrs(dom) { return { lang: dom.getAttribute('lang') }; }
        }]
      }
    }
  });

} catch (e: any) {
  console.error('Could not create schemas', e.message);
  throw e;
}

const schemas: { [name: string]: Schema } = {
  /** A free format schema with optional title mostly used for imports */
  manuscript: manuscriptSchema,
  /** A part twithout restrictions (nor an optional header) */
  free: freeSchema,
  /** Part that starts with a heading */
  chapter: sciflowBaseSchema,
  /** Only title */
  title: documentTitleSchema,
  /** Schema just with inline nodes */
  inline: inlineSchema,
  /** 
   * Docx schema used for pre-processing docx (may change without notice)
   * @internal
   */
  docx
};

/**
 * Validates that a JSON document matches a schema.
 */
const validateJSON = (node: any, schema: string): { ok: true; doc: Node; warnings: any[]; } | { ok: false; errors: any[]; warnings: any[]; } => {
  if (!schemas[schema]) { throw new Error('No such schema: ' + schema); }
  const errors: any[] = [];
  const warnings: any[] = [];

  try {
    const schemaObj = schemas[schema];
    // if the schema requires a heading and none is found we add an empty heading
    if (schemaObj.nodes[SFNodeType.document].spec.content?.startsWith(SFNodeType.heading)) {
      if (node.content && node.content?.[0]?.type !== SFNodeType.heading) {
        warnings.push({ message: 'The chapter did not start with a heading', context: { schema, type: node.type, attrs: node?.attrs } });
        node.content = [schemaObj.nodes[SFNodeType.heading].createAndFill({ level: 1 })?.toJSON(), ...node.content];
      }
    }

    try {
      const doc = Node.fromJSON(schemaObj, node);
      doc.descendants((node) => {
        try {
          node.check();
        } catch (e: any) {
          errors.push({ message: e.message, context: { schema, type: node.type, attrs: node?.attrs } });
        }
      });
      doc.check();
      return {
        ok: true,
        warnings,
        doc
      }
    } catch (e: any) {
      errors.push({ message: e.message, context: { schema, type: node.type, attrs: node?.attrs } });
    }
  } catch (e: any) {
    errors.push({ message: e.message, context: { schema, type: node.type, attrs: node?.attrs } });
  }

  return {
    ok: false,
    warnings,
    errors
  }
}

const fromJSON = (schema: string | Schema) => (json: object) => Node.fromJSON(typeof schema === 'string' ? schemas[schema] : schema, json);
const getDomSerializer = (schema: string | Schema) => DOMSerializer.fromSchema(typeof schema === 'string' ? schemas[schema] : schema)

export {
  fromJSON,
  docx,
  sciflowBaseSchema,
  baseSchema,
  titleOnlySchema,
  documentTitleSchema,
  schemas,
  validateJSON,
  getDomSerializer
};
