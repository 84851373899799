import { Plugin, PluginKey, EditorState, TextSelection, NodeSelection } from 'prosemirror-state';
import { Fragment, Node, Slice } from 'prosemirror-model';
import { canSplit, insertPoint, ReplaceAroundStep } from 'prosemirror-transform';
import { createId, SFNodeType } from '@sciflow/schema';

export const fixProblems = (state) => {
    const tr = state.tr;
    const ids = new Map();
    const getUniqueId = () => {
        const newId = createId();
        if (ids.has(newId)) { return getUniqueId(); }
        ids.set(newId, true);
        return newId;
    }
    const problems = [] as any;

    // check whether the document has a title
    if (tr.doc.firstChild?.type.name !== 'header') {
        problems.push({ problem: 'no-document-header' });
    }

    const schema = state.schema;
    tr.doc.descendants((node, pos, parent) => {

        if (node.type.name === SFNodeType.part) {
            if (node.childCount === 0) {
                problems.push({ problem: 'empty-part', pos, node, parent });
            }
        }

        const forcePart = localStorage.getItem('editor.forcePart');
        if (forcePart === 'true') {
            if (node.type.name === SFNodeType.heading && parent?.type.name === SFNodeType.document && node.attrs?.level === 1) {
                problems.push({ problem: 'force-part', pos, node, parent });
            }
        }

        if (node.type.name === SFNodeType.heading && parent?.type.name === SFNodeType.part && node.attrs?.level === 1) {
            // make sure the heading is the first element
            if (parent?.firstChild !== node) {
                if (parent?.firstChild.textContent?.length > 0) {
                    // skip this for non-empty nodes
                    problems.push({ problem: 'heading-1-inside-part', pos, node, parent });
                }
            }
        }

        if (node.type.name === SFNodeType.figure) {
            // check that every figure has a caption
            const hasCaption = node.content.content?.some((n) => n.type.name === SFNodeType.caption);
        }

        if (node.type.name === SFNodeType.code && parent.type.name !== SFNodeType.figure) {
            // tables should be wrapped in figures
            problems.push({ problem: 'rogue-code-block', pos, node, parent });
        }


        if (node.type.spec.tableRole === 'table' && parent.type.name !== 'figure') {
            // tables should be wrapped in figures
            problems.push({ problem: 'rogue-table', pos, node });
        }
        if (node.type.spec.tableRole === 'cell' || node.type.spec.tableRole === 'header_cell') {
            if (node.childCount === 0) {
                problems.push({ problem: 'empty-cell', pos, node, parent });
            }
        }

        // go through the document tree and set attributes for all nodes that have an id attr.
        if (node.attrs?.id === undefined) { return true; }

        if (node.attrs.id) { ids.set(node.attrs.id, node.type.name); }
        if (node.attrs?.id === null) {
            const id = getUniqueId();
            tr.setNodeMarkup(pos, null, { ...node.attrs, id }, node.marks);
        }

        return true;
    });

    for (let { problem, pos, node, parent } of problems) {

        // make sure we reflect steps already applied
        pos = tr.mapping.map(pos);
        switch (problem) {
            case 'no-document-header':
                if (state.schema.nodes.header) {
                    const header = state.schema.nodes.header.create({}, [state.schema.nodes.heading.create({}, [state.schema.text('Untitled document')])]);
                    tr.insert(0, [header]);
                }
                break;
            case 'rogue-table':
                rogue(tr, 'native-table', state, pos);
                break;
            case 'force-part':
                tr.delete(pos, pos + node.nodeSize);
                tr.insert(pos, schema.nodes[SFNodeType.part].create({}, [node]));
                break;
            case 'rogue-code-block':
                rogue(tr, 'code', state, pos);
                break;
            case 'heading-1-inside-part':
                const partPos = tr.doc.resolve(pos).before();
                const slice = tr.doc.slice(partPos - 1, pos);
                tr.delete(partPos, pos);

                let newContent: Node[] = [];
                slice.content.forEach(node => {
                    if (node.nodeSize > 2) {
                        newContent.push(node);
                    }
                });
                tr.insert(partPos - 2, newContent);

                //tr.setSelection(TextSelection.create(tr.doc, partPos + 2));
                //tr.scrollIntoView();

                break;
            case 'empty-part':
            case 'empty-cell':
                const insert = insertPoint(tr.doc, pos + 1, state.schema.nodes.paragraph);
                if (insert) {
                    const fill = state.schema.nodes.paragraph.createAndFill({ id: createId() });
                    tr.insert(insert, [fill]);
                    tr.setMeta('FIX_PROBLEM', problem);
                } else {
                    console.error('could not find insertion point to add paragraph to empty cell', pos, insert, node, parent);
                }
                break;
        }
    }

    return tr.docChanged ? tr : null;
};

export const rogue = (tr, type, state, pos) => {
    const tableSelection: NodeSelection = new NodeSelection(tr.doc.resolve(pos));
    const figureType = state.schema.nodes.figure;
    const { $from, $to } = tableSelection;
    // create figure slice we can wrap the table into
    const figure = new Slice(Fragment.from([figureType.create({ type: type }, [state.schema.nodes.caption.create({}, [state.schema.nodes.paragraph.createAndFill({ id: createId() })])])]), 0, 0);
    tr.step(new ReplaceAroundStep(
        $from.pos, // replace from
        $to.pos, // replace to
        $from.pos, // start cutting gap at
        $to.pos, // end cutting gap
        figure, // use this slice as a wrapper
        1, // insertion point of the cut cap in the slice
        true)); // replace existing structure
};

export const integrityPluginKey = new PluginKey('integrity');

/*** Makes sure the document is valid. */
export const integrityPlugin = new Plugin({
    key: integrityPluginKey,
    props: {
        handlePaste(view, event, slice: Slice) {
            // TODO clean up Microsoft Word and other formats, styles, scripts
            console.log('Pasted HTML');

            // make sure the slice has fresh ids (otherwise they might duplicate those of a different part)
            slice.content.nodesBetween(0, slice.content.size - 2, (node, pos) => {
                if (node.attrs?.id !== undefined) {
                    // FIXME FE
                    // @ts-ignore
                    node.attrs.id = createId();
                }
                return true;
            });
            return false;
        },
        handleDrop(view, event, slice, moved) {
            if (!moved) {
                const schema = view.state.schema;
                // try to do an xref if needed
                // figure out what kind of element this belongs to
                if (slice.content?.firstChild?.marks.some((mark => mark.type.name === 'anchor'))) {
                    const label = slice.content?.firstChild.textContent;
                    const href = slice?.content?.firstChild?.marks.find((mark => mark.type.name === 'anchor'))?.attrs?.href;
                    const url = new URL(href);
                    const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });

                    if (!eventPos) { return false; }

                    // create a valid xref instead
                    const xref = schema.nodes.link.create({ type: 'xref', href: url.hash.replace('#', '') }, [schema.text(label)]);
                    const tr = view.state.tr;
                    const insertAt = insertPoint(tr.doc, eventPos.pos, schema.nodes.link);
                    if (!insertAt) { return false; }

                    console.log(xref.check(), xref, insertAt);

                    tr.insert(insertAt, xref);
                    tr.setSelection(TextSelection.create(tr.doc, insertAt, xref.nodeSize));
                    view.dispatch(tr);

                    return true;
                }
            }
            return false;
        }
    },
    appendTransaction(transactions, _oldState: EditorState, newState: EditorState) {
        if (!transactions.some((transaction) => transaction.docChanged)) { return null; }
        return fixProblems(newState);
    }
});
