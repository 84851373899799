/*
 * Public API Surface of reference-management
 */

export * from './lib/services/reference-management.service';
export * from './lib/rendering.service';
export * from './lib/reference-management.module';
export * from './lib/store/references.reducer';
export * from './lib/store/references.actions';
export * from './lib/prosemirror/references.plugin';
export * from './lib/prosemirror/commands';
export * from './lib/prosemirror/citation.view';
