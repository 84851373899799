<ng-container *ngIf="menuItems && menuItems.length > 0" data-test="presetmMenu.menuItems">
  <div *ngIf="schema" class="items-center flex-end preset-info">
    <ng-container *ngIf="presetMessage; else showInitial">
      <div class="items-center flex-start">
        <mat-icon fontIcon="info" style="padding-right: 1.2em"></mat-icon>
        <span>{{ presetMessage }}</span>
      </div>
    </ng-container>
    <ng-template #showInitial>
      <div class="initial-message flex-start">
        <span class="initial">{{ initialMessage }}</span>
      </div>
    </ng-template>

    <!-- Begin Menu -->
    <button
      mat-icon-button
      aria-label="Button that chooses a Preset"
      matTooltip="Choose a Preset"
      matTooltipPosition="left"
      [matMenuTriggerFor]="parentMenu"
    >
      <mat-icon
        color="secondary"
        aria-hidden="false"
        aria-label="Button that chooses a Preset"
        fontIcon="bolt"
      ></mat-icon>
    </button>

    <mat-menu #parentMenu>
      <ng-container *ngFor="let item of menuItems">
        <!-- <pre>{{item | json}}</pre> -->
        <ng-container *ngIf="isRootMenu(item.path)">
          <button
            mat-menu-item
            *ngFor="let menuExample of item.examples; let i = index;"
            (click)="applyExample(item, menuExample.data)"
          >
            <span class="items-center" [ngClass]="{'active': menuExample.isActive}">
              {{menuExample.data["_comment"] || menuExample.data}}
            </span>
          </button>
        </ng-container>
        <ng-container *ngIf="!isRootMenu(item.path)">
          <button mat-menu-item [matMenuTriggerFor]="subMenu">
            {{ getLastKey(item.path) | titlecase }}
          </button>
          <mat-menu #subMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let menuExample of item.examples;"
              (click)="applyExample(item, menuExample.data)"
            >
              <span class="items-center" [ngClass]="{'active': menuExample.isActive}">
                {{menuExample.data["_comment"] || menuExample.data}}
              </span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </mat-menu>
  </div>
</ng-container>
