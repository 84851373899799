<form action="" class="hidden-form m2" enctype="multipart/form-data" style="display: none;">
  <input #fileUpload type="file" accept=".bib" (change)="onUpload($event)" class="btn">
</form>

<div class="dca-heading flex items-center justify-between px2" aria-label="reference">
  <div class="h2 flex items-center">
    <span>References</span>
  </div>

  <div class="flex items-center">
    <!-- <button mat-icon-button aria-label="search" (click)="search()">
      <mat-icon>search</mat-icon>
    </button> -->
    <button mat-icon-button matTooltip="Upload bib file" (click)="uploadReferenceFile()">
      <mat-icon>playlist_add</mat-icon>
    </button>
  </div>
</div>
<div class="dca-scrollarea">
  
  <div *ngIf="referenceList$ | async; let references;">
    <div class="citation-card p2" *ngFor="let reference of references" [ngClass]="{ 'mat-elevation-z1': reference.citationsfromEditor }">
      <div class="flex items-center justify-between mxn1">
        <div class="px1 flex" draggable="true" matTooltip="drag reference into document" (dragstart)="dragStart($event, reference)">
          
          <div class="draggable"></div>
          <div class="ml1">
            <p class="m0">{{ reference.bib || reference | json }}</p>
          </div>
        </div>
        <div class="px1 flex flex-column">
         <!--  <button mat-icon-button aria-label="Calendar">
            <mat-icon>calendar_today</mat-icon>
          </button> -->
          <!-- <button mat-icon-button aria-label="Delete">
            <mat-icon>delete</mat-icon>
          </button> -->
        </div>
      </div>
      <mat-expansion-panel class="mat-elevation-z0 mt2" *ngIf="reference.citationsfromEditor">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Citations in text
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-list dense>
            <mat-list-item *ngFor="let citations of reference.citationsfromEditor; index as i" class="cursor-pointer"
            (click)="editCitation(citations)"> {{ i+1 }}. &nbsp; {{citations.textContent}} </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </div>

  </div>

  <div *ngIf="referenceList$ | async; let reference">
    <div class="p2" *ngIf="reference.length === 0">
      <h3>Upload your references</h3>
      <p>The editor is able to format citations automatically when the document is being exported. To do that, it needs to
        learn about your references.</p>
      <a (click)="uploadReferenceFile()" class="uppercase">Upload .bib File</a>
      <a href="#" class="ml2 uppercase">Learn more</a>
    </div>
  </div>
</div>
