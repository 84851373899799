import CSL from 'citeproc';
import { CitationCache, CSLReference, Citation, CitationIDNoteIndexPair, CitationItem, customDisplayFormat, CitationItemOptions, BibliographyFormatting } from './types';
import { getDefaults } from './csl';

const { styleXML, localeXML } = getDefaults();

const styleUrl = 'https://www.zotero.org/styles';
const localUrl = 'https://github.com/citation-style-language/locales/raw/6b0cb4689127a69852f48608b6d1a879900f418b/locales-';

export const getStyleXML = async (id = 'apa'): Promise<string> => {
    const res = await fetch(`${styleUrl}/${id}`);

    if (res.status === 200) {
        return res.text();
    }

    throw new Error('Could not load style');
}

export const getLocaleXML = async (locale = 'en-US') => {
    const res = await fetch(`${localUrl}${locale}.xml`);

    if (res.status === 200) {
        return res.text();
    }

    throw new Error('Could not load locale');
}

export const getCitationOptions = (format: customDisplayFormat): CitationItemOptions => {
    switch (format) {
        case 'author-only':
            return {
                'author-only': true,
                'suppress-author': undefined
            }
        case 'combined':
            return {
                'author-only': true,
                'suppress-author': true
            }
        case 'suppress-author':
            return {
                'author-only': undefined,
                'suppress-author': true
            }
    }

    return {
        'author-only': undefined,
        'suppress-author': undefined
    };
}

/**
 * A stateful reference renderer.
 */
export class ReferenceRenderer {

    id = Math.random().toString(36).slice(0, 8);

    /** The citeproc-js citation renderer */
    private citeproc: CSL.Engine | undefined;

    public footnotes;

    /** Gets the reference it's and note index of already cited references */
    private get pre() {
        return this.citeproc.registry.citationreg.citationByIndex.map((citation, index) => [citation.citationID, index]);
    }

    /** A cache of all rendered citations */
    private citations: {
        [citationID: string]: CitationCache
    } = {};

    constructor(public styleXML: string, public localeXML: string, private references: CSLReference[], options?: { format?: 'text' | 'html' }) {
        this.citeproc = new CSL.Engine({
            retrieveLocale: () => this.localeXML,
            retrieveItem: (id: string) => {
                const reference = this.references.find(ref => ref.id === id);
                if (!reference) { throw new Error('Could not find reference ' + id); }
                // Zotero uses journalAbbreviation and shortTitle instead of container-title-short and title-short
                if (reference?.journalAbbreviation?.length > 0 && !reference?.['container-title-short']) {
                    reference['container-title-short'] = reference.journalAbbreviation;
                }
                if (reference?.shortTitle?.length > 0 && !reference?.['title-short']) {
                    reference['title-short'] = reference.shortTitle;
                }
                return reference;
            }
        }, styleXML);

        this.footnotes = this.citeproc?.cslXml?.dataObj?.attrs?.class === 'note';

        if (options?.format) {
            this.citeproc.setOutputFormat(options?.format);
        }
    }

    /** Renders a bibliography based on all cited references 
     * since creating the renderer. */
    getBibliography(filter?: any): {
        references: string[];
        /** General formating and styling requirements */
        formatting: BibliographyFormatting;
    } {
        // TODO add filter e.g. to create two bibliogafies for web resources and monographs
        // @see https://citeproc-js.readthedocs.io/en/latest/running.html#makebibliography
        const [formatting, references] = this.citeproc.makeBibliography();
        return {
            references,
            formatting
        };
    }

    /**
     * Renders a citation in context of the current document
     * (e.g. rendering will change the render result for following references)
     */
    public renderCitation(citation: Citation): CitationCache {
        return this.addCitationAtEnd(citation);
    }

    /** Adds a citation to the end of the reference list (no pre or post needed) */
    addCitationAtEnd(citation: Citation) {
        return this.addCitationAtPos(citation, this.pre, []);
    }

    /**
     * Returns a rendered citation and the citation's id  for later reference (e.g. to retrieve an updated citation id string after all references have been processed)
     * (!) adding a citation can always affect previous citations (e.g. because same last names were causing ambiguations)
     */
    addCitationAtPos(citation: Citation, pre: CitationIDNoteIndexPair[], post: CitationIDNoteIndexPair[]): CitationCache {

        let isCombined;
        // combined format is not supported by citeproc-js
        // so we build our own string
        if (citation.citationItems?.some(item => item['suppress-author'] && item['author-only'])) {
            citation.citationItems = citation.citationItems.map(item => {
                delete item['suppress-author'];
                delete item['author-only'];

                return item;
            });
            isCombined = true;
        }

        const citationIndex = pre.length;
        // process the reference as normal even if it is combined
        const [result, citations] = this.citeproc.processCitationCluster(citation, pre, post);
        for (let processedCitation of citations) {
            this.citations[processedCitation[2]] = {
                index: processedCitation[0],
                renderedCitation: processedCitation[1],
                citationID: processedCitation[2]
            };
        }

        const citationResult = citations.find(([index]) => index === citationIndex);
        if (!citationResult) { throw new Error('Could not get citation from index'); }

        try {
            if (isCombined) {
                const authorOnly = this.citeproc.previewCitationCluster({
                    ...citation,
                    citationItems: citation.citationItems.map(item => ({ ...item, 'author-only': true }))
                }, pre, post, 'html');
                const supressAuthor = this.citeproc.previewCitationCluster({
                    ...citation,
                    citationItems: citation.citationItems.map(item => ({ ...item, 'suppress-author': true }))
                }, pre, post, 'html');
                return {
                    index: this.citations[citationResult[2]]?.index,
                    renderedCitation: `${authorOnly} ${supressAuthor}`,
                    citationID: this.citations[citationResult[2]]?.citationID
                };
            }
        } catch (e: any) {
            console.error(e);
        }

        return this.citations[citationResult[2]];
    }

    /** Returns a citation that has previously been added */
    getCitation(citationID: string): CitationCache {
        return this.citations[citationID];
    }

    getBibliographyIdMap() {
        const { references, formatting } = this.getBibliographyFromAll();
        return references.map((plainCitation, index) => {
            const id = formatting.entry_ids?.[index]?.[0];
            // we only fetch the first id because we asume that
            // all references have been rendered with only one id
            return {
                id,
                plainCitation
            };
        });
    }

    /** Renders a citation without respect to previous or following citations
     * (!) This is very fast because rules for ibid and others are not used.
     * Do not use this to create an acurate citation in context of a document context.
     * This could be used in an editor context where it is important to understand what 
     * reference was placed  but not necessarily how it is represented in the final publication.
     */
    public renderCitationOutOfContext(citationItems: CitationItem[]): string {
        return this.citeproc.makeCitationCluster(citationItems);
    }

    /**
     * Returns a bibliograpy of all references (even unused).
     */
    public getBibliographyFromAll() {
        this.citeproc.updateItems(this.references.map(ref => ref.id));
        return this.getBibliography();
    }
}

export class SourceField {
    /** Decodes a string in the legacy reference format to a citation. */
    public static fromString(s: string): Citation {
        if (typeof s !== 'string') {
            throw new Error(s + ' is not a string');
        }

        if (s.length === 0) {
            return { citationItems: [], properties: { noteIndex: 0 } };
        }

        if (s.indexOf('%5B') === 0) {
            // encoded [ in the beginning of the string
            s = decodeURI(s);
        }

        if (s.indexOf('[') === 0) {
            // [{  }]
            const info: CitationItem[] = JSON.parse(s);
            if (!Array.isArray(info)) {
                throw new Error('Source must be array but was ' + s);
            }

            // return { citationItems: [{ id: info.map((ref) => { return ref.id; }).toString() }], properties: { noteIndex: 0 } };
            return { citationItems: info.map((ref) => { return ref; }) as unknown as CitationItem[], properties: { noteIndex: 0 } };
        } else {
            return { citationItems: [{ id: s.split(',').filter((v: string) => v !== '').toString() }], properties: { noteIndex: 0 } };
        }
    }

    /** Creates a JSON serialization of all citation items in order */
    public static toString(citation: Citation): string {
        if (!citation) {
            return '';
        }

        return JSON.stringify(citation.citationItems).replace(/"/g, '\"');
    }
}

export class MockRenderer extends ReferenceRenderer {
    constructor(references: any[]) { super(styleXML, localeXML, references); }
}

/** Renders a one-of bibliography by using the order provided */
export const renderStaticBibliography = (references, style, locale) => {
    const proc = new CSL.Engine({
        retrieveItem: (id: string) => references.find(r => r.id === id),
        retrieveLocale: () => locale,
        retrieveStyle: () => style,
    }, style);
    //proc.opt.development_extensions.wrap_url_and_doi = true;
    const ids = references.map(r => r.id);

    proc.updateItems(ids);
    const result = proc.makeBibliography();
    if (!result) { return null; }
    return {
        errors: result[0].bibliography_errors,
        entries: result[1].map((el: string, index: number) => {
            return {
                id: result[0].entry_ids[index][0],
                renderedString: el.trim()
            }
        })
    };
};
