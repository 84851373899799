<div class="menu" *ngIf="data.node?.attrs.role !== 'title'">
    <!-- <button mat-icon-button class="menu-trigger" [matMenuTriggerFor]="menu" matTooltip="Change hierarchy">
    <mat-icon>chevron_right</mat-icon>
</button>
<mat-menu #menu="matMenu"> -->
    <div class="flex flex-column">
        <div class="flex flex-row">
            <button mat-button class="col-10" [matMenuTriggerFor]="headingMenu" matTooltipPosition="above"
                [ngSwitch]="data.node.type.name">
                <b *ngSwitchCase="'heading'">
                    Heading {{ data.node?.attrs.level }}
                </b>
                <b *ngSwitchCase="'paragraph'" class="m1">
                    Paragraph
                </b>
                <b *ngSwitchDefault class="m1">
                    {{ data.node?.type?.name }}
                </b>
            </button>
            <button class="col-2" *ngIf="data.type === 'heading' && data.node.attrs.level === 1" mat-icon-button
                [matMenuTriggerFor]="settingsMenu" aria-label="Open numbering menu" matTooltipPosition="below"
                matTooltip="Settings">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
        <!--         <div class="flex flex-row">
            <button *ngIf="data.node.type.name === 'paragraph' || data.node.attrs.level < 6" mat-button
                matTooltipPosition="before" (click)="setHeadingLevel((data.node.attrs?.level || 0) + 1)"
                matTooltip="Make heading {{(data.node.attrs?.level || 0) + 1}}" class="my1">
                <mat-icon>arrow_drop_up</mat-icon>
                <small> h{{(data.node.attrs?.level || 0) + 1}}</small>
            </button>

            <button *ngIf="data.node.type.name === 'heading' && data.node.attrs?.level > 1"
                (click)="setHeadingLevel((data.node.attrs?.level || 1) - 1)" mat-button matTooltipPosition="after"
                matTooltip="Make heading {{(data.node.attrs?.level || 0) - 1}}" class="my1">
                <mat-icon>arrow_drop_down</mat-icon>
                <small> {{(data.node.attrs?.level || 1) - 1}}</small>
            </button>
            <button *ngIf="data.node.type.name === 'heading' && data.node.attrs?.level === 1"
                (click)="setHeadingLevel((data.node.attrs?.level || 1) - 1)" mat-button matTooltipPosition="after"
                matTooltip="Make paragraph" class="my1">
                <mat-icon>arrow_drop_down</mat-icon>
                <small> p</small>
            </button>
        </div> -->
        <div class="flex flex-row chapter-options col-12 p1" *ngIf="data.node.attrs.level === 1">
            <div class="flex flex-column col-8">
                <p *ngIf="data?.node.attrs.type" class="m0 p0">{{ data?.node.attrs.type }}</p>
                <p *ngIf="data?.node.attrs.placement" class="m0 p0">{{ data?.node.attrs.placement }} placement</p>
                <p *ngIf="data?.node.attrs.numbering" class="m0 p0">{{ data?.node.attrs.numbering }} numbering</p>
                <p *ngIf="!data?.node.attrs.numbering" class="m0 p0">not numbered</p>
                <p *ngIf="data?.node.attrs.role" class="m0 p0">role: {{ data?.node.attrs.role }}</p>
            </div>
        </div>
    </div>

    <mat-menu #headingMenu="matMenu" yPosition="above">
        <button mat-menu-item [disabled]="data.node.type.name === 'paragraph'" (click)="setNodeType('paragraph')"
            [ngClass]="{ selected: data.node.type.name === 'paragraph' }">
            <mat-icon>short_text</mat-icon>
            <span>Paragraph</span>
        </button>
        <button *ngFor="let heading of headings" [disabled]="data.node.attrs.level === heading.level" mat-menu-item
            (click)="setHeadingLevel(heading.level)" class="button"
            [ngClass]="{ selected: (data.node.attrs.level === heading.level) }">
            <small>{{ '#'.repeat(heading.level) }} </small><span>Heading {{heading.level}}</span>
        </button>
    </mat-menu>
    <mat-menu #settingsMenu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="numberingMenu" aria-label="Open numbering menu"
            matTooltip="Change numbering" matTooltipPosition="before" *ngIf="data.node.attrs.level === 1">
            Numbering
        </button>
        <mat-menu #numberingMenu="matMenu">
            <button *ngFor="let numbering of counterStyle" mat-menu-item (click)="setNumbering(numbering.numbering)"
                [ngClass]="{ selected: (data.node.attrs.numbering === numbering.numbering) }">
                <span>{{ numbering.text }}</span>
            </button>
        </mat-menu>
        <button mat-menu-item [matMenuTriggerFor]="hedingType" matTooltipPosition="before"
            matTooltip="Change Heading Type">
            <span>Type</span>
        </button>
        <mat-menu #hedingType="matMenu">
            <button *ngFor="let heading of headingTypes" mat-menu-item (click)="setType(heading.type)"
                [ngClass]="{ selected: (data.node.attrs.type === heading.type) }">
                <mat-icon>{{ heading.icon }}</mat-icon>
                <span>{{ heading.type }}</span>
            </button>
        </mat-menu>
        <button mat-menu-item (click)="addRole()" matTooltipPosition="before" matTooltip="Set role (advanced)">
            <span>Role</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="placementMenu" aria-label="Open placement menu"
            matTooltip="Change placement" matTooltipPosition="before" *ngIf="data.node.attrs.level === 1">
            Placement
        </button>
        <mat-menu #placementMenu="matMenu">
            <button *ngFor="let placement of placements" mat-menu-item (click)="setPlacement(placement.value)"
                [ngClass]="{ selected: (data.node.attrs.placement === placement.value) }">
                <span>{{ placement.label }}</span>
            </button>
        </mat-menu>
    </mat-menu>
    <!-- </mat-menu> -->
</div>