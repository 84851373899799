/**
 * Finds the differences between two objects recursively.
 * @param {object} obj1 - The first object for comparison.
 * @param {object} obj2 - The second object for comparison.
 * @returns {object} An object containing the differences between obj1 and obj2.
 */
export function getObjectDifferences<T>(obj1: T, obj2: T): Partial<T> {
  if (obj1 === obj2) {
    return {}; // No differences
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 !== obj2 ? obj2 : {}; // Return the differing value if not equal
  }

  const differences: Partial<T> = {};

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (const key of keys1) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      const diff = getObjectDifferences(obj1[key], obj2[key]);
      if (Object.keys(diff).length > 0) {
        differences[key] = diff;
      }
    } else {
      differences[key] = obj2[key];
    }
  }

  for (const key of keys2) {
    if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
      differences[key] = obj2[key];
    }
  }

  return Object.keys(differences).length > 0 ? differences : {};
}

/**
 * Recursively extracts key-value pairs from an object along with their paths.
 * @param {object} obj - The object from which to extract key-value pairs.
 * @param {string} [prefix=''] - The prefix to use for the path of each key-value pair.
 * @returns {{[key: string]: number | string | boolean}[]} An array of objects containing key-value pairs and their paths.
 */
export function getPaths(
  obj: object,
  prefix: string = '',
): {[key: string]: number | string | boolean}[] {
  return Object.keys(obj).reduce(
    (acc: {[key: string]: number | string | boolean}[], key: string) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively call getPathsV2 for nested objects
        acc.push(...getPaths(obj[key], fullPath));
      } else if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        // Push the key-value pair as an object
        const entry: {[key: string]: number | string | boolean} = {};
        entry[fullPath] = obj[key];
        acc.push(entry);
      }
      return acc;
    },
    [],
  );
}
