import { createSelector } from '@ngrx/store';
import { SimplifiedManuscriptFile } from '@sciflow/schema';

import { selectEditorState } from 'editor';
import { selectAuthorManagementState } from 'author-management';
import { selectUsedReferences } from 'reference-management';
import { selectExportState, selectTemplate } from 'export';
import { CSLReference } from '../../../../../libs/cite/src/types';

export const selectAppState = (state): any => state;

/**
 * Selects everything from the store (all modules) that needs to be saved.
 */
export const selectManuscriptFile = createSelector(
    selectAppState,
    (state: any) => {

        const template = selectTemplate(state);
        const editor = selectEditorState(state);
        const authorManagement = selectAuthorManagementState(state);
        const exportState = selectExportState(state);

        if (editor.instances.length === 0) {
            return null;
        }

        const instance = editor.instances[0];
        const document = instance.state.doc;
        const references = selectUsedReferences(state).map(ref => ref?.csl).filter(ref => ref != undefined);

        const manuscript: SimplifiedManuscriptFile = {
            document,
            authors: authorManagement.authors,
            references: (references|| []) as CSLReference[],
            files: instance.state.files || [],
            metaData: exportState.metaData,
            lastModified: Date.now(),
            template: template?.slug
        };

        return { manuscript, id: instance.id, projectId: instance.projectId, previousVersion: instance.version };
    }
);

export const selectEditorInstance = createSelector(
    selectAppState,
    (state: any) => {
        const editor = selectEditorState(state);
        if (editor.instances.length === 0) {
            return null;
        }

        return editor.instances[0];
    }
)

export const selectIsDocumentLoaded = createSelector(
    selectEditorState,
    (state) => {
        return state?.instances?.length > 0;
    }
);

export const selectOpenFile = createSelector(
    selectAppState,
    (state: any) => {
        const editor = selectEditorState(state);
        const instance = editor?.instances?.[0];
        const lastModified = instance?.lastModified;
        const dirty = editor?.dirty;

        if (!instance) { return null; }

        return {
            isDirty: dirty === true,
            lastModified,
            projectId: instance?.projectId,
            id: instance?.id,
            version: instance?.version
        };
    }
)
