import {Component, Input} from '@angular/core';
import {AdmonitionType} from './admonition.types';

/**
 * SfoAdmonitionComponent is a reusable UI component that displays an admonition panel with a configurable type, title, and icon. It can also be expanded or collapsed.
 */
@Component({
  selector: 'sfo-admonition',
  templateUrl: './admonition.component.html',
  styleUrls: ['./admonition.component.scss'],
})
export class SfoAdmonitionComponent {
  private _expanded: boolean = false;
  private _hideToggle: boolean = false;

  /**
   * The type of admonition, which determines the overall style and icon.
   * Defaults to 'default' if not provided.
   * @type {AdmonitionType}
   */
  @Input() type: AdmonitionType = 'default';
  /**
   * The title displayed in the admonition panel.
   * @type {string | undefined}
   */
  @Input() title: string | undefined = undefined;

  /**
   * The icon displayed in the admonition panel. If not provided, an icon is selected based on the `type` property, if the `type` is provided.
   * @type {string}
   */
  @Input() icon: string | undefined = undefined;
  /**
   * Sets the expanded state of the admonition panel. If the value is an empty string,
   * it will be considered `true` (expanded).
   * @param {boolean | string} value - The new expanded state.
   */
  @Input() set expanded(value: boolean | string) {
    this._expanded = value === '' || !!value;
  }

  /**
   * Sets whether the toggle button should be hidden. If the value is 'true' or an empty string,
   * the toggle button will be hidden.
   * @param {boolean | string} value - The new state for hiding the toggle.
   */
  @Input() set hideToggle(value: boolean | string) {
    this._hideToggle = value === '' || !!value;
  }

  get expanded(): boolean {
    return this._expanded;
  }

  get hideToggle(): boolean {
    return this._hideToggle;
  }

  get preset(): string {
    return this.type ? `admonition-${this.type}` : 'admonition-default';
  }

  get computedIcon(): string {
    return this.icon || this.iconMap[this.type];
  }

  private readonly iconMap: Record<AdmonitionType, string> = {
    info: 'info',
    success: 'done',
    warning: 'warning',
    tip: 'lightbulb',
    note: 'note',
    danger: 'error',
    default: '',
  };
}
