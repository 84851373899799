import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'sfo-footnote',
  templateUrl: './footnote.component.html',
  styleUrls: ['./footnote.component.scss']
})
export class FootnoteComponent implements OnInit {
  @Output() closePanel = new EventEmitter<void>();

  constructor(private bottomSheetRef: MatBottomSheetRef<FootnoteComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    bottomSheetRef.disableClose = true;
  }

  updateDocument(node: Node): void {
    this.bottomSheetRef.dismiss(node);
  }

  close(): void {
    this.bottomSheetRef.dismiss(null);
  }

  ngOnInit(): void {
  }

}
