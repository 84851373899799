import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SfoUiJSONSchema7} from '../../../../metadata.model';

@Component({
  selector: 'sfo-boolean-input',
  templateUrl: './boolean-input.component.html',
})
export class BooleanInputComponent {
  @Input() schema!: SfoUiJSONSchema7;
  @Input() aFormControl!: FormControl;
  @Input() defaultValue: unknown;

  ngOnInit(): void {
    const currentValue = this.aFormControl.value;
  
    if (currentValue === null || currentValue === undefined) {
      const defaultValue = this.defaultValue ?? this.schema?.default;
      if (defaultValue !== undefined) {
        this.aFormControl.setValue(defaultValue, { emitEvent: false });
      }
    }
  }
}
